<template>
  <div class="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
    <section aria-labelledby="integrations_details_heading">
      <div class="shadow sm:rounded-md sm:overflow-hidden">
        <div class="bg-white py-6 px-4 sm:p-6">
          <div class="mb-4">
            <h2 id="user_details_heading" class="text-lg leading-6 font-medium text-gray-900">
              Integrations
            </h2>
            <p class="mt-1 text-sm text-gray-500">
              Select an integration to synchronize it automatically
            </p>
          </div>
          <!-- Connect -->
          <div v-if="currentIntegration" class="max-w-lg mx-auto mt-6">
            <Loader v-if="loading" text="Loading..." />
            <div class="bg-gray-50 overflow-hidden shadow rounded-lg relative" v-else>
              <div class="absolute top-1 right-4 text-lg text-gray-400 cursor-pointer hover:text-gray-700" @click="hideIntegration()">
                x
              </div>
              <div class="px-12 py-12 sm:p-6">
                <img class="mx-auto w-32 mt-2" :src="getImage(currentIntegration.id)" :alt="currentIntegration.name">
                <p class="text-center mx-auto text-gray-500 mt-4 text-sm">
                  Connect your {{ currentIntegration.name }} account
                  <span
                    v-if="currentIntegration.beta"
                    class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800 absolute top-2 right-2">
                    beta
                  </span>
                </p>
              </div>
              <div class="bg-white px-6 py-6 sm:px-12">
                <div v-if="showOtp">
                  <p class="text-center text-gray-600 text-lg mb-2">
                    We need an extra code to connect
                  </p>
                  <p class="text-center text-red-400 text-sm mb-4">
                    <ExclamationTriangleIcon class="mb-1 mr-1 w-5 h-5 inline" /> Please note that if you keep multi-factor authentication enabled, we will not be able to synchronize in real time {{ currentIntegration.name }}. You will be asked to reauthenticate frequently.
                  </p>
                </div>
                <p v-if="error" class="text-center text-red-600 mb-4">
                  {{ error }}
                </p>
                <div class="sm:col-span-3" v-if="!showOtp">
                  <label for="username" class="block text-sm font-medium text-gray-700">
                    Username
                  </label>
                  <div class="mt-1 mb-4">
                    <input
                      v-model="username"
                      type="text"
                      name="username"
                      id="username"
                      autocomplete="username"
                      class="shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      >
                  </div>
                </div>
                <div class="sm:col-span-3" v-if="!showOtp">
                  <label for="password" class="block text-sm font-medium text-gray-700">
                    Password
                  </label>
                  <div class="mt-1">
                    <input
                      @keyup.enter="connect(currentIntegration.id)"
                      v-model="password"
                      type="password"
                      name="password"
                      id="password"
                      autocomplete="password"
                      class="shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-sm border-gray-300 rounded-md">
                  </div>
                </div>
                <div class="sm:col-span-3 mb-4" v-if="showOtp">
                  <label for="oneTimePassword" class="block text-sm font-medium text-gray-700">
                    One time password
                  </label>
                  <div class="mt-1">
                    <input v-model="oneTimePassword" placeholder="123456" type="text" name="oneTimePassword" id="oneTimePassword" autocomplete="none" class="shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-sm border-gray-300 rounded-md">
                  </div>
                </div>
                <!-- Connect button -->
                <div class="mt-6">
                  <button @click="connect(currentIntegration.id)" class="group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                    <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                      <!-- Heroicon name: solid/lock-closed -->
                      <svg class="h-5 w-5 text-emerald-500 group-hover:text-emerald-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
                      </svg>
                    </span>
                    Connect {{ currentIntegration.name }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- Integrations list -->
          <div
            v-else
            class="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:py-2 lg:px-8">
            <div class="mt-4 grid grid-cols-2 gap-4 md:grid-cols-4 lg:mt-2">
              <div
                @click="showIntegration(integration)"
                class="col-span-1 flex justify-center py-8 px-8 rounded bg-gray-50 relative"
                :class="[
                  { 'opacity-50' : integration.soon },
                  { 'cursor-pointer hover:bg-gray-100': !integration.soon && !isConnected(integration.id) },
                  { 'border-emerald-500 border bg-emerald-50 cursor-pointer hover:bg-emerald-100 hover:border-emerald-400': isConnected(integration.id) === 'valid' },
                  { 'border-red-500 border bg-red-50 cursor-pointer hover:bg-red-100 hover:bordr-red-600': isConnected(integration.id) && isConnected(integration.id) !== 'valid' }
                ]"
                v-for="integration in integrations"
                :key="integration.name">
                <img class="max-h-12" :src="getImage(integration.id)" :alt="integration.name">
                <span
                  v-if="integration.soon"
                  class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800 absolute bottom-1 right-1">
                  coming soon
                </span>
                <span
                  v-else-if="integration.beta"
                  class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800 absolute top-2 right-2">
                  beta
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import Loader from '@/components/Loader'
import {
  ExclamationTriangleIcon
} from '@heroicons/vue/24/outline'
export default {
  name: 'IntegrationsSettings',
  components: {
    ExclamationTriangleIcon,
    Loader
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['trialLeft'])
  },
  data () {
    return {
      username: '',
      password: '',
      oneTimePassword: '',
      currentIntegration: null,
      showOtp: false,
      error: null,
      connected: [],
      integrations: [{
        name: 'Degiro',
        id: 'degiro',
        beta: true
      }, {
        name: 'Interactive Brokers',
        id: 'interactive-brokers',
        soon: true
      }, {
        name: 'Fidelity',
        id: 'fidelity',
        soon: true
      }, {
        name: 'Vanguard',
        id: 'vanguard',
        soon: true
      }, {
        name: 'Charles Schwab',
        id: 'charles-schwab',
        soon: true
      }, {
        name: 'M1 Finance',
        id: 'm1-finance',
        soon: true
      }, {
        name: 'TD Ameritrade',
        id: 'td-ameritrade',
        soon: true
      }, {
        name: 'Robinhood',
        id: 'robinhood',
        soon: true
      }, {
        name: 'Boursorama',
        id: 'boursorama',
        soon: true
      }, {
        name: 'Etoro',
        id: 'etoro',
        soon: true
      }, {
        name: 'SAXO Banque',
        id: 'saxobanque',
        soon: true
      }, {
        name: 'Bourse Direct',
        id: 'bourse-direct',
        soon: true
      }, {
        name: 'HSBC',
        id: 'hsbc',
        soon: true
      }, {
        name: 'E*trade',
        id: 'etrade',
        soon: true
      }, {
        name: 'Trading212',
        id: 'trading-212',
        soon: true
      }]
    }
  },
  async mounted () {
    const a = await this.$http.get('/integrations')
    this.connected = a.data
  },
  methods: {
    ...mapMutations(['SHOW_UPGRADE']),
    getImage (id) {
      return require(`../../assets/images/integrations/${id}.svg`)
    },
    hideIntegration () {
      this.currentIntegration = null
    },
    showIntegration (integration) {
      if (integration.soon) return false
      if (this.user.plan === 'free' && !this.trialLeft) this.SHOW_UPGRADE(`integration-${integration.id}`)
      if (this.isConnected(integration.id)) {
        const int = this.connected.find(i => i.name === integration.id)
        this.$router.push(`/settings/integrations/${int._id}`)
      }
      this.currentIntegration = integration
    },
    isConnected (id) {
      const int = this.connected.find(i => i.name === id)
      if (int && !int.error) return 'valid'
      else if (int && int.error) return int.error
      return false
    },
    getUsername (id) {
      const int = this.connected.find(i => i.name === id)
      if (int && int.credentials) return int.credentials.username
      return null
    },
    async connect (name) {
      this.loading = true
      this.error = null
      if (!this.username.length || !this.password.length) {
        this.error = 'Please complete all fields'
      } else if (this.showOtp && !this.oneTimePassword.length) {
        this.error = 'Please enter one time passowrd'
      }
      const payload = { name, username: this.username, password: this.password }
      if (this.oneTimePassword) payload.oneTimePassword = this.oneTimePassword
      const res = await this.$http.post('/integration', payload)
      this.loading = false
      if (res.data === 'badCredentials') this.error = 'Please check your credentials'
      else if (res.data === 'error' && this.showOtp) this.error = 'Invalid code, please try again'
      else if (res.data === 'totpNeeded') this.showOtp = true
      else if (res.data.success) {
        this.hideIntegration()
        const a = await this.$http.get('/integrations')
        this.connected = a.data
        const int = this.connected.find(i => i.name === name)
        this.$router.push(`/settings/integrations/${int._id}`)
      }
    }
  }
}
</script>
