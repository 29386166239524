<template>
  <div class="bg-white shadow rounded-lg mt-4">
    <div class="px-4 py-5 sm:p-6">
      <div class="sm:flex sm:items-start">
        <div class="mt-2 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <h3 class="text-lg leading-6 font-medium text-gray-900 sm:-ml-5" id="modal-headline">
            <ArrowLeftIcon class="w-5 h-5 mr-4 text-gray-300 float-left hover:text-emerald-600 cursor-pointer" @click="$emit('cancel')" />
            Add transactions
          </h3>
          <div class="mt-1 mb-5 ml-4">
            <p class="text-sm leading-5 text-gray-500">
              Add transactions each time you buy or sell stocks in your portfolio
            </p>
          </div>
          <div class="sm:grid grid-cols-12 hidden gap-1 text-gray-500 text-xs mt-2">
            <div class="flex items-center p-1 sm:col-span-2">
              Date
            </div>
            <div class="flex items-center p-1 sm:col-span-3">
              Stock Name
            </div>
            <div class="flex items-center p-1 sm:col-span-1">
              Operation
            </div>
            <div class="flex items-center p-1 sm:col-span-1">
              Quantity
            </div>
            <div class="flex items-center p-1 sm:col-span-2">
              Price
            </div>
            <div class="flex items-center p-1 sm:col-span-2">
              Fee
            </div>
            <div class="flex items-center p-1 sm:col-span-1"></div>
          </div>
          <div class="grid grid-cols-1 mt-4 sm:mt-0 sm:grid-cols-12 gap-2 p-1 text-sm border-b sm:border-none border-gray-200" v-for="(t, i) in transactions" :key="i">
            <DatePicker class="sm:col-span-2" @select="selectDate" />
            <StockSearch :i="i" @select="selectStock" :class="[
                'sm:col-span-3',
                { 'border-red-400': showErrors && !t.stockId }
              ]" />
            <select
              v-model="t.type"
              name="type"
              class="sm:col-span-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 placeholder-gray-400 focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm rounded-md">
              <option v-for="type in types" :key="type" :value="type">{{ type }}</option>
            </select>
            <input v-if="!['dividend', 'fee'].includes(t.type)" type="number" min="1" step="1" v-model="t.units" placeholder="Quantity" :class="[
                'sm:col-span-1 block w-full text-base border-gray-300 placeholder-gray-400 focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm rounded-md',
                { 'border-red-400': showErrors && (!t.units || isNaN(t.units) || t.units <= 0) }
              ]" />
            <div v-else class="sm:col-span-1 block w-full text-base sm:text-sm rounded-md bg-gray-100 border-gray-200 border text-gray-400 p-2 align-middle">
              -
            </div>
            <div class="sm:col-span-2 relative">
              <input type="text" v-model="t.price" placeholder="Price" :class="[
                  'block w-full pl-3 pr-10 py-2 text-base border-gray-300 placeholder-gray-400 focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm rounded-md',
                  { 'border-red-400': showErrors && (!t.price || isNaN(t.price) || t.price < 0) }
                ]" />
              <div v-if="t.currency" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span class="text-gray-400 sm:text-sm" id="price-currency">
                  {{ t.currency }}
                </span>
              </div>
            </div>
            <div class="sm:col-span-2 relative">
              <input type="text" v-model="t.fee" placeholder="Fee" :class="[
                  'block w-full pl-3 pr-10 py-2 text-base border-gray-300 placeholder-gray-400 focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm rounded-md',
                  { 'border-red-400': showErrors && (!t.fee || isNaN(t.fee) || t.price < 0) }
                ]" />
              <div v-if="t.currency" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span class="text-gray-400 sm:text-sm" id="fee-currency">
                  {{ t.currency }}
                </span>
              </div>
            </div>
            <div class="sm:col-span-1 flex py-2 sm:space-x-3 pl-2 flex-row-reverse sm:flex-row mr-2">
              <DocumentDuplicateIcon class="w-5 h-5 text-gray-300 hover:text-emerald-600 cursor-pointer" @click="duplicateTransaction(t)" />
              <TrashIcon v-if="transactions.length > 1" class="w-5 h-5 mr-2 text-gray-300 hover:text-emerald-600 cursor-pointer" @click="deleteTransaction(i)" />
            </div>
          </div>
          <DividerButton class="mt-6 w-full mx-auto" text="Add another transaction" @action="addTransaction" />
        </div>
      </div>
    </div>
    <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
      <span class="flex w-full rounded-md sm:ml-3 sm:w-auto">
        <button @click="createTransactions()" type="button" class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 text-base leading-6 font-medium text-white bg-emerald-600 hover:bg-emerald-500 focus:outline-none focus:shadow-outline-emerald focus:border-emerald-700 active:bg-emerald-700 transition ease-in-out duration-150 sm:text-sm sm:leading-5">
          Add Transactions
        </button>
      </span>
      <span class="mt-3 flex w-full rounded-md sm:mt-0 sm:w-auto">
        <button @click="cancel()" type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
          Cancel
        </button>
      </span>
    </div>
  </div>
</template>

<script>
import DatePicker from '@/components/DatePicker'
import StockSearch from '@/components/StockSearch'
import DividerButton from '@/components/DividerButton'
import { ArrowLeftIcon, DocumentDuplicateIcon, TrashIcon } from '@heroicons/vue/24/outline'

export default {
  name: 'AddTransactionsManual',
  props: {
    portfolioId: {
      type: String,
      default: null
    }
  },
  components: {
    ArrowLeftIcon,
    DatePicker,
    DividerButton,
    StockSearch,
    TrashIcon,
    DocumentDuplicateIcon
  },
  data () {
    return {
      types: ['buy', 'sell', 'dividend', 'split', 'reversesplit', 'fee'],
      dropdownShow: null,
      showOptions: false,
      transaction: {
        symbol: null,
        type: 'buy',
        price: null,
        units: 1,
        fee: 0,
        stockId: null,
        currency: null
      },
      transactions: [],
      date: null,
      stocks: [],
      search: {},
      showErrors: false
    }
  },
  mounted () {
    this.addTransaction()
  },
  methods: {
    addTransaction () {
      this.transactions.push(Object.assign({}, this.transaction))
    },
    createTransactions () {
      for (const t of this.transactions) {
        if (!t.date) t.date = this.date
        t.portfolioId = this.portfolioId
        t.source = 'manual'
        if (['dividend', 'fee'].includes(t.type)) t.units = 0
        if (!t.stockId || (!['dividend', 'fee'].includes(t.type) && !t.units) || !t.price || isNaN(t.units) || isNaN(t.price) || t.units < 0 || t.price < 0) {
          this.showErrors = true
          return
        }
      }
      return this.$http.post('/transactions', this.transactions).then(res => {
        this.$emit('created')
      })
    },
    findStock (q) {
      const path = q ? `/stock?q=${q}` : '/stock'
      this.$http.get(path).then(res => {
        this.stocks = res.data
      })
    },
    selectStock (stock, i) {
      this.transactions[i].symbol = stock.symbol
      this.transactions[i].stockId = stock._id
      this.transactions[i].price = stock.price
      this.transactions[i].currency = stock.currency
      this.search[i] = stock.name
      this.dropdownShow = null
    },
    cancel () {
      this.$emit('cancel')
    },
    setActiveSearch (i) {
      this.search[i] = null
      if (this.showOptions && this.dropdownShow !== i) this.showOptions = false
      this.dropdownShow = i
      this.findStock()
    },
    selectDate (date) {
      this.date = date
    },
    deleteTransaction (i) {
      this.transactions.splice(i, 1)
    },
    duplicateTransaction (transaction) {
      this.transactions.push(Object.assign({}, transaction))
    }
  },
  watch: {
    search: {
      handler (search) {
        return this.findStock(search[this.dropdownShow])
      },
      deep: true
    }
  }
}
</script>
