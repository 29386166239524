<template>
  <div class="bgdark min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-emeraldlight">
    <FullLoader v-if="loading" text="Activating your subscription..." type="tacos" />
    <div class="w-full min-w-64 -mt-12 sm:-mt-32" v-else-if="sent">
      <div class="w-full px-4">
        <div class="mb-12">
          <EnvelopeIcon class="w-20 h-20 sm:w-32 sm:h-32 mx-auto mb-5 font-thin text-gray-700 opacity-10" />
          <h2 class="mt-2 text-center text-2xl sm:text-4xl leading-9 font-bold text-emerald-800">
            You Lifetime Premium account has been activated!
          </h2>
          <h2 class="mt-2 sm:mt-6 text-center text-base sm:text-xl leading-9 font-light text-emerald-700 w-full xxl:w-4/12 mx-auto">
            Please check your e-mail {{ form.email }} and click on the link to sign in
          </h2>
        </div>
      </div>
    </div>
    <div class="w-full sm:w-4/12 xxl:w-1/5 min-w-64 sm:-mt-32" v-else>
      <a href="/">
        <ArrowLeftIcon class="w-8 h-8 absolute top-4 left-4 text-gray-400 hover:text-emerald-600" />
      </a>
      <div class="mt-32">
        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-xl">
          <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 text-left">
            <img src="@/assets/images/appsumo.svg" class="w-20 mb-2 mx-auto" alt="AppSumo" />
            <img src="@/assets/images/bean.svg" class="w-16 mb-3 mx-auto" alt="Beanvest" />
            <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">Hey Sumo-lings!</h2>
            <div class="text-sm text-center text-gray-500 mt-4 mb-5 leading-relaxed">
              I'm really happy you chose to join Beanvest and hope it will help you become a sucessful investor.
              After filling the form you will receive an email to activate your lifetime access.
              Double check your email below before submitting 😉
            </div>
            <div v-if="error" class="text-red-600 mb-5 text-center">{{ error }}</div>
            <div class="space-y-3">
              <div>
                <label for="first_name" class="block text-sm font-medium text-gray-700">
                  First name
                </label>
                <div class="mt-1">
                  <input
                    v-model="form.first_name"
                    id="first_name"
                    name="first_name"
                    type="first_name"
                    autocomplete="first_name"
                    required=""
                    class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm" />
                </div>
              </div>
              <div>
                <label for="email" class="block text-sm font-medium text-gray-700">
                  Last name
                </label>
                <div class="mt-1">
                  <input
                    v-model="form.last_name"
                    id="last_name"
                    name="last_name"
                    type="last_name"
                    autocomplete="last_name"
                    required=""
                    class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm" />
                </div>
              </div>
              <div>
                <label for="email" class="block text-sm font-medium text-gray-700">
                  Email address
                </label>
                <div class="mt-1">
                  <input
                    v-model="form.email"
                    id="email"
                    name="email"
                    type="email"
                    autocomplete="email"
                    required=""
                    class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm"
                    :class="[
                      { 'border-red-500 focus:bg-white focus:border-red-500': error },
                      { 'border-emerald-500 focus:bg-white focus:border-emerald-500': isValid }
                    ]" />
                </div>
              </div>
              <div>
                <label for="coupon" class="block text-sm font-medium text-gray-700">
                  AppSumo code
                </label>
                <div class="mt-1">
                  <input
                    v-model="form.code"
                    id="code"
                    name="code"
                    type="code"
                    autocomplete="code"
                    required=""
                    class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm" />
                </div>
              </div>
              <div>
                <button
                  class="w-full mt-4 border-transparent col-auto transition-all duration-200 text-white px-4 py-3 rounded focus:outline-none focus:ring-4 focus:ring-emerald-500 focus:ring-opacity-50"
                  :class="[
                    { 'bg-emerald-600 hover:bg-emerald-700 active:bg-emerald-700': !isValid },
                    { 'bg-emerald-500 hover:bg-emerald-600 active:bg-emerald-800': isValid }
                  ]"
                  @click="signin">
                  Create my account
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import FullLoader from '@/components/FullLoader'
import { ArrowLeftIcon, EnvelopeIcon } from '@heroicons/vue/24/outline'
const emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export default {
  name: 'Login',
  components: {
    FullLoader,
    ArrowLeftIcon,
    EnvelopeIcon
  },
  data () {
    return {
      form: {
        first_name: '',
        last_name: '',
        email: '',
        code: ''
      },
      error: null,
      loading: false,
      sent: false,
      userExists: false
    }
  },
  mounted () {
    const code = this.$route.params.code
    if (code) this.form.code = code
  },
  computed: {
    ...mapState(['user']),
    isValid () {
      return emailReg.test(this.form.email)
    }
  },
  methods: {
    signin () {
      if (!this.form.email || this.form.email === '' ||
        !this.form.first_name || this.form.first_name === '' ||
        !this.form.last_name || this.form.last_name === '' ||
        !this.form.code || this.form.code === '') {
        this.error = 'Please fill all fields to create an account'
        return
      } else if (!emailReg.test(this.form.email)) {
        this.error = 'Please enter a valid email address'
        return
      }
      this.error = null
      this.loading = true
      this.$http
        .post('/appsumo', this.form)
        .then(res => {
          this.sent = true
          if (res.data?.userExists) this.userExists = true
        })
        .catch(error => {
          if (error && error.response) {
            this.error = error.response.data || `[${error.response.status}] An error occured`
          } else this.error = error.status
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>
  .bgdark {
    background: #f5efe8;
  }
</style>
