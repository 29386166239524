<template>
  <div>
    <div class="mb-4 text-xs h-4 text-emerald-700 -mt-2">
      <div v-if="currentInfo.name">{{ currentInfo.name }}</div>
    </div>
    <div class="w-4xl max-w-4xl h-2xl max-h-4xl m-auto flex flex-col relative items-center justify-center">
      <div class="absolute">
        <!-- Hover -->
        <div v-if="currentInfo.name">
          <!-- <div class="text-3xl xxl:text-4xl text-gray-500">{{ currentInfo.percent }}%</div>
          <div class="text-xs xxl:text-sm text-gray-300">
            {{ formatValue(currentInfo.baseCurrencyCurrentValue) }}
            <span class="text-xs">{{ currency }}</span>
          </div> -->
          <div class="text-xs text-gray-500">
            <div class="text-lg">{{ formatPercent(currentInfo.percent) }}%</div>
            <div class="text-xs text-gray-400">
              {{ formatValue(currentInfo.baseCurrencyCurrentValue) }} {{ currency }}
            </div>
          </div>
        </div>
        <!-- Base info -->
        <div class="w-full text-center" v-else>
          <div class="text-l text-gray-500" v-if="defaultValue">
            {{ defaultValue }}
          </div>
          <div class="text-xs text-gray-400 mx-auto w-3/5 text-center">{{ defaultTitle || '-' }}</div>
        </div>
      </div>
      <Chart
        v-if="chartdata"
        type="doughnut"
        :chartdata="chartdata"
        :chartId="chartId"
        :options="options"
        @hover="showData"
        @click="showData"
        @mouseout="resetData" />
    </div>
  </div>
</template>

<script>
import Chart from './Chart.vue'
import format from '@/mixins/format'

export default {
  name: 'PieChart',
  components: { Chart },
  mixins: [format],
  props: {
    lines: {
      type: Array,
      note: 'Portfolio lines'
    },
    currency: {
      type: String,
      note: 'Base currency'
    },
    allocation: {
      type: Boolean,
      note: 'Show percent allocation',
      default: false
    },
    chartId: {
      type: String
    },
    defaultTitle: {
      type: String
    },
    defaultValue: {
      type: String
    }
  },
  data () {
    return {
      currentInfo: {
        name: null,
        percent: null,
        baseCurrencyCurrentValue: null,
        baseCurrency: null,
        profit: null
      },
      colors: [
        '#2d9669',
        '#4ab385',
        '#5dc396',
        '#70cda4',
        '#87dbb6',
        '#9de3c4'
      ],
      options: {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 1,
        legend: {
          position: 'top',
          display: false
        },
        hover: {
          animationDuration: 0,
          enabled: false
        },
        plugins: {
          tooltip: {
            enabled: false
          }
        },
        title: {
          display: false
        },
        animation: {
          animateScale: true,
          animateRotate: true
        }
      }
    }
  },
  computed: {
    chartdata () {
      if (!this.lines) return null
      const labels = []
      const datasets = [{
        data: [],
        backgroundColor: []
      }]
      let baseCurrencyTotal = 0
      let totalCurrentValue = 0
      for (const i in this.lines) {
        datasets[0].data.push(this.lines[i].baseCurrencyCurrentValue)
        datasets[0].backgroundColor.push(this.getColor(i))
        labels.push(this.lines[i].name)
        baseCurrencyTotal += this.lines[i].baseCurrencyTotal
        totalCurrentValue += this.lines[i].baseCurrencyCurrentValue
      }
      return {
        baseCurrencyTotal,
        totalCurrentValue,
        datasets,
        labels
      }
    },
    profit () {
      return Math.round(this.chartdata.totalCurrentValue - this.chartdata.baseCurrencyTotal)
    },
    percent () {
      return Math.round(this.profit / this.chartdata.baseCurrencyTotal * 100)
    },
    totalCurrentValue () {
      return this.formatValue(this.chartdata.totalCurrentValue)
    }
  },
  methods: {
    getColor (i) {
      if (this.colors[i]) return this.colors[i]
      return '#88c7ac'
    },
    showData (index) {
      if (index && this.lines[index]) {
        this.currentInfo.name = this.lines[index].name
        this.currentInfo.baseCurrencyCurrentValue = this.lines[index].baseCurrencyCurrentValue
        this.currentInfo.profit = Math.round(this.lines[index].baseCurrencyCurrentValue - this.lines[index].baseCurrencyTotal)
        this.currentInfo.percent = this.lines[index].percent
        this.$emit('hover', index)
      }
    },
    resetData () {
      this.currentInfo.name = null
      this.currentInfo.percent = null
      this.currentInfo.currentValue = null
      this.currentInfo.profit = null
      this.$emit('hover', null)
    }
  }
}
</script>
