<template>
  <div>
    <div class="">
      <div class="px-2 py-4 sm:px-4">
        <Loader class="mx-auto mt-10 py-32" v-if="loading" :hideText="true" />
        <template v-else>
          <img v-if="stock.image" class="rounded-md w-10 sm:w-14 mx-auto mb-6" :src="stock.image" :alt="stock.name">
          <h3 class="text-3xl font-medium text-gray-900 mb-4 max-w-2xl mx-auto">
            <span>{{ stock.name || '-' }}</span>
          </h3>
          <div class="mt-2 mb-2 text-xs text-gray-800">
            <div>{{ stock.symbol || '-' }} <span class="mx-1 text-gray-400">•</span> {{ stock.isin || '-' }} <span class="mx-1 text-gray-400">•</span> {{ stock.exchange || '-' }} <span class="mx-1 text-gray-400">•</span> {{ stock.type || '-' }}</div>
          </div>
          <div class="mt-1 text-sm  text-gray-600" v-if="stock.sector">
            <span>{{ stock.sector }} / {{ stock.industry }}</span>
          </div>
        </template>
      </div>
    </div>
    <!-- Description -->
    <div class="px-3 py-2 sm:px-2 mb-4" v-if="stock.description && stock.description.length">
      <div class="max-w-xl text-xs text-left leading-6 text-gray-600 lg:mx-auto">
        <div class="mx-auto text-center" v-if="loading"></div>
        <span v-else-if="showDescription">{{ stock.description }}</span>
        <span v-else @click="showDescription = true" class="hover:text-gray-900 cursor-pointer">
          {{ shortDescription }}
          <span class="px-3 py-4 whitespace-no-wrap text-right text-xs leading-5 font-medium">
            <span class="text-emerald-600 hover:text-emerald-900 cursor-pointer">Read more</span>
          </span>
        </span>
      </div>
    </div>
    <!-- Delisted stock -->
    <div class="rounded-md bg-yellow-50 p-3 max-w-lg mx-auto mb-3 shadow-sm" v-if="stock.profile?.General?.IsDelisted">
      <div class="flex">
        <div class="flex-shrink-0">
          <ExclamationTriangleIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
        </div>
        <div class="ml-3">
          <h3 class="text-sm font-medium text-yellow-800">{{ stock.name || 'This asset' }} was delisted on {{ delistedDate }}</h3>
        </div>
      </div>
    </div>
    <!-- Main statistics overview -->
    <div class="mx-8 sm:mx-auto" v-if="!loading">
      <StatsBlock :stats="stats" />
    </div>

    <div class="relative h-full flex flex-col container mx-auto mt-12">
      <section class="text-gray-700 body-font xl:overflow-hidden h-full" v-if="!loading">
        <div class="md:flex flex-col md:flex-row h-full w-full">
          <!-- Left submenu navigation -->
          <nav class="space-y-1 flex flex-col w-full mt-2 mr-1 md:w-48" aria-label="Sidebar">
            <router-link
              v-for="route in routes"
              :key="route.name"
              :to="`/asset/${stockId}${route.path}`"
              :class="[
                $route.path === `/asset/${stockId}${route.path}`
                  ? 'text-emerald-600 font-bold'
                  : 'text-gray-600 hover:text-emerald-500',
                'group flex items-center px-3 py-2 text-sm font-medium rounded-md'
              ]"
              :aria-current="$route.path === `/portfolio/${stockId}${route.path}` ? 'page' : undefined">
              <component
                v-if="route.icon"
                :is="route.icon"
                :class="[
                  $route.path === `/asset/${stockId}${route.path}`
                    ? 'text-emerald-600'
                    : 'text-gray-400 group-hover:text-emerald-500',
                  'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                ]"
                aria-hidden="true" />
              <span class="truncate">
                {{ route.name }}
              </span>
            </router-link>
          </nav>
          <!-- Main view -->
          <router-view class="w-full relative mb-8" />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import moment from 'moment'
import StatsBlock from '@/components/StatsBlock'
import Table from '@/components/Table'
import Loader from '@/components/Loader'
import format from '@/mixins/format'
import { CurrencyDollarIcon, ArrowTrendingUpIcon, RectangleStackIcon, BuildingLibraryIcon, DocumentChartBarIcon, BanknotesIcon, UserGroupIcon, ExclamationTriangleIcon } from '@heroicons/vue/24/outline'

export default {
  name: 'Stock',
  components: {
    StatsBlock,
    Table,
    CurrencyDollarIcon,
    ArrowTrendingUpIcon,
    RectangleStackIcon,
    BuildingLibraryIcon,
    DocumentChartBarIcon,
    Loader,
    BanknotesIcon,
    UserGroupIcon,
    ExclamationTriangleIcon
  },
  mixins: [format],
  computed: {
    ...mapState(['stock', 'loading']),
    routes () {
      const r = [{
        name: 'Price',
        path: '/price',
        icon: ArrowTrendingUpIcon
      }, {
        name: 'Transactions',
        path: '/holdings',
        icon: CurrencyDollarIcon
      }]
      /* , {
        name: 'Valuation',
        path: '/valuation',
        icon: VariableIcon
      }, {
        name: 'News',
        path: '/news',
        icon: DocumentTextIcon
      } */
      if (this.stock.profile?.Earnings) {
        r.unshift({
          name: 'Earnings',
          path: '/earnings',
          icon: DocumentChartBarIcon
        })
        r.push({
          name: 'Dividends',
          path: '/dividends',
          icon: BanknotesIcon
        })
      }
      if (this.stock.profile?.Financials && this.financials) {
        r.unshift({
          name: 'Fundamentals',
          path: '',
          icon: BuildingLibraryIcon
        })
      }
      if (this.stock.profile?.Holders && this.financials) {
        r.push({
          name: 'Insiders',
          path: '/insiders',
          icon: UserGroupIcon
        })
      }
      return r
    },
    stats () {
      if (this.loading) {
        return [{
          name: '-',
          stat: '-'
        }, {
          name: '-',
          stat: '-'
        }, {
          name: '-',
          stat: '-'
        }, {
          name: '-',
          stat: '-'
        }]
      }
      const defaultInfos = [{
        name: 'Market cap',
        stat: this.cap?.number ? this.cap.number : '-',
        currency: this.stock.currency,
        substat: this.cap?.category ? this.cap.category : '-'
      }, {
        name: 'Share Price',
        stat: this.stock.price ? Math.round(this.stock.price * 100) / 100 : '-',
        currency: this.stock.currency,
        substat: this.change + '%',
        change: this.change,
        changeType: this.change > 0 ? 'increase' : 'decrease',
        link: `/asset/${this.stockId}/price`
      }]
      const stockInfos = [{
        name: 'P/E Ratio',
        stat: this.stock.profile ? Math.round(this.stock.profile?.Highlights?.PERatio) : '-',
        substat: this.stock.profile ? 'Fwd PE: ' + Math.round(this.stock.profile?.Valuation?.ForwardPE) : '-'
      }, {
        name: 'PEG Ratio',
        stat: this.stock.profile ? Math.round(this.stock.profile?.Highlights?.PEGRatio * 100) / 100 : '-'
      }, {
        name: 'Profit Margin',
        stat: this.stock.profile ? Math.round(this.stock.profile?.Highlights?.ProfitMargin * 100) + '%' : '-',
        substat: this.stock.profile ? 'Op. margin: ' + Math.round(this.stock.profile?.Highlights?.OperatingMarginTTM * 100) + '%' : '-'
      }, {
        name: 'EPS',
        stat: this.stock.profile ? Math.round(this.stock.profile?.Highlights?.EarningsShare * 100) / 100 : '-',
        link: `/asset/${this.stockId}/earnings`
      }, {
        name: 'Dividend Yield',
        stat: this.stock.profile ? Math.round(this.stock.profile?.Highlights?.DividendYield * 10000) / 100 + '%' : '-',
        substat: this.stock.profile ? 'Payout ratio: ' + Math.round(this.stock.profile?.SplitsDividends?.PayoutRatio * 10000) / 100 + '%' : '-',
        link: `/asset/${this.stockId}/dividends`
      }]
      if (this.stock.profile?.AnalystRatings) {
        stockInfos.push({
          name: 'Analyst Ratings',
          stat: this.stock.profile ? Math.round(this.stock.profile?.AnalystRatings?.Rating * 100) / 100 : '-',
          substat: this.stock.profile ? 'Target price: ' + Math.round(this.stock.profile?.AnalystRatings?.TargetPrice * 100) / 100 + ' ' + this.stock.currency : '-',
          tooltip: '- Strong Buy: ' + this.stock.profile?.AnalystRatings?.StrongBuy + '<br />' +
            '- Buy: ' + this.stock.profile?.AnalystRatings?.Buy + '<br />' +
            '- Hold: ' + this.stock.profile?.AnalystRatings?.Hold + '<br />' +
            '- Sell: ' + this.stock.profile?.AnalystRatings?.Sell + '<br />' +
            '- Strong Sell: ' + this.stock.profile?.AnalystRatings?.StrongSell + '<br />'
        })
      }
      const ETFInfos = [{
        name: 'Yield',
        stat: this.stock.profile ? Math.round(this.stock.profile?.ETF_Data?.Yield * 100) / 100 : '-'
      }, {
        name: 'Nb of holdings',
        stat: this.stock.profile ? this.stock.profile?.ETF_Data?.Holdings_Count : '-'
      }]
      const currencyInfos = [{
        name: 'Market cap',
        stat: this.stock.profile ? this.formatValue(this.stock.profile?.Highlights?.MarketCapitalization) : '-',
        currency: this.stock.currency
      }, {
        name: 'Share Price',
        stat: this.stock.price ? this.formatValue(this.stock.price) : '-',
        currency: this.stock.currency
      }, {
        name: 'Supply',
        stat: this.stock.profile ? this.formatValue(this.stock.profile?.Statistics?.TotalSupply) : '-',
        substat: this.stock.profile ? 'Max supply: ' + Math.round(this.stock.profile?.Statistics?.MaxSupply * 100) / 100 : '-'
      }]
      if (['stock', 'common stock'].includes(this.stock.type?.toLowerCase())) return [...defaultInfos, ...stockInfos]
      if (this.stock.type?.toLowerCase() === 'etf') return [...defaultInfos, ...ETFInfos]
      if (this.stock.type?.toLowerCase() === 'currency') return currencyInfos
      return defaultInfos
    },
    change () {
      if (!this.stock.closePrice) return 0
      return Math.round((this.stock.price - this.stock.closePrice) / this.stock.closePrice * 10000) / 100
    },
    shortDescription () {
      if (!this.stock || !this.stock.description) return ''
      return this.stock.description.substring(0, 180) + '...'
    },
    stockId () {
      return this.$route.params.id
    },
    cap () {
      const cap = this.stock.profile?.Highlights?.MarketCapitalization || this.stock.profile?.Statistics?.MarketCapitalization || this.stock.mktCap
      if (!cap) return null
      if (cap >= 1000000000000) {
        return { category: 'Giga cap', number: Math.round(cap / 10000000000) / 100 + ' T' }
      } else if (cap >= 300000000000) {
        return { category: 'Mega cap', number: Math.round(cap / 1000000000) + ' B' }
      } else if (cap >= 10000000000) {
        return { category: 'Large cap', number: Math.round(cap / 1000000000) + ' B' }
      } else if (cap >= 2000000000) {
        return { category: 'Mid cap', number: Math.round(cap / 1000000000) + ' B' }
      } else if (cap >= 300000000) {
        return { category: 'Small cap', number: Math.round(cap / 1000000) + ' M' }
      } else if (cap >= 50000000) {
        return { category: 'Micro cap', number: Math.round(cap / 1000000) + ' M' }
      } else {
        return { category: 'Nano cap', number: Math.round(cap / 1000000) + ' M' }
      }
    },
    delistedDate () {
      return moment(this.stock.profile?.General?.DelistedDate).format('LL')
    }
  },
  data () {
    return {
      showDescription: false,
      financials: true
    }
  },
  async mounted () {
    await this.getStock(this.stockId)
  },
  methods: {
    ...mapMutations(['SET_STOCK', 'SET_LOADING']),
    async getStock (stockId) {
      this.SET_LOADING(true)
      const res = await this.$http.get(`/stock/${stockId}`)
      document.title = `${res.data.symbol} - ${res.data.name}`
      this.SET_STOCK(res.data)
      this.SET_LOADING(false)
    }
  },
  watch: {
    async stockId () {
      await this.getStock(this.stockId)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
