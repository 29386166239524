<template>
  <span class="mx-auto align-middle">
    <div class="group relative inline-block text-center">
      <InformationCircleIcon class="w-4 h-4 text-gray-300 hover:text-gray-700 transition-colors duration-300" />
      <div
        class="mb-2 opacity-0 w-64 bg-black text-white text-left text-xs rounded py-2 absolute z-20 group-hover:opacity-90 bottom-full -left-1/2 -ml-32 px-3 pointer-events-none">
        <div v-html="text" />
        <svg class="absolute text-black ml-4 h-2 w-full left-0 top-full" x="0px" y="0px" viewBox="0 0 255 255" xml:space="preserve"><polygon class="fill-current" points="0,0 127.5,127.5 255,0"/></svg>
      </div>
    </div>
  </span>
</template>

<script>

import { InformationCircleIcon } from '@heroicons/vue/24/outline'
export default {
  name: 'Tooltip',
  props: {
    text: {
      type: String,
      default: ''
    }
  },
  components: {
    InformationCircleIcon
  }
}
</script>
