<template>
  <div class="relative highlight" @click="SHOW_UPGRADE(source)">
    <div class="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
      <div class="text-center sm:px-16">
        <p class="font-semibold text-white">
          <span class="inline">
            {{ text }}
          </span>
          <span class="ml-4 inline-block" v-if="cta">
            <div
              class="text-white font-bold hover:underline hover:text-gray-100 cursor-pointer">
              {{ cta }} <span aria-hidden="true">&rarr;</span>
            </div>
          </span>
        </p>
      </div>
      <div v-if="closable" class="absolute inset-y-0 right-0 pt-1 pr-1 flex items-start sm:pt-1 sm:pr-2 sm:items-start">
        <button @click="$emit('close')" type="button"
          class="flex p-2 rounded-md hover:bg-emerald-500 focus:outline-none focus:ring-2 focus:ring-white">
          <span class="sr-only">Dismiss</span>
          <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { XMarkIcon } from '@heroicons/vue/24/outline'
import { mapMutations } from 'vuex'

export default {
  components: {
    XMarkIcon
  },
  methods: {
    ...mapMutations(['SHOW_UPGRADE'])
  },
  props: {
    text: {
      type: String
    },
    cta: {
      type: String
    },
    source: {
      type: String
    },
    closable: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.highlight {
  background-image: -webkit-gradient(linear,left top, right top,from(#e67399),to(#f2a640));
  background-image: linear-gradient(90deg,#e67399,#f2a640);
  opacity: 0.95;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
}
</style>
