<template>
  <div class="relative">
    <div class="absolute inset-0 flex items-center" aria-hidden="true">
      <div class="w-full border-t border-gray-300" />
    </div>
    <div class="relative flex justify-center">
      <button
        @click="$emit('action')"
        type="button"
        class="inline-flex items-center shadow-sm px-4 py-1.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-600 bg-white hover:bg-emerald-500 hover:text-white hover:border-emerald-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
        <PlusSmallIcon class="-ml-1.5 mr-1 h-5 w-5" aria-hidden="true" />
        <span>{{ text }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { PlusSmallIcon } from '@heroicons/vue/24/outline'

export default {
  name: 'DividerButton',
  components: {
    PlusSmallIcon
  },
  props: {
    text: {
      type: String,
      default: ''
    }
  }
}
</script>
