<template>
  <div
    aria-live="assertive"
    class="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start mt-28 z-30">
    <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
      <Notification
        v-for="(n, i) in notifications"
        :key="i"
        :text="n.text"
        :type="n.type"
        :description="n.description"
        :nb="i"
        @hide="hide" />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import Notification from '@/components/Notification'

export default {
  components: {
    Notification
  },
  computed: {
    ...mapState(['notifications'])
  },
  methods: {
    ...mapMutations(['HIDE_NOTIFICATION']),
    hide (id) {
      this.HIDE_NOTIFICATION(id)
    }
  }
}
</script>
