<template>
  <Chart
    v-if="cashflows && chartdata"
    chartId="dcf-chart"
    :options="options"
    :chartdata="chartdata" />
</template>

<script>
import Chart from '@/components/Chart'

export default {
  name: 'DCFChart',
  components: {
    Chart
  },
  props: {
    cashflows: {
      type: Array,
      default: () => []
    },
    futureCashFlows: {
      type: Array,
      default: () => []
    },
    unit: {
      type: Object,
      default: () => {}
    },
    currency: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    options () {
      const unit = `${(this.unit?.name !== '-' ? this.unit?.name : '') || ''}${this.currency || ''}`
      return {
        responsive: true,
        legend: {
          position: 'top',
          display: false
        },
        hover: {
          animationDuration: 0,
          enabled: false
        },
        plugins: {
          tooltip: {
            enabled: false
          }
        },
        title: {
          display: false
        },
        animation: {
          animateScale: true,
          animateRotate: true
        },
        scales: {
          x: {
            grid: { color: '#eee' },
            offset: true
          },
          y: {
            grid: { color: '#eee' },
            offset: true,
            ticks: {
              userCallback: function (item) {
                return `${item} ${unit}`
              }
            }
          }
        }
      }
    },
    chartdata () {
      const labels = []
      const datasets = [{
        type: 'bar',
        data: [],
        backgroundColor: [],
        pointRadius: 5,
        lineTension: 0,
        borderWidth: 3,
        pointBorderColor: 'rgba(0, 0, 0, 0)',
        borderColor: 'rgba(0, 0, 0, 0)'
      }, {
        type: 'line',
        data: [],
        backgroundColor: [],
        pointRadius: 5,
        pointBorderColor: 'rgba(0, 0, 0, 0)',
        borderColor: 'rgba(0, 0, 0, 0.1)',
        borderDashOffset: 2,
        borderDash: [10, 5],
        fill: false,
        showLine: true
      }]
      for (const c of this.cashflows) {
        datasets[0].data.push(c.fcf / this.unit.n)
        datasets[0].backgroundColor.push('#3e885e')
        datasets[1].data.push(c.fcf / this.unit.n)
        datasets[1].backgroundColor.push('#3e885e')
        labels.push(c.year)
      }
      for (const c of this.futureCashFlows) {
        datasets[0].data.push(c.fcf)
        datasets[0].backgroundColor.push('rgba(0, 0, 0, .08)')
        datasets[1].data.push(c.fcf)
        datasets[1].backgroundColor.push('#3e885e')
        labels.push(c.year)
      }
      return {
        datasets,
        labels
      }
    }
  }
}
</script>
