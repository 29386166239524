<template>
  <div>
    <img v-if="type === 'tacos'" class="load mx-auto animate-bounce" src="@/assets/images/tacos.svg" alt="Sumo loading" />
    <img v-else class="load mx-auto" src="@/assets/images/load.svg" alt="Beanvest" />
    <div v-if="!hideText" class="text mt-6 mb-4 text-sm font-semibold">{{ text }}</div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    text: {
      type: String,
      default: 'loading...'
    },
    hideText: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'default'
    }
  }
}
</script>

<style lang="scss" scoped>
.load {
  width: 40px;
  height: 40px;
  background-size: auto 100%;
  z-index: 10001;
}
.text {
  z-index: 10001;
  text-align: center;
  color: #bbb5ad;
  animation: 5s blink infinite ease-in-out;
  transition: all 0.3s ease-in-out;
}

@keyframes blink {
    0% {
    color:#bbb5ad;
    }
    40% {
    color:#bbb5ad;
    }
    80% {
    color:#343434;
    }
    100% {
    color:#bbb5ad;
    }
    }
</style>
