<template>
  <div>
    <div class="flex w-full justify-between" v-if="!loading">
      <div class="flex items-center mb-2 grow mr-3">
        <div v-for="ticker in tickerFilters" :key="ticker">
          <span class="sr-only">{{ ticker }}</span>
        </div>
        <div
          class="hidden ml-2 space-x-2 lg:block"
          :class="(ticker === tickerFilter || (ticker === 'See All' && !tickerFilter)) ? 'text-emerald-500 font-bold' : ''"
          v-for="ticker in tickerFilters"
          :key="ticker"
          @click="selectTicker(ticker)">
          <div class="text-xs hover:text-emerald-500 cursor-pointer">
            {{ ticker }}
          </div>
        </div>
      </div>
      <div class="inline-flex rounded-md shadow-sm mb-2 flex-none">
        <button @click="setCompact(false)" type="button" class="relative inline-flex items-center rounded-l-md border border-gray-300 px-3 py-2 text-sm font-medium focus:z-10 focus:outline-none"
          :class="[{ 'bg-emerald-600 text-white hover:bg-emerald-600': !compact }, { 'bg-white hover:bg-gray-50': compact }]">
          <Squares2X2Icon class="h-3 w-3" aria-hidden="true" />
        </button>
        <button @click="setCompact(true)" type="button" class="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 px-3 py-2 text-sm font-medium focus:z-10 focus:outline-none"
          :class="[{ 'bg-emerald-600 text-white hover:bg-emerald-600': compact }, { 'bg-white hover:bg-gray-50': !compact }]">
          <Bars4Icon class="h-3 w-3" aria-hidden="true" />
        </button>
      </div>
    </div>

    <Loader class="mx-auto mt-24" v-if="loading" text="Loading news..." />

    <div class="overflow-hidden bg-white shadow sm:rounded-md" v-else-if="compact">
      <ul role="list" class="divide-y divide-gray-200 text-left">
        <li v-for="post in filteredNews" :key="post.title">
          <a :href="`${post.url}?source=beanvest.com`" target="_blank" class="block hover:bg-gray-50">
            <div class="flex items-center">
              <div class="flex min-w-0 flex-1 items-center p-2">
                <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4 ">
                  <div>
                    <p class="truncate text-base font-medium text-gray-900 mb-1 mt-1">{{ post.title }}</p>
                    <div class="flex space-x-1 text-gray-500 text-xs">
                      <p class="text-xs font-medium text-emerald-600">
                        {{ post.symbol }}
                      </p>
                      <span aria-hidden="true">
                        &middot;
                      </span>
                      <time :datetime="post.publishedDate">
                        {{ formatDate(post.publishedDate) }}
                      </time>
                      <span aria-hidden="true">
                        &middot;
                      </span>
                      <span>{{ post.site }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <ChevronRightIcon class="h-5 w-5 text-gray-400 mr-4" aria-hidden="true" />
              </div>
            </div>
          </a>
        </li>
      </ul>
    </div>
    <div class="max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none text-left" v-else>
      <div v-for="post in filteredNews" :key="post.title" class="flex flex-col rounded-lg shadow-md hover:shadow-lg overflow-hidden">
        <div class="flex-shrink-0">
          <img class="h-48 w-full object-cover bg-gray-100" :src="post.image" />
        </div>
        <div class="flex-1 bg-white p-6 flex flex-col justify-between">
          <div class="flex-1">
            <a :href="`${post.url}?source=beanvest.com`" target="_blank" class="block mt-2">
              <p class="text-xl font-semibold text-gray-900">
                {{ post.title }}
              </p>
              <p class="mt-3 text-base text-gray-500">
                {{ post.text }}
              </p>
            </a>
          </div>
          <div class="flex space-x-1 text-sm text-gray-500 mt-4">
              <p class="text-sm font-medium text-emerald-600">
                {{ post.symbol }}
              </p>
              <span aria-hidden="true">
                &middot;
              </span>
              <time :datetime="post.publishedDate">
                {{ formatDate(post.publishedDate) }}
              </time>
              <span aria-hidden="true">
                &middot;
              </span>
              <span class="text-sm">{{ post.site }}</span>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { ChevronRightIcon, Bars4Icon } from '@heroicons/vue/24/solid'
import { Squares2X2Icon } from '@heroicons/vue/24/outline'

import moment from 'moment'
import Loader from '@/components/Loader'

export default {
  name: 'News',
  components: {
    Loader,
    ChevronRightIcon,
    Squares2X2Icon,
    Bars4Icon
  },
  data () {
    return {
      loading: false,
      compact: false,
      tickerFilter: null
    }
  },
  computed: {
    ...mapState(['news', 'portfolio', 'user']),
    tickerFilters () {
      const tickers = ['See All']
      for (const post of this.news) {
        if (!tickers.includes(post.symbol)) tickers.push(post.symbol)
      }
      return tickers
    },
    filteredNews () {
      if (!this.tickerFilter) return this.news
      return this.news.filter(n => n.symbol === this.tickerFilter)
    }
  },
  mounted () {
    this.getNews()
    this.compact = (this.user.newsView === 'compact')
  },
  methods: {
    ...mapMutations(['SET_NEWS']),
    getNews () {
      this.loading = true
      if (!this.portfolio.lines || !this.portfolio.lines.length) return
      const symbols = [...new Set(this.portfolio.lines.map(s => s.symbol))]
      this.$http.post('/stock/news', { symbols }).then(res => {
        this.SET_NEWS(res.data)
        this.loading = false
      })
    },
    formatDate (date) {
      return moment(date, 'YYYYMMDD').fromNow()
    },
    setCompact (compact) {
      this.compact = compact
      return this.$http.patch('/user', { newsView: (compact ? 'compact' : 'grid') })
    },
    selectTicker (ticker) {
      if (ticker === 'See All') this.tickerFilter = null
      else this.tickerFilter = ticker
    }
  }
}
</script>
