<template>
  <div class="h-full w-full relative">
    <Loader class="mx-auto py-20 mt-8" v-if="loading" text="Calculating DCF..." />
    <div class="flex mx-auto" v-else>
      <div class="w-full container m-auto p-3 sm:p-0 mt-4" v-if="!stockId">
        <h2 class="text-3xl sm:text-4xl font-bold text-gray-800 mt-4 mb-2 sm:mb-4 max-w-4xl mx-auto">
          Discounted Cash Flow
        </h2>
        <div class="text-sm sm:text-xl text-gray-500 mb-6 sm:mb-8 max-w-5xl mx-auto">
          Please select an asset to perform the DCF valuation
        </div>
        <StockSearch class="max-w-xl mx-auto" @select="selectStock" :rounded="false" :persist="false" />
        <div class="rounded-md bg-yellow-50 p-4 mt-3" v-if="error">
          <div class="flex">
            <div class="flex-shrink-0">
              <ExclamationTriangleIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
            </div>
            <div class="ml-3">
              <h3 class="text-sm font-medium text-yellow-800">{{ error }}</h3>
            </div>
          </div>
        </div>
        <Loader class="mx-auto py-20 mt-8" v-if="loadingList" text="Loading stocks..." />
        <div v-else class="mt-10 max-w-6xl mx-auto grid grid-cols-1 gap-4 sm:grid-cols-3 lg:grid-cols-4 text-left transition-all duration-300">
          <div @click="selectStock(stock)" v-for="stock in stocks" :key="stock._id" class="cursor-pointer relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-4 py-3 shadow-sm focus-within:ring-2 focus-within:ring-green-500 focus-within:ring-offset-2 hover:bg-gray-50">
            <div class="flex-shrink-0">
              <img v-if="stock.image" class="h-10 w-10 rounded-sm" :src="stock.image" :alt="stock.name" />
              <div v-else class="bg-gray-100 w-10 h-10 rounded-sm inline-flex items-center justify-center">
                <span class="text-xs font-medium leading-none text-gray-300">{{ (stock.name || stock.symbol)?.charAt(0)?.toUpperCase() }}</span>
              </div>
            </div>
            <div class="min-w-0 flex-1">
              <div class="focus:outline-none">
                <span class="absolute inset-0" aria-hidden="true" />
                <p class="text-sm font-medium text-gray-900">{{ stock.name }}</p>
                <p class="truncate text-xs text-gray-500">{{ stock.symbol }} {{ stock.exchange }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full max-w-7xl mx-auto" v-else>
        <h3 class="text-xl sm:text-3xl font-medium text-gray-900 mb-4 max-w-2xl flex items-center justify-start justify-items-start">
          <BackspaceIcon v-if="!loading" class="h-7 w-7 ml-1 mr-2 sm:mr-4 text-gray-300 hover:text-emerald-500 cursor-pointer hover:w-8 hover:h-8" aria-hidden="true" @click="cancel" />
          <router-link :to="`/asset/${stock._id}`">
            <img v-if="!loading && stock.image" class="rounded-md w-6 sm:w-8 mr-3 cursor-pointer" :src="stock.image" :alt="stock.name">
          </router-link>
          <span v-if="loading">Loading...</span>
          <span v-else>{{ stock.name || 'Loading...' }}</span>
        </h3>
        <div class="relative flex flex-col max-w-7xl sm:mx-auto mx-3 mt-5 mb-4 bg-white rounded-lg shadow">
          <div class="absolute right-2 top-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800">beta</div>
          <section class="text-gray-700 body-font xl:overflow-hidden h-full" v-if="!loading">
            <div class="md:flex flex-col space-y-2 md:flex-row h-full w-full">
              <!-- FCF -->
              <div class="space-y-1 flex flex-col w-full mr-1 min-w-48 md:w-80 text-left p-3 border-r border-gray-200" aria-label="Options">
                <div class="mb-2">
                  <div class="text-sm mb-1 font-semibold">Growth rate</div>
                  <Select :options="growthOptions" :defaultOption="growthOptions[0]" @select="updateGrowth" />
                </div>
                <div class="mb-2" v-if="customGrowth">
                  <div class="mt-1 relative rounded-md shadow-sm">
                    <input
                      v-model="growthRate"
                      type="number"
                      name="growthRate"
                      id="growthRate"
                      class="focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-xs border-gray-300 rounded-md pr-8"
                      placeholder="10%" />
                      <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <span class="text-gray-500 sm:text-xs">%</span>
                      </div>
                  </div>
                </div>
                <div class="mb-4" v-if="currentGrowthOption === 'slowing'">
                  <div class="mb-2 relative rounded-md shadow-sm">
                    <label for="slowingGrowthRate" class="block text-sm font-medium text-gray-700">Decrease Rate</label>
                    <input
                      v-model="slowingGrowthRate"
                      type="number"
                      step="10"
                      name="slowingGrowthRate"
                      id="slowingGrowthRate"
                      class="focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-xs border-gray-300 rounded-md pr-8"
                      placeholder="10" />
                      <div class="absolute inset-y-0 top-5 right-0 pr-3 flex items-center pointer-events-none">
                        <span class="text-gray-500 sm:text-xs">%</span>
                      </div>
                  </div>
                </div>
                <div class="mt-3">
                  <div class="flex items-center justify-between mb-1">
                    <div class="text-sm font-semibold">Free Cash Flows</div>
                    <div v-if="units.length">
                      <MenuSimple :options="units" @select="selectUnit" :name="`${(selectedUnit?.name !== '-' ? selectedUnit?.name : '') || ''}${stock.currency}`" :openHover="true" size="w-12" />
                    </div>
                  </div>
                  <div class="text-xs flex flex-row justify-between items-center" v-for="c in [...futureCashFlows].reverse()" :key="c.year">
                    <div class="mr-3">
                      <span class="font-bold mr-2">{{ c.year }}</span>
                      <span class="text-gray-400">(<span v-if="c.growthRate > 0">+</span>{{ Math.round(c.growthRate * 100) }}%)</span>
                    </div>
                    <div class="mt-1 relative rounded-md shadow-sm">
                      <input
                        v-model="c.fcf"
                        type="number"
                        name="fcf"
                        id="fcf"
                        class="focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-xs border-gray-300 rounded-md"
                        :class="[{ 'pr-10' : selectedUnit?.name === '-'  }, { 'pr-12' : selectedUnit?.name !== '-' }]"
                        placeholder="10000"
                        aria-describedby="price-currency" />
                      <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <span class="text-gray-500 sm:text-xs mr-" id="price-currency"> <span class="text-gray-400 sm:text-xs mr-1" v-if="selectedUnit?.n > 1">{{ selectedUnit?.name }}</span>{{ stock.currency }} </span>
                      </div>
                    </div>
                  </div>
                  <div class="mt-2 pt-2 mb-2">
                    <div class="text-xs flex flex-row justify-between" v-for="c in displayedCashFlows" :key="c.year">
                      <div>
                        <span class="font-bold mr-2">{{ c.year }}</span>
                        <span v-if="c.growth" class="text-gray-400">(<span v-if="c.growth > 0">+</span>{{ c.growth }}%)</span>
                      </div>
                      <div><span class="mr-2">{{ showUnit(c.fcf) }}</span> <span class="text-gray-400 sm:text-xs mr-1" v-if="selectedUnit?.n > 1">{{ selectedUnit?.name }}</span>{{ stock.currency }}</div>
                    </div>
                  </div>
                  <button
                    v-if="!seeAllCashflows && cashflows.length > displayedCashFlows.length"
                    type="button"
                    class="inline-flex text-center items-center mb-2 px-3 w-full mx-auto border border-gray-200 shadow-sm text-sm leading-4 font-medium rounded-md text-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
                    @click="seeAllCashflows = true">
                    <EllipsisHorizontalIcon class="h-4 w-4 text-gray-900 mx-auto" />
                  </button>
                  <div class="mt-2 p-2">
                    <div class="mt-2">
                      <label for="discountRate" class="block text-sm font-medium text-gray-700">Discount Rate</label>
                      <div class="mt-1 relative rounded-md shadow-sm">
                        <input
                          v-model="discountRate"
                          type="number"
                          name="discountRate"
                          id="discountRate"
                          class="focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-xs border-gray-300 rounded-md pr-8"
                          placeholder="10%" />
                          <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <span class="text-gray-500 sm:text-xs">%</span>
                          </div>
                      </div>
                    </div>
                    <div class="mt-2">
                      <label for="terminalValue" class="block text-sm font-medium text-gray-700">Terminal Value</label>
                      <div class="mt-1 relative rounded-md shadow-sm">
                        <input
                          v-model="terminalValue"
                          type="number"
                          name="terminalValue"
                          id="terminalValue"
                          class="focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-xs border-gray-300 rounded-md pr-8"
                          placeholder="10%" />
                          <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <span class="text-gray-500 sm:text-xs">%</span>
                          </div>
                      </div>
                    </div>
                    <div class="mt-2">
                      <label for="nbFutureYears" class="block text-sm font-medium text-gray-700">Number of years projected</label>
                      <div class="mt-1 relative rounded-md shadow-sm">
                        <input
                          v-model="nbFutureYears"
                          type="number"
                          name="nbFutureYears"
                          id="nbFutureYears"
                          class="focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-xs border-gray-300 rounded-md"
                          placeholder="5"
                          min="1"
                          max="20" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Main view -->
              <div class="h-full w-full">
                <div class="flex items-stretch m-auto">
                  <div class="flex-1 text-gray-700 text-center px-4 py-6">
                    <div class="mb-6">
                      {{ stock.name }} has a fair value of <span class="font-bold text-lg">{{ fairValue }} {{ stock.currency }}</span><span v-if="stock.price"> (vs {{ Math.round(stock.price) }} {{ stock.currency }})</span>.
                      <span v-if="stock.price">It is <span class="font-bold text-lg" :class="[{ 'text-emerald-600' : fairValue - stock.price > 0 }, { 'text-red-600' : fairValue - stock.price < 0}]">{{ Math.abs(Math.round((fairValue - stock.price) / stock.price * 100)) }}% <span v-if="fairValue - stock.price > 0">undervalued</span><span v-else>overvalued</span></span> according to this model.</span>
                    </div>
                    <DcfChart
                      v-if="stock && !loading"
                      :i="i"
                      :unit="selectedUnit"
                      :cashflows="cashflows"
                      :futureCashFlows="futureCashFlows"
                      :currency="stock.currency"
                      type="LineWithLine"
                      class="w-full relative sm:mb-2" />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StockSearch from '@/components/StockSearch'
import Loader from '@/components/Loader'
import Select from '@/components/Select'
import MenuSimple from '@/components/MenuSimple'
import DcfChart from './DcfChart.vue'
import { mapState, mapMutations } from 'vuex'
import { BackspaceIcon, EllipsisHorizontalIcon, ExclamationTriangleIcon } from '@heroicons/vue/24/outline'
import format from '@/mixins/format'

export default {
  name: 'Dcf',
  components: {
    StockSearch,
    Loader,
    DcfChart,
    BackspaceIcon,
    EllipsisHorizontalIcon,
    Select,
    MenuSimple,
    ExclamationTriangleIcon
  },
  mixins: [format],
  data () {
    return {
      i: 0,
      error: null,
      loading: false,
      loadingList: false,
      stockId: this.$route.params.id || null,
      stocks: [],
      cashflows: [],
      futureCashFlows: [],
      seeAllCashflows: false,
      nbFutureYears: 5,
      discountRate: 10,
      terminalValue: 2,
      growthRate: null,
      currentGrowthOption: null,
      customGrowth: false,
      slowingGrowthRate: 30,
      selectedUnit: null,
      units: [
        { name: 'b', n: 1000000000 },
        { name: 'm', n: 1000000 },
        { name: 'k', n: 100000 },
        { name: '-', n: 1 }
      ]
    }
  },
  async mounted () {
    this.loadingList = true
    if (this.stockId) await this.getStock(this.stockId)
    this.$http.get('/stock?limit=40').then(res => {
      this.stocks = res.data.filter(s => s.type === 'Common Stock')
      this.loadingList = false
    })
  },
  computed: {
    ...mapState(['stock']),
    growth () {
      let min = null
      let max = null
      let median3 = null
      let median5 = null
      let median10 = null
      let last = 0
      const cf = [...this.cashflows].reverse()
      const lastCashFlows = [...cf].splice(0, 5)
      for (const c of lastCashFlows) {
        if (max === null && c.growth) max = c.growth / 100
        if (min === null && c.growth) min = c.growth / 100
        if (c.growth > max * 100) max = c.growth / 100
        if (c.growth < min * 100) min = c.growth / 100
      }
      if (cf && cf.length >= 3) {
        median3 = Math.round((cf[0]?.fcf - cf[2]?.fcf) / cf[2]?.fcf / 3 * 100) / 100
      }
      if (cf && cf.length >= 4) {
        median5 = Math.round((cf[0]?.fcf - cf[4]?.fcf) / cf[4]?.fcf / 5 * 100) / 100
      }
      if (cf && cf.length >= 9) {
        median10 = Math.round((cf[0]?.fcf - cf[9]?.fcf) / cf[9]?.fcf / 10 * 100) / 100
      }
      if (cf?.length) last = cf[0].growth / 100
      return {
        min,
        median3,
        median5,
        median10,
        max,
        last
      }
    },
    presentValueOfFutureCashFlows () {
      const cf = [...this.futureCashFlows].sort((a, b) => {
        return b.date - a.date
      })
      let value = 0
      for (const i in cf) {
        value += cf[i].fcf / (1 + this.discountRate / 100) ** i
      }
      if (cf?.length) value += cf[cf.length - 1]?.fcf * this.terminalValue
      return value
    },
    fairValue () {
      const price = this.presentValueOfFutureCashFlows / this.stock?.profile?.SharesStats?.SharesOutstanding * (this.selectedUnit?.n || 1)
      return price < 10 ? Math.round(price * 100) / 100 : Math.round(price)
    },
    growthOptions () {
      let options = []
      if (this.growth.median3 !== null && !isNaN(this.growth.median3)) {
        options.push({ id: 'last3', name: `3Y average (${Math.round(this.growth.median3 * 100)}%)`, value: this.growth.median3 })
      }
      if (this.growth.median5 !== null && !isNaN(this.growth.median5)) {
        options.push({ id: 'last5', name: `5Y average (${Math.round(this.growth.median5 * 100)}%)`, value: this.growth.median5 })
      }
      if (this.growth.median10 !== null && !isNaN(this.growth.median10)) {
        options.push({ id: 'last10', name: `10Y average (${Math.round(this.growth.median10 * 100)}%)`, value: this.growth.median10 })
      }
      if (!isNaN(this.growth.last)) options.push({ id: 'last', name: `Last year (${Math.round(this.growth.last * 100)}%)`, value: this.growth.last })
      if (!isNaN(this.growth.last)) options.push({ id: 'slowing', name: 'Slowing growth (custom)', value: this.growth.last })
      if (!isNaN(this.growth.min)) options.push({ id: 'worst', name: `Worst year (${Math.round(this.growth.min * 100)}%)`, value: this.growth.min })
      options = [
        ...options,
        { id: 'minus10', name: 'Medium decrease (-10%)', value: -0.10 },
        { id: 'minus5', name: 'Slow decrease (-5%)', value: -0.05 },
        { id: 'flat', name: 'Flat (0%)', value: 0 },
        { id: 'flatlast', name: 'Flat from previous year (0%)', value: 0 },
        { id: '5', name: 'Slow increase (5%)', value: 0.05 },
        { id: '10', name: 'Medium increase (10%)', value: 0.10 }
        /* { id: 'custom', name: 'Custom', value: 0 } */
      ]
      return options
    },
    displayedCashFlows () {
      const cashflows = [...this.cashflows].reverse()
      if (this.seeAllCashflows) return cashflows
      return cashflows.slice(0, 5)
    }
  },
  methods: {
    ...mapMutations(['SET_STOCK']),
    cancel () {
      this.stockId = null
      this.cashflows = []
      this.futureCashFlows = []
      this.$router.push('/dcf')
    },
    selectStock (stock) {
      if (stock.type === 'Common Stock') {
        this.stockId = stock._id
        this.error = null
      } else this.error = `DCF valuation does not work for ${stock.type}. You need to select a Common Stock to analyse. `
    },
    async getStock (stockId) {
      this.loading = true
      const res = await this.$http.get(`/stock/${stockId}`)
      this.$router.push(`/dcf/${stockId}`)
      this.SET_STOCK(res.data)
      document.title = `DCF ${res.data.symbol} - ${res.data.name}`
      this.cashflows = []
      let previous = null
      const dates = Object.keys(this.stock.profile.Financials.Cash_Flow.yearly).sort((a, b) => {
        return new Date(a) - new Date(b)
      })
      for (const c of dates) {
        const cashflow = this.stock.profile.Financials.Cash_Flow.yearly[c]
        if (cashflow.freeCashFlow) {
          const growth = previous && previous > 0 ? Math.round((parseInt(cashflow.freeCashFlow) - previous) / previous * 100) : null
          this.cashflows.push({
            year: new Date(cashflow.date).getFullYear(),
            fcf: parseInt(cashflow.freeCashFlow),
            growth
          })
          previous = parseInt(cashflow.freeCashFlow)
        }
      }
      this.cashflows.sort((a, b) => a.year - b.year)
      this.computeFuture()
      this.autoSelectUnit()
      this.loading = false
    },
    computeFuture () {
      this.futureCashFlows = []
      if (!this.cashflows.length) return this.futureCashFlows
      this.cashflows.sort((a, b) => a.year - b.year)
      let last = this.cashflows[this.cashflows.length - 1]
      for (const i in new Array(this.nbFutureYears).fill('')) {
        let growthRate = last.growthRate || this.growthRate
        if (this.currentGrowthOption === 'slowing') growthRate = growthRate * (1 - this.slowingGrowthRate / 100)
        let newFcf = last.fcf * (1 + growthRate)
        if (this.currentGrowthOption === 'flatlast') newFcf = this.cashflows[this.cashflows.length - 2].fcf
        let fcf = newFcf > 10 ? Math.round(newFcf) : Math.round(newFcf * 100) / 100
        if (i === '0') fcf = this.showUnit(fcf)
        const newCF = {
          year: last.year + 1,
          fcf,
          growthRate,
          i
        }
        this.futureCashFlows.push(newCF)
        last = newCF
      }
    },
    updateGrowth (option) {
      this.currentGrowthOption = option.id
      if (option.id === 'custom') this.customGrowth = true
      else {
        this.growthRate = option.value
        this.customGrowth = false
      }
      this.i++
    },
    selectUnit (unit) {
      this.selectedUnit = unit
      this.computeFuture()
      this.i++
    },
    showUnit (number) {
      if (!this.selectedUnit) return number
      const n = number / this.selectedUnit.n
      if (n < 10) return Math.round(n * 100) / 100
      if (n < 100) return Math.round(n * 10) / 10
      return Math.round(n)
    },
    autoSelectUnit () {
      const lastCF = this.cashflows[this.cashflows.length - 1]?.fcf
      for (const unit of this.units.reverse()) {
        if (lastCF / unit.n > 100) this.selectedUnit = unit
      }
    }
  },
  watch: {
    async stockId () {
      if (this.stockId) await this.getStock(this.stockId)
    },
    currentGrowthOption () {
      this.computeFuture()
    },
    slowingGrowthRate () {
      this.computeFuture()
    },
    nbFutureYears () {
      this.nbFutureYears = parseInt(this.nbFutureYears)
      this.computeFuture()
    }
  }
}
</script>
