<template>
<div class="w-full">
  <EditTransaction
    v-if="showEdit"
    :transaction="transaction"
    @edited="edited"
    @cancel="showEdit = false" />
  <DeleteTransaction
    v-if="showDelete"
    :transactionId="transactionId"
    @deleted="transactionDeleted"
    @cancel="cancelDelete" />
  <div class="flex items-center mb-3">
    <div v-for="period in periodFilters" :key="period.id">
      <span class="sr-only">{{ period.name }}</span>
    </div>
    <div
      class="hidden ml-2 space-x-2 lg:block"
      :class="period === id ? 'text-emerald-500 font-bold' : ''"
      v-for="{ id, name } in periodFilters"
      :key="id"
      @click="selectPeriod(id)">
      <div class="text-xs hover:text-emerald-500 cursor-pointer">
        {{ name }}
      </div>
    </div>
    <div class="ml-5 text-xs text-gray-400">
      ({{ transactions.length }} transactions)
    </div>
  </div>
  <div class="" v-if="loading" />
  <Table
    v-else-if="transactions.length"
    :data="transactions"
    :columns="columns"
    :actions="actions"
    @delete="confirmDelete"
    @edit="edit" />
  <div class="text-gray-400 py-20 flex-1 text-center px-4 m-2 bg-white rounded-lg shadow" v-else>
    No transaction for this period
  </div>
</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import moment from 'moment'
import Table from '@/components/Table'
import DeleteTransaction from '../Portfolio/Modals/DeleteTransaction'
import EditTransaction from '../Portfolio/Modals/EditTransaction'

export default {
  name: 'Transactions',
  components: {
    Table,
    DeleteTransaction,
    EditTransaction
  },
  computed: {
    ...mapState(['transactions']),
    stockId () {
      return this.$route.params.id
    }
  },
  data () {
    return {
      periodFilters: [
        { id: 'current-month', name: 'This month' },
        { id: 'last-month', name: 'Last month' },
        { id: 'current-year', name: 'This year' },
        { id: 'last-year', name: 'Last year' },
        { id: 'all', name: 'All transactions' }
      ],
      period: 'current-year',
      showDelete: false,
      showEdit: false,
      loading: false,
      columns: [
        {
          key: 'type',
          value: 'Type',
          sort: l => l.type,
          map: l => `<span class="text-xs py-1 px-2 rounded ${this.getColor(l.type)}">${l.type}</span>`
        },
        {
          key: 'date',
          value: 'Date',
          sort: l => l.date,
          map: l => '<div class="flex-shrink-0 flex flex-col text-right">' +
            '<span class="text-gray-800 text-xs">' +
            moment(l.date).format('LLL') +
            '</span></div>'
        },
        {
          key: 'quantity',
          value: 'Quantity',
          sort: l => l.units,
          map: l => '<div class="flex-shrink-0 flex flex-col text-right">' +
            '<span class="text-gray-800 text-xs">' +
            (l.type === 'dividend' ? '-' : l.units) +
            '</span></div>'
        },
        {
          key: 'price',
          value: 'Price',
          sort: l => l.price,
          map: l => '<div class="flex-shrink-0 flex flex-col text-right">' +
            '<span class="text-gray-800 text-xs">' +
            l.price + ' ' + (l.stockId.currency || '$') +
            '</span></div>'
        },
        {
          key: 'fees',
          value: 'Fees',
          sort: l => l.fee,
          map: l => '<div class="flex-shrink-0 flex flex-col text-right">' +
            '<span class="text-gray-800 text-xs">' +
            (l.fee || 0) + ' ' + (l.stockId.currency || '$') +
            '</span></div>'
        },
        {
          key: 'total',
          value: 'Total',
          sort: l => l.total,
          map: l => Math.round(l.units * l.price * 100) / 100 + ' ' + (l.stockId.currency || '$')
        },
        {
          key: 'comments',
          value: 'Comments',
          sort: l => l.comments,
          map: l => '<div class="flex-shrink-0 flex flex-col text-right">' +
            '<span class="text-gray-800 text-xs">' +
            (l.comments || '-') +
            '</span></div>'
        },
        {
          key: 'portfolio',
          value: 'Portfolio',
          sort: l => (l.portfolioId?.name || '-'),
          map: l => '<div class="flex-shrink-0 flex flex-col text-right">' +
            '<span class="text-gray-800 text-xs">' +
            l.portfolioId?._id
            ? `<a class="hover:text-emerald-600" href="${process.env.NODE_ENV === 'development' ? '' : '/app'}/portfolio/${l.portfolioId?._id}">${l.portfolioId?.name || '-'}</a>`
            : (l.portfolioId?.name || '-') +
            '</span></div>'
        }
      ],
      actions: ['delete', 'edit'],
      transactionId: null
    }
  },
  mounted () {
    if (this.$route.query.period) this.selectPeriod(this.$route.query.period)
    else this.getTransactions()
  },
  methods: {
    ...mapMutations(['SET_TRANSACTIONS', 'NOTIFY']),
    getTransactions () {
      this.loading = true
      this.$http.get(`/stock/${this.stockId}/transactions`, { params: { period: this.period } }).then(res => {
        this.SET_TRANSACTIONS(res.data)
        this.loading = false
      })
    },
    confirmDelete (transaction) {
      this.transactionId = transaction._id
      this.showDelete = true
    },
    transactionDeleted () {
      this.transactionId = null
      this.showDelete = false
      this.getTransactions()
      this.$emit('reload')
    },
    cancelDelete () {
      this.transactionId = null
      this.showDelete = false
    },
    selectPeriod (period) {
      this.period = period
      this.setUrlParam('period', period)
      this.getTransactions()
    },
    setUrlParam (name, value) {
      const queryParams = new URLSearchParams(window.location[name])
      queryParams.set(name, value)
      history.replaceState(null, null, '?' + queryParams.toString())
    },
    edit (transaction) {
      this.transaction = transaction
      this.showEdit = true
    },
    edited () {
      this.showEdit = false
      this.transaction = null
      this.getTransactions()
      this.NOTIFY({ text: 'Transaction edited successfully' })
      this.$emit('reload')
    },
    getColor (type) {
      if (type === 'buy') return 'bg-emerald-100 text-emerald-500'
      if (type === 'sell') return 'bg-red-100 text-red-500'
      if (['dividend', 'split', 'reversesplit'].includes(type)) return 'bg-yellow-100 text-yellow-500'
      return 'bg-gray-100 text-gray-500'
    }
  }
}
</script>
