<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-800 bg-opacity-80 transition-opacity" />
      </TransitionChild>

      <div class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="poptop relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-2xl sm:w-full sm:p-6">
              <template v-if="step === 0">
                <!-- 1. Welcome -->
                <img
                  src="@/assets/images/romain.png"
                  class="object-cover m-auto mt-4 mb-6 w-32 h-32 xl:w-32 xl:h-32 bg-center rounded-full" alt="Romain" />
                <div class="mt-2 text-center sm:mt-3 sm:mb-4">
                  <DialogTitle as="h3" class="text-2xl leading-6 font-medium text-gray-900">
                    {{ welcome }}
                  </DialogTitle>
                </div>
                <p class="text-lg text-gray-700 mb-2 text-center">
                  My name is <span class="text-gray-900">Romain</span>, I am the founder of Beanvest.<br />
                </p>
                <div class="flex flex-col w-1/2 mx-auto my-6 relative border border-gray-300 rounded-md px-3 py-3 shadow-sm focus-within:ring-1 focus-within:ring-emerald-600 focus-within:border-emerald-600">
                  <label for="name" class="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900">What's your first name?</label>
                  <input v-model="first_name" type="text" name="name" id="name" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-400 focus:ring-0 sm:text-sm" placeholder="John" />
                </div>
                <p class="text-sm text-gray-500 mb-6 text-center">
                  I hope this software will help you become a better investor.<br />
                  If you don't mind, I'll ask you a few questions before your get started.
                </p>
              </template>
              <template v-if="step === 1">
                <!-- 2. Invest question -->
                <p class="text-2xl leading-8 font-medium text-gray-900 text-center mb-6 max-w-lg mx-auto">
                  {{ first_name }}, how would you describe your investment approach?
                </p>
                <Options :options="investOptions" @select="updateInvest" />
              </template>
              <template v-if="step === 2 && user.upgradeSource === 'appsumo'">
                <!-- 3. AppSumo Review -->
                <div class="flex justify-center mb-5 mt-6">
                  <img class="w-10 animate-bounce" src="@/assets/images/tacos.svg" alt="tacos" />
                  <img class="w-10 animate-bounce" src="@/assets/images/tacos.svg" alt="tacos" />
                  <img class="w-10 animate-bounce" src="@/assets/images/tacos.svg" alt="tacos" />
                  <img class="w-10 animate-bounce" src="@/assets/images/tacos.svg" alt="tacos" />
                  <img class="w-10 animate-bounce" src="@/assets/images/tacos.svg" alt="tacos" />
                </div>
                <p class="text-2xl leading-6 font-medium text-gray-900 text-center mb-6">
                  Review Beanvest on AppSumo!
                </p>
                <p class="text-base text-gray-800 mb-10 text-center max-w-lg mx-auto">
                  The more people use Beanvest, the more awesome it will be for you.<br />
                  I'll be able to allocate more resources to build awesome features and gather a <a href="https://www.facebook.com/groups/beanvest" class="text-emerald-600 hover:underline" target="_blank">community of fellow investors</a>!
                </p>
                <div class="flex justify-center mb-6" @click="close">
                  <a href="https://appsumo.com/products/beanvest#reviews" target="_blank">
                    <button type="button" class="inline-flex items-center px-5 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none ring-8 ring-yellow-100">
                      <img class="w-6 mr-3" src="@/assets/images/tacos.svg" alt="tacos" />
                      Leave a Review
                    </button>
                  </a>
                </div>
                <div @click="close" class="flex justify-center text-sm text-gray-400 hover:text-emerald-600 cursor-pointer mt-10 mb-4 text-center">
                  Go to my Beanvest account first
                </div>
              </template>
              <template v-else-if="step === 2 && (user.source === 'producthunt' || user.source?.startsWith('https://www.producthunt.com'))">
                <!-- 3. PH Review -->
                <div class="flex justify-center mt-2 mb-3">
                  <img class="w-20" src="@/assets/images/product-hunt.svg" alt="ph" />
                </div>
                <p class="text-2xl leading-6 font-medium text-gray-900 text-center mb-6">
                  Review Beanvest on Product Hunt!
                </p>
                <p class="text-base text-gray-800 mb-10 text-center max-w-lg mx-auto">
                  The more people use Beanvest, the more awesome it will be for you.<br />
                  I'll be able to allocate more resources to build awesome features and gather a <a href="https://www.facebook.com/groups/beanvest" class="text-emerald-600 hover:underline" target="_blank">community of fellow investors</a>!
                </p>
                <div class="flex justify-center mb-6" @click="close">
                  <a href="https://appsumo.com/products/beanvest#reviews" target="_blank">
                    <button type="button" class="flex items-center px-6 py-3 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none ring-8 ring-red-100">
                      Leave a Review
                    </button>
                  </a>
                </div>
                <div @click="close" class="flex justify-center text-sm text-gray-400 hover:text-emerald-600 cursor-pointer mt-10 mb-4 text-center">
                  Go to my Beanvest account first
                </div>
              </template>
              <template v-else-if="step === 2">
                <!-- 3. Upgrade -->
                <div class="flex justify-center">
                  <div class="justify-center text-3xl font-extrabold text-gray-900 tracking-normal sm:text-xl xl:text-4xl xl:mt-8 underlinecolor text-center">
                    One Time Offer
                  </div>
                </div>
                <div class="max-w-sm mx-auto mt-4 text-center text-xl">Upgrade to Premium now and get <b>90% off on your first 2 months</b>.</div>
                <Pay class="max-w-xl mx-auto" @success="open = false" :embed="true" offer="90OFF2MONTHS" upgradeSource="welcome" />
                <!-- Features list -->
                <FeaturesSummary class="mb-4" />
                <div class="mx-auto cursor-pointer text-gray-400 text-center text-sm hover:text-red-600 transition-all duration-400" @click="open = false">No thank you <span class="text-xs">(This offer will never be shown again)</span></div>
              </template>
              <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse" v-if="step < 2">
                <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  :class="[goNext ? 'hover:bg-emerald-700 bg-emerald-600 focus:ring-emerald-500 focus:underline focus:font-bold' : 'hover:bg-gray-400 bg-gray-400 focus:ring-gray-200 cursor-default']" @click="nextStep">Next</button>
                <button v-if="step > 0" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:w-auto sm:text-sm" @click="step--">Back</button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { mapMutations } from 'vuex'
import Options from '@/components/Options'
import FeaturesSummary from '@/components/FeaturesSummary'
import Pay from '@/views/Pay'

export default {
  name: 'Welcome',
  components: {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    Options,
    Pay,
    FeaturesSummary
  },
  data () {
    return {
      open: true,
      step: 0,
      first_name: this.user.first_name || '',
      strategy: null,
      investOptions: [
        { id: 'value', name: 'Long-term investor', description: 'I am investing for the long-term' },
        { id: 'trading', name: 'Trader', description: 'I have mainly short-term positions' },
        { id: 'mixed', name: 'Mixed-strategy', description: 'I have mixed short-long strategy' },
        { id: 'other', name: 'Other' }
      ]
    }
  },
  props: {
    user: {
      type: Object,
      default: () => {}
    }
  },
  mounted () {
    if (!this.first_name?.length) this.step = 0
    else if (this.first_name?.length && !this.user.strategy) this.step = 1
    else if (this.user.strategy) this.step = 2
  },
  computed: {
    goNext () {
      if (this.step === 0 && this.first_name?.length > 1) return true
      if (this.step === 1 && this.strategy) return true
      if (this.step === 2 && this.strategy) return true
      return false
    },
    welcome () {
      if (this.user.source === 'appsumo') return 'Welcome Sumo-ling!'
      return 'Welcome to Beanvest!'
    }
  },
  methods: {
    ...mapMutations(['SET_USER']),
    getUser () {
      return this.$http.get('/user')
        .then(res => {
          this.SET_USER({ user: res.data })
        })
    },
    nextStep () {
      if (!this.goNext) return false
      if (this.step === 1) this.updateUser()
      this.step++
    },
    updateInvest (invest) {
      this.strategy = invest.id
    },
    updateUser () {
      return this.$http.patch('/user', {
        first_name: this.first_name,
        strategy: this.strategy
      })
    },
    close () {
      this.getUser()
      this.open = false
    }
  }
}
</script>

<style lang="scss" scoped>
.poptop {
  opacity: 0;
  animation: pop-top 1s ease-out forwards;
  animation-delay: .5s;
}
@keyframes pop-top {
  0% {
    transform: translateY(-3rem) scale(.90);
  }
  30% {
    opacity: 1;
    transform: translateY(1rem) scale(1.05);
  }
  50% {
    transform: none;
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.underlinecolor {
  background-image: linear-gradient(90deg,#e67399,#f2a640);
  background-position: bottom;
  background-size: 100% 30%;
  background-repeat: no-repeat;
  padding-bottom: 1px;
}
</style>
