import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/style/tailwind.css'
import api from '@/libs/api'
import fb from '@/libs/fb'
import ap from '@/libs/amplitude'

const app = createApp(App)

ap.init()

app.config.globalProperties.$http = api
app.config.globalProperties.$track = (event, options = {}) => {
  options = Object.assign({ facebook: true, amplitude: true }, options)
  if (options.amplitude) ap.track(event)
  if (options.facebook) fb.track(event)
}

app
  .use(store)
  .use(router)
  .mount('#app')
