<template>
  <div class="fixed z-20 inset-0 overflow-y-auto">

    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <!--
        Background overlay, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100"
          To: "opacity-0"
      -->
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
      <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      -->
      <div class="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full overflow-visible" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 rounded-lg">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Edit transaction ({{ transaction.symbol }})
          </h3>
          <h4 class="text-sm leading-6 font-medium text-gray-400">
            {{ transaction.units }} x {{ transaction.price }} {{ transaction.stockId.currency }} = {{ total }} {{ transaction.stockId.currency }}
          </h4>
          <div class="mt-6 sm:mt-5 space-y-5 sm:space-y-3">
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label for="date" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Date
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <DatePicker :date="transaction.date" @select="selectDate" />
              </div>
            </div>

            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label for="stock" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Name
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <StockSearch :value="currentTransaction.stockId" @select="selectStock" :class="[
                  'sm:col-span-3',
                  { 'border-red-400': showErrors && !currentTransaction.stockId }
                ]" />
              </div>
            </div>

            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label for="type" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Operation
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <select
                  v-model="currentTransaction.type"
                  name="type"
                  class="sm:col-span-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 placeholder-gray-400 focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm rounded-md">
                  <option v-for="type in types" :key="type" :value="type">{{ type }}</option>
                </select>
              </div>
            </div>

            <div v-if="!['dividend', 'fee'].includes(currentTransaction.type)" class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label for="quantity" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Quantity
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input type="number" min="1" step="1" v-model="currentTransaction.units" placeholder="Quantity" :class="[
                  'sm:col-span-1 block w-full text-base border-gray-300 placeholder-gray-400 focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm rounded-md',
                  { 'border-red-400': showErrors && (!currentTransaction.units || isNaN(currentTransaction.units) || currentTransaction.units <= 0) }
                ]" />
              </div>
            </div>

            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label for="price" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Price
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <div class="sm:col-span-2 relative">
                  <input type="text" v-model="currentTransaction.price" placeholder="Price" :class="[
                      'block w-full pl-3 pr-10 py-2 text-base border-gray-300 placeholder-gray-400 focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm rounded-md',
                      { 'border-red-400': showErrors && (!currentTransaction.price || isNaN(currentTransaction.price) || currentTransaction.price < 0) }
                    ]" />
                  <div v-if="currency" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span class="text-gray-400 sm:text-sm" id="price-currency">
                      {{ currency }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label for="fee" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Fee
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <div class="sm:col-span-2 relative">
                  <input type="text" v-model="currentTransaction.fee" placeholder="Fee" :class="[
                    'block w-full pl-3 pr-10 py-2 text-base border-gray-300 placeholder-gray-400 focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm rounded-md',
                    { 'border-red-400': showErrors && (!currentTransaction.fee || isNaN(currentTransaction.fee) || currentTransaction.price < 0) }
                  ]" />
                  <div v-if="currency" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span class="text-gray-400 sm:text-sm" id="fee-currency">
                      {{ currency }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label for="quantity" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Notes
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input type="text" v-model="currentTransaction.comments" placeholder="You can add comments (optional)" :class="[
                  'sm:col-span-1 block w-full text-base border-gray-300 placeholder-gray-400 focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm rounded-md'
                ]" />
              </div>
            </div>
          </div>
        </div>
        <div class="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <span class="flex w-full rounded-md sm:ml-3 sm:w-auto">
            <button @click="editTransaction()" type="button" class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 text-base leading-6 font-medium text-white bg-emerald-600 hover:bg-emerald-500 focus:outline-none focus:shadow-outline-emerald focus:border-emerald-700 active:bg-emerald-700 transition ease-in-out duration-150 sm:text-sm sm:leading-5">
              Update transaction
            </button>
          </span>
          <span class="mt-3 flex w-full rounded-md sm:mt-0 sm:w-auto">
            <button @click="cancel()" type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
              Cancel
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from '@/components/DatePicker'
import StockSearch from '@/components/StockSearch'
export default {
  name: 'EditTransaction',
  props: {
    transaction: {
      type: Object
    }
  },
  components: {
    DatePicker,
    StockSearch
  },
  data () {
    return {
      types: ['buy', 'sell', 'dividend', 'split', 'reversesplit', 'fee'],
      dropdownShow: null,
      showOptions: false,
      stocks: [],
      search: {},
      showErrors: false,
      currency: this.transaction.stockId.currency,
      currentTransaction: Object.assign({}, this.transaction),
      total: Math.round(this.transaction.units * this.transaction.price * 100) / 100
    }
  },
  methods: {
    editTransaction () {
      if (['dividend', 'fee'].includes(this.currentTransaction.type)) this.currentTransaction.units = 0
      return this.$http.patch(`/transaction/${this.currentTransaction._id}`, this.currentTransaction).then(res => {
        this.$emit('edited')
      })
    },
    findStock (q) {
      const path = q ? `/stock?q=${q}` : '/stock'
      this.$http.get(path).then(res => {
        this.stocks = res.data
      })
    },
    selectStock (stock, i) {
      this.currentTransaction.stockId = stock
      this.currentTransaction.symbol = stock.symbol
      this.currentTransaction.stockId = stock._id
      this.currentTransaction.price = stock.price
      this.search[i] = stock.name
      this.dropdownShow = null
      this.currency = stock.currency
    },
    cancel () {
      this.$emit('cancel')
    },
    setActiveSearch (i) {
      this.search[i] = null
      if (this.showOptions && this.dropdownShow !== i) this.showOptions = false
      this.dropdownShow = i
      this.findStock()
    },
    selectDate (date) {
      this.currentTransaction.date = date
    }
  },
  watch: {
    search: {
      handler (search) {
        return this.findStock(search[this.dropdownShow])
      },
      deep: true
    }
  }
}
</script>
