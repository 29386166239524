<template>
  <div>
    <div class="relative origin-top-right">
      <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
        <div
          v-if="showOptions"
          class="dropdown bg-white z-10 origin-top-center absolute left-0 top-0 mt-11 w-2xl sm:w-7xl rounded-md shadow-lg cursor-pointer ring-1 ring-black ring-opacity-5">
          <div class="rounded-md bg-white shadow-xs" role="menu" aria-orientation="vertical" aria-labelledby="stocks-menu">
            <div>
              <div class="sm:block bg-gray-50">
                <div class="border-b border-gray-200">
                  <nav class="-mb-px flex space-x-2" aria-label="Types">
                    <div
                      @click="selectedType = type.name"
                      v-for="type in types"
                      :key="type.name"
                      :class="[selectedType === type.name ? 'border-emerald-500 text-emerald-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200', 'whitespace-nowrap flex py-2 px-2 border-b-2 font-medium text-xs']"
                      :aria-current="selectedType === type.name ? 'page' : undefined">
                      {{ type.name || 'All' }}
                      <span
                        v-if="type.count"
                        :class="[selectedType === type.name ? 'bg-emerald-100 text-emerald-600' : 'bg-gray-100 text-gray-500', 'hidden ml-2 py-0 px-1.5 rounded-full font-medium text-xs md:inline-block']">
                        {{ type.count }}
                      </span>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
            <div
              class="block px-4 py-5 text-xs text-gray-400"
              v-if="loading">
              <Loader text="Searching..." />
            </div>
            <div
              class="block px-4 py-5 text-xs text-gray-400"
              v-else-if="!stocks.length">
              No asset found corresponding to "{{ search }}"
            </div>
            <div
              v-else
              v-for="stock in filteredStocks"
              :key="stock._id"
              @click="selectStock(stock)"
              class="flex px-4 py-2 text-xs text-gray-700 hover:bg-gray-50 hover:text-emerald-500 text-left"
              role="menuitem">
              <div class="flex-col flex-shrink-0 flex h-8 w-8 items-center mr-2">
                <img v-if="stock.image" class="rounded-sm w-8" :src="stock.image" :alt="stock.name">
                <div v-else class="bg-gray-100 w-8 h-8 rounded-sm inline-flex items-center justify-center">
                  <span class="text-xs font-medium leading-none text-gray-300">{{ (stock.name || stock.symbol)?.charAt(0)?.toUpperCase() }}</span>
                </div>
              </div>
              <div class="flex flex-col">
                <div class="flex flex-row">
                  <span class="font-bold text-xs mr-2">{{ stock.symbol }}</span>
                  <span>{{ stock.name }}</span>
                </div>
                <div class="flex flex-row text-gray-400 text-xs">
                  {{ stock.exchange }} <span class="text-gray-200 mx-1">•</span> {{ stock.currency }} <span class="text-gray-200 mx-1">•</span> {{ stock.isin }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div class="relative">
      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <MagnifyingGlassIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
      <input
        @focus="focus()"
        @keydown.escape="cancel($event)"
        @keydown.native.escape="cancel($event)"
        @keydown.enter="select($event)"
        @keydown.native.enter="select($event)"
        v-model="search"
        type="text"
        placeholder="Search by name, ticker, ISIN..."
        class="z-1 block w-full pl-10 pr-3 py-2 text-base border-gray-300 placeholder-gray-400 focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm overflow-hidden"
        :class="[rounded ? 'rounded-full bg-dk' : 'rounded-md']" />    </div>
  </div>
</template>

<script>
import { MagnifyingGlassIcon } from '@heroicons/vue/24/solid'
import Loader from './Loader.vue'

export default {
  name: 'StockSearch',
  props: {
    i: {
      type: Number,
      default: 0
    },
    value: {
      type: Object
    },
    rounded: {
      type: Boolean,
      default: false
    },
    persist: {
      type: Boolean,
      default: true
    }
  },
  components: {
    MagnifyingGlassIcon,
    Loader
  },
  data () {
    return {
      showOptions: false,
      selectedType: null,
      stocks: [],
      search: '',
      timeout: null,
      loading: false
    }
  },
  mounted () {
    if (this.value && this.value.name) {
      this.search = this.value.name
    }
  },
  methods: {
    findStock (q) {
      const path = q ? `/stock?q=${q}` : '/stock'
      this.$http.get(path).then(res => {
        this.stocks = res.data
        if (this.selectedType && !this.filteredStocks.length) this.selectedType = null
        this.loading = false
      })
    },
    selectStock (stock, i) {
      if (this.persist) this.search = stock.name
      else this.search = ''
      this.showOptions = false
      this.$emit('select', stock, this.i)
    },
    focus () {
      this.search = null
      this.showOptions = true
      this.$emit('focus')
      this.findStock()
    },
    cancel (event) {
      this.search = ''
      this.showOptions = false
      event.target.blur()
    },
    select (event) {
      if (this.stocks[0]) this.selectStock(this.stocks[0])
      this.cancel(event)
    }
  },
  watch: {
    search () {
      this.loading = true
      if (this.timeout) clearTimeout(this.timeout)
      const t = this
      this.timeout = setTimeout(() => {
        return t.findStock(t.search)
      }, 500)
    }
  },
  computed: {
    filteredStocks () {
      if (!this.selectedType) return this.stocks
      return this.stocks.filter(s => s.type === this.selectedType)
    },
    types () {
      let total = 0
      const types = {}
      for (const stock of this.stocks) {
        if (!types[stock.type]) types[stock.type] = 1
        else types[stock.type]++
      }
      const res = []
      for (const type of Object.keys(types)) {
        res.push({ name: type, count: types[type] })
        total += types[type]
      }
      res.unshift({ name: null, count: total })
      return res
    }
  }
}
</script>

<style scoped>
.dropdown {
  width: 500px;
  max-height: 250px;
  margin-bottom: 20px;
  overflow-y: auto;
}
.bg-dk {
  background: #fbfaf7;
  padding-top: 6px;
  padding-bottom: 6px;
}
</style>
