<template>
  <div class="group flex gap-2 mr-4 opacity-80 hover:opacity-100 transition-all duration-400" v-if="portfolio && integrations?.length">
    <router-link :to="`/settings/integrations/${integration._id}`" class="flex items-center justify-between gap-1.5" v-for="(integration, i) in integrations" :key="i">
      <img
        v-if="integration.name"
        class="rounded-md w-5"
        :src="getImage(integration.name)"
        :alt="integration.name" />
      <div class="text-xs text-red-700" v-if="integration?.error">
        {{ integration?.error }}
      </div>
      <div class="text-xs text-gray-800" v-else>
        Updated {{ formatDate(integration?.updatedAt) }}
      </div>
    </router-link>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    portfolio: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      integrations: []
    }
  },
  async mounted () {
    this.listIntegrations()
  },
  methods: {
    getImage (id) {
      return require(`@/assets/images/integrations/icon/${id}.svg`)
    },
    async listIntegrations () {
      const integrations = (await this.$http.get('/integrations')).data
      this.integrations = integrations.filter(i => i.portfolioId === this.portfolio._id)
    },
    formatDate (date) {
      return moment(date).utc().fromNow()
    }
  }
}
</script>
