<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <Listbox as="div" v-model="selected">
    <ListboxLabel v-if="label" class="block text-sm font-medium text-gray-700">{{ label }}</ListboxLabel>
    <div class="mt-1 relative">
      <ListboxButton class="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm">
        <span class="block truncate" :class="[{ 'text-gray-400': !selected?.name }]">{{ selected?.name || placeholder }}</span>
        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
      </ListboxButton>

      <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
          <ListboxOption as="template" v-for="option in options" :key="option.id" :value="option" v-slot="{ active, selected }">
            <li :class="[active ? 'text-white bg-emerald-600' : 'text-gray-900', 'cursor-pointer select-none relative py-2 pl-8 pr-4']">
              <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                {{ option.name || '-' }}
              </span>
              <span v-if="option.secondary" class="ml-2 truncate text-gray-400 group-hover:text-white">{{ option.secondary }}</span>
              <span v-if="selected" :class="[active ? 'text-white' : 'text-emerald-600', 'absolute inset-y-0 left-0 flex items-center pl-1.5']">
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script>
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/24/solid'

export default {
  components: {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    ChevronUpDownIcon
  },
  props: {
    name: {
      type: String
    },
    label: {
      type: String
    },
    options: {
      type: Array,
      default: () => []
    },
    defaultOption: {
      type: Object
    },
    placeholder: {
      type: String,
      default: 'Select an option'
    }
  },
  mounted () {
    if (this.defaultOption) this.selected = this.defaultOption
  },
  data () {
    return {
      selected: null
    }
  },
  watch: {
    selected: function (option) {
      this.$emit('select', option, this.name)
    }
  }
}
</script>
