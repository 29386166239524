<template>
<div>
  <div class="sm:rounded-lg">
    <section class="text-gray-700 body-font">
      <div class="container px-5 py-6 md:py-20 xxl:w-2/3 mx-auto">
        <div class="flex flex-wrap -m-2">
          <div v-if="!portfolios.length && !loading" class="align-center mx-auto">
            <h3 class="text-3xl font-medium text-emerald-800 align-center mx-auto mb-4">
              Create a portfolio to add your current stocks
            </h3>
          </div>

          <div class="w-full sm:w-1/2 mx-auto" v-else>
            <h1
              class="mb-3 text-2xl md:text-3xl md:leading-tight font-semibold text-gray-900">
              {{ randomGreetings }}
            </h1>
            <div class="relative mb-8 flex justify-center">
              <span class="px-2 text-sm text-gray-500">
                {{ randomSentence }}
              </span>
            </div>
            <Loader v-if="loading" class="mx-auto my-24" text="Loading portfolios" />
            <div v-else class="bg-white shadow overflow-hidden rounded-md">
              <ul class="divide-y divide-gray-200">
                <li v-for="portfolio in portfolios" :key="portfolio.id">
                  <router-link :to="`/portfolio/${portfolio._id}`" class="group block transition-all bg-white duration-300 hover:bg-yellow-50/20 text-gray-800 hover:text-gray-950">
                    <div class="px-4 py-4 flex items-center sm:px-6">
                      <div class="min-w-0 flex-1 flex items-center justify-between">
                        <div class="">
                          <div class="flex items-center gap-2">
                            <div class="flex-shrink-0" v-if="connectedPortfolios[portfolio._id]">
                              <img
                                v-if="connectedPortfolios[portfolio._id]?.name"
                                class="rounded-md w-7"
                                :src="getImage(connectedPortfolios[portfolio._id]?.name)"
                                :alt="connectedPortfolios[portfolio._id]?.name" />
                              <span :class="['bg-gray-800 inline-flex items-center justify-center h-5 w-5 rounded-lg']" v-else>
                                <ArrowsRightLeftIcon class="h-3 w-3 text-white" aria-hidden="true" />
                              </span>
                            </div>
                            <p class="group-hover:font-semibold group-hover:translate-x-2 transition duration-200">{{ portfolio.name }}</p>
                          </div>
                        </div>
                        <div class="flex items-center justify-end gap-x-2 sm:w-5/12 sm:flex-none">
                          <div class="hidden sm:block text-right sm:w-1/2">
                            <p class="text-sm leading-6 text-gray-500 group-hover:text-gray-900" v-if="portfolio.currentValue">{{ formatNumber(portfolio.currentValue, user.numberFormat, portfolio.currency) }}</p>
                          </div>
                          <div class="hidden sm:block text-right sm:w-1/2">
                            <p
                              v-if="portfolio.percent"
                              class="text-sm text-right font-semibold leading-6"
                              :class="[
                                { 'text-emerald-600': portfolio.percent > 0 },
                                { 'text-red-600': portfolio.percent < 0 }
                              ]">{{ portfolio.percent ? (portfolio.percent > 0 ? '+' : '') + (portfolio.percent || 0) + '%' : '-' }}</p>
                          </div>
                          <ChevronRightIcon class="ml-3 h-5 w-5 flex-none text-green-800/20 opacity-0 group-hover:opacity-100 group-hover:translate-x-2 transition-all delay-200 duration-400" aria-hidden="true" />
                        </div>
                      </div>
                    </div>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <DividerButton v-if="!loading" class="mt-12 w-full sm:w-1/2 mx-auto" text="Create new portfolio" @action="createPortfolio" />
      </div>
    </section>

  </div>
</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { ArrowsRightLeftIcon } from '@heroicons/vue/24/outline'
import { ChevronRightIcon } from '@heroicons/vue/24/solid'
import DividerButton from '@/components/DividerButton'
import Loader from '@/components/Loader'
import format from '@/mixins/format'

export default {
  name: 'Portfolios',
  components: {
    ChevronRightIcon,
    DividerButton,
    Loader,
    ArrowsRightLeftIcon
  },
  mixins: [format],
  data () {
    return {
      loading: true,
      name: 'My portfolio',
      greetings: [
        'Welcome',
        'Hello',
        'Hey',
        'Aloha',
        'Bonjour',
        'What\'s up',
        'Ola',
        'Howdy',
        'Ahoy',
        'Konnichiwa'
      ],
      sentences: [
        'Check how your portfolios are doing today',
        'Hope you are doing well',
        'Get ready to check your portfolios',
        'Here are your portfolios',
        'Today is a good day to check your investments',
        'Here are your portfolios',
        'Let\'s check your investments'
      ],
      showUpgrade: false,
      integrations: [],
      connectedPortfolios: {}
    }
  },
  computed: {
    ...mapState(['user', 'portfolios']),
    randomSentence () {
      const date = new Date()
      const day = date.getDate()
      return this.sentences[day % this.sentences.length]
    },
    randomGreetings () {
      if (!this.user.first_name) return 'Welcome to Beanvest'
      const date = new Date()
      const day = date.getDate()
      return this.greetings[day % this.greetings.length] + ' ' + this.user.first_name
    }
  },
  async mounted () {
    this.listPortfolios()
    this.listIntegrations()
  },
  methods: {
    ...mapMutations(['SET_PORTFOLIOS', 'NOTIFY', 'SHOW_UPGRADE']),
    createPortfolio (name) {
      const payload = {}
      if (name && name.length > 0) payload.name = name
      if (this.user.plan === 'free' && this.portfolios.length) {
        this.SHOW_UPGRADE('create-portfolio')
        return
      } else if (this.user.plan === 'premium' && this.portfolios.length >= 30) {
        this.NOTIFY({ text: 'You already have 30 portfolios! Please contact me at romain@beanvest.com to unlock more.' })
        return
      }
      return this.$http.post('/portfolio', payload).then(res => {
        this.$router.push(`/portfolio/${res.data._id}`, payload)
      })
    },
    listPortfolios () {
      this.loading = true
      return this.$http.get('/portfolio').then(res => {
        this.SET_PORTFOLIOS(res.data)
        this.loading = false
      })
    },
    async listIntegrations () {
      this.integrations = (await this.$http.get('/integrations')).data
      if (this.integrations) {
        for (const i of this.integrations) {
          this.connectedPortfolios[i.portfolioId] = i
        }
      }
    },
    getImages (portfolio) {
      return portfolio.stockIds.filter(s => s.image).slice(0, 3)
    },
    getImage (id) {
      return require(`@/assets/images/integrations/icon/${id}.svg`)
    }
  }
}
</script>

<style lang="scss" scoped>
  .folio {
    .planet {
      transform: scale(.85);
    }
    .details {
      display: none;
    }
  }
  .folio:hover {
    .details {
      display: inline-flex;
    }
    .planet {
      transition: .2s all ease-out;
      transform: scale(.9);
      transform: rotate(8deg);
    }
  }
</style>
