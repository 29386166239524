export default {
  methods: {
    getLink (stock) {
      const name = (stock.name || '')
        .toLowerCase()
        .replace(/(inc|company|corporation|co)(\.)?( |$)/g, '')
        .replace(/&/g, '')
        .replace(/(\.|,)/g, '')
        .trim()
        .replace(/\s/g, '-')
      return `/app/asset/${stock.symbol}/${name}`
    }
  }
}
