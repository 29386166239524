<template>
  <div>
    <div class="bg-white shadow sm:rounded-lg max-w-7xl mx-auto pt-4 mb-5">
      <div class="bg-white px-3 py-5 border-b border-gray-200 sm:px-6">
        <Filters @change="update" />
      </div>
      <Results :results="results" :selectedFields="selectedFields" @sort="sortBy" />
    </div>
  </div>
</template>

<script>
import Filters from './Filters'
import Results from './Results'

export default {
  name: 'Screener',
  components: {
    Filters,
    Results
  },
  data () {
    return {
      results: [],
      count: 0,
      filters: [],
      sort: {},
      sortOrder: 1
    }
  },
  mounted () {
    this.updateFilters()
  },
  computed: {
    selectedFields () {
      return [...this.filters].map(f => f.field)
    }
  },
  methods: {
    async updateFilters () {
      this.results = (await this.$http.post('/stock'))?.data?.results
    },
    async search () {
      const remappedFilters = this.filters.map(f => ({ field: f.field?.id || f.field, operator: f.operator, value: f.value?.id || f.value }))
      const res = (await this.$http.post('/stock', { filters: remappedFilters, sort: this.sort }))?.data
      this.results = res?.results
      this.count = res?.count
    },
    async update (filters) {
      this.filters = [...filters]
      await this.search()
    },
    async sortBy (sort) {
      if (this.sort.by === sort) this.sort.order = -(this.sort.order || 1)
      else this.sort.order = -1
      this.sort.by = sort
      await this.search()
    }
  }
}
</script>
