<template>
<div class="antialiased sans-serif">
  <div>
    <div class="container mx-auto">
      <div class="">
        <div class="relative">
          <!--<input type="hidden" name="date" v-model="date">-->
          <input
            type="text"
            readonly
            v-model="datepickerValue"
            @click="showDatepicker = !showDatepicker"
            @keydown.escape="showDatepicker = false"
            class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 placeholder-gray-400 focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm rounded-md overflow-hidden"
            :class="{ 'text-gray-300': showDatepicker }"
            placeholder="Select date">
            <div class="absolute top-0 right-0 px-3 py-2">
              <CalendarIcon class="h-5 w-5 text-gray-300" :class="{ 'text-emerald-600': showDatepicker }" />
            </div>

            <div
              class="bg-white z-20 mt-10 rounded-lg shadow-lg p-4 absolute top-0 left-0"
              style="width: 17rem"
              v-if="showDatepicker">
              <!-- @click.away="showDatepicker = false"> -->

              <div class="flex justify-between items-center mb-2 align-middle">
                <div class="flex justify-between items-center">
                  <button
                    type="button"
                    class="transition ease-in-out duration-100 inline-flex cursor-pointer text-gray-400 p-1 hover:bg-emerald-50 hover:text-emerald-600 rounded-full"
                    :class="{'cursor-not-allowed opacity-25': year == 1950 && month === 0 }"
                    :disabled="year == 1950 && month === 0"
                    @click="previousYear()">
                    <ChevronDoubleLeftIcon class="h-4 w-4 inline-flex" />
                  </button>
                  <button
                    type="button"
                    class="transition ease-in-out duration-100 inline-flex cursor-pointer text-gray-400 p-1 hover:bg-emerald-50 hover:text-emerald-600 rounded-full"
                    :class="{'cursor-not-allowed opacity-25': year == 1950 && month === 0 }"
                    :disabled="year == 1950 && month === 0"
                    @click="previousMonth()">
                    <ChevronLeftIcon class="h-4 w-4 inline-flex" />
                  </button>
                </div>
                <div class="flex justify-between items-center">
                  <span class="text-base text-gray-900 mr-2">{{ months[month] }}</span>
                  <span class="text-base text-gray-900">{{ year }}</span>
                </div>
                <div class="flex justify-between items-center">
                  <button
                    type="button"
                    class="transition ease-in-out duration-100 inline-flex cursor-pointer text-gray-400 p-1 hover:bg-emerald-50 hover:text-emerald-600 rounded-full"
                    :class="{'cursor-not-allowed opacity-25': nextMonthDisabled }"
                    :disabled="nextMonthDisabled"
                    @click="nextMonth()">
                    <ChevronRightIcon class="h-4 w-4 inline-flex" />
                  </button>
                  <button
                    type="button"
                    class="transition ease-in-out duration-100 inline-flex cursor-pointer text-gray-400 p-1 hover:bg-emerald-50 hover:text-emerald-600 rounded-full"
                    :class="{'cursor-not-allowed opacity-25': nextYearDisabled }"
                    :disabled="nextYearDisabled"
                    @click="nextYear()">
                    <ChevronDoubleRightIcon class="h-4 w-4 inline-flex" />
                  </button>
                </div>
              </div>

              <div class="mb-1 mx-1 w-full grid grid-cols-7 text-center">
                <div v-for="day in days" :key="day">
                  <div
                    style="width: 14.28%"
                    class="text-center p-1 text-xs font-small text-gray-400">
                    {{ day }}
                  </div>
                </div>
              </div>

              <div class="flex flex-wrap -mx-1">
                <div
                  v-for="(blankday, i) in blankdays"
                  :key="`${blankday}-${i}`"
                  style="width: 14.28%"
                  class="text-center border p-1 border-transparent text-sm">
                </div>
                <div
                  v-for="(date, dateIndex) in no_of_days"
                  style="width: 14.28%"
                  class="px-1 mb-1"
                  :key="`day-${date}-${dateIndex}`">
                  <div
                    @click="getDateValue(date)"
                    class="cursor-pointer text-center text-sm rounded-full leading-loose transition ease-in-out duration-100"
                    :class="{
                      'bg-emerald-500 text-white': isToday(date) == true,
                      'text-gray-700 hover:bg-gray-50 hover:text-emerald-500': isToday(date) == false,
                      'text-gray-200 cursor-not-allowed opacity-25': !enabledDay(date)
                      }">
                    {{ date }}
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, ChevronLeftIcon, ChevronRightIcon, CalendarIcon } from '@heroicons/vue/24/outline'

export default {
  name: 'DatePicker',
  props: {
    date: {
      type: Date,
      default: null
    },
    nextDisabled: {
      type: Boolean,
      default: () => true
    }
  },
  components: {
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    CalendarIcon
  },
  data () {
    return {
      showDatepicker: false,
      datepickerValue: '',
      month: '',
      year: '',
      no_of_days: [],
      blankdays: [],
      days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    }
  },
  mounted () {
    this.initDate()
    this.getNoOfDays()
  },
  computed: {
    nextMonthDisabled () {
      const now = new Date()
      if (this.month === now.getMonth() && this.year === now.getFullYear()) {
        return true
      }
      return false
    },
    nextYearDisabled () {
      const now = new Date()
      if (this.year === now.getFullYear()) {
        return true
      }
      return false
    }
  },
  methods: {
    enabledDay (day) {
      const now = new Date()
      if (day <= now.getDate()) return true
      if (this.month === now.getMonth() && this.year === now.getFullYear()) return !this.nextDisabled
      return true
    },
    previousMonth () {
      if (this.month > 0) this.month--
      else {
        this.month = 11
        this.year--
      }
      this.getNoOfDays()
    },
    nextMonth () {
      if (this.month < 11) this.month++
      else {
        this.month = 0
        this.year++
      }
      this.getNoOfDays()
    },
    previousYear () {
      if (this.year > 1900) this.year--
      this.getNoOfDays()
    },
    nextYear () {
      const now = new Date()
      const currentYear = now.getFullYear()
      const currentMonth = now.getMonth()
      if (this.year === currentYear - 1) {
        this.year++
        if (this.month > currentMonth) this.month = currentMonth
      } else if (this.year < currentYear) this.year++
      this.getNoOfDays()
    },
    initDate () {
      const date = this.date ? new Date(this.date) : new Date()
      this.month = date.getMonth()
      this.year = date.getFullYear()
      this.datepickerValue = new Date(this.year, this.month, date.getDate()).toDateString()
      this.$emit('select', date)
    },
    isToday (date) {
      const today = new Date()
      const d = new Date(this.year, this.month, date)
      return today.toDateString() === d.toDateString()
    },
    getDateValue (date) {
      if (!this.enabledDay(date)) return false
      const selectedDate = new Date(this.year, this.month, date)
      this.datepickerValue = selectedDate.toDateString()
      this.$emit('select', selectedDate)
      this.showDatepicker = false
      // this.$refs.date.value = selectedDate.getFullYear() + '-' + ('0' + selectedDate.getMonth()).slice(-2) + '-' + ('0' + selectedDate.getDate()).slice(-2)
    },
    getNoOfDays () {
      const daysInMonth = new Date(this.year, this.month + 1, 0).getDate()
      // find where to start calendar day of week
      const dayOfWeek = new Date(this.year, this.month).getDay()
      const blankdaysArray = []
      for (let i = 1; i <= dayOfWeek; i++) {
        blankdaysArray.push(i)
      }
      const daysArray = []
      for (let j = 1; j <= daysInMonth; j++) {
        daysArray.push(j)
      }
      this.blankdays = blankdaysArray
      this.no_of_days = daysArray
    }
  }
}
</script>
