<template>
  <div class="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
    <section aria-labelledby="integrations_details_heading">
      <div class="shadow sm:rounded-md sm:overflow-hidden relative">
        <ArrowLeftIcon class="w-5 h-5 m-4 text-gray-300 float-left hover:text-emerald-600 cursor-pointer" @click="cancel" />
        <span
          class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800 absolute top-4 right-4">
          beta
        </span>
        <div class="bg-white py-6 px-4 sm:p-6" v-if="connected">
          <div class="mb-4">
            <div class="px-12 py-12 sm:p-6">
              <img class="mx-auto w-32 mt-2" :src="getImage(connected.name)" :alt="connected.name">
              <div class="mt-6 mx-auto text-center text-m text-gray-500" v-if="connected?.credentials?.username && !connected.error">
                <span class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-emerald-100 text-emerald-800">
                  <svg class="-ml-0.5 mr-1.5 h-2 w-2 text-emerald-400" fill="currentColor" viewBox="0 0 8 8">
                    <circle cx="4" cy="4" r="3" />
                  </svg>
                  Connected as {{ connected?.credentials?.username }}
                </span>
                <p v-if="connected?.updatedAt" class="text-xs text-gray-400 mt-1">Last update {{ formatDate(connected?.updatedAt) }}</p>
                <p v-else class="text-xs text-gray-400 mt-1">Account has not been synchronized yet</p>
              </div>
              <div class="mt-6 mx-auto text-center text-m text-gray-500" v-else-if="connected?.credentials?.username && connected.error">
                <span class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                  <svg class="-ml-0.5 mr-1.5 h-2 w-2 text-red-400" fill="currentColor" viewBox="0 0 8 8">
                    <circle cx="4" cy="4" r="3" />
                  </svg>
                  <span v-if="connected?.error === 'Unauthorized'">Please reconnect your account</span>
                  <span v-else>{{ connected?.error }}</span>
                </span>
                <!-- OTP update -->
                <div class="sm:max-w-xl sm:mx-auto lg:mx-auto my-4">
                  <div class="sm:flex max-w-sm mx-auto">
                    <div class="min-w-0 flex-1 mx-auto">
                      <input
                        @keyup.enter="updateOTP"
                        v-model="oneTimePassword"
                        placeholder="Enter your One Time Password"
                        type="text"
                        name="otp"
                        id="otp"
                        class="sm:max-w-xl block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-700 focus:border-gray-700 sm:text-sm" />
                    </div>
                    <div class="sm:ml-3">
                      <button @click="updateOTP" class="bg-emerald-500 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-600">
                        Reconnect
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="sm:hidden">
                  <label for="tabs" class="sr-only">Select a tab</label>
                  <select id="tabs" name="tabs" class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm rounded-md">
                    <option :selected="!showLogs" @click="showLogs = false">
                      Settings
                    </option>
                    <option :selected="showLogs" @click="showLogs = true">
                      Logs
                    </option>
                  </select>
                </div>
                <div class="hidden sm:block">
                  <div class="border-b border-gray-200">
                    <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                      <div @click="showLogs = false" :class="[!showLogs ? 'border-emerald-500 text-emerald-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200', 'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm cursor-pointer']" :aria-current="showLogs ? 'page' : undefined">
                        Settings
                      </div>
                      <div @click="showLogs = true" :class="[showLogs ? 'border-emerald-500 text-emerald-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200', 'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm cursor-pointer']" :aria-current="showLogs ? 'page' : undefined">
                        Logs
                        <span v-if="connected.logs.length" :class="[showLogs ? 'bg-emerald-100 text-emerald-600' : 'bg-gray-100 text-gray-900', 'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block']">{{ connected.logs.length }}</span>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
              <div class="flow-root bg-gray-50" v-if="showLogs">
                <div class="p-4 text-sm text-gray-400 line-clamp-2" v-if="!connected.logs?.length">
                  This integration has not been synchronized yet. This may take up to 15 minutes.
                </div>
                <ul role="list" class="divide-y divide-gray-200" v-else>
                  <li v-for="(log, i) in connected.logs" :key="i" class="py-2 px-3">
                    <div class="relative focus-within:ring-2 focus-within:ring-emerald-500">
                      <p class="mt-1 text-sm text-gray-600 line-clamp-2">
                        {{ log }}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div v-else>
                <p class="mt-4 mb-5 text-sm text-gray-600">Select the portfolio you want to synchronize with {{ integrationName }}:</p>
                <Options
                  v-if="!loading"
                  :options="portfolioOptions"
                  :defaultOption="selectedPortfolio"
                  @select="updateSelectedPortfolio" />
              </div>
            </div>
          </div>
        </div>
        <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
          <button @click="disconnect" type="submit" class="bg-red-500 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-900">
            Disconnect
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import moment from 'moment'
import Options from '@/components/Options'
import { ArrowLeftIcon } from '@heroicons/vue/24/outline'

export default {
  name: 'Integration',
  components: {
    Options,
    ArrowLeftIcon
  },
  data () {
    return {
      loading: false,
      connected: null,
      oneTimePassword: null,
      selectedPortfolio: null,
      showLogs: false
    }
  },
  async mounted () {
    this.loading = true
    await this.load()
    await this.listPortfolios()
    this.loading = false
  },
  computed: {
    ...mapState(['portfolios']),
    integrationId () {
      return this.$route.params.id
    },
    integrationName () {
      return this.connected.name.charAt(0).toUpperCase() + this.connected.name.slice(1)
    },
    portfolioOptions () {
      if (!this.portfolios) return []
      return this.portfolios.map(p => ({
        id: p._id,
        name: p.name
      }))
    }
  },
  methods: {
    ...mapMutations(['NOTIFY', 'SET_PORTFOLIOS']),
    async load () {
      const a = await this.$http.get('/integrations')
      this.connected = a.data.find(i => i._id === this.integrationId)
    },
    getImage (id) {
      return require(`../../assets/images/integrations/${id}.svg`)
    },
    async disconnect () {
      this.loading = true
      await this.$http.delete(`/integration/${this.integrationId}`)
      this.loading = false
      this.NOTIFY({ text: 'Integrations has been disconnected' })
      this.cancel()
    },
    async updateOTP () {
      if (!this.oneTimePassword || this.oneTimePassword.length !== 6) return
      this.loading = true
      await this.$http.patch(`/integration/${this.integrationId}`, {
        oneTimePassword: this.oneTimePassword
      })
      this.loading = false
      this.NOTIFY({ text: 'Integration has been reconnected' })
      await this.load()
    },
    cancel () {
      this.$router.push('/settings/integrations')
    },
    async listPortfolios () {
      const res = await this.$http.get('/portfolio')
      this.SET_PORTFOLIOS(res.data)
      if (res.data) {
        this.selectedPortfolio = res.data.map(p => ({
          id: p._id,
          name: p.name
        })).find(p => p.id === this.connected.portfolioId)
      }
    },
    updateSelectedPortfolio (portfolio) {
      if (this.selectedPortfolio?.id === portfolio.id) return
      this.loading = true
      this.selectedPortfolio = portfolio
      return this.$http.patch(`/integration/${this.integrationId}`, {
        portfolioId: portfolio.id
      }).then(() => {
        this.NOTIFY({ text: `${this.integrationName}'s transactions will be synchronized to "${portfolio.name}"` })
      }).finally(() => {
        this.loading = false
      })
    },
    formatDate (date) {
      return moment(date).utc().fromNow()
    }
  }
}
</script>
