<template>
  <Chart :type="type" :data="chartdata" :options="currentOptions" :plugins="plugins" />
</template>

<script>
import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  DoughnutController,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Title,
  Tooltip
} from 'chart.js'
import { Chart } from 'vue-chartjs'

ChartJS.register(ArcElement, BarController, BarElement, CategoryScale, DoughnutController, LinearScale, LineController, LineElement, PointElement, Title, Tooltip)

class LineWithLine extends LineController {
  draw (ease) {
    super.draw(ease)
    if (this.chart.tooltip?._active?.length) {
      const x = this.chart.tooltip._active[0].element.x
      const yAxis = this.chart.scales.y
      const ctx = this.chart.ctx
      // draw line
      ctx.save()
      ctx.beginPath()
      ctx.moveTo(x, yAxis.top)
      ctx.lineTo(x, yAxis.bottom)
      ctx.lineWidth = 2
      ctx.strokeStyle = 'rgba(50, 50, 50, .04)'
      ctx.stroke()
      ctx.restore()
    }
  }
}
LineWithLine.id = 'LineWithLine'
LineWithLine.defaults = LineController.defaults

ChartJS.register(LineWithLine)

export default {
  name: 'BarChart',
  props: {
    type: {
      type: String,
      default: 'line'
    },
    chartdata: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: () => ({})
    },
    plugins: {
      type: Array,
      default: () => []
    },
    chartId: {
      type: String,
      default: 'chart'
    },
    isHoverExtended: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Chart
  },
  data () {
    return {
      data: null,
      currentIndex: null,
      defaultOptions: Object.assign({
        backgroundColor: '#119955',
        responsive: true,
        onHover: (event, elements) => {
          if (event.type === 'mouseout') {
            this.currentIndex = null
            this.$emit('mouseout')
          } else if (elements?.length) {
            this.currentIndex = elements[0].index
            this.$emit('hover', elements[0].index)
          } else if (this.isHoverExtended) {
            const xElements = this.chart?.getElementsAtEventForMode(event, 'x', { intersect: false })
            if (xElements?.length && this.currentIndex !== xElements[0]?.index) {
              this.currentIndex = xElements[0].index
              this.$emit('hover', xElements[0].index)
            }
          }
        },
        onClick: (event, elements) => {
          if (elements && elements[0]) {
            this.currentIndex = elements[0].index
            this.$emit('click', elements[0].index)
          } else {
            this.currentIndex = null
            this.$emit('mouseout')
          }
        }
      }, this.options),
      chart: null
    }
  },
  computed: {
    currentOptions () {
      return Object.assign(this.defaultOptions, this.options)
    }
  }
}
</script>
