<template>
  <div :class="{ 'mt-4 pb-12 sm:mt-6 sm:pb-16 lg:mt-8 lg:pb-18': !(isTrial || embed) && showCard }">
    <div class="relative">
      <div class="absolute inset-0 h-3/4" />
      <div class="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="max-w-md mx-auto space-y-4 lg:max-w-xl lg:grid lg:grid-cols-1 lg:gap-5 lg:space-y-0">
          <div class="flex flex-col rounded-lg overflow-hidden" :class="{ 'shadow-lg': !(isTrial || embed) && showCard }">
            <div class="px-6 py-8 bg-white sm:p-10 sm:pb-6 sm:pt-4 relative" v-if="!success">
              <div class="text-sm text-gray-500 sm:text-sm lg:text-sm m-4 mx-auto text-center italic" v-if="isTrial">
                After your 14-day trial you will be charged
              </div>
              <div class="text-sm text-gray-600 sm:text-sm lg:text-sm m-4 mx-auto" v-else>
                You will subscribe to <span class="text-emerald-600 font-bold">Beanvest Premium</span> and will be charged
              </div>
              <div class="flex flex-row items-center mt-1" v-if="!loading">
                <div v-if="coupon" class="mx-auto flex items-baseline text-3xl font-extrabold">
                  <div class="line-through text-gray-400 mr-3 font-medium text-2xl" v-if="period === 'monthly'"> $10 </div>
                  <div class="line-through text-gray-400 mr-3 font-medium text-2xl" v-else> $120 </div>
                  ${{ discountedPrice }}
                  <span class="ml-1 text-xl font-medium" v-if="coupon?.duration == 'once'">
                    for one month <span class="text-xs text-gray-400">(and $10 after)</span>
                  </span>
                  <span class="ml-1 text-xl font-medium text-gray-400" v-else-if="coupon?.duration_in_months">
                    / month
                  </span>
                  <span class="ml-1 text-xl font-medium text-gray-400" v-else>
                    / {{ period === 'monthly' ? 'month' : 'year' }}
                  </span>
                </div>
                <div class="mx-auto flex items-baseline text-3xl font-extrabold" v-else-if="period === 'monthly'">
                  $10
                  <span class="ml-1 text-xl font-medium text-gray-400">
                    / month
                  </span>
                </div>
                <div class="mx-auto flex items-baseline text-3xl font-extrabold" v-else>
                  <div class="line-through text-gray-400 mr-3 font-medium text-2xl"> $120 </div>
                  $100
                  <span class="ml-1 text-xl font-medium text-gray-400">
                    / year
                  </span>
                </div>
              </div>
              <div v-if="coupon?.duration_in_months" class="text-center mt-1 text-xs text-gray-400">the first {{  coupon?.duration_in_months }} months, and $10 after</div>
              <div class="flex flex-row items-center justify-end mt-2" v-if="!(isTrial || embed)">
                <GiftIcon v-if="period === 'monthly'" class="w-5 h-5 mr-2 text-emerald-600" />
                <div v-if="period === 'monthly'" class="text-xs align-middle mr-1 font-bold text-emerald-600">
                  Save $20 / year with yearly commitment
                </div>
                <div v-else class="text-xs align-middle mr-1 font-bold text-emerald-700">
                  Yearly commitment
                </div>
                <ArrowLongRightIcon v-if="period === 'monthly'" class="w-5 h-5 ml-1 mr-1 text-emerald-600" />
                <Toggle @change="switchYearly" />
              </div>
              <div class="mt-6 text-lg">
                <StripeCard
                  :offer="offer"
                  :isTrial="isTrial"
                  :text="isTrial ? 'Activate my 14-day Trial' : 'Subscribe'"
                  @token="subscribe"
                  @discount="addDiscount"
                  @success="showSuccess"
                  @fail="showFail"
                  :clientSecret="clientSecret"
                  :intentType="intentType"
                  :intentId="intentId"
                  :loading="loading" />
                <div class="text-gray-700 text-xs text-center">
                  <i class="ri-lock-line text-gray-500" /> Subscription is secured by Stripe. You can cancel it at any time.
                </div>
              </div>
            </div>
            <!-- Success -->
            <div class="px-6 py-8 bg-white sm:p-8 sm:pb-6" v-else-if="success && !loading">
              <div class="mx-auto flex items-center justify-center h-10 w-10 rounded-full bg-emerald-100">
                <CheckIcon class="h-6 w-6 text-emerald-600" />
              </div>
              <p class="mt-6 mb-3 text-lg leading-6 font-medium text-gray-900">Your Premium account is now activated</p>
              <router-link to="/" class="flex-1 flex flex-col justify-between px-6 pt-6 pb-4 space-y-6 sm:p-6 sm:pt-6 cursor-pointer">
                <div class="rounded-md shadow">
                  <div class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-500 cursor-pointer" aria-describedby="tier-standard">
                    Back to my portfolios
                  </div>
                </div>
              </router-link>
            </div>
            <!-- Fail -->
            <div class="px-6 py-8 bg-white sm:p-8 sm:pb-6" v-else-if="error && !loading">
              <div class="mx-auto flex items-center justify-center h-10 w-10 rounded-full bg-emerald-100 text-red-600">
                {{ error }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StripeCard from '@/components/StripeCard'
import Toggle from '@/components/Toggle'
import { CheckIcon, GiftIcon, ArrowLongRightIcon } from '@heroicons/vue/24/outline'
import { mapMutations, mapState } from 'vuex'
export default {
  data () {
    return {
      loading: false,
      success: false,
      clientSecret: null,
      intentType: null,
      plan: 'premium',
      period: 'monthly',
      coupon: null,
      error: null,
      intentId: null
    }
  },
  props: {
    isTrial: {
      type: Boolean,
      default: () => false
    },
    embed: {
      type: Boolean,
      default: () => false
    },
    showCard: {
      type: Boolean,
      default: () => true
    },
    upgradeSource: {
      type: String,
      default: () => null
    },
    offer: {
      type: Object,
      default: () => null
    }
  },
  mounted () {
    if (window) window.scrollTo(0, 0)
    if (this.user.plan !== 'free') {
      this.$router.push('/settings/billing')
    } else {
      this.$track('AddToCart')
    }
    if (this.offer) {
      this.addDiscount(this.offer)
    }
  },
  computed: {
    ...mapState(['user']),
    source () {
      return this.upgradeSource || this.$route.query.source
    },
    upgradeText () {
      return this.period === 'monthly' ? 'Save 2-months with yearly commitment' : ''
    },
    discountedPrice () {
      if (!this.coupon) return null
      const price = this.period === 'monthly' ? 10 : 100
      if (this.coupon.amount_off) return Math.round(Math.max(0, price * 100 - this.coupon.amount_off)) / 100
      else if (this.coupon.percent_off) return Math.max(0, price - price * this.coupon.percent_off / 100)
      return null
    }
  },
  components: {
    StripeCard,
    CheckIcon,
    GiftIcon,
    ArrowLongRightIcon,
    Toggle
  },
  methods: {
    ...mapMutations(['NOTIFY', 'SET_USER']),
    getUser () {
      return this.$http.get('/user')
        .then(res => {
          this.SET_USER(Object.assign({ user: res.data }, { plan: this.plan }))
        })
    },
    subscribe (res) {
      this.error = null
      this.loading = true
      const sub = {
        plan: this.plan,
        period: this.period,
        source: this.source
      }
      if (this.coupon) sub.coupon = this.coupon.id
      if (this.isTrial) sub.trial = true
      this.$http
        .post('/billing/subscribe', sub)
        .then(res => {
          this.intentType = res.data.type
          if (res.data.clientSecret) {
            this.clientSecret = res.data.clientSecret
            if (res.data.intentId) this.intentId = res.data.intentId
          } else this.showSuccess()
        })
        .catch(error => {
          if (error && error.message) {
            this.NOTIFY({ text: error.message, type: 'error' })
          } else if (error && error.response) {
            this.error = error.response.data || `[${error.response.status}] An error occured`
          } else this.error = error.status
          this.loading = false
        })
    },
    addDiscount (coupon) {
      this.coupon = coupon
    },
    showSuccess () {
      this.$track('Subscribe', { facebook: false })
      this.$emit('success')
      // Temporary set Premium before loading the user again
      this.SET_USER({ user: Object.assign(this.user, { plan: 'premium' }) })
      const t = this
      setTimeout(() => t.getUser(), 5000)
      this.loading = false
      this.success = true
    },
    showFail (message) {
      this.loading = false
      this.success = false
      this.error = message
    },
    switchYearly (enabled) {
      this.period = enabled ? 'yearly' : 'monthly'
    }
  }
}
</script>
