<template>
  <div class="h-full">
    <div class="flex items-stretch m-auto" v-if="stock">
      <div class="flex-1 text-gray-700 text-center px-4 py-8 m-3 bg-white rounded-lg shadow">
        <div class="mb-6" v-if="chartdata?.surprises?.length">
          {{ stock.name }} beat estimates <span class="font-bold text-lg">{{ surprisesPercent }}%</span> of the time in the last {{ chartdata?.surprises?.length }} earnings. Average EPS surprise is
          <span class="font-bold text-lg" :class="[{ 'text-emerald-600' : surprisesAverage > 0 }, { 'text-red-600' : surprisesAverage < 0}]">{{ surprisesAverage }}%</span>
        </div>
        <Chart
          v-if="chartdata"
          type="LineWithLine"
          chartId="earnings-chart"
          :options="options"
          :chartdata="chartdata" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Chart from '@/components/Chart'
import moment from 'moment'

export default {
  name: 'Price',
  components: {
    Chart
  },
  data () {
    return {
      options: {
        responsive: true,
        legend: {
          position: 'top',
          display: false
        },
        hover: {
          animationDuration: 0,
          enabled: false
        },
        plugins: {
          tooltip: {
            mode: 'index',
            intersect: false,
            position: 'average',
            callbacks: {
              title: function (chart) {
                return moment(chart[0].label).format('LL')
              },
              label: function (chart) {
                const i = chart.datasetIndex
                const names = {
                  0: 'EPS',
                  1: 'Estimate'
                }
                const value = chart.chart.data.datasets[i].data[chart.dataIndex]
                return ` ${names[i]}  ${value}`
              },
              footer: function (chart) {
                if (!chart[1]) return
                const beat = chart[0].raw >= chart[1].raw
                const diff = Math.abs(Math.round((chart[0].raw - chart[1].raw) / chart[1].raw * 1000) / 10)
                return (beat ? 'Beat by ' : 'Missed by ') + diff + '%'
              },
              labelColor: function (chart) {
                const beat = chart.chart.config.data.datasets[0].data[chart.dataIndex] >= chart.chart.config.data.datasets[1].data[chart.dataIndex]
                const colors = {
                  0: chart.chart.config.data.datasets[1].data[chart.dataIndex] ? (beat ? '#3e885e' : '#e91e63') : '#333',
                  1: '#ddd'
                }
                return {
                  borderColor: colors[chart.datasetIndex],
                  backgroundColor: colors[chart.datasetIndex]
                }
              }
            }
          }
        },
        title: {
          display: false
        },
        animation: {
          animateScale: true,
          animateRotate: true
        },
        scales: {
          x: { grid: { color: '#eee' } },
          y: { grid: { color: '#eee' } }
        }
      }
    }
  },
  computed: {
    ...mapState(['stock']),
    stockId () {
      return this.$route.params.id
    },
    chartdata () {
      if (!this.stock?.profile) return null
      const labels = []
      const surprises = []
      const datasets = [{
        data: [],
        backgroundColor: [],
        pointRadius: 5,
        pointHoverRadius: 3,
        lineTension: 0,
        borderWidth: 3,
        pointBorderColor: 'rgba(0, 0, 0, 0)',
        borderColor: 'rgba(0, 0, 0, .05)',
        borderDashOffset: 2,
        borderDash: [10, 5],
        fill: false,
        showLine: true
      }, {
        data: [],
        backgroundColor: [],
        pointRadius: 5,
        pointHoverRadius: 3,
        pointBorderColor: 'rgba(0, 0, 0, 0)',
        fill: false,
        showLine: false
      }]
      const dates = Object.keys(this.stock.profile?.Earnings?.History).reverse()
      for (const date of dates) {
        const data = this.stock?.profile?.Earnings?.History[date]
        if (data.surprisePercent != null) surprises.push(data.surprisePercent)
        datasets[0].data.push(data.epsActual)
        datasets[1].data.push(data.epsEstimate)
        let color = '#333'
        if (data.epsEstimate) color = data.epsActual > data.epsEstimate ? '#3e885e' : '#e91e63'
        datasets[0].backgroundColor.push(color)
        datasets[1].backgroundColor.push('#ddd')
        labels.push(data.date)
      }
      // surprises.slice(Math.max(surprises.length - 10, 1))
      return {
        surprises,
        datasets,
        labels
      }
    },
    surprisesPercent () {
      const s = this.chartdata.surprises
      if (!s?.length) return '-'
      const nbBeat = s.filter(n => n > 0).length
      return Math.round(nbBeat / s.length * 100)
    },
    surprisesAverage () {
      const s = this.chartdata?.surprises
      if (!s?.length) return '-'
      return Math.round(s.reduce((a, b) => a + b, 0) / s.length * 100) / 100
    }
  }
}
</script>
