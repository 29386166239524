<template>
  <div>
    <div v-if="filters?.length" class="w-full text-left min-w-0 md:px-8 lg:px-0 flex items-left flex-col space-y-2 mb-4">
      <div class="w-full items-center grid grid-cols-1 mt-4 sm:mt-0 sm:grid-cols-12 gap-2" v-for="(filter, i) in filters" :key="i">
        <Select class="sm:col-span-3" :options="sortedFields" placeholder="Select a field" v-model="filter.field" @select="updateField" />
        <Select class="sm:col-span-2" :name="i" v-if="filter.field?.type && getOptions(filter.field?.type)" :options="getOptions(filter.field?.type)" :defaultOption="getOptions(filter.field?.type)[0]" @select="updateOptions" />
        <div class="text-center sm:col-span-1" v-else-if="filter.field?.type">is</div>
        <Select class="sm:col-span-3" :name="i" v-if="filter.field && Object.keys(values)?.includes(filter.field?.id)" v-model="filter.value" :options="getOptionsValues(filter.field?.id)" :defaultOption="getOptionsValues(filter.field?.id)[0]" @select="updateOptions" />
        <input placeholder="Enter a value" :type="getFieldType(filter.field?.type)" v-else-if="filter.field && filter.operator" v-model="filter.value" class="sm:col-span-2 py-2 text-base border-gray-300 placeholder-gray-400 focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm rounded-md" />
        <TrashIcon class="ml-2 w-5 h-5 mr-2 text-gray-300 hover:text-red-600 cursor-pointer" @click="removeFilter(i)" />
      </div>
    </div>
    <button @click="addField" class="relative flex gap-2 items-center rounded-md bg-white hover:bg-gray-50 cursor-pointer py-1.5 px-3 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-black-600 sm:text-sm sm:leading-6">
      <PlusCircleIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      <span class="block truncate">Add Filter</span>
    </button>
  </div>
</template>

<script>
import Select from '@/components/Select'
import { PlusCircleIcon, TrashIcon } from '@heroicons/vue/24/outline'

export default {
  name: 'Filters',
  components: {
    Select,
    PlusCircleIcon,
    TrashIcon
  },
  data () {
    return {
      fields: [
        { id: 'volume', name: 'Volume', type: 'number' },
        { id: 'type', name: 'Asset type', type: 'pick' },
        { id: 'exchange', name: 'Exchange', type: 'pick' },
        { id: 'sector', name: 'Sector', type: 'pick' },
        { id: 'industry', name: 'Industry', type: 'pick' },
        { id: 'mktCapUSD', name: 'Market Capitalization (USD)', type: 'number' },
        { id: 'mktCapCategory', name: 'Market Cap Range', type: 'pick' },
        { id: 'lastDiv', name: 'Last Dividend', type: 'number' },
        { id: 'eps', name: 'EPS', type: 'number' },
        { id: 'ebitda', name: 'EBITDA', type: 'number' },
        { id: 'sharesOutstanding', name: 'Shares Outstanding', type: 'number' },
        { id: 'employees', name: 'Number of employees', type: 'number' },
        { id: 'pe', name: 'PE Ratio', type: 'number' },
        { id: 'peg', name: 'PEG Ratio', type: 'number' },
        { id: 'dilutedEps', name: 'Diluted EPS', type: 'number' },
        { id: 'bookValue', name: 'Book Value', type: 'number' },
        { id: 'dividendYield', name: 'Dividend Yield', type: 'number' },
        { id: 'profitMargin', name: 'Profit Margin', type: 'number' },
        { id: 'operatingMargin', name: 'Operating Margin', type: 'percent' },
        { id: 'targetPrice', name: 'Target Price', type: 'number' },
        { id: 'roe', name: 'Return on Equity', type: 'number' },
        { id: 'roa', name: 'Return on Assets', type: 'number' },
        { id: 'revenue', name: 'Revenue', type: 'number' },
        { id: 'revenuePerShare', name: 'Revenue per Share', type: 'number' },
        { id: 'esgScore', name: 'ESG Score', type: 'number' },
        { id: 'analystRatings', name: 'Analysts Rating', type: 'number' },
        { id: 'analystTargetPrice', name: 'Analysts Target Price', type: 'number' },
        { id: 'analystsFollowing', name: 'Number of Analysts', type: 'number' },
        { id: 'percentInsiders', name: 'Insiders Ownership', type: 'percent' },
        { id: 'percentInstitutions', name: 'Institutions Ownership', type: 'percent' },
        { id: 'shortRatio', name: 'Short Ratio', type: 'number' },
        { id: 'shortPercent', name: 'Short Percent', type: 'percent' },
        { id: 'payoutRatio', name: 'Dividend Payout Ratio', type: 'percent' },
        { id: 'quarterlyRevenueGrowthYOY', name: 'Quarterly Revenue Growth (YOY)', type: 'percent' },
        { id: 'quarterlyEarningsGrowthYOY', name: 'Quarterly Earnings Growth (YOY)', type: 'percent' },
        { id: 'beta', name: 'Beta', type: 'number' },
        { id: 'grossProfit', name: 'Gross Profit', type: 'number' },
        { id: 'country', name: 'Country', type: 'pick' }
      ],
      values: null,
      filters: [],
      filterOptions: {
        number: [
          { id: 'gt', name: 'More than' },
          { id: 'gte', name: 'More or equal to' },
          { id: 'eq', name: 'Equals' },
          { id: 'lte', name: 'Less or equal to' },
          { id: 'lt', name: 'Less than' }
        ],
        percent: [
          { id: 'gt', name: 'More than' },
          { id: 'gte', name: 'More or equal to' },
          { id: 'eq', name: 'Equals' },
          { id: 'lte', name: 'Less or equal to' },
          { id: 'lt', name: 'Less than' }
        ]
      }
    }
  },
  computed: {
    sortedFields () {
      return [...this.fields].sort((a, b) => a.name.localeCompare(b.name))
    }
  },
  mounted () {
    this.getValues()
  },
  methods: {
    async getValues () {
      this.values = (await this.$http.get('/stock/values'))?.data
    },
    updateField (selectedField, i) {
      if (this.filters[i]) this.filters[i].field = selectedField
    },
    updateOptions (selectedOption, i) {
      if (this.filters[i]) this.filters[i].operator = selectedOption.id
    },
    addField () {
      this.filters.push({ field: null })
    },
    getOptions (type) {
      return this.filterOptions[type]
    },
    getOptionsValues (fieldName) {
      return this.values[fieldName].map(v => ({ id: v._id, name: v._id })) || []
    },
    removeFilter (i) {
      this.filters.splice(i, 1)
    },
    getFieldType (type) {
      if (type === 'number') return 'number'
      if (type === 'percent') return 'number'
      return 'text'
    }
  },
  watch: {
    filters: {
      handler: function (filters) {
        this.$emit('change', filters)
      },
      deep: true
    }
  }
}
</script>
