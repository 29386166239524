<template>
  <transition enter-active-class="transform ease-out duration-500 transition" enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition ease-in duration-400" leave-from-class="opacity-100" leave-to-class="opacity-0">
    <div v-if="show" class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden z-30">
      <div class="p-4">
        <div class="flex items-start">
          <div class="flex-shrink-0">
            <CheckCircleIcon class="h-6 w-6 text-red-400" aria-hidden="true" v-if="type === 'error'" />
            <CheckCircleIcon class="h-6 w-6 text-emerald-400" aria-hidden="true" v-else />
          </div>
          <div class="ml-3 w-0 flex-1 pt-0.5">
            <p class="text-sm font-medium text-gray-900" v-if="text">
              {{ text }}
            </p>
            <p class="mt-1 text-sm text-gray-500" v-if="description">
              {{ description }}
            </p>
          </div>
          <div class="ml-4 flex-shrink-0 flex">
            <button @click="hide" class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
              <span class="sr-only">Close</span>
              <XMarkIcon class="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { ref } from 'vue'
import { CheckCircleIcon } from '@heroicons/vue/24/outline'
import { XMarkIcon } from '@heroicons/vue/24/solid'

export default {
  components: {
    CheckCircleIcon,
    XMarkIcon
  },
  props: {
    type: {
      type: String,
      default: 'success'
    },
    text: {
      type: String
    },
    description: {
      type: String
    },
    id: {
      type: Number
    },
    duration: {
      type: Number,
      default: () => 4000
    }
  },
  setup () {
    const show = ref(false)

    return {
      show
    }
  },
  methods: {
    hide () {
      this.show = false
      this.$emit('hide', this.id)
    }
  },
  mounted () {
    this.show = true
    const d = this.duration
    setTimeout(() => this.hide(), d)
  }
}
</script>
