<template>
  <div class="bg-white shadow overflow-hidden sm:rounded-md mt-2">
    <div class="bg-white px-4 py-3 border-b border-gray-200 sm:px-6">
      <div class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
        <div class="text-left  ml-2 mt-2">
          <h3 class="text-sm text-gray-500">
            Map fields from your csv file to fields in Beanvest
          </h3>
          <div class="mt-3" v-if="errors.length">
            <div class="text-sm text-red-500" v-for="error in errors" :key="error">
              <div class="relative inline-flex items-center ml-2">
                <ExclamationTriangleIcon class="w-4 h-4 mr-2" />
                {{ error }}
              </div>
            </div>
          </div>
        </div>
        <div class="ml-4 mt-2 flex-shrink-0">
          <button
            @click="confirm"
            type="button"
            class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
            :class="[{ 'bg-emerald-600 hover:bg-emerald-700': errors.length === 0 }, { 'bg-gray-300': errors.length > 0 }]">
            <ChevronRightIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            Continue import
          </button>
        </div>
      </div>
    </div>
    <ul class="divide-y divide-gray-200">
      <li v-for="(field, i) in mapping" :key="i" class="flex items-center md:grid md:grid-cols-12">
        <div class="col-span-6 text-left text-sm px-6 py-3 sm:px-8">
          {{ field.name }}
          <div class="text-gray-400 text-xs">{{ field.example }}</div>
        </div>
        <div class="col-span-1">
          <ArrowLongRightIcon
            v-if="field.mappedTo"
            class="text-gray-300 group-hover:text-gray-200 h-4 w-4 md:block text-right" />
        </div>
        <div class="col-span-5 md:block text-right max-w-2xl px-4 py-2 sm:px-4">
          <select
            @change="updateSubmapping(field)"
            v-model="field.mappedTo"
            id="mappedTo"
            name="mappedTo"
            class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm rounded-md"
            :class="[{ 'bg-gray-50 text-gray-400': !field.mappedTo }]">
            <option class="text-gray-300" value="">- Not imported -</option>
            <option v-for="option of Object.keys(fields.global)" :value="option" :key="option">
              {{ formatName(option) }}
            </option>
          </select>
        </div>
        <div
          class="col-span-12 border-t border-gray-200 px-6 py-4 bg-gray-50"
          v-if="field.mappedTo && ['date', 'type', 'currency'].includes(field.mappedTo)">
          <Options
            v-if="field.mappedTo === 'date' && field.options.format"
            @select="updateDateFormat"
            :defaultOption="field.options.format"
            :options="dateFormat" />
          <div
            v-if="['type', 'currency'].includes(field.mappedTo) && field.options.mapping">
            <div v-for="(value, i) in field.options.mapping" :key="i" class="flex items-center md:grid md:grid-cols-12">
              <div class="col-span-6 text-left text-sm px-8 py-2 sm:px-12">
                {{ value.name || '(empty)' }}
              </div>
              <div class="col-span-1">
                <ArrowLongRightIcon
                  v-if="value.mappedTo"
                  class="text-gray-300 group-hover:text-gray-200 h-4 w-4 md:block text-right" />
              </div>
              <div class="col-span-5 md:block text-right max-w-2xl px-4 py-2 sm:px-4">
                <select
                  v-model="value.mappedTo"
                  id="mappedTo"
                  name="mappedTo"
                  class="mt-1 block w-full pl-3 pr-8 py-2 text-base border-gray-300 focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm rounded-md"
                  :class="[{ 'bg-red-50 text-red-400 border-red-500': !value.mappedTo }]">
                  <option class="text-gray-300" value="">- PLEASE MAP FIELD -</option>
                  <option class="text-gray-300" value="ignore">- Not imported -</option>
                  <option v-for="option of Object.keys(fields[field.mappedTo])" :value="option" :key="option">
                    {{ formatName(option) }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import moment from 'moment'
import Options from '@/components/Options'
import { ArrowLongRightIcon, ChevronRightIcon, ExclamationTriangleIcon } from '@heroicons/vue/24/outline'

export default {
  name: 'ImportMapFields',
  components: {
    Options,
    ArrowLongRightIcon,
    ChevronRightIcon,
    ExclamationTriangleIcon
  },
  data () {
    return {
      fields: {
        global: {
          comments: ['comments', 'comment', 'description', 'details', 'id'],
          currency: ['currency', 'currencyprimary'],
          date: ['date', 'trade date', 'tradedate', 'time'],
          fee: ['fees', 'fee', 'brokerage fee', 'frais', 'courtage', 'brokerage'],
          isin: ['isin', 'code isin'],
          name: ['produit', 'name'],
          price: ['price', 'prix', 'cours', 'value', 'tradeprice'],
          rate: ['rate', 'exchange rate', 'taux de change', 'change'],
          symbol: ['ticker', 'symbol', 'instrument code', 'code', 'stock'], // code
          type: ['type', 'transaction type', 'buy/sell', 'buy', 'buysell', 'action'],
          units: ['units', 'unit', 'quantity', 'number', 'quantité', 'qty', 'no. of shares', 'shares'],
          exchange: ['exchange', 'place boursière', 'bourse', 'market', 'place', 'listingexchange']
        },
        type: {
          buy: ['buy', 'achat', 'buy_lot'],
          sell: ['sell', 'selling', 'sale', 'vente', 'sell_lot'],
          fee: ['fee', 'frais'],
          dividend: ['dividend', 'dividende'],
          split: ['split', 'stock split'],
          'reverse-split': ['reverse split']
          // cash: ['cash', 'cash_in', 'cash_out', 'cashin', 'cashout', 'cash_in_lot', 'cash_out_lot']
        },
        currency: {
          EUR: ['eur', 'euro', 'euros', '€'],
          USD: ['usd', 'us dollar', 'us dollars', 'dollar', 'dollars', '$'],
          CAD: ['cad', 'canadian dollar', 'canadian dollar'],
          HKD: ['hkd', 'hk$', 'hong kong dollar', 'hong kong dollars', '港元'],
          ISK: ['isk', 'icelandic króna', 'icelandic krona', 'krona'],
          PHP: ['php', 'philippine peso', 'piso', '₱'],
          DKK: ['dkk', 'danish krone', 'kroner'],
          HUF: ['huf', 'hungarian forint', 'ft', 'forint'],
          CZK: ['czk', 'czech koruna', 'koruna česká', 'koruna ceska'],
          GBX: ['gbx', 'penny', 'pence', 'british penny', 'british pence', 'penny sterling'],
          GBP: ['gbp', 'pound', 'pounds', 'british pound', 'british pounds', '£'],
          RON: ['ron', 'l', 'romanian leu', 'lei', 'leu'],
          SEK: ['sek', 'swedish krona', 'kronor'],
          IDR: ['idr', 'indonesian rupiah', 'rupiah', 'rp'],
          INR: ['inr', 'indian rupee', 'rupee', 'rupees', '₹'],
          BRL: ['rbl', 'real', 'brizilian real', 'r$'],
          RUB: ['rub', 'russian ruble', 'ruble', 'rouble', '₽'],
          HRK: ['hrk', 'croatian kuna', 'kn', 'kuna'],
          JPY: ['jpy', 'yen', '¥', '円', '圓'],
          THB: ['thb', 'thai baht', 'baht', '฿'],
          CHF: ['chf', 'swiss franc', 'sfr', 'franc suisse'],
          MYR: ['myr', 'malaysian ringgit', 'ringgit', 'rm'],
          BGN: ['bgn', 'bularian lev', 'lev', 'Лв', 'leva'],
          TRY: ['try', 'turkish lira', 'lira', '₺'],
          CNY: ['cny', 'chinese yuan', 'renminbi', '元', 'yuán', 'yuan', '¥'],
          NOK: ['nok', 'norwegian krone', 'krone'],
          NZD: ['nzd', 'new zealand dollar', 'nz$'],
          ZAR: ['zar', 'south african rand', 'rand'],
          MXN: ['mxn', 'mexican peso', 'peso', 'pesos', 'mex$'],
          SGD: ['sgd', 'singapore dollar', 's$'],
          AUD: ['aud', 'australian dollar', 'a$'],
          ILS: ['ils', 'israeli new shekel', 'israeli shekel', 'shekel', '₪'],
          KRW: ['krw', 'wouth korean won', 'korean won', 'won', '₩'],
          PLN: ['pln', 'poland złoty', 'złoty', 'zloty', 'zł']
        }
      },
      dateFormat: [
        { id: 1, name: 'International format', format: 'YYYY-MM-DD', description: 'YYYY-MM-DD : Today is ' + moment().format('YYYY-MM-DD') },
        { id: 2, name: 'EU format with /', format: 'DD/MM/YYYY', description: 'DD/MM/YYYY : Today is ' + moment().format('DD/MM/YYYY') },
        { id: 3, name: 'EU format with -', format: 'DD-MM-YYYY', description: 'DD-MM-YYYY : Today is ' + moment().format('DD-MM-YYYY') },
        { id: 4, name: 'EU timestamp with - and hours', format: 'DD-MM-YY HH:mm:ss', description: 'DD-MM-YY HH:mm : Today is ' + moment().format('DD-MM-YY HH:mm:ss') },
        { id: 5, name: 'US format with /', format: 'MM/DD/YYYY', description: 'MM/DD/YYYY : Today is ' + moment().format('MM/DD/YYYY') },
        { id: 6, name: 'US format with -', format: 'MM-DD-YYYY', description: 'MM-DD-YYYY : Today is ' + moment().format('MM-DD-YYYY') },
        { id: 7, name: 'Timestamp', format: null, description: 'YYYY-MM-DDTHH:mm:ss+tz : Today is ' + moment().format() },
        { id: 8, name: 'Unix timestamp', format: 'x', description: 'Today is ' + moment().unix() }
      ],
      mapping: [],
      alreadyMapped: []
    }
  },
  mounted () {
    this.alreadyMapped = []
    this.mapFields()
  },
  computed: {
    errors () {
      const errors = []
      // Isin or code should be mapped
      let hasCodeOrIsin = false
      let hasDuplicate = false
      let hasPrice = false
      let hasUnits = false
      let missingFormat = false
      let missingSubmapping = false
      const fields = {}
      for (const map of this.mapping) {
        if (['symbol', 'isin'].includes(map.mappedTo)) hasCodeOrIsin = true
        if (map.mappedTo === 'price') hasPrice = true
        if (map.mappedTo === 'units') hasUnits = true
        if (map.mappedTo && map.mappedTo.length) {
          if (fields[map.mappedTo]) hasDuplicate = true
          fields[map.mappedTo] = true
        }
        if (map.mappedTo === 'date' && !map.options.format) missingFormat = true
        if (['type', 'currency'].includes(map.mappedTo) && map.options.mapping) {
          for (const value of map.options.mapping) {
            if (!value.mappedTo || !value.mappedTo.length) missingSubmapping = true
          }
        }
      }
      if (!hasCodeOrIsin) errors.push('Please map one field to either Symbol or Isin')
      if (!hasPrice) errors.push('Please map one field to Price')
      if (!hasUnits) errors.push('Please map one field to Units')
      if (missingFormat) errors.push('Please select a format for Date')
      if (missingSubmapping) errors.push('Please map all values')
      if (hasDuplicate) errors.push('Some fields are mapped to the same field multiple times')
      return errors
    }
  },
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    formatName (name) {
      const cleanName = name.replace(/-/g, ' ')
      if (cleanName === 'symbol') return 'Ticker Symbol'
      return `${cleanName.charAt(0).toUpperCase()}${cleanName.slice(1)}`
    },
    mapFields () {
      this.mapping = this.columns.map(name => {
        const mappedTo = this.autoMap(name)
        let example = this.data[0][name]
        if (this.data[1] && this.data[1][name]) example += ', ' + this.data[1][name]
        if (this.data[2] && this.data[2][name]) example += ', ' + this.data[2][name]
        if (this.data[3] && this.data[3][name]) example += ', ...'
        if (!example) example = '-'
        const options = {}
        if (mappedTo === 'date') {
          options.format = this.dateFormat[0]
          for (const d of this.dateFormat) {
            if (moment(this.data[1][name], d.format).format(d.format) === this.data[1][name]) {
              options.format = d
            }
          }
        }
        if (['type', 'currency'].includes(mappedTo)) {
          const values = []
          for (const line of this.data) {
            if (!values.includes(line[name])) values.push(line[name])
          }
          options.mapping = values.map(name => ({ name, mappedTo: this.autoMap(name, mappedTo) }))
        }
        return {
          example,
          mappedTo,
          name,
          options
        }
      })
    },
    // Update submapping options when field mapping is changed
    updateSubmapping (field) {
      for (const { mappedTo, name, options } of this.mapping) {
        if (mappedTo === field.mappedTo) {
          if (mappedTo === 'date') {
            options.format = this.dateFormat[0]
            for (const d of this.dateFormat) {
              if (moment(this.data[1][name], d.format).format(d.format) === this.data[1][name]) {
                options.format = d
              }
            }
          }
          if (['type', 'currency'].includes(mappedTo)) {
            const values = []
            for (const line of this.data) {
              if (!values.includes(line[name])) values.push(line[name])
            }
            options.mapping = values.map(name => ({ name, mappedTo: this.autoMap(name, mappedTo) }))
          }
        }
      }
    },
    autoMap (name, category = 'global') {
      const normalizedName = name.toLowerCase().replace(/\(\)/g, '')
      const names = name.toLowerCase().split(' ')
      const keywordMap = {}
      for (const key of Object.keys(this.fields[category])) {
        for (const value of this.fields[category][key]) {
          keywordMap[value] = key
        }
      }
      const keyword = keywordMap[normalizedName]
      if (keyword && !this.alreadyMapped.includes(keyword)) {
        this.alreadyMapped.push(keyword)
        return keyword
      }
      for (const name of names) {
        if (keywordMap[name] && (category !== 'global' || !this.alreadyMapped.includes(keywordMap[name]))) {
          this.alreadyMapped.push(keywordMap[name])
          return keywordMap[name]
        }
      }
      return ''
    },
    updateDateFormat (format) {
      for (const map of this.mapping) {
        if (map.mappedTo === 'date') map.options.format = format
      }
    },
    confirm () {
      if (this.errors.length) return false
      this.$emit('confirm', this.mapping)
    }
  }
}
</script>
