<template>
  <div class="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
    <section aria-labelledby="user_details_heading">
      <div class="shadow sm:rounded-md sm:overflow-hidden">
        <div class="bg-white py-6 px-4 sm:p-6">
          <div>
            <h2 id="user_details_heading" class="text-lg leading-6 font-medium text-gray-900">
              Number format
            </h2>
            <p class="mt-1 text-sm text-gray-500">
              Numbers will be formatted as following: <span class="font-bold">{{ exampleFormat }}</span>
            </p>
          </div>

          <div class="mt-6 grid grid-cols-4 gap-6">

            <div class="col-span-4 sm:col-span-2">
              <label for="symbolType" class="block text-sm font-medium text-gray-700">Currency Name/Symbol</label>
              <select v-model="numberFormat.symbolType" id="symbolType" name="symbolType" autocomplete="symbolType" class="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-700 focus:border-gray-700 sm:text-sm">
                <option v-for="symbolType in symbolTypes" :key="symbolType.id" :value="symbolType.id">{{ symbolType.name }}</option>
              </select>
            </div>

            <div class="col-span-4 sm:col-span-2">
              <label for="symbolPosition" class="block text-sm font-medium text-gray-700">Currency Position</label>
              <select v-model="numberFormat.symbolPosition" id="symbolPosition" name="symbolPosition" autocomplete="symbolPosition" class="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-700 focus:border-gray-700 sm:text-sm">
                <option v-for="symbolPosition in symbolPositions" :key="symbolPosition.id" :value="symbolPosition.id">{{ symbolPosition.name }}</option>
              </select>
            </div>

            <div class="col-span-4 sm:col-span-2">
              <label for="thousandsSeparator" class="block text-sm font-medium text-gray-700">Thousands separator</label>
              <select v-model="numberFormat.thousandsSeparator" id="thousandsSeparator" name="thousandsSeparator" autocomplete="thousandsSeparator" class="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-700 focus:border-gray-700 sm:text-sm">
                <option v-for="thousandsSeparator in thousandsSeparators" :key="thousandsSeparator.id" :value="thousandsSeparator.id">{{ thousandsSeparator.name }}</option>
              </select>
            </div>

            <div class="col-span-4 sm:col-span-2">
              <label for="decimalsSeparator" class="block text-sm font-medium text-gray-700">Decimals separator</label>
              <select v-model="numberFormat.decimalsSeparator" id="decimalsSeparator" name="decimalsSeparator" autocomplete="decimalsSeparator" class="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-700 focus:border-gray-700 sm:text-sm">
                <option v-for="decimalsSeparator in decimalsSeparators" :key="decimalsSeparator.id" :value="decimalsSeparator.id">{{ decimalsSeparator.name }}</option>
              </select>
            </div>

            <div class="rounded-md bg-yellow-50 p-4 col-span-4" v-if="numberFormat.thousandsSeparator === numberFormat.decimalsSeparator">
              <div class="flex">
                <div class="flex-shrink-0">
                  <ExclamationTriangleIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
                </div>
                <div class="ml-3">
                  <h3 class="text-sm font-medium text-yellow-800">Selecting the same separator for both thousands and decimals can be confusing.</h3>
                </div>
              </div>
            </div>

            <div class="col-span-4 sm:col-span-2">
              <label for="emailSubscribe" class="block text-sm font-medium text-gray-700">
                Add space between number and currency
              </label>
              <Toggle :isActive="numberFormat.symbolSpace" @change="switchSpace" />
            </div>
          </div>
        </div>
        <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
          <button @click="updateUser" class="bg-emerald-500 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-600">
            Save
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import getSymbolFromCurrency from 'currency-symbol-map'
import Toggle from '@/components/Toggle'
import format from '@/mixins/format'
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline'

export default {
  name: 'PreferencesSettings',
  components: {
    Toggle,
    ExclamationTriangleIcon
  },
  mixins: [format],
  data () {
    return {
      editing: false,
      numberFormat: {},
      thousandsSeparators: [
        { id: 'none', name: 'No separator (123456789)' },
        { id: 'space', name: 'Space (123 456 789)' },
        { id: 'comma', name: 'Comma (123,456,789)' },
        { id: 'dot', name: 'Dot (123.456.789)' }
      ],
      decimalsSeparators: [
        { id: 'comma', name: 'Comma (1,23)' },
        { id: 'dot', name: 'Dot (1.23)' }
      ]
    }
  },
  mounted () {
    this.getUser()
    this.cancel()
  },
  computed: {
    ...mapState(['user']),
    exampleFormat () {
      return this.formatNumber(1234567.89, this.numberFormat, this.user.currency)
    },
    currency () {
      if (this.numberFormat.symbolType === 'name') return this.user.currency
      return this.getSymbolFromCurrency(this.user.currency)
    },
    symbolTypes () {
      return [
        { id: 'name', name: `Name (${this.user.currency})` },
        { id: 'symbol', name: `Symbol (${this.getSymbolFromCurrency(this.user.currency)})` }
      ]
    },
    symbolPositions () {
      return [
        { id: 'before', name: `Before (${this.currency} 1000)` },
        { id: 'after', name: `After (1000 ${this.currency})` }
      ]
    }
  },
  methods: {
    ...mapMutations(['SET_USER', 'NOTIFY']),
    getSymbolFromCurrency,
    cancel () {
      this.numberFormat = this.user.numberFormat
      this.editing = false
    },
    getUser () {
      return this.$http.get('/user')
        .then(res => {
          this.SET_USER({ user: res.data })
          this.numberFormat = this.user.numberFormat
        })
    },
    updateUser () {
      return this.$http.patch('/user', { numberFormat: this.numberFormat })
        .then(() => {
          this.getUser()
          this.cancel()
          this.NOTIFY({ text: 'Settings saved successfully' })
        })
    },
    switchSpace () {
      this.numberFormat.symbolSpace = !this.numberFormat.symbolSpace
    }
  }
}
</script>
