<template>
  <RadioGroup class="text-left w-full" v-model="selected">
    <RadioGroupLabel class="sr-only" v-if="name">
      {{ name }}
    </RadioGroupLabel>
    <div class="bg-white rounded-md -space-y-px">
      <RadioGroupOption as="template" v-for="(option, optionIdx) in options" :key="option.name" :value="option" v-slot="{ checked, active }"
        :class="[(checked || option.id !== selected?.id) ? 'hover:bg-gray-50' : '']">
        <div :class="[optionIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '', optionIdx === options.length - 1 ? 'rounded-bl-md rounded-br-md' : '', (checked || option.id === selected?.id) ? 'bg-emerald-50 border-emerald-200 z-10' : 'border-gray-200', 'relative border p-3 flex cursor-pointer focus:outline-none']">
          <span :class="[(checked || option.id === selected?.id) ? 'bg-emerald-600 border-transparent' : 'bg-white border-gray-300', active ? 'ring-2 ring-offset-2 ring-emerald-500' : '', 'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center']" aria-hidden="true">
            <span class="rounded-full bg-white w-1.5 h-1.5" />
          </span>
          <div class="ml-3 flex flex-col">
            <RadioGroupLabel as="span" :class="[(checked || option.id === selected?.id) ? 'text-emerald-900' : 'text-gray-900', 'block text-sm font-medium']">
              {{ option.name }}
            </RadioGroupLabel>
            <RadioGroupDescription as="span" :class="[(checked || option.id === selected?.id) ? 'text-emerald-700' : 'text-gray-500', 'block text-sm']" v-if="option.description">
              {{ option.description }}
            </RadioGroupDescription>
          </div>
        </div>
      </RadioGroupOption>
    </div>
  </RadioGroup>
</template>

<script>
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'

export default {
  components: {
    RadioGroup,
    RadioGroupDescription,
    RadioGroupLabel,
    RadioGroupOption
  },
  props: {
    name: {
      type: String
    },
    options: {
      type: Array,
      default: () => []
    },
    defaultOption: {
      type: Object
    }
  },
  mounted () {
    if (this.defaultOption) this.selected = this.defaultOption
  },
  data () {
    return {
      selected: null
    }
  },
  watch: {
    selected: function (option) {
      this.$emit('select', option)
    }
  }
}
</script>
