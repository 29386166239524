<template>
  <div>
    <div class="pt-4 sm:pt-16 lg:pt-12">
      <div class="max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8" v-if="success">
        <div class="max-w-3xl mx-auto space-y-6 lg:max-w-none">
          <p class="text-3xl font-extrabold text-gray-900 sm:text-3xl lg:text-4xl max-w-xl mx-auto">
            Welcome to Beanvest Premium
          </p>
        </div>
      </div>
      <div class="max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8" v-else>
        <div class="max-w-3xl mx-auto space-y-6 lg:max-w-none">
          <p class="text-3xl font-extrabold text-gray-900 sm:text-3xl lg:text-4xl max-w-xl mx-auto">
            <span v-if="source === 'create-portfolio'">Upgrade to Premium to create more portfolios</span>
            <span v-else-if="source === 'benchmark'">Upgrade to Premium to track your performance</span>
            <span v-else-if="source === 'holdings'">Upgrade to Premium to have unlimited holdings</span>
            <span v-else-if="source === 'performance-years'">Upgrade to Premium to show your historical performance</span>
            <span class="leading-snug" v-else-if="source === 'integration-degiro'">Upgrade to Premium to synchronize your Degiro account</span>
            <span v-else>Upgrade to Premium</span>
          </p>
          <p class="text-xl text-gray-700">
            Unlock to full potential of Beanvest by subscribing to Premium
          </p>
        </div>
        <!-- Features list -->
        <div class="flex items-center text-base">
          <ul class="flex flex-row mx-auto mt-6 space-x-4 px-4">
            <li class="flex">
              <CheckIcon class="flex-shrink-0 w-6 h-6 text-emerald-500" />
              <span class="ml-3 text-gray-500">30 portfolios</span>
            </li>

            <li class="flex">
              <CheckIcon class="flex-shrink-0 w-6 h-6 text-emerald-500" />
              <span class="ml-3 text-gray-500">Unlimited holdings</span>
            </li>

            <li class="flex">
              <CheckIcon class="flex-shrink-0 w-6 h-6 text-emerald-500" />
              <span class="ml-3 text-gray-500">Performance benchmarks</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <Pay @success="success = true"/>
    <h3 v-if="!success" class="mt-2 mb-3 text-xl font-bold text-gray-800 sm:text-xl sm:leading-relaxed sm:tracking-tight lg:text-xl max-w-xl mx-auto">
      <img
        src="../assets/images/climate.svg"
        width="1433"
        height="785"
        class="m-auto mb-4 w-5 sm:w-10 bg-center"
        alt="Stripe climate" />
      Beanvest will contribute 1% of your subscription to remove CO₂ from the atmosphere
    </h3>
    <a v-if="!success" class="text-emerald-500 hover:text-emerald-600 font-normal text-base mt-4 mb-12" href="https://climate.stripe.com/CuFL31" target="_blank">Learn more</a>
    <Compare class="mt-16 py-16 sm:py-12 sm:px-6 lg:px-8" v-if="!success" />
  </div>
</template>

<script>
import { CheckIcon } from '@heroicons/vue/24/outline'
import Compare from './Compare.vue'
import Pay from './Pay.vue'
import { mapState } from 'vuex'
export default {
  data () {
    return {
      success: false
    }
  },
  mounted () {
    if (window) window.scrollTo(0, 0)
    if (this.user.plan !== 'free') {
      this.$router.push('/settings/billing')
    } else {
      this.$track('AddToCart')
    }
  },
  computed: {
    ...mapState(['user']),
    source () {
      return this.$route.query.source
    }
  },
  components: {
    CheckIcon,
    Compare,
    Pay
  }
}
</script>
