<template>
  <div>
    <Loader text="Subscription in progress..." v-if="loading || load" />
    <form id="stripe-element-form" v-show="!loading && !load">
      <div id="stripe-element-mount-point" />
      <div v-if="showDiscount && !coupon" class="flex mt-6 gap-x-4">
        <div class="flex flex-col w-2/3 relative border border-gray-300 rounded-md px-3 py-3 shadow-sm focus-within:ring-1 focus-within:ring-emerald-600 focus-within:border-emerald-600">
          <label for="name" class="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900">Discount code</label>
          <input v-model="code" type="text" name="name" id="name" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-400 focus:ring-0 sm:text-sm" placeholder="Enter your coupon code" />
        </div>
        <div
          @click="checkDiscount"
          class="flex flex-col w-1/3 items-center justify-center px-2 py-2 border border-transparent text-base font-xs sm:font-sm rounded-md text-white"
          :class="[code?.length ?  'bg-emerald-600 hover:bg-emerald-500 cursor-pointer' :  'bg-gray-300 hover:bg-gray-300']">
          Add Discount
        </div>
      </div>
      <div v-if="coupon && !isTrial" class="rounded-md bg-emerald-50 p-3 mt-3">
        <div class="flex">
          <div class="flex-shrink-0">
            <TicketIcon class="h-5 w-5 text-emerald-400" aria-hidden="true" />
          </div>
          <div class="ml-3">
            <h3 class="text-sm font-medium text-emerald-600">
              Coupon Added: <span class="bold">{{ code }}</span>
            </h3>
          </div>
        </div>
      </div>
      <div class="justify-end mx-2 mt-3 flex items-center" v-if="!showDiscount && !isTrial">
        <div class="text-xs text-gray-400 hover:text-emerald-500 cursor-pointer justify-end flex items-center" @click="showDiscount = true; error = null">
          <TicketIcon class="h-4 w-4 mr-1" aria-hidden="true" />
          Add coupon
        </div>
      </div>
      <slot name="stripe-element-errors">
        <div class="mt-4 text-red-500 text-sm" id="stripe-element-errors" role="alert" />
      </slot>
      <div class="rounded-md bg-red-50 p-3 mt-4 mb-3" v-if="error">
        <div class="flex">
          <div class="flex-shrink-0">
            <XCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
          <div class="ml-3">
            <h3 class="text-sm font-medium text-red-500">{{ error }}</h3>
          </div>
        </div>
      </div>
      <div @click="submit" class="flex-1 flex flex-col justify-between pt-3 pb-8 space-y-6 sm:pb-4 sm:pt-3">
        <div class="rounded-md shadow">
          <div class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-500 cursor-pointer" aria-describedby="tier-standard">
            {{ text }}
          </div>
        </div>
      </div>
      <button ref="submitButtonRef" type="submit" class="hidden" />
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader'
import { XCircleIcon, TicketIcon } from '@heroicons/vue/24/outline'
import { loadStripe } from '@stripe/stripe-js/dist/pure.esm.js'
const DEFAULT_ELEMENT_STYLE = {
  base: {
    color: '#32325d',
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4'
    }
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a'
  }
}
const ELEMENT_TYPE = 'card'
const pk = 'pk_live_51HddhUJ9ktlV3Sky4vuoldjW21uTkPPo01PMYsjnXplMUkeh0NzIAleAxz561U2BQxYjdYaDiWWQl8gFT8TMLHIY0014LWrOwj'
// const pk = process.env.VUE_STRIPE_KEY || 'pk_test_51HddhUJ9ktlV3SkyWckDkESPD8KeyG0n6yYopJUCsaJ1YLwfGaQQmU7QuyaG9BNSlyslSdqt9qN7oJvik5XMSezP000Fs7xox9'
export default {
  components: {
    Loader,
    XCircleIcon,
    TicketIcon
  },
  props: {
    text: {
      type: String,
      default: 'Subscribe'
    },
    stripeAccount: {
      type: String
    },
    apiVersion: {
      type: String
    },
    locale: {
      type: String,
      default: 'auto'
    },
    elementsOptions: {
      type: Object,
      default: () => ({})
    },
    disableAdvancedFraudDetection: {
      type: Boolean
    },
    // element specific options
    classes: {
      type: Object,
      default: () => ({})
    },
    elementStyle: {
      type: Object,
      default: () => (DEFAULT_ELEMENT_STYLE)
    },
    value: {
      type: String
    },
    hidePostalCode: {
      type: Boolean,
      default: true
    },
    iconStyle: {
      type: String,
      default: 'default',
      validator: value => ['solid', 'default'].includes(value)
    },
    hideIcon: Boolean,
    disabled: Boolean,
    clientSecret: {
      type: String
    },
    intentType: {
      type: String
    },
    intentId: {
      type: String
    },
    loading: {
      type: Boolean,
      default: false
    },
    isTrial: {
      type: Boolean,
      default: () => false
    },
    offer: {
      type: String,
      default: () => null
    }
  },
  data () {
    return {
      pk,
      load: false,
      stripe: null,
      elements: null,
      element: null,
      card: null,
      showDiscount: false,
      code: null,
      error: null,
      coupon: null
    }
  },
  computed: {
    ...mapState(['couponCode']),
    form () {
      return document.getElementById('stripe-element-form')
    }
  },
  async mounted () {
    if (this.disableAdvancedFraudDetection) loadStripe.setLoadParameters({ advancedFraudSignals: false })

    const stripeOptions = {
      stripeAccount: this.stripeAccount,
      apiVersion: this.apiVersion,
      locale: this.locale
    }
    const createOptions = {
      classes: this.classes,
      style: this.elementStyle,
      value: this.value,
      hidePostalCode: this.hidePostalCode,
      iconStyle: this.iconStyle,
      hideIcon: this.hideIcon,
      disabled: this.disabled
    }

    this.stripe = await loadStripe(this.pk, stripeOptions)
    this.elements = this.stripe.elements(this.elementsOptions)
    this.element = this.elements.create(ELEMENT_TYPE, createOptions)
    this.element.mount('#stripe-element-mount-point')

    this.element.on('change', (event) => {
      const displayError = document.getElementById('stripe-element-errors')
      if (event.error) {
        displayError.textContent = event.error.message
      } else {
        displayError.textContent = ''
      }
      this.onChange(event)
    })

    this.element.on('blur', this.onBlur)
    this.element.on('click', this.onClick)
    this.element.on('escape', this.onEscape)
    this.element.on('focus', this.onFocus)
    this.element.on('ready', this.onReady)

    this.form.addEventListener('submit', async (event) => {
      try {
        this.$emit('loading', true)
        event.preventDefault()
        const data = {
          ...this.element
        }
        if (this.amount) data.amount = this.amount
        const { token, error } = await this.stripe.createToken(data)
        if (error) {
          const errorElement = document.getElementById('stripe-element-errors')
          errorElement.textContent = error.message
          this.$emit('error', error)
          return
        }
        this.$emit('token', token)
      } catch (error) {
        this.$emit('error', error)
      } finally {
        this.$emit('loading', false)
      }
    })
    if (this.couponCode || this.offer) {
      this.code = this.couponCode || this.offer
      this.showDiscount = true
      this.checkDiscount()
    }
  },
  methods: {
    /**
     * Triggers the submission of the form
     * @return {void}
     */
    submit () {
      this.$refs.submitButtonRef.click()
    },
    /**
     * Clears the element
     * @return {void}
     */
    clear () {
      this.element.clear()
    },
    /**
     * Destroys the element
     * @return {void}
     */
    destroy () {
      this.element.destroy()
    },
    /**
     * Focuses on the element
     * @return {void}
     */
    focus () {
      // This method will currently not work on iOS 13+ due to a system limitation
      this.element.focus()
    },
    /**
     * Unmounts the element
     * @return {void}
     */
    unmount () {
      this.element.unmount()
    },
    /**
     * Updates the element
     * @param {string} opts.classes.base The base class applied to the container. Defaults to StripeElement.
     * @param {string} opts.classes.complete The class name to apply when the Element is complete. Defaults to StripeElement--complete.
     * @param {string} opts.classes.empty The class name to apply when the Element is empty. Defaults to StripeElement--empty.
     * @param {string} opts.classes.focus The class name to apply when the Element is focused. Defaults to StripeElement--focus.
     * @param {string} opts.classes.invalid The class name to apply when the Element is invalid. Defaults to StripeElement--invalid.
     * @param {string} opts.classes.webkitAutoFill The class name to apply when the Element has its value autofilled by the browser (only on Chrome and Safari). Defaults to StripeElement--webkit-autofill.
     * @param {Object} opts.style Customize the appearance of this element using CSS properties passed in a Style object.
     * @param {string} opts.value A pre-filled set of values to include in the input (e.g., {postalCode: '94110'}). Note that sensitive card information (card number, CVC, and expiration date) cannot be pre-filled
     * @param {boolean} opts.hidePostalCode Hide the postal code field. Default is false. If you are already collecting a full billing address or postal code elsewhere, set this to true.
     * @param {string} opts.iconStyle Appearance of the icon in the Element. Either solid or default.
     * @param {boolean} opts.hideIcon Hides the icon in the Element. Default is false.
     * @param {boolean} opts.disabled Applies a disabled state to the Element such that user input is not accepted. Default is false.
     */
    update (opts) {
      this.element.update(opts)
    },
    // events
    onChange (e) {
      this.$emit('element-change', e)
    },
    onReady (e) {
      this.$emit('element-ready', e)
    },
    onFocus (e) {
      this.$emit('element-focus', e)
    },
    onBlur (e) {
      this.$emit('element-blur', e)
    },
    onEscape (e) {
      this.$emit('element-escape', e)
    },
    onClick (e) {
      this.$emit('element-click', e)
    },
    confirm () {
      if (this.intentType === 'payment_intent') {
        return this.stripe.confirmCardPayment(this.clientSecret, {
          payment_method: {
            card: this.element
          }
        }).then(result => {
          if (result.error) {
            this.$emit('fail', `Payment failed: ${result.error.message}`)
          } else {
            this.$emit('success')
          }
        })
      } else if (this.intentType === 'setup_intent') {
        return this.stripe.confirmCardSetup(this.clientSecret, {
          payment_method: {
            card: this.element
          }
        }).then(result => {
          if (result.error) {
            this.$emit('fail', `Setup intent confirm failed: ${result.error.message}`)
          } else {
            this.$emit('success')
          }
        })
      }
    },
    checkDiscount () {
      if (!this.code) return false
      this.error = null
      this.coupon = null
      return this.$http.post('/billing/discount', { code: this.code })
        .then(res => {
          this.coupon = res.data.response
          this.$emit('discount', this.coupon)
        })
        .catch(e => {
          this.error = 'Invalid Coupon Code'
          this.code = null
          this.showDiscount = false
        })
    }
  },
  watch: {
    clientSecret: function (secret) {
      this.confirm()
    }
  }
}
</script>

<style scoped>
/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid #d1d5da;
  border-radius: 4px;
  background-color: white;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
  border: 1px solid #111827;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--complete {
  border-color: #069668;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.hide {
  visibility: hidden;
}
</style>
