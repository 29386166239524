import axios from 'axios'
const baseURL = process.env.VUE_APP_API_BASE_URL || 'http://localhost:3001'

const request = axios.create({
  baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

request.interceptors.request.use(config => {
  return import('../store')
    .then(({ default: store }) => {
      const token = store.state.token
      if (token) config.headers.Authorization = `Bearer ${token}`
      return config
    })
}, e => {
  return Promise.reject(e)
})

request.interceptors.response.use(
  res => (res),
  error => {
    if (error && error.response && error.response.status === 401) {
      return import('../store')
        .then(({ default: store }) => {
          store.commit('SET_LOGGED_OUT')
          if (!['/login', '/app/login'].includes(window.location.pathname)) {
            if (process.env.NODE_ENV !== 'production') window.location = '/login'
            else window.location = '/app/login'
          }
          return Promise.reject(error)
        })
    }
    return Promise.reject(error)
  }
)

export default request
