<template>
  <div class="h-full">
    <div class="flex items-stretch m-auto">
      <div class="flex-1 text-gray-700 text-left px-4 py-8 m-3 bg-white rounded-lg shadowflow-root">
        <ul role="list" class="-mb-8">
          <li v-for="(transaction, transactionIdx) in insiders" :key="transaction.id">
            <div class="relative pb-2">
              <span v-if="transactionIdx !== insiders.length - 1" class="absolute top-7 left-4 -ml-px h-2 w-0.5 bg-gray-200" aria-hidden="true" />
              <div class="relative flex items-start space-x-3">
                <div>
                  <div class="relative px-1">
                    <div class="h-6 w-6 bg-gray-100 rounded-full ring-6 ring-white flex items-center justify-center"
                      :class="[transaction.transactionCode === 'P' ? 'bg-emerald-50' : '', transaction.transactionCode === 'S' ? 'bg-red-50' : '']">
                      <ArrowTrendingUpIcon v-if="transaction.transactionCode === 'P'" class="h-4 w-4 text-emerald-500" aria-hidden="true" />
                      <ArrowTrendingDownIcon v-else-if="transaction.transactionCode === 'S'" class="h-4 w-4 text-red-600" aria-hidden="true" />
                    </div>
                  </div>
                </div>
                <div class="min-w-0 flex-1 py-1.5">
                  <div class="text-sm text-gray-500">
                    <a v-if="transaction.secLink" :href="transaction.secLink" target="_blank" class="font-medium text-gray-900 hover:text-emerald-500 cursor-pointer">
                      {{ transaction.ownerName }}
                    </a>
                    <span v-else class="font-medium text-gray-900">{{ transaction.ownerName }}</span>
                    {{ ' ' }}
                    <span v-if="transaction.transactionCode === 'P'">purchased</span>
                    <span v-else-if="transaction.transactionCode === 'S'">sold</span>
                    {{ ' ' }}
                    <span class="font-medium text-gray-900">{{ transaction.transactionAmount }}</span> shares at
                    {{ ' ' }}
                    <span class="font-medium text-gray-900">{{ transaction.transactionPrice }} {{ stock.currency }}</span>
                    {{ ' ' }}
                    <span>{{ formatDate(transaction.date) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { ArrowTrendingUpIcon, ArrowTrendingDownIcon } from '@heroicons/vue/24/outline'
import moment from 'moment'

export default {
  name: 'Insiders',
  components: {
    ArrowTrendingUpIcon,
    ArrowTrendingDownIcon
  },
  computed: {
    ...mapState(['stock']),
    stockId () {
      return this.$route.params.id
    },
    insiders () {
      if (!this.stock?.profile?.InsiderTransactions) return []
      const transactions = []
      for (const i of Object.keys(this.stock.profile.InsiderTransactions)) transactions.push(this.stock.profile.InsiderTransactions[i])
      return transactions
    }
  },
  methods: {
    formatDate (date) {
      return moment(date, 'YYYYMMDD').fromNow()
    }
  }
}
</script>
