<template>
  <Menu as="div" class="relative inline-block text-left">
    <div>
      <MenuButton class="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 focus:ring-offset-gray-100">
        <span class="sr-only">Group By</span>
        <QueueListIcon v-if="!groupName" class="h-5 w-5 text-emerald-600 hover:text-emerald-500 hover:scale-110 transition duration-400 text-sm" aria-hidden="true" />
        <span v-else class="inline-flex items-center rounded-full bg-emerald-600 hover:scale-105 transition duration-400 py-0.5 px-2 pr-2.5 text-xs font-medium text-emerald-100">
          <QueueListIcon class="h-4 w-4 text-white text-sm mr-1" aria-hidden="true" />
          {{ groupName }}
        </span>
      </MenuButton>
    </div>

    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
      <MenuItems class="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div class="px-3 py-2">
          <p class="text-xs font-bold">Group by:</p>
        </div>
        <div>
          <MenuItem v-for="field in fields" :key="field.id" @click="saveGroup(field)">
            <div :class="[field?.id === portfolio?.settings?.groupBy ? 'text-white bg-emerald-600' : 'text-gray-900 hover:bg-emerald-50', 'relative cursor-pointer select-none py-2 pl-8 pr-4 text-xs']">
              <span :class="[field?.id === portfolio?.settings?.groupBy ? 'font-semibold' : 'font-normal', 'block truncate']">{{ field.name }}</span>
              <span v-if="field?.id === portfolio?.settings?.groupBy" :class="[field?.id === portfolio?.settings?.groupBy ? 'text-white' : 'text-emerald-600', 'absolute inset-y-0 left-0 flex items-center pl-1.5']">
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </div>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script >
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { QueueListIcon, CheckIcon } from '@heroicons/vue/24/outline'
import { mapMutations } from 'vuex'

export default {
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    QueueListIcon,
    CheckIcon
  },
  props: {
    portfolio: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      fields: [
        { id: null, name: 'No Group' },
        { id: 'country', name: 'Country' },
        { id: 'currency', name: 'Currency' },
        { id: 'exchange', name: 'Exchange' },
        { id: 'industry', name: 'Industry' },
        { id: 'sector', name: 'Sector' },
        { id: 'type', name: 'Type' },
        { id: 'mktCapCategory', name: 'Market Cap' }
      ]
    }
  },
  mounted () {
    if (this.portfolio.settings) this.settings = this.portfolio.settings
    this.name = this.portfolio.name
  },
  computed: {
    groupName () {
      const group = this.fields.find(f => f.id === this.portfolio?.settings?.groupBy)
      if (group?.id) return group.name
      return null
    }
  },
  methods: {
    ...mapMutations(['SET_GROUP']),
    saveGroup (group) {
      return this.$http.patch(`/portfolio/${this.portfolio._id}`, {
        settings: {
          groupBy: group.id
        }
      }).then(() => {
        this.SET_GROUP(group.id)
      })
    }
  }
}
</script>
