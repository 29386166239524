<template>
  <div id="app">
    <Notifications />
    <Banner v-if="token && user.plan === 'free'" :text="bannerText" cta="Subscribe now" :source="source" />
    <SumoBanner v-if="showSumo" />
    <FullLoader v-if="loading" />
    <Welcome
      v-if="token && user && (!user.first_name || user.first_name === '' || !user.strategy || user.strategy === '')"
      :user="user" />
    <Upgrade v-if="token && user && showUpgrade" :user="user" :upgradeSource="showUpgrade" />
    <Navbar v-if="token" />
    <div class="inset-0 pb-10">
      <router-view />
    </div>
  </div>
</template>

<script>
import Navbar from './views/Navbar'
import Notifications from './views/Notifications'
import FullLoader from '@/components/FullLoader'
import Banner from '@/components/Banner'
import SumoBanner from '@/components/SumoBanner'
import Welcome from '@/components/Welcome'
import Upgrade from '@/components/Upgrade'
import { mapState, mapGetters, mapMutations } from 'vuex'
import moment from 'moment'

export default {
  name: 'app',
  components: {
    Navbar,
    FullLoader,
    Banner,
    SumoBanner,
    Notifications,
    Welcome,
    Upgrade
  },
  data () {
    return {
      loading: true
    }
  },
  computed: {
    ...mapState(['user', 'token', 'notifications', 'showUpgrade']),
    ...mapGetters(['trialLeft']),
    showSumo () {
      return this.token && this.user.plan !== 'free' && this.user.upgradeSource === 'appsumo' && moment().diff(moment(this.user.createdAt), 'days') > 2
    },
    bannerText () {
      if (this.trialLeft > 0) return `Your Premium trial is ending in ${this.trialLeft} day${this.trialLeft > 1 ? 's' : ''}`
      else if (this.trialLeft === 0) return 'Your Premium trial is ending very soon!'
      else return 'Upgrade to Premium to unlock more features'
    },
    source () {
      if (this.trialLeft >= 0) return 'banner-trial'
      return 'banner'
    }
  },
  methods: {
    ...mapMutations(['SET_USER', 'SHOW_UPGRADE']),
    getUser () {
      return this.$http.get('/user')
        .then(res => {
          this.SET_USER({ user: res.data })
        })
    }
  },
  mounted () {
    if (this.token) this.getUser()
    this.loading = false
  }
}
</script>

<style lang="scss">
body {
  margin: 0;
  padding: 0;
  background: #f6f4ee;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

// For Google Chrome
::-webkit-scrollbar {
  width: 6px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: #c3c6de;
  border-radius: 20px;
  cursor: grabbing;
  opacity: .6;
  transition: .2s all;

  &:hover {
    opacity: 1;
  }
}

::-webkit-scrollbar-track {
  background: none;
}

// For Internet Explorer
body {
  scrollbar-face-color: #1a202c;
  scrollbar-track-color: none;
}
</style>
