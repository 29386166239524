<template>
  <div class="relative mx-auto h-screen w-1/2 mt-20">
    <Loader :type="type" class="loader m-auto" :text="text" />
    <div class="bg" />
  </div>
</template>

<script>
import Loader from './Loader'
export default {
  name: 'FullLoader',
  props: {
    text: {
      type: String,
      default: 'Loading...'
    },
    type: {
      type: String,
      default: 'default'
    }
  },
  components: {
    Loader
  }
}
</script>

<style lang="scss" scoped>
.loader-wrapper {
  display: block;
  position: relative;
  z-index: 10000;
}
.loader {
  position: relative;
  top: 0;
  margin: auto;
  background-size: auto 100%;
  display: block;
  z-index: 10001;
}
.text {
  z-index: 10001;
  position: relative;
  top: 0;
  left: 0;
  color: #026949;
  font-size: 22px;
  margin-bottom: 25px;
}
.bg {
  background: #f5efe8;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  opacity: .95;
}
</style>
