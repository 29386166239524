<template>
  <div class="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
    <section aria-labelledby="billing_details_heading">
      <div class="shadow sm:rounded-md sm:overflow-hidden relative">
        <!-- Unsubscribe -->
        <div class="bg-white py-6 px-4 sm:p-6">
          <div class="p-3" v-if="cancelled">
            <h2 class="text-lg leading-6 font-medium text-gray-900 mb-1">
              Your subscription has been cancelled
            </h2>
          </div>
          <div class="p-3" v-else-if="showCancel">
            <FaceFrownIcon class="w-14 h-14 mb-4 text-red-400 mx-auto" />
            <h2 class="text-2xl leading-6 font-medium text-gray-900 mb-6 mx-auto text-center">
              {{ sad }}
            </h2>
            <h2 class="text-sm text-gray-700 mb-3">
              Please select a reason and confirm :
            </h2>
            <Options :options="cancelOptions" @select="updateReason" />
            <div class="mb-4">
              <label for="comment" class="block text-sm text-gray-700 mt-4 mb-3">How could I improve Beanvest?</label>
              <div class="mt-2">
                <textarea
                  placeholder="You should improve..."
                  rows="4"
                  name="comment"
                  v-model="comment"
                  class="shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block w-full sm:text-sm border-gray-300 rounded-md" />
              </div>
            </div>
            <div class="rounded-md bg-orange-50 p-2 px-3 text-orange-600 text-sm mb-4 transition-all duration-200" v-if="showError && error">
              <div class="flex">
                <div class="flex-shrink-0">
                  <ExclamationTriangleIcon class="h-5 w-5 text-orange-400" aria-hidden="true" />
                </div>
                <div class="ml-3 flex-1 md:flex md:justify-between">
                  {{ error }}
                </div>
              </div>
            </div>
            <div class="rounded-md bg-blue-50 p-4" v-if="['feature', 'bugs', 'expensive'].includes(reason?.id)">
              <div class="flex">
                <div class="flex-shrink-0">
                  <InformationCircleIcon class="h-5 w-5 text-blue-400" aria-hidden="true" />
                </div>
                <div class="ml-3 flex-1 md:flex md:justify-between">
                  <p class="text-sm text-blue-700" v-if="reason?.id === 'feature'">Beanvest is updated regularly with new features. The one you want might come next!</p>
                  <p class="text-sm text-blue-700" v-if="reason?.id === 'bugs'">You can report issue using the "Feedback" button in the top menu. I will try my best to help you!</p>
                  <p class="text-sm text-blue-700" v-if="reason?.id === 'expensive'">You get 2 months free by choosing a Yearly subscription! (You can save $20 per year)</p>
                  <p class="mt-3 text-sm md:mt-0 md:ml-6" v-if="reason?.id === 'feature'">
                    <a href="https://www.beanvest.com/roadmap" target="_blank" class="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600">
                      View the Roadmap
                      <span aria-hidden="true"> &rarr;</span>
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div class="flex flex-row-reverse grid-cols-2 gap-4 mt-6">
              <button
                @click="cancel"
                type="button"
                class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400">
                Yes, cancel my subscription
              </button>
              <div
                @click="showCancel = false"
                class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-bold text-emerald-600 hover:text-emerald-400 cursor-pointer">
                I changed my mind
              </div>
            </div>
          </div>
          <!-- Current plan -->
          <div class="mb-6" v-else>
            <h2 id="billing_heading" class="text-xl leading-6 font-medium text-gray-900">
              <span v-if="user.upgradeSource === 'appsumo'" class="bg-yellow-200 text-yellow-800 px-2 py-1 text-sm rounded-lg mr-2">AppSumo Lifetime Deal</span>
              You are currently on the {{ user.plan }} plan
            </h2>
            <div @click="SHOW_UPGRADE('billing')" v-if="user.plan === 'free'">
              <p class="mt-3 text-gray-500 cursor-pointer">Unlock to full potential of Beanvest by subscribing to Premium</p>
              <button
                class="w-xl mt-6 border-transparent col-auto transition-all duration-200 text-sm px-4 py-2 rounded focus:outline-none focus:ring-4 focus:ring-emerald-500 focus:ring-opacity-50 bg-emerald-600 hover:bg-emerald-500 active:bg-emerald-800 text-white">
                Upgrade to Premium
              </button>
            </div>
            <div
              v-else
              @click="showCancel = true"
              class="absolute top-6 right-6 text-xs font-medium cursor-pointer text-gray-400 hover:text-red-600">
              Cancel subscription
            </div>
          </div>
          <Compare v-if="!showCancel && !cancelled" class="bg-gray-50 rounded-md py-4" :showTitle="false" />
        </div>
      </div>
    </section>

    <section aria-labelledby="billing_details_heading">
      <div class="shadow sm:rounded-md sm:overflow-hidden relative">
        <div class="bg-white py-6 px-4 sm:p-6">
          <!-- Invoices -->
          <div class="mb-3">
            <h2 id="billing_heading" class="text-xl leading-6 font-medium text-gray-900">
              Invoices
            </h2>
            <Loader v-if="loading" text="" />
            <div class="flex flex-col mt-3 text-gray-400" v-else-if="!invoices.length">
              You don't have any invoice yet
            </div>
            <div class="flex flex-col mt-2" v-else-if="invoices.length">
              <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div class="sm:rounded-lg">
                    <table class="min-w-full rounded mt-2 divide-y divide-gray-200">
                      <tbody>
                        <tr v-for="(invoice, index) in invoices" :key="index" :class="index % 2 === 0 ? 'bg-gray-50' : 'bg-white'">
                          <td class="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                            {{ formatDate(invoice.created) }}
                          </td>
                          <td class="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                            {{ invoice.amount_paid / 100 }} {{ invoice.currency?.toUpperCase() }}
                          </td>
                          <td class="px-4 py-2 whitespace-nowrap text-right text-sm font-medium">
                            <a :href="invoice.invoice_pdf" target="_blank" class="text-emerald-600 hover:text-emerald-900">Download</a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import Options from '@/components/Options'
import Loader from '@/components/Loader'
import Compare from '../Compare.vue'
import moment from 'moment'
import { FaceFrownIcon, InformationCircleIcon, ExclamationTriangleIcon } from '@heroicons/vue/24/outline'

export default {
  name: 'BillingSettings',
  mounted () {
    this.loading = true
    this.$http.get('/billing/invoices')
      .then(res => {
        if (res.data.invoices) this.invoices = res.data.invoices
        this.loading = false
      })
  },
  data () {
    return {
      loading: false,
      reason: null,
      showCancel: false,
      cancelled: false,
      cancelOptions: [
        { id: 'feature', name: 'Missing features', description: 'There are some features I need that are missing' },
        { id: 'expensive', name: 'Too expensive', description: 'The Premium plan is too expensive for me' },
        { id: 'competitor', name: 'I am using another tool', description: 'I am using another software that is better than Beanvest' },
        { id: 'bugs', name: 'Too many bugs', description: 'I had too many issues with the software' },
        { id: 'other', name: 'Other', description: 'Another reason' }
      ],
      comment: '',
      invoices: [],
      showError: false
    }
  },
  methods: {
    ...mapMutations(['SHOW_UPGRADE']),
    updateReason (reason) {
      this.reason = reason
    },
    cancel (res) {
      this.showError = false
      this.loading = true
      if (this.error) {
        this.showError = true
        return
      }
      this.$http
        .post('/billing/unsubscribe', {
          reason: this.reason?.id,
          comment: this.comment
        })
        .then(res => {
          this.$track('Unsubscribe', { facebook: false })
          this.reason = null
          this.showCancel = false
          this.cancelled = true
        })
        .catch(error => {
          if (error && error.message) {
            this.NOTIFY({ text: error.message, type: 'error' })
          } else if (error && error.response) {
            this.error = error.response.data || `[${error.response.status}] An error occured`
          } else this.error = error.status
        })
        .finally(() => {
          this.loading = false
        })
    },
    formatDate (date) {
      return moment(date).format('LL')
    }
  },
  components: {
    Options,
    Loader,
    Compare,
    FaceFrownIcon,
    InformationCircleIcon,
    ExclamationTriangleIcon
  },
  computed: {
    ...mapState(['user']),
    sad () {
      const n = this.user.first_name
      if (n && n !== '') return `Sad to let you go ${n}`
      return 'Sad to let you go...'
    },
    error () {
      if (!this.reason?.id) return 'Please select a reason'
      if (!this.comment?.length) return 'Please add a comment to help me improve Beanvest'
      return null
    }
  }
}
</script>
