<template>
  <div class="w-full" v-if="!loading && portfolio.lines">
    <ul class="grid grid-cols-1 gap-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">

      <!-- Holdings -->
      <li class="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
        <div class="flex-1 flex flex-col mb-2">
          <h3 class="mt-6 text-gray-900 text-sm font-medium">Holdings</h3>
          <dl class="mt-1 flex-grow flex flex-col justify-between">
            <dd class="text-gray-500 text-xs">Holdings</dd>
          </dl>
          <dd class="mt-3 flex gap-1 mx-auto">
            <span class="px-2 py-1 text-emerald-800 text-xs font-medium bg-emerald-100 rounded-full">
              {{ holdings.length }} holdings
            </span>
          </dd>
        </div>
        <div>
          <PieChart
            class="mx-auto w-full p-4 lg:p-6 xxl:p-8 py-10 align-top"
            chartId="allocation-holdings"
            :defaultTitle="holdingsDefaultTitle"
            :defaultValue="holdingsDefaultValue"
            :allocation="true"
            :lines="holdings"
            :currency="portfolio.currency"
            v-if="!loading && holdings && holdings.length" />
        </div>
      </li>
      <!-- Currencies -->
      <li class="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
        <div class="flex-1 flex flex-col mb-2">
          <h3 class="mt-6 text-gray-900 text-sm font-medium">Currencies</h3>
          <dl class="mt-1 flex-grow flex flex-col justify-between">
            <dd class="text-gray-500 text-xs">Most represented currencies in you portfolio</dd>
          </dl>
          <dd class="mt-3 flex gap-1 mx-auto">
            <span class="px-2 py-1 text-emerald-800 text-xs font-medium bg-emerald-100 rounded-full">
              {{ amountInBaseCurrency }}% in {{ portfolio.currency }}
            </span>
            <span class="px-2 py-1 text-red-800 text-xs font-medium bg-red-100 rounded-full" v-if="amountInBaseCurrency < 100">
              {{ 100 - amountInBaseCurrency }}% in other currencies
            </span>
          </dd>
        </div>
        <div>
          <PieChart
            class="mx-auto w-full p-4 lg:p-6 xxl:p-8 py-10 align-top"
            chartId="allocation-currencies"
            :defaultTitle="currenciesDefaultTitle"
            :defaultValue="currenciesDefaultValue"
            :allocation="true"
            :lines="currencies"
            :currency="portfolio.currency"
            v-if="!loading && currencies && currencies.length" />
        </div>
      </li>
      <!-- Types / Asset classes -->
      <li class="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
        <div class="flex-1 flex flex-col mb-2">
          <h3 class="mt-6 text-gray-900 text-sm font-medium">Asset classes</h3>
          <dl class="mt-1 flex-grow flex flex-col justify-between">
            <dd class="text-gray-500 text-xs">Repartition of your different asset classes</dd>
          </dl>
          <dd class="mt-3 flex gap-1 mx-auto">
            <span class="px-2 py-1 text-emerald-800 text-xs font-medium bg-emerald-100 rounded-full">
              {{ majorityAssetClass }}
            </span>
          </dd>
        </div>
        <div>
          <PieChart
            class="mx-auto w-full p-4 lg:p-6 xxl:p-8 py-10 align-top"
            chartId="allocation-types"
            :defaultTitle="typesDefaultTitle"
            :defaultValue="typesDefaultValue"
            :allocation="true"
            :lines="types"
            :currency="portfolio.currency"
            v-if="!loading && types && types.length" />
        </div>
      </li>
      <!-- Countries -->
      <li class="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
        <div class="flex-1 flex flex-col mb-2">
          <h3 class="mt-6 text-gray-900 text-sm font-medium">Countries</h3>
          <dl class="mt-1 flex-grow flex flex-col justify-between">
            <dd class="text-gray-500 text-xs">Countries represented in your portfolio</dd>
          </dl>
          <dd class="mt-3 flex gap-1 mx-auto">
            <span class="px-2 py-1 text-emerald-800 text-xs font-medium bg-emerald-100 rounded-full">
              {{ majorityCountry }}
            </span>
            <span class="px-2 py-1 text-emerald-800 text-xs font-medium bg-emerald-100 rounded-full" v-if="countries.length > 1">
              {{ countries.length }} countries
            </span>
            <span class="px-2 py-1 text-red-800 text-xs font-medium bg-red-100 rounded-full" v-else>
              only one country
            </span>
          </dd>
        </div>
        <div>
          <PieChart
            class="mx-auto w-full p-4 lg:p-6 xxl:p-8 py-10 align-top"
            chartId="allocation-countries"
            :defaultTitle="countriesDefaultTitle"
            :defaultValue="countriesDefaultValue"
            :allocation="true"
            :lines="countries"
            :currency="portfolio.currency"
            v-if="!loading && countries && countries.length" />
        </div>
      </li>
      <!-- Market Cap -->
      <li class="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
        <div class="flex-1 flex flex-col mb-2">
          <h3 class="mt-6 text-gray-900 text-sm font-medium">Market cap</h3>
          <dl class="mt-1 flex-grow flex flex-col justify-between">
            <dd class="text-gray-500 text-xs">Size of companies in your portfolio</dd>
          </dl>
        </div>
        <div>
          <PieChart
            class="mx-auto w-full p-4 lg:p-6 xxl:p-8 py-10 align-top"
            chartId="allocation-mktCap"
            :defaultTitle="mktCapDefaultTitle"
            :defaultValue="mktCapDefaultValue"
            :allocation="true"
            :lines="mktCap"
            :currency="portfolio.currency"
            v-if="!loading && mktCap && mktCap.length" />
        </div>
      </li>
      <!-- Industries -->
      <li class="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
        <div class="flex-1 flex flex-col mb-2">
          <h3 class="mt-6 text-gray-900 text-sm font-medium">Industries</h3>
          <dl class="mt-1 flex-grow flex flex-col justify-between">
            <dd class="text-gray-500 text-xs">Industries represented in your portfolio</dd>
          </dl>
          <dd class="mt-3 flex gap-1 mx-auto">
            <span class="px-2 py-1 text-emerald-800 text-xs font-medium bg-emerald-100 rounded-full">
              {{ industries.length }} industries
            </span>
          </dd>
        </div>
        <div>
          <PieChart
            class="mx-auto w-full p-4 lg:p-6 xxl:p-8 py-10 align-top"
            chartId="allocation-industries"
            :defaultTitle="industriesDefaultTitle"
            :defaultValue="industriesDefaultValue"
            :allocation="true"
            :lines="industries"
            :currency="portfolio.currency"
            v-if="!loading && industries && industries.length" />
        </div>
      </li>
      <!-- Sectors -->
      <li class="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
        <div class="flex-1 flex flex-col mb-2">
          <h3 class="mt-6 text-gray-900 text-sm font-medium">Sectors</h3>
          <dl class="mt-1 flex-grow flex flex-col justify-between">
            <dd class="text-gray-500 text-xs">Sectors represented in your portfolio</dd>
          </dl>
          <dd class="mt-3 flex gap-1 mx-auto">
            <span class="px-2 py-1 text-emerald-800 text-xs font-medium bg-emerald-100 rounded-full">
              {{ sectors.length }} sectors
            </span>
          </dd>
        </div>
        <div>
          <PieChart
            class="mx-auto w-full p-4 lg:p-6 xxl:p-8 py-10 align-top"
            chartId="allocation-sectors"
            :defaultTitle="sectorsDefaultTitle"
            :defaultValue="sectorsDefaultValue"
            :allocation="true"
            :lines="sectors"
            :currency="portfolio.currency"
            v-if="!loading && sectors && sectors.length" />
        </div>
      </li>
      <!-- Request more -->
      <li class="col-span-1 flex flex-col text-center">
        <div class="flex-1 flex flex-col mb-2">
          <h3 class="mx-12 mt-24 text-gray-600 text-xl">
            Do you need more allocation charts?
          </h3>
          <div class="m-4 text-gray-500 text-base">
            Click on the feedback button in the menu to request more
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PieChart from '@/components/PieChart'

export default {
  name: 'Allocation',
  components: {
    PieChart
  },
  computed: {
    ...mapState(['loading', 'portfolio']),
    amountInBaseCurrency () {
      const baseCurrency = this.currencies.find(c => c.name === this.portfolio.currency)
      return Math.round(baseCurrency?.percent || 0)
    },
    majorityAssetClass () {
      return `${this.typesDefaultValue} invested in ${this.typesDefaultTitle}`
    },
    majorityCountry () {
      return `${this.countriesDefaultValue} invested in ${this.countriesDefaultTitle}`
    },
    holdings () {
      if (!this.portfolio || !this.portfolio.lines) return []
      return this.portfolio.lines
        .map(l => ({
          name: l.name,
          currentValue: l.currentValue,
          baseCurrencyCurrentValue: l.baseCurrencyCurrentValue,
          percent: Math.round(l.baseCurrencyCurrentValue / this.portfolio.currentValue * 1000) / 10
        }))
        .sort((a, b) => b.percent - a.percent)
    },
    holdingsDefaultTitle () {
      return this.portfolio.lines[0].name
    },
    holdingsDefaultValue () {
      return Math.round(this.portfolio.lines[0].baseCurrencyCurrentValue / this.portfolio.currentValue * 100) + '%'
    },
    currencies () {
      const currencies = {}
      for (const line of this.portfolio.lines) {
        if (!currencies[line.currency]) currencies[line.currency] = { currentValue: 0, baseCurrencyCurrentValue: 0 }
        currencies[line.currency].currentValue += line.currentValue
        currencies[line.currency].baseCurrencyCurrentValue += line.baseCurrencyCurrentValue
      }
      const currenciesArray = []
      for (const c of Object.keys(currencies)) {
        currencies[c].percent = currencies[c].baseCurrencyCurrentValue / this.portfolio.currentValue * 100
        currencies[c].name = c
        currenciesArray.push(currencies[c])
      }
      return currenciesArray.sort((a, b) => b.percent - a.percent)
    },
    currenciesDefaultTitle () {
      return this.currencies[0].name
    },
    currenciesDefaultValue () {
      return Math.round(this.currencies[0].percent) + '%'
    },
    types () {
      const types = {}
      for (const line of this.portfolio.lines) {
        if (!types[line.type]) types[line.type] = { currentValue: 0, baseCurrencyCurrentValue: 0 }
        types[line.type].currentValue += line.currentValue
        types[line.type].baseCurrencyCurrentValue += line.baseCurrencyCurrentValue
      }
      const typesArray = []
      for (const t of Object.keys(types)) {
        types[t].percent = types[t].baseCurrencyCurrentValue / this.portfolio.currentValue * 100
        types[t].name = t
        typesArray.push(types[t])
      }
      return typesArray.sort((a, b) => b.percent - a.percent)
    },
    typesDefaultTitle () {
      return this.types[0].name
    },
    typesDefaultValue () {
      return Math.round(this.types[0].percent) + '%'
    },
    countries () {
      // @TODO: Better handle countries for ETFs
      const countries = {}
      for (const line of this.portfolio.lines) {
        if (!countries[line.country]) countries[line.country] = { currentValue: 0, baseCurrencyCurrentValue: 0 }
        countries[line.country].currentValue += line.currentValue
        countries[line.country].baseCurrencyCurrentValue += line.baseCurrencyCurrentValue
      }
      const countriesArray = []
      for (const c of Object.keys(countries)) {
        countries[c].percent = countries[c].baseCurrencyCurrentValue / this.portfolio.currentValue * 100
        countries[c].name = c
        countriesArray.push(countries[c])
      }
      return countriesArray.sort((a, b) => b.percent - a.percent)
    },
    countriesDefaultTitle () {
      return this.countries[0].name
    },
    countriesDefaultValue () {
      return Math.round(this.countries[0].percent) + '%'
    },
    industries () {
      const industries = {}
      for (const line of this.portfolio.lines) {
        const industry = line.industry || 'Other'
        if (!industries[industry]) industries[industry] = { currentValue: 0, baseCurrencyCurrentValue: 0 }
        industries[industry].currentValue += line.currentValue
        industries[industry].baseCurrencyCurrentValue += line.baseCurrencyCurrentValue
      }
      const industriesArray = []
      for (const c of Object.keys(industries)) {
        industries[c].percent = industries[c].baseCurrencyCurrentValue / this.portfolio.currentValue * 100
        industries[c].name = c
        industriesArray.push(industries[c])
      }
      return industriesArray.sort((a, b) => b.percent - a.percent)
    },
    industriesDefaultTitle () {
      return this.industries[0].name
    },
    industriesDefaultValue () {
      return Math.round(this.industries[0].percent) + '%'
    },
    sectors () {
      const sectors = {}
      for (const line of this.portfolio.lines) {
        const sector = line.sector || 'Other'
        if (!sectors[sector]) sectors[sector] = { currentValue: 0, baseCurrencyCurrentValue: 0 }
        sectors[sector].currentValue += line.currentValue
        sectors[sector].baseCurrencyCurrentValue += line.baseCurrencyCurrentValue
      }
      const sectorsArray = []
      for (const c of Object.keys(sectors)) {
        sectors[c].percent = sectors[c].baseCurrencyCurrentValue / this.portfolio.currentValue * 100
        sectors[c].name = c
        sectorsArray.push(sectors[c])
      }
      return sectorsArray.sort((a, b) => b.percent - a.percent)
    },
    sectorsDefaultTitle () {
      return this.sectors[0].name
    },
    sectorsDefaultValue () {
      return Math.round(this.sectors[0].percent) + '%'
    },
    mktCap () {
      const mktCap = {}
      for (const line of this.portfolio.lines) {
        const cap = this.cap(line.mktCap).category
        if (!mktCap[cap]) mktCap[cap] = { currentValue: 0, baseCurrencyCurrentValue: 0 }
        mktCap[cap].currentValue += line.currentValue
        mktCap[cap].baseCurrencyCurrentValue += line.baseCurrencyCurrentValue
      }
      const mktCapArray = []
      for (const c of Object.keys(mktCap)) {
        mktCap[c].percent = mktCap[c].baseCurrencyCurrentValue / this.portfolio.currentValue * 100
        mktCap[c].name = c
        mktCapArray.push(mktCap[c])
      }
      return mktCapArray.sort((a, b) => b.percent - a.percent)
    },
    mktCapDefaultTitle () {
      return this.mktCap[0].name
    },
    mktCapDefaultValue () {
      return Math.round(this.mktCap[0].percent) + '%'
    }
  },
  methods: {
    cap (mktCap) {
      if (mktCap >= 1000000000000) {
        return { category: 'Giga cap', number: Math.round(mktCap / 10000000000) / 100 + ' Trillion' }
      } else if (mktCap >= 300000000000) {
        return { category: 'Mega cap', number: Math.round(mktCap / 1000000000) + ' Billion' }
      } else if (mktCap >= 10000000000) {
        return { category: 'Large cap', number: Math.round(mktCap / 1000000000) + ' Billion' }
      } else if (mktCap >= 2000000000) {
        return { category: 'Mid cap', number: Math.round(mktCap / 1000000000) + ' Billion' }
      } else if (mktCap >= 300000000) {
        return { category: 'Small cap', number: Math.round(mktCap / 1000000) + ' Million' }
      } else if (mktCap >= 50000000) {
        return { category: 'Micro cap', number: Math.round(mktCap / 1000000) + ' Million' }
      } else if (mktCap) {
        return { category: 'Nano cap', number: Math.round(mktCap / 1000000) + ' Million' }
      } else {
        return { category: '-', number: '?' }
      }
    }
  }
}
</script>
