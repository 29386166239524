<template>
  <div class="flex flex-col text-left">
    <div class="overflow-x-auto">
      <div class="inline-block min-w-full align-middle">
        <div class="overflow-hidden">
          <!--<div v-if="selected.length > 0" class="absolute top-0 left-12 flex h-12 items-center space-x-3 bg-gray-50 sm:left-16">
            <button type="button" class="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30">Bulk edit</button>
            <button type="button" class="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30">Delete all</button>
          </div>-->
          <table class="min-w-full divide-y divide-gray-300">
            <thead class="bg-gray-50">
              <tr>
                <!--<th scope="col" class="relative w-12 px-6 sm:w-16 sm:px-8">
                  <input type="checkbox" class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-500 sm:left-6" :checked="selected.length === results.length" />
                </th>-->
                <th @click="sortBy('name')" scope="col" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold cursor-pointer text-gray-800 hover:text-gray-950 sm:pl-6">Name</th>
                <th @click="sortBy('exchange')" scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold cursor-pointer text-gray-800 hover:text-gray-950">Exchange</th>
                <th @click="sortBy('symbol')" scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold cursor-pointer text-gray-800 hover:text-gray-950">Symbol</th>
                <th @click="sortBy(field.id)" v-for="(field, i) in selectedFields" :key="i" scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold cursor-pointer text-gray-800 hover:text-gray-950">{{ field?.name }}</th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 bg-white">
              <tr v-for="result in results" :key="result._id">
                <!--<td class="relative w-12 px-6 sm:w-16 sm:px-8">
                  <div v-if="selected.includes(result.email)" class="absolute inset-y-0 left-0 w-0.5 bg-green-600"></div>
                  <input type="checkbox" class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-500 sm:left-6" :value="result.email" v-model="selected" />
                </td>-->
                <td :class="['whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6', selected.includes(result.email) ? 'text-green-600' : 'text-gray-900']">
                  <router-link class="hover:text-green-600 hover:font-bold flex flex-col text-left" :to="`/asset/${result._id}`">
                    {{ result.name || '-' }}
                  </router-link>
                </td>
                <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-90">
                  {{ result.exchange }}
                </td>
                <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-90">
                  {{ result.symbol }}
                </td>
                <td v-for="(field, i) in selectedFields" :key="i" class="whitespace-nowrap px-2 py-2 text-sm text-gray-90">
                  <span v-if="field?.type === 'percent'">{{ Math.round(result[field?.id] * 100) / 100 || '-' }}%</span>
                  <span v-else-if="field?.type === 'number'">{{ (!isNaN(result[field?.id]) ? formatNumber(result[field?.id], this.user.numberFormat) : result[field?.id]) || '-' }}</span>
                  <span v-else>{{ result[field?.id] || '-' }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import format from '@/mixins/format'

export default {
  name: 'Results',
  mixins: [format],
  props: {
    results: {
      type: Object
    },
    selectedFields: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState(['user'])
  },
  data () {
    return {
      selected: []
    }
  },
  methods: {
    sortBy (sort) {
      this.$emit('sort', sort)
    }
  }
}
</script>
