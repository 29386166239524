<template>
  <a href="https://appsumo.com/products/beanvest#reviews" target="_blank" v-if="!hidesumo" @click="hideAppSumo">
    <div class="relative bg-yellow-200 hover:bg-yellow-300 transition duration-1000 delay-300">
      <div class="py-2 px-3 sm:px-3 lg:px-4 max-w-4xl mx-auto">
        <div class="text-center sm:px-2 container flex items-center justify-between mx-auto font-medium text-yellow-800">
          <div class="inline">
            <img class="w-6 h-6 float-left" src="@/assets/images/tacos.svg" />
            <img class="w-6 h-6 float-left" src="@/assets/images/tacos.svg" />
            <img class="w-6 h-6 float-left" src="@/assets/images/tacos.svg" />
            <img class="w-6 h-6 float-left" src="@/assets/images/tacos.svg" />
            <img class="w-6 h-6 float-left" src="@/assets/images/tacos.svg" />
            <span class="ml-5">Hey Sumo-Ling, could you leave a good review on AppSumo?</span>
          </div>
          <span class="ml-3 inline-block text-yellow-800 font-bold">
            Leave a Review <span aria-hidden="true">&rarr;</span>
          </span>
        </div>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  data () {
    return {
      hidesumo: false
    }
  },
  mounted () {
    if (localStorage.getItem('hidesumo') === 'true') this.hidesumo = true
  },
  methods: {
    hideAppSumo () {
      this.hidesumo = true
      localStorage.setItem('hidesumo', true)
    }
  }
}
</script>
