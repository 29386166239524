<template>
<div class="relative h-full flex flex-col container mx-auto">
  <SelectColumns
    v-if="showColumns"
    :portfolio="portfolio"
    :tabIndex="currentTabIndex"
    @cancel="showColumns = false"
    @edited="columnsEdited" />
  <Loader class="mt-24" v-if="loading" text="Loading your portfolio..." />
  <div v-if="portfolio && !loading" :class="[showTopInfos ? 'mb-4': '']">
    <div class="container px-2 py-3 md:py-4 mx-auto header">
      <div class="w-full" :class="[showTopInfos ? 'text-center': 'text-center sm:text-left sm:ml-40 pl-4']">
        <h1
          class="mb-1 md:leading-tight font-semibold text-gray-900"
          :class="[showTopInfos ? 'text-3xl md:text-4xl': 'text-xl md:text-2xl']">
          {{ portfolio.name }}
        </h1>
      </div>
    </div>
  </div>
  <Stats v-if="!loading && portfolio && portfolio.lines?.length && !selectedImportMethod && !showAddTransactions && showTopInfos" :portfolio="portfolio" :numberFormat="user.numberFormat" />
  <div class="flex items-center justify-end" v-if="!loading && !selectedImportMethod && !showAddTransactions && showTopInfos">
    <!-- Views -->
    <div v-if="portfolio.views && portfolio.views.length > 1" class="inline-block align-bottom text-left transform transition-all sm:align-middle flex-grow overflow-visible px-4 -mb-6 ml-40 pl-6">
      <div class="sm:hidden">
        <label for="tabs" class="sr-only">Select a view</label>
        <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
        <select id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 focus:border-emerald-500 focus:ring-emerald-500">
          <option v-for="(view, i) in portfolio.views" :key="view.name" :selected="currentTabIndex === i">{{ view.name }}</option>
        </select>
      </div>
      <div class="hidden sm:block">
        <div class="">
          <nav class="-mb-px flex" aria-label="Tabs">
            <div @click="selectView(i)" v-for="(view, i) in portfolio.views" :key="view.name" :class="[currentTabIndex === i ? 'border-emerald-500 text-emerald-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'cursor-pointer w-1/6 py-2 px-1 text-center border-b-2 font-medium text-xs']" :aria-current="(currentTabIndex === i) ? 'page' : undefined">
              {{ view.name || '-' }}
            </div>
          </nav>
        </div>
      </div>
    </div>
    <LastSyncDate class="inline-block align-middle mr-4" :portfolio="portfolio" />
    <ViewColumnsIcon class="inline-block align-middle w-5 h-5 text-emerald-600 hover:text-emerald-500 hover:scale-110 transition duration-200 mr-4 cursor-pointer" @click="showColumns = true" />
    <PositionsDropdown class="inline-block align-middle mr-4" :portfolio="portfolio" />
    <GroupDropdown class="inline-block align-middle" :portfolio="portfolio" />
    <div class="sm:p-3 pl-1 sm:px-6 sm:pl-1">
      <div class="mt-2 flex justify-end lg:mt-0 flex-col sm:flex-row">
        <div class="flex lg:mt-0 lg:ml-2 p-4 sm:p-0 place-self-end justify-end">
          <span class="ml-5 rounded-md" v-if="!loading && portfolio && portfolio.lines && portfolio.lines.length">
            <button @click="showTransactions = true" type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-500 duration-400 focus:outline-none focus:shadow-outline-emerald focus:border-emerald-700 active:bg-emerald-700 transition duration-150 ease-in-out">
              <!-- Heroicon name: plus-ciccle -->
              <svg class="-ml-1 mr-2 h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path>
              </svg>
              Add Transactions
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
  <!-- Empty view -->
  <div v-if="showAddTransactions && !loading" class="align-center mx-auto p-2">
    <Import
      v-if="selectedImportMethod === 'csv'"
      :portfolioId="portfolio._id"
      @created="transactionAdded"
      @cancel="selectedImportMethod = null" />
    <AddTransactionsManual
      v-else-if="selectedImportMethod === 'manual'"
      :portfolioId="portfolio._id"
      @created="transactionAdded"
      @cancel="selectedImportMethod = null" />
    <div class="max-w-lg mx-auto" v-else>
      <div v-if="portfolio && !portfolio.lines.length">
        <h2 class="text-lg font-medium text-gray-900">Your portfolio is empty</h2>
        <p class="mt-1 text-sm text-gray-500">You need to add transactions to display your current stocks in your portfolio. Please select an import method:</p>
      </div>
      <div class="text-left" v-else>
        <ArrowLeftIcon class="w-5 h-5 mr-6 text-gray-300 float-left hover:text-emerald-600 cursor-pointer" @click="cancel" />
        <h2 class="text-sm font-medium text-gray-500 my-4">Please select an import method:</h2>
      </div>
      <AddTransactions @action="selectImportMethod" />
    </div>
  </div>
  <section class="text-gray-700 body-font xl:overflow-hidden h-full" v-else-if="!loading">
    <div class="md:flex flex-col md:flex-row h-full w-full">
      <!-- Left submenu navigation -->
      <nav class="space-y-1 flex flex-row sm:flex-col w-full mr-2 md:w-48" aria-label="Sidebar">
        <router-link
          v-for="route in routes"
          :key="route.name"
          :to="`/portfolio/${portfolioId}${route.path}`"
          :class="[
            $route.path === `/portfolio/${portfolioId}${route.path}`
              ? 'text-emerald-600 font-bold'
              : 'text-gray-600 hover:text-emerald-500',
            'group flex items-center px-2 sm:px-3 py-2 text-sm font-medium rounded-md'
          ]"
          :aria-current="$route.path === `/portfolio/${portfolioId}${route.path}` ? 'page' : undefined">
          <component
            :is="route.icon"
            :class="[
              $route.path === `/portfolio/${portfolioId}${route.path}`
                ? 'text-emerald-600'
                : 'text-gray-400 group-hover:text-emerald-500',
              'flex-shrink-0 mr-3 h-6 w-6'
            ]"
            aria-hidden="true" />
          <span class="truncate hidden sm:block">
            {{ route.name }}
          </span>
        </router-link>
      </nav>
      <!-- Main view -->
      <router-view class="w-full relative mb-8" @reload="getPortfolio" :currentTabIndex="currentTabIndex" />
    </div>
  </section>
</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import Import from './Import/index'
import AddTransactions from './AddTransactions'
import AddTransactionsManual from './AddTransactionsManual'
import SelectColumns from './Modals/SelectColumns'
import Stats from '@/components/Stats'
import Loader from '@/components/Loader'
import GroupDropdown from './GroupDropdown.vue'
import PositionsDropdown from './PositionsDropdown.vue'
import LastSyncDate from './LastSyncDate.vue'
import { CogIcon, ChartPieIcon, ChartBarIcon, CurrencyDollarIcon, DocumentTextIcon, Squares2X2Icon, ViewColumnsIcon } from '@heroicons/vue/24/outline'
import { ArrowLeftIcon, HomeIcon, ChevronRightIcon, ArrowTrendingUpIcon } from '@heroicons/vue/24/solid'

export default {
  name: 'Portfolio',
  components: {
    ArrowLeftIcon,
    ArrowTrendingUpIcon,
    CogIcon,
    ChartBarIcon,
    ChartPieIcon,
    CurrencyDollarIcon,
    DocumentTextIcon,
    Squares2X2Icon,
    Stats,
    Loader,
    Import,
    AddTransactions,
    AddTransactionsManual,
    HomeIcon,
    ChevronRightIcon,
    PositionsDropdown,
    GroupDropdown,
    ViewColumnsIcon,
    SelectColumns,
    LastSyncDate
  },
  data () {
    return {
      showTransactions: false,
      showColumns: false,
      loading: false,
      currentIndex: null,
      selectedImportMethod: null,
      currentTabIndex: 0
    }
  },
  mounted () {
    this.getPortfolio()
  },
  computed: {
    ...mapState(['portfolio', 'user']),
    portfolioId () {
      return this.$route.params.id
    },
    showTopInfos () {
      return this.$route.path.endsWith(this.portfolioId)
    },
    showAddTransactions () {
      if (this.portfolio && this.portfolio.lines && !this.portfolio.lines.length) return true
      if (this.showTransactions) return true
      return false
    },
    routes () {
      return [{
        name: 'Holdings',
        path: '',
        icon: Squares2X2Icon
      }, {
        name: 'Performance',
        path: '/performance',
        icon: ArrowTrendingUpIcon
      }, {
        name: 'Transactions',
        path: '/transactions',
        icon: CurrencyDollarIcon
      }, {
        name: 'Allocation',
        path: '/allocation',
        icon: ChartPieIcon
      }, {
        name: 'Benchmark',
        path: '/benchmark',
        icon: ChartBarIcon
      }, {
        name: 'News',
        path: '/news',
        icon: DocumentTextIcon
      }, {
        name: 'Settings',
        path: '/settings',
        icon: CogIcon
      }]
    }
  },
  methods: {
    ...mapMutations(['SET_PORTFOLIO', 'NOTIFY']),
    getPortfolio (reload = false) {
      this.loading = !reload
      if (this.portfolioId) {
        this.$http.get(`/portfolio/${this.portfolioId}`).then(res => {
          this.SET_PORTFOLIO(res.data)
          if (this.currentTabIndex > this.portfolio.views?.length - 1) this.selectView(0)
          window.document.title = res.data.name
          this.loading = false
        })
      }
    },
    selectImportMethod (action) {
      if (action === 'csv') this.selectedImportMethod = 'csv'
      else if (action === 'manual') this.selectedImportMethod = 'manual'
      else if (action === 'sync') this.$router.push('/settings/integrations')
    },
    transactionAdded () {
      this.cancel()
      this.getPortfolio()
    },
    cancel () {
      this.showTransactions = false
      this.selectedImportMethod = null
    },
    showIndex (index) {
      this.currentIndex = index
    },
    showSettings () {
      this.$router.push(`/portfolio/${this.portfolioId}/settings`)
    },
    columnsEdited () {
      this.showColumns = false
      this.getPortfolio()
    },
    selectView (i) {
      this.currentTabIndex = i
    }
  },
  watch: {
    portfolioId: function () {
      this.getPortfolio()
    }
  }
}
</script>
