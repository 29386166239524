<template>
  <div class="h-full">
    <div class="flex items-stretch m-auto">
      <div class="flex-1 text-gray-700 text-center px-4 py-2 m-2 bg-white rounded-lg shadow">
        <PriceHistory :stockId="stockId" :currency="stock.currency" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PriceHistory from '@/components/PriceHistory'

export default {
  name: 'Price',
  components: {
    PriceHistory
  },
  computed: {
    ...mapState(['stock']),
    stockId () {
      return this.$route.params.id
    }
  }
}
</script>
