<template>
  <div class="fixed z-20 inset-0 overflow-y-auto">

    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <!--
        Background overlay, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100"
          To: "opacity-0"
      -->
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
      <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      -->
      <div class="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full overflow-visible" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 rounded-lg">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Customize Views
          </h3>
        </div>
        <div>
          <div class="sm:hidden">
            <label for="tabs" class="sr-only">Select a view</label>
            <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
            <select id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 focus:border-emerald-500 focus:ring-emerald-500">
              <option v-for="(view, i) in views" :key="view.name" :selected="currentTabIndex === i">{{ view.name }}</option>
            </select>
          </div>
          <div class="hidden sm:block">
            <div class="border-b border-gray-200">
              <nav class="-mb-px flex" aria-label="Tabs">
                <div @click="selectView(i)" v-for="(view, i) in views" :key="view.name" :class="[currentTabIndex === i ? 'border-emerald-500 text-emerald-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'cursor-pointer w-1/6 py-2 px-1 text-center border-b-2 font-medium text-xs']" :aria-current="(currentTabIndex === i) ? 'page' : undefined">
                  {{ view.name || '-' }}
                </div>
                <div @click="addView" class="mr-1 border-t border-r border-l rounded-t-md text-gray-500 hover:text-gray-700 hover:border-gray-300 w-1/12 py-2 px-1 text-center font-medium text-xs cursor-pointer hover:bg-emerald-50 hover:text-emerald-600">
                  <PlusIcon class="w-4 h-4 mx-auto" />
                </div>
              </nav>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-12 max-h-80" v-if="views[currentTabIndex]?.columns">
          <fieldset class="col-span-4 max-h-80 overflow-auto">
            <div class="divide-y divide-gray-200 border-r border-gray-200">
              <div
                v-for="(field, fieldIdx) in fields"
                :key="fieldIdx"
                class="relative flex items-start py-1 px-3 cursor-pointer"
                :class="[views[currentTabIndex].columns.includes(field.id) ? 'bg-emerald-50 hover:bg-emerald-100' : 'hover:bg-gray-50']"
                @click="select(field)">
                <div class="mr-4 flex h-5 items-center">
                  <input :id="`field-${field.id}`" :name="`field-${field.id}`" :checked="views[currentTabIndex].columns.includes(field.id)" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-emerald-600 focus:ring-emerald-500" />
                </div>
                <div class="min-w-0 flex-1 text-sm">
                  <div :for="`field-${field.id}`" class="select-none font-medium text-gray-700">{{ field.name }}</div>
                </div>
              </div>
            </div>
          </fieldset>
          <!-- Name & Delete -->
          <div class="relative w-full max-h-80 overflow-auto col-span-8 pt-3 px-8 py-6 bg-gray-50">
            <div class="w-full">
              <div class="flex items-center px-2 py-3 sm:px-3">
                <div class="flex min-w-0 flex-1 items-center">
                  <div class="min-w-0 flex-1 px-2">
                    <div class="flex align-middle">
                      <label for="view" class="sr-only">View name</label>
                      <input v-model="views[currentTabIndex].name" type="text" name="view" id="view" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm" placeholder="Enter view name" />
                      <TrashIcon v-if="views.length > 1" class="w-5 h-5 mt-2 ml-5 text-gray-400 cursor-pointer hover:text-red-600 float-right" @click="removeView" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Selected Fields -->
            <div class="bg-white shadow sm:rounded-md w-full mt-2">
              <ul role="list" class="divide-y divide-gray-200 w-full">
                <transition v-for="field in views[currentTabIndex].columns" :key="field" enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                  <li class="field w-full">
                    <div class="flex items-center px-2 py-2 sm:px-3">
                      <div class="flex min-w-0 flex-1 items-center">
                        <div class="min-w-0 flex-1 px-1 md:grid md:grid-cols-2 md:gap-4">
                          <div>
                            <Bars4Icon class="w-5 h-5 mr-3 float-left text-gray-300" />
                            <p class="text-sm font-medium text-gray-900">{{ getName(field) }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </transition>
              </ul>
            </div>
            <div class="text-gray-400 m-4 mt-8 text-center mx-auto" v-if="views[currentTabIndex].columns.length === 0">
              This view is empty. Add some fields from the left menu!
            </div>
          </div>
        </div>
        <div class="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200 justify-between">
          <div class="sm:flex sm:flex-row-reverse">
            <span class="flex w-full rounded-md sm:ml-3 sm:w-auto">
              <button @click="saveViews()" type="button" class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 text-base leading-6 font-medium text-white bg-emerald-600 hover:bg-emerald-500 focus:outline-none focus:shadow-outline-emerald focus:border-emerald-700 active:bg-emerald-700 transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                Save views
              </button>
            </span>
            <span class="mt-3 flex w-full rounded-md sm:mt-0 sm:w-auto">
              <button @click="cancel()" type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                Cancel
              </button>
            </span>
          </div>
          <div v-if="showReset" class="float-left m-1 text-sm cursor-pointer text-gray-400 hover:text-red-600" @click="resetViews">
            Reset default views
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PlusIcon, TrashIcon, Bars4Icon } from '@heroicons/vue/24/outline'

export default {
  name: 'SelectColumns',
  components: {
    PlusIcon,
    TrashIcon,
    Bars4Icon
  },
  props: {
    portfolio: {
      type: Object,
      default: null
    },
    tabIndex: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      fields: [
        { id: 'image', name: 'Logo' },
        { id: 'name', name: 'Name' },
        { id: 'symbol', name: 'Symbol' },
        { id: 'exchange', name: 'Exchange' },
        { id: 'gain', name: 'Total gain' },
        { id: 'gain.realized', name: 'Realized Gain' },
        { id: 'gain.unrealized', name: 'Unrealized Gain' },
        { id: 'gain.dividend', name: 'Dividend Gain' },
        { id: 'gain.currency', name: 'Currency Gain' },
        { id: 'gain.fees', name: 'Fees' },
        { id: 'currency', name: 'Currency' },
        { id: 'units', name: 'Shares' },
        { id: 'price', name: 'Price' },
        { id: 'costPerShare', name: 'Cost per share' },
        { id: 'baseCurrencyCurrentValue', name: 'Market Value' },
        { id: 'percent', name: '% of portfolio' },
        { id: 'mktCapUSD', name: 'Market Cap' },
        { id: 'mktCapCategory', name: 'Market Cap Category' },
        { id: 'country', name: 'Country' },
        { id: 'beta', name: 'Beta' },
        { id: 'type', name: 'Asset type' },
        { id: 'employees', name: 'Number of employees' },
        { id: 'pe', name: 'PE Ratio' },
        { id: 'peg', name: 'PEG Ratio' },
        { id: 'dilutedEps', name: 'Diluted EPS' },
        { id: 'targetPrice', name: 'Target Price' },
        { id: 'ebitda', name: 'EBITDA' },
        { id: 'bookValue', name: 'Book Value' },
        { id: 'dividendYield', name: 'Dividend Yield' },
        { id: 'profitMargin', name: 'Profit Margin' },
        { id: 'operatingMargin', name: 'Operating Margin' },
        { id: 'roe', name: 'Return on Equity' },
        { id: 'roa', name: 'Return on Assets' },
        { id: 'revenue', name: 'Revenue' },
        { id: 'revenuePerShare', name: 'Revenue per Share' },
        { id: 'esgScore', name: 'ESG Score' },
        { id: 'analystRatings', name: 'Analysts Rating' },
        { id: 'analystTargetPrice', name: 'Analysts Target Price' },
        { id: 'analystsFollowing', name: 'Number of Analysts' },
        { id: 'percentInsiders', name: 'Insiders Ownership' },
        { id: 'percentInstitutions', name: 'Institutions Ownership' },
        { id: 'shortRatio', name: 'Short Ratio' },
        { id: 'shortPercent', name: 'Short Percent' },
        { id: 'payoutRatio', name: 'Dividend Payout Ratio' }
      ],
      views: [],
      currentTabIndex: this.tabIndex || 0,
      defaultViews: [{ name: 'Overview', columns: ['image', 'name', 'gain', 'units', 'price', 'costPerShare', 'baseCurrencyCurrentValue', 'percent'] }]
    }
  },
  mounted () {
    this.views = this.portfolio.views?.length ? [...this.portfolio.views] : [...this.defaultViews]
    if (this.currentTabIndex > this.views.length - 1) this.selectView(0)
  },
  computed: {
    showReset () {
      if (this.views.length !== this.defaultViews.length) return true
      if (this.views[0].columns.join('--') !== this.defaultViews[0].columns.join('--')) return true
      return false
    }
  },
  methods: {
    saveViews () {
      return this.$http.patch(`/portfolio/${this.portfolio._id}/views`, this.views).then(res => {
        this.$emit('edited')
      })
    },
    select (field) {
      if (this.views[this.currentTabIndex].columns.includes(field.id)) this.views[this.currentTabIndex].columns = this.views[this.currentTabIndex].columns.filter(f => f !== field.id)
      else this.views[this.currentTabIndex].columns.push(field.id)
    },
    cancel () {
      this.views = this.portfolio.views?.length ? [...this.portfolio.views] : [...this.defaultViews]
      this.$emit('cancel')
    },
    getName (fieldId) {
      return this.fields.find(f => f.id === fieldId)?.name
    },
    addView () {
      this.views.push({ name: 'View ' + (this.views.length + 1), columns: ['image', 'name'] })
      this.currentTabIndex = this.views.length - 1
    },
    selectView (i) {
      this.currentTabIndex = i
    },
    removeView () {
      const current = this.currentTabIndex
      if (current > 0) this.selectView(current - 1)
      this.views.splice(current, 1)
    },
    resetViews () {
      this.views = [...this.defaultViews]
      this.selectView(0)
    }
  }
}
</script>
