<template>
  <div class="bg-white shadow overflow-hidden rounded-md mt-6 mb-10">
    <ul class="divide-y divide-gray-200 text-left">
      <li v-for="(item, i) in items" :key="i">
        <div class="block"
          :class="[
            { 'hover:bg-gray-50 text-gray-800 hover:text-emerald-500': !item.soon },
            { 'bg-gray-100 text-gray-500': item.soon }
          ]">
          <div class="relative group py-4 px-3 flex items-start space-x-3">
            <div class="flex-shrink-0 mr-3">
              <span :class="[item.iconColor, 'inline-flex items-center justify-center h-10 w-10 rounded-lg']">
                <component :is="item.icon" class="h-6 w-6 text-white" aria-hidden="true" />
              </span>
            </div>
            <div class="min-w-0 flex-1">
              <div class="text-sm font-medium">
                <div class="cursor-pointer" @click="$emit('action', item.action)">
                  <span class="absolute inset-0" aria-hidden="true" />
                  {{ item.name }}
                  <span v-if="item.soon" class="highlight ml-2 text-base text-yellow-900">coming soon</span>
                </div>
              </div>
              <p class="text-sm text-gray-500">{{ item.description }}</p>
            </div>
            <div class="flex-shrink-0 self-center">
              <ChevronRightIcon class="h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { ChevronRightIcon, ArrowsRightLeftIcon, DocumentTextIcon, Bars3BottomLeftIcon } from '@heroicons/vue/24/outline'

const items = [
  {
    name: 'Add manually',
    description: 'Manually add one or multiple transactions',
    action: 'manual',
    iconColor: 'bg-emerald-500',
    icon: Bars3BottomLeftIcon
  },
  {
    name: 'Import from a file',
    description: 'Import a .csv file with transactions',
    action: 'csv',
    iconColor: 'bg-emerald-600',
    icon: DocumentTextIcon
  },
  {
    name: 'Synchronize broker',
    description: 'Synchronize your broker to automatically update your portfolio',
    action: 'sync',
    iconColor: 'bg-emerald-900',
    icon: ArrowsRightLeftIcon
  }
]

export default {
  components: {
    ChevronRightIcon
  },
  setup () {
    return {
      items
    }
  }
}
</script>

<style scoped>
.highlight {
  font-size: 12px;
  padding: 0.2em 0.5em;
  border-radius: 0.8em 0.3em;
  background: transparent;
  background-image: linear-gradient(90deg, #e67399, #f2a640);
  color: #fff;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
</style>
