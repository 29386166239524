import getSymbolFromCurrency from 'currency-symbol-map'

export default {
  methods: {
    formatValue (v) {
      return Math.round(v).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    formatPercent (v) {
      return Math.round(v * 100) / 100
    },
    formatNumber (value, numberFormat, currency, showCurrency = true) {
      let v = value
      let c = currency || ''
      if (!numberFormat) {
        numberFormat = {
          symbolType: 'name',
          symbolPosition: 'after',
          symbolSpace: true,
          thousandsSeparator: 'comma',
          decimalsSeparator: 'dot',
          decimals: 2
        }
      }
      let symbol = ''
      const space = numberFormat.symbolSpace ? ' ' : ''
      if (c === 'GBX') {
        c = 'GBP'
        v = v / 100
      }
      v = parseFloat(v.toFixed(2))
      if (showCurrency) {
        if (numberFormat?.symbolType === 'name') symbol = c
        else if (numberFormat?.symbolType === 'symbol') {
          symbol = getSymbolFromCurrency(c) || c
        }
      }

      let formattedValue = ''
      const units = v.toString().split('.')

      if (numberFormat?.thousandsSeparator === 'none') formattedValue = units[0].replace(/\B(?=(\d{3})+(?!\d))/g, '')
      else if (numberFormat?.thousandsSeparator === 'space') formattedValue = units[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      else if (numberFormat?.thousandsSeparator === 'comma') formattedValue = units[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      else if (numberFormat?.thousandsSeparator === 'dot') formattedValue = units[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')

      if (units[1]) {
        if (numberFormat?.decimalsSeparator === 'comma') formattedValue = formattedValue + ',' + units[1]
        else if (numberFormat?.decimalsSeparator === 'dot') formattedValue = formattedValue + '.' + units[1]
      }

      if (numberFormat?.symbolPosition === 'before') return `${symbol || ''}${space}${formattedValue}`
      return `${formattedValue}${space}${symbol || ''}`
    }
  }
}
