<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-800 bg-opacity-80 transition-opacity" />
      </TransitionChild>

      <div class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel
              class="poptop relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-2xl sm:w-full sm:p-6">
              <XMarkIcon @click="close()" class="h-6 w-6 absolute top-4 right-4 text-slate-600 hover:text-gray-900 cursor-pointer" />
              <div class="flex justify-center">
                <DialogTitle as="h3"
                  class="justify-center text-2xl font-extrabold text-gray-900 tracking-normal sm:text-xl xl:text-4xl xl:mt-8 underlinecolor text-center">
                  <span v-if="upgradeSource === 'create-portfolio'">Create Unlimited Portfolios</span>
                  <span v-else-if="upgradeSource === 'benchmark'">Benchmark Your Performance</span>
                  <span v-else-if="upgradeSource === 'holdings'">Add Unlimited Holdings</span>
                  <span v-else-if="upgradeSource === 'performance-years'">Unlock Historical Performance</span>
                  <span v-else-if="upgradeSource === 'nb-years'">20+ Years Historical Data</span>
                  <span v-else-if="upgradeSource === 'multiple-charts'">Multiple Charts Overview</span>
                  <span class="leading-snug" v-else-if="upgradeSource === 'integration-degiro'">Synchronize With Degiro</span>
                  <span v-else>Upgrade to Premium</span>
                </DialogTitle>
              </div>
              <Pay class="max-w-xl mx-auto" @success="success" :showCard="false" :upgradeSource="upgradeSource" />
              <!-- Features list -->
              <FeaturesSummary class="mb-4" />
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { mapMutations } from 'vuex'
import FeaturesSummary from '@/components/FeaturesSummary'
import Pay from '@/views/Pay'
import { XMarkIcon } from '@heroicons/vue/24/solid'

export default {
  name: 'Upgrade',
  components: {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XMarkIcon,
    Pay,
    FeaturesSummary
  },
  data () {
    return {
      open: true
    }
  },
  props: {
    user: {
      type: Object,
      default: () => { }
    },
    upgradeSource: {
      type: String,
      default: () => null
    }
  },
  methods: {
    ...mapMutations(['SHOW_UPGRADE']),
    getUser () {
      return this.$http.get('/user')
        .then(res => {
          this.SET_USER({ user: res.data })
        })
    },
    updateUser () {
      return this.$http.patch('/user', {
        first_name: this.first_name,
        strategy: this.strategy
      })
    },
    success () {
      this.getUser()
      this.close()
    },
    close () {
      this.open = false
      this.SHOW_UPGRADE(false)
    }
  }
}
</script>

<style lang="scss" scoped>
.poptop {
  opacity: 0;
  animation: pop-top 1s ease-out forwards;
  animation-delay: .5s;
}

@keyframes pop-top {
  0% {
    transform: translateY(-3rem) scale(.90);
  }

  30% {
    opacity: 1;
    transform: translateY(1rem) scale(1.05);
  }

  50% {
    transform: none;
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

.underlinecolor {
  background-image: linear-gradient(90deg, #e67399, #f2a640);
  background-position: bottom;
  background-size: 100% 30%;
  background-repeat: no-repeat;
  padding-bottom: 1px;
}</style>
