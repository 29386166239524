<template>
  <div class="max-w-6xl mx-auto pb-10 lg:py-12 lg:px-8">
    <div class="lg:grid lg:grid-cols-12 lg:gap-x-5">
      <aside class="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
        <h2 class="text-base font-bold mt-6 text-left text-gray-900">
          <ArrowLeftIcon class="w-6 h-6 mr-8 text-gray-300 float-left hover:text-emerald-600 cursor-pointer" @click="$emit('cancel')" />
          Import transactions
        </h2>
        <ProgressSteps :steps="steps" :currentStep="step" @select="selectStep" />
      </aside>
      <div class="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
        <Upload @uploaded="uploaded" @filename="setFilename" v-if="step === 1" />
        <MapFields @confirm="confirmMapping" :columns="columns" :data="parsed" v-else-if="step === 2" />
        <Preview @created="created" :portfolioId="portfolioId" :data="parsed" :mapping="mapping" :filename="filename" v-else-if="step === 3" />
      </div>
    </div>
  </div>
</template>

<script>
import Papa from 'papaparse'
import Upload from './Upload'
import MapFields from './MapFields'
import Preview from './Preview'
import ProgressSteps from '@/components/ProgressSteps'
import { mapMutations } from 'vuex'
import { ArrowLeftIcon } from '@heroicons/vue/24/outline'

export default {
  name: 'Import',
  components: {
    Upload,
    MapFields,
    Preview,
    ProgressSteps,
    ArrowLeftIcon
  },
  props: {
    portfolioId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      step: 1,
      raw: null,
      parsed: null,
      parseOptions: {
        skipEmptyLines: true,
        header: true
      },
      mapping: null,
      steps: [{
        name: '1. Select import method'
      }, {
        name: '2. Upload csv file'
      }, {
        name: '3. Select fields'
      }, {
        name: '4. Preview and confirm'
      }],
      filename: null
    }
  },
  methods: {
    ...mapMutations(['NOTIFY']),
    uploaded (raw) {
      this.raw = raw
      this.parseCsv()
      this.step = 2
    },
    parseCsv () {
      const csv = Papa.parse(this.raw, this.parseOptions)
      if (csv.errors) {
        for (const error of csv.errors) this.NOTIFY({ text: error.message, type: 'error' })
      }
      this.parsed = csv.data
      this.columns = csv.meta.fields
    },
    selectStep (i) {
      if (i === 0) this.$emit('cancel')
      if (i < 1) this.raw = null
      if (i < 2) this.parsed = null
      this.step = i
    },
    confirmMapping (mapping) {
      this.mapping = mapping
      this.step = 3
    },
    created () {
      this.selectStep(0)
      this.$emit('created')
    },
    setFilename (name) {
      this.filename = name
    }
  }
}
</script>
