<template>
  <div class="max-w-5xl mx-auto px-4">
    <h2 class="text-left mx-auto text-lg sm:text-base font-base tracking-tight mb-6 ml-2" v-if="showTitle">
      Compare plans & features
    </h2>
    <div class="max-w-5xl mx-auto">
      <!-- xs to lg -->
      <div class="max-w-2xl mx-auto space-y-16 lg:hidden">
        <section v-for="tier in tiers" :key="tier.name">
          <table v-for="section in sections" :key="section.name" class="w-full">
            <caption class="bg-gray-50 border-t border-gray-200 py-3 px-4 text-sm font-medium text-gray-900 text-left">
              {{ section.name }}
            </caption>
            <thead>
              <tr>
                <th class="sr-only" scope="col">Feature</th>
                <th class="sr-only" scope="col">Included</th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <tr class="border-t border-gray-200" v-for="feature in section.features" :key="feature.name">
                <th class="py-2 px-4 text-sm font-normal text-gray-500 text-left" scope="row">{{ feature.name }}</th>
                <td class="py-2 pr-4 text-center">
                  <span v-if="feature.tiers[tier.name] == 'soon'" class="highlight text-yellow-900">coming soon</span>
                  <span v-else-if="typeof feature.tiers[tier.name] === 'string'" class="block text-sm text-gray-700 text-right">{{ feature.tiers[tier.name] }}</span>
                  <template v-else>
                    <!-- Heroicon name: outline/check -->
                    <svg v-if="feature.tiers[tier.name] === true" class="ml-auto h-6 w-6 text-gray-900 max-auto" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                    </svg>
                    <span class="sr-only">{{ feature.tiers[tier.name] === true ? 'Yes' : 'No' }}</span>
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
      </div>
      <!-- lg+ -->
      <div class="hidden lg:block">
        <table class="w-full h-px table-fixed">
          <caption class="sr-only">
            Pricing plan comparison
          </caption>
          <thead>
            <tr>
              <th class="pb-4 px-6 text-sm font-medium text-gray-900 text-left" scope="col">
              </th>
              <th v-for="tier in tiers" :key="tier.name" class="w-1/4 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-center" scope="col">
                {{ tier.name }}
              </th>
            </tr>
          </thead>
          <tbody class="border-t border-gray-200 divide-y divide-gray-200">
            <template v-for="section in sections">
              <tr v-for="feature in section.features" :key="section.name+'-'+feature.name">
                <th class="py-2 px-6 text-sm font-normal text-gray-500 text-left" scope="row">{{ feature.name }}</th>
                <td v-for="tier in tiers" :key="tier.name" class="py-2 px-6 text-center">
                  <span v-if="feature.tiers[tier.name] == 'soon'" class="highlight text-yellow-900">coming soon</span>
                  <span v-else-if="typeof feature.tiers[tier.name] === 'string'" class="block text-sm text-gray-700">{{ feature.tiers[tier.name] }}</span>
                  <template v-else>
                    <!-- Heroicon name: outline/check -->
                    <svg v-if="feature.tiers[tier.name] === true" class="h-5 w-5 mx-auto text-emerald-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                    </svg>
                    <span class="sr-only">{{ feature.tiers[tier.name] === true ? 'Included' : 'Not included' }} in {{ tier.name }}</span>
                  </template>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showTitle: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      tiers: [
        {
          name: 'Free',
          priceMonthly: 0,
          description: 'Free plan'
        },
        {
          name: 'Premium',
          priceMonthly: 10,
          description: 'Premium plan'
        }
      ],
      sections: [
        {
          name: 'Limits',
          features: [
            { name: 'Portfolios', tiers: { Free: '1', Premium: '30' } },
            { name: 'Holdings', tiers: { Free: '20', Premium: 'Unlimited' } }
          ]
        },
        {
          name: 'Features',
          features: [
            { name: 'Portfolio real-time updates', tiers: { Free: true, Premium: true } },
            { name: 'Track more than than 250k+ stocks, cryptos, ETFs, mutual funds, commodities, bonds...', tiers: { Free: true, Premium: true } },
            { name: 'Allocation charts', tiers: { Free: true, Premium: true } },
            { name: 'Historical Performance', tiers: { Free: 'Current year only', Premium: true } },
            // { name: 'Email alerts', tiers: { Free: 'soon', Premium: 'soon' } },
            // { name: 'Portfolio sharing', tiers: { Free: 'soon', Premium: 'soon' } },
            { name: 'Portfolio benchmark vs any index', tiers: { Premium: true } },
            { name: 'Broker syncronization (Degiro)', tiers: { Premium: true } }
          ]
        }
      ]
    }
  }
}
</script>

<style scoped>
.highlight {
  font-size: 12px;
  padding: 0.2em 0.5em;
  border-radius: 0.8em 0.3em;
  background: transparent;
  background-image: linear-gradient(90deg, #e67399, #f2a640);
  color: #fff;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
</style>
