/* eslint-disable */
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js')

let fbPixel = false

function init (email) {
  if (!fbPixel && window && window.fbq && window.fbq.init) {
    window.fbq.init('408789114026976', {
      em: email
    })
    fbPixel = true
    window.fbq('track', 'PageView')
  }
}

function track (event) {
  if (!fbPixel && fbq && fbq.init) {
    fbq('track', event)
  }
}

export default { init, track }