<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="flex flex-col w-full">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="pt-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-2 py-2 text-center text-xs font-medium text-gray-500 cursor-pointer hover:text-gray-600"
                  v-for="column in columns"
                  :key="column.key"
                  :class="{ [column.key]: true, 'text-gray-600': sortName === column.key }"
                  @click="setSort(column)">
                  <div class="flex flex-row">
                    <ArrowSmallUpIcon v-if="sortName === column.key && sortOrder === 1" class="text-gray-600 -ml-1 mr-1 flex-shrink-0 self-center h-4 w-4 float-left" />
                    <ArrowSmallDownIcon v-else-if="sortName === column.key && sortOrder === -1" class="text-gray-600 -ml-1 mr-1 flex-shrink-0 self-center h-4 w-4 float-left" />
                    <ArrowsUpDownIcon v-else-if="column.sort" class="text-gray-300 -ml-1 mr-1 flex-shrink-0 self-center h-4 w-4 float-left" />
                    {{ column.value }}
                  </div>
                </th>
                <th
                  v-if="actions && actions.length"
                  class="px-6 py-3 text-right text-xs font-medium text-gray-500"
                  key="actions">Actions</th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <template v-for="(group) in Object.keys(sortedData)">
                <tr
                  v-for="(line, i) in sortedData[group]"
                  @click="$emit('showLine', line)"
                  :key="line._id"
                  class="group hover:bg-green-100/20 cursor-pointer transition-all duration-400"
                  :class="{ 'bg-green-300/10': currentIndex === i || selectedNames.includes(line.field)  }">
                  <td
                    v-for="(col, j) in columns"
                    :key="j"
                    :class="['border-dashed border-t border-gray-200 p-1 sm:p-1 overflow-hidden', col.key, { 'text-right' : i > 0}]">
                    <span
                      v-if="col.map"
                      v-html="col.map(line, i)"
                      class="text-gray-700 px-2 py-1 xl:px-2 xl:py-1 flex items-end text-xs xxl:text-sm"
                      :class="{ 'font-bold text-green-600': j === 0 && (currentIndex === i || selectedNames.includes(line.field)), 'group-hover:font-bold group-hover:text-gray-800': j === 0 }" />
                    <span
                      v-else-if="col.key"
                      v-html="line[col.key]"
                      class="text-gray-700 px-2 py-1 xl:px-2 xl:py-1 flex items-end text-xs xxl:text-sm"
                      :class="{ 'font-bold text-green-600': j === 0 && (currentIndex === i || selectedNames.includes(line.field)), 'group-hover:font-bold group-hover:text-gray-800': j === 0 }" />
                  </td>
                  <td
                    v-if="actions && actions.length"
                    class="actions px-3 py-2 whitespace-nowrap flex flex-row space-x-4 justify-end text-right text-sm font-medium">
                    <PencilIcon
                      v-if="actions.includes('edit')"
                      class="w-5 h-5 text-gray-300 hover:text-emerald-600 cursor-pointer items-end"
                      @click="$emit('edit', line)" />
                    <TrashIcon
                      v-if="actions.includes('delete')"
                      @click="$emit('delete', line)"
                      class="w-5 h-5 text-gray-300 hover:text-emerald-600 cursor-pointer items-end" />
                  </td>
                </tr>
                <!-- eslint-disable-next-line -->
                <tr v-if="showTotal" class="group bg-gray-50 divide-gray-100 text-sm text-left hover:font-bold text-gray-800 transition-all duration-200 hover:text-gray-600 hover:bg-gray-100">
                  <td v-for="(col, j) in columns" :key="j">
                    <span v-if="j === 0" class="border-l-4 ml-4" />
                    <span v-if="j === 1" class="flex items-end text-xs xxl:text-sm font-bold border-gray-300 p-1 sm:p-2 px-2">
                      <template v-if="group && [undefined, 'undefined', 'null'].includes(group)">-</template>
                      <template v-else-if="group && group !== 'default'">{{ group || '-' }}</template>
                      <template v-else>TOTAL</template>
                    </span>
                    <span v-else-if="col.summary === 'sum'" class="flex items-end text-xs xxl:text-sm font-bold p-1 sm:p-2 px-2">{{ sumBy(col, group) }}</span>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
          <div class="bg-gray-50 border-t border-gray-200" v-if="overLimit > 0">
            <div class="mx-auto text-center p-4 pt-12 max-w-xl">
              <h2 class="text-center mb-6 text-xl sm:text-3xl md:text-4xl md:leading-tight font-semibold text-gray-900">
                Upgrade to Premium to have Unlimited Holdings
              </h2>
              <h3 class="text-gray-600 m-4">
                You have {{ overLimit }} more positions but your plan is limited to 20
              </h3>
              <div
                @click="SHOW_UPGRADE('holdings')"
                class="max-w-xl cursor-pointer">
                <div class="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 space-y-6 sm:p-10 sm:pt-6">
                  <div class="rounded-md shadow">
                    <div class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-500 cursor-pointer">
                      Upgrade
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ArrowSmallUpIcon, ArrowSmallDownIcon, ArrowsUpDownIcon, PencilIcon, TrashIcon } from '@heroicons/vue/24/outline'
import { mapMutations } from 'vuex'

export default {
  name: 'Table',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    },
    actions: {
      type: Array,
      default: () => []
    },
    currentIndex: {
      type: Number,
      default: null
    },
    overLimit: {
      type: Number,
      default: 0
    },
    showTotal: {
      type: Boolean,
      default: false
    },
    group: {
      type: String,
      default: null
    },
    selectedNames: {
      type: Array,
      default: () => []
    }
  },
  components: {
    ArrowSmallUpIcon,
    ArrowSmallDownIcon,
    ArrowsUpDownIcon,
    PencilIcon,
    TrashIcon
  },
  data () {
    return {
      selectedRows: [],
      open: false,
      sortBy: null,
      sortName: null,
      sortOrder: 1
    }
  },
  computed: {
    sortedData () {
      const data = this.data
      if (this.sortBy) data.sort((a, b) => this.sortOrder * (this.sortBy(b) - this.sortBy(a)))
      if (this.group) {
        return this.groupBy(data, this.group)
      }
      return { default: data }
    }
  },
  methods: {
    ...mapMutations(['SHOW_UPGRADE']),
    groupBy (xs, key) {
      return xs.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || []).push(x)
        return rv
      }, {})
    },
    sumBy (col, group) {
      if (col.key === 'percent' && [null, 'default'].includes(group)) return ''
      const result = this.data.filter(d => !this.group || d[this.group] === group).reduce((acc, d) => (acc += col.summaryField ? col.summaryField(d) : acc[col.key]), 0)
      if (col.summaryFormat) return col.summaryFormat(result)
      return result
    },
    toggleColumn (key) {
      // Note: All td must have the same class name as the columns key!
      const columns = document.querySelectorAll('.' + key)
      if (this.$refs[key].classList.contains('hidden') && this.$refs[key].classList.contains(key)) {
        columns.forEach(column => {
          column.classList.remove('hidden')
        })
      } else {
        columns.forEach(column => {
          column.classList.add('hidden')
        })
      }
    },

    getRowDetail ($event, id) {
      const rows = this.selectedRows
      if (rows.includes(id)) {
        const index = rows.indexOf(id)
        rows.splice(index, 1)
      } else {
        rows.push(id)
      }
    },

    selectAllCheckbox ($event) {
      const columns = document.querySelectorAll('.rowCheckbox')
      this.selectedRows = []

      if ($event.target.checked === true) {
        columns.forEach(column => {
          column.checked = true
          this.selectedRows.push(parseInt(column.name))
        })
      } else {
        columns.forEach(column => {
          column.checked = false
        })
        this.selectedRows = []
      }
    },

    setSort (column) {
      if (!column.sort) return false
      this.sortBy = column.sort
      if (this.sortName === column.key) {
        this.sortOrder = -this.sortOrder
      } else {
        this.sortName = column.key
        this.sortOrder = 1
      }
    }
  }
}
</script>
