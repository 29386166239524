<template>
  <div class="bg-white shadow rounded-lg text-left">
    <DeletePortfolio v-if="showDelete" :portfolioId="portfolio._id" :name="portfolio.name" @deleted="portfolioDeleted"
      @cancel="showDelete = false" />
    <DuplicatePortfolio v-if="showDuplicate" :portfolioId="portfolio._id" :name="portfolio.name"
      @copied="portfolioDuplicated" @cancel="showDuplicate = false" />
    <div class="px-4 py-3 sm:p-6 relative">
      <div class="sm:flex sm:items-start">
        <div class="mt-2 text-center sm:mt-0 sm:ml-4 sm:text-left grid grid-cols-6">
          <div class="col-span-1 flex flex-row absolute right-4 top-4">
            <DocumentArrowDownIcon @click="download()"
              class="w-5 h-5 text-gray-300 my-2 mx-2 hover:text-emerald-600 cursor-pointer" />
            <DocumentDuplicateIcon @click="dup()"
              class="w-5 h-5 text-gray-300 my-2 mx-2 hover:text-emerald-600 cursor-pointer" />
            <TrashIcon @click="showDelete = true"
              class="w-5 h-5 text-gray-300 my-2 mx-2 hover:text-red-600 cursor-pointer" />
          </div>
        </div>
      </div>
    </div>
    <div class="divide-y divide-gray-200 px-6 py-2">
      <div class="mt-2">
        <dl>
          <div class="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt class="text-sm font-medium text-gray-500">
              Name
            </dt>
            <div class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <input v-model="name" type="text" name="name" id="name"
                class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-emerald-600 focus:border-emerald-600 sm:text-sm" />
            </div>
          </div>
          <!--div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
            <dt class="text-sm font-medium text-gray-500">
              Performance calculation method
            </dt>
            <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <select
                v-model="settings.returnCalculation"
                name="returnCalculation"
                class="sm:col-span-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 placeholder-gray-400 focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm rounded-md">
                <option v-for="r in returnCalculations" :key="r.id" :value="r.id">{{ r.name }}</option>
              </select>
            </dd>
          </div>-->
          <!--<dl class="divide-y divide-gray-200">
            <div class="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">
                Fiscal country
              </dt>
              <div class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <input v-model="settings.fiscalCountry" type="text" name="name" id="name" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-emerald-600 focus:border-emerald-600 sm:text-sm" />
              </div>
            </div>
          </dl>-->
          <!--<SwitchGroup as="div" class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
            <SwitchLabel as="dt" class="text-sm font-medium text-gray-500" passive>
              Share public portfolio
            </SwitchLabel>
            <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <Switch v-model="settings.public" :class="[settings.public ? 'bg-emerald-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:ml-auto']">
                <span aria-hidden="true" :class="[settings.public ? 'translate-x-5' : 'translate-x-0', 'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
              </Switch>
            </dd>
          </SwitchGroup>-->
          <div class="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt class="text-sm font-medium text-gray-500">
              Benchmark
            </dt>
            <div class="mt-1 text-sm text-gray-900 sm:col-span-2">
              <div class="flex items-center justify-between">
                <span class="isolate inline-flex rounded-md shadow-sm">
                  <button v-for="(option, i) in benchmarkOptions" @click="pickBenchmark(option.id)" :key="i"
                    type="button" :class="[{
                      'rounded-l-md -ml-px': i === 0,
                      'rounded-r-md -ml-px': i === benchmarkOptions.length - 1,
                      '-ml-px': i !== 0 && i !== benchmarkOptions.length - 1,
                      'bg-emerald-600 text-white ring-emerald-600': selectedBenchmark === option.id,
                      'bg-white text-gray-900 hover:text-emerald-600 hover:bg-emerald-50 ring-gray-300': selectedBenchmark !== option.id,
                    }]" class="relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset focus:z-10">
                    {{ option.name }}
                  </button>
                </span>
                <div class="text-gray-500 mx-3">or</div>
                <button @click="pickBenchmark('custom')" :key="i" type="button" :class="[{
                  'bg-emerald-600 text-white ring-emerald-600': selectedBenchmark === 'custom',
                  'bg-white text-emerald-900 hover:text-emerald-600 hover:bg-emerald-50 ring-gray-300': selectedBenchmark !== 'custom',
                }]"
                  class="relative rounded-md inline-flex items-center px-6 py-2 text-sm ring-1 ring-inset focus:z-10">
                  Custom
                </button>
              </div>
              <div class="p-5 mt-6 bg-gray-50 rounded border flex flex-col justify-end"
                v-if="selectedBenchmark === 'custom'">
                <!-- Edit stocks -->
                <div v-for="(b, i) in benchmarks" :key="i" class="p-1 flex flex-row items-center justify-between">
                  <span class="p-1 mr-4 ml-4 text-gray-400 w-3">{{ i > 0 ? '+' : '' }}</span>
                  <StockSearch class="w-8/12" :i="i" :value="{ _id: b.stockId, name: b.name }"
                    @select="selectBenchmark" />
                  <span class="p-1 mx-2 text-gray-400">x</span>
                  <input v-if="benchmarks.length > 1" type="number" min="0" max="100" step="10" v-model="b.percent"
                    placeholder="Percent" :class="[
                      'sm:col-span-1 block py-2 text-base border-gray-300 placeholder-gray-400 focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm rounded-md',
                      { 'border-red-400': error && (!b.percent || isNaN(b.percent) || b.percent <= 0 || b.percent > 100) }
                    ]" />
                  <div v-else class="flex items-center">
                    <span class="p-1 mx-2 text-gray-400">100</span>
                  </div>
                  <span class="p-1 mx-2 text-gray-400">%</span>
                  <svg v-if="benchmarks.length > 1" @click="removeBenchmark(i)"
                    class="w-5 h-5 text-gray-400 cursor-pointer hover:text-emerald-600 m-1" fill="none"
                    stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16">
                    </path>
                  </svg>
                </div>
                <button @click="addBenchmark()" type="button"
                  class="text-emerald-900 hover:text-emerald-600 hover:bg-emerald-50 hover:ring-emerald-200 ring-gray-300 relative rounded-md items-center px-6 py-2 text-sm ring-1 ring-inset focus:z-10 grow-0"
                  :class="[{ 'mt-4': benchmarks.length }]">
                  {{ benchmarks.length > 0 ? 'Add another' : 'Select a custom benchmark' }}
                </button>
              </div>
            </div>
          </div>
          <SwitchGroup as="div" class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
            <SwitchLabel as="dt" class="text-sm font-medium text-gray-500" passive>
              Weekly report email
            </SwitchLabel>
            <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <Switch v-model="settings.emails.weeklyRecap"
                :class="[settings.emails.weeklyRecap ? 'bg-emerald-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:ml-auto']">
                <span aria-hidden="true"
                  :class="[settings.emails.weeklyRecap ? 'translate-x-5' : 'translate-x-0', 'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
              </Switch>
            </dd>
          </SwitchGroup>
        </dl>
      </div>
    </div>

    <!-- Errors -->
    <div class="text-red-600 text-sm p-4 text-right" v-if="benchmarkError">
      {{ benchmarkError }}
    </div>

    <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
      <span class="flex w-full rounded-md sm:ml-3 sm:w-auto">
        <button @click="updateSettings()" type="button"
          class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 text-base leading-6 font-medium transition ease-in-out duration-150 sm:text-sm sm:leading-5"
          :class="[{ 'text-white bg-emerald-600 hover:bg-emerald-500 focus:outline-none focus:shadow-outline-emerald focus:border-emerald-700 active:bg-emerald-700': saveEnabled }, { 'text-gray-500 bg-gray-200 cursor-default': !saveEnabled }]">
          Update settings
        </button>
      </span>
      <span class="mt-3 flex w-full rounded-md sm:mt-0 sm:w-auto">
        <button @click="cancel" type="button"
          class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
          Cancel
        </button>
      </span>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import DeletePortfolio from './Modals/DeletePortfolio'
import DuplicatePortfolio from './Modals/DuplicatePortfolio'
import StockSearch from '@/components/StockSearch'
import { TrashIcon, DocumentDuplicateIcon, DocumentArrowDownIcon } from '@heroicons/vue/24/outline'
import {
  Switch,
  SwitchGroup,
  SwitchLabel
} from '@headlessui/vue'

export default {
  name: 'PortfolioSettings',
  components: {
    DeletePortfolio,
    DuplicatePortfolio,
    TrashIcon,
    DocumentDuplicateIcon,
    DocumentArrowDownIcon,
    Switch,
    SwitchGroup,
    SwitchLabel,
    StockSearch
  },
  data () {
    return {
      benchmarkEdited: false,
      benchmarks: [],
      showDelete: false,
      showDuplicate: false,
      name: null,
      benchmarkOptions: [],
      settings: {
        returnCalculation: 'twr',
        fiscalYearEndDay: 31,
        fiscalYearEndMonth: 12,
        fiscalCountry: null,
        accountType: null,
        taxExemptedAccount: false,
        addCashWhenSellling: false,
        public: false,
        groupBy: null,
        emails: {
          weeklyRecap: false,
          priceChange: false
        }
      },
      returnCalculations: [
        { id: 'irr', name: 'Internal Rate of Return' },
        { id: 'dietz', name: 'Simple Dietz method' },
        { id: 'twr', name: 'Time-Weighted Rate of Return' }
      ],
      selectedBenchmark: null
    }
  },
  async mounted () {
    if (this.portfolio.settings) this.settings = this.portfolio.settings
    this.name = this.portfolio.name
    this.benchmarkOptions = (await this.$http.get('/stock/benchmarks'))?.data.map(p => ({
      id: p._id,
      name: p.name
    })) || []
    if (this.portfolio.benchmark?.length === 1) {
      const getBench = this.benchmarkOptions.find(o => o.id === this.portfolio.benchmark[0].stockId)
      if (getBench) this.selectedBenchmark = getBench.id
      else this.selectedBenchmark = 'custom'
    } else if (this.portfolio.benchmark?.length > 0) {
      this.selectedBenchmark = 'custom'
    }
    if (this.portfolio.benchmark?.length) {
      this.benchmarks = this.portfolio.benchmark
    }
  },
  computed: {
    ...mapState(['portfolio', 'portfolios', 'benchmark']),
    ...mapGetters(['trialLeft']),
    showUpgrade () {
      return this.user.plan === 'free' && this.trialLeft < 0
    },
    portfolioId () {
      return this.$route.params.id
    },
    totalPercent () {
      let tot = 0
      for (const b of this.benchmarks) {
        tot += Number(b.percent)
      }
      return Math.round(tot)
    },
    saveEnabled () {
      const nameEdited = this.name !== this.portfolio.name
      const validName = this.name?.length > 0
      const weeklyRecapEdited = this.settings.emails.weeklyRecap !== this.portfolio.settings?.emails?.weeklyRecap
      if (nameEdited && !validName) return false
      if (this.benchmarkEdited && this.benchmarkError) return false
      return nameEdited || weeklyRecapEdited || this.benchmarkEdited
    },
    benchmarkError () {
      if (this.totalPercent !== 100) {
        return `Total of all allocations is ${this?.totalPercent}% but must be 100%`
      }
      for (const b of this.benchmarks) {
        b.percent = Number(b.percent)
        if (b.percent < 0 || b.percent > 100) {
          return 'Percent must be between 1 and 100'
        }
        if (!b.stockId || !b.name) {
          return 'Please select a stock for all benchmarks allocations'
        }
      }
      return null
    }
  },
  methods: {
    ...mapMutations(['NOTIFY']),
    ...mapActions(['getPortfolio']),
    cancel () {
      this.$router.push(`/portfolio/${this.portfolio._id}`)
    },
    updateSettings () {
      if (!this.saveEnabled) return false
      return this.$http.patch(`/portfolio/${this.portfolio._id}`, {
        name: this.name,
        settings: this.settings
      }).then(() => {
        this.NOTIFY({ text: 'Portfolio settings saved successfully' })
        if (this.selectedBenchmark !== this.portfolio.benchmark) {
          return this.saveBenchmark()
            .then(() => {
              this.NOTIFY({ text: 'Portfolio benchmark updated successfully' })
            })
        }
      }).then(() => {
        this.$emit('reload')
        this.cancel()
      })
    },
    portfolioDeleted () {
      this.$router.push('/portfolios')
      this.NOTIFY({ text: 'Portfolio has been deleted' })
    },
    portfolioDuplicated (id) {
      this.$router.push(`/portfolio/${id}`)
      this.NOTIFY({ text: 'Portfolio copied' })
      this.showDuplicate = false
      this.getPortfolio()
    },
    dup () {
      if (this.portfolios.length >= 30) this.NOTIFY({ text: 'You already have 30 portfolios! Please contact me at romain@beanvest.com to unlock more.' })
      else this.showDuplicate = true
    },
    isCurentOption (option) {
      return this.currentOption === option.id
    },
    selectBenchmark (stock, i) {
      this.benchmarkEdited = true
      this.benchmarks[i].stockId = stock._id || stock.id
      this.benchmarks[i].name = stock.name
    },
    pickBenchmark (selectedId) {
      this.benchmarkEdited = true
      this.selectedBenchmark = selectedId
    },
    addBenchmark () {
      this.benchmarks.push({
        name: null,
        stockId: null,
        percent: 100
      })
      this.rebalance()
    },
    removeBenchmark (i) {
      this.benchmarkEdited = true
      this.benchmarks.splice(i, 1)
      this.rebalance()
    },
    rebalance () {
      for (const b of this.benchmarks) {
        b.percent = Math.round(1 / this.benchmarks.length * 100 * 100) / 100
      }
    },
    saveBenchmark () {
      if (this.benchmarkError) return false
      return this.$http.patch(`/portfolio/${this.portfolioId}`, { benchmark: this.benchmarks })
    },
    switchPercent (toggled) {
      this.loading = true
      this.percent = toggled
      this.loading = false
    },
    async download () {
      const res = await this.$http.get(`/portfolio/${this.portfolioId}/export`)
      const csvFile = new Blob([res.data], { type: 'text/csv;charset=utf-8;' })
      const fileName = `${this.portfolio.name.replace(/ /g, '-')}.csv`
      if (window.navigator.msSaveOrOpenBlob) {
        // IE hack;
        window.navigator.msSaveBlob(csvFile, fileName)
      } else {
        const a = window.document.createElement('a')
        a.href = window.URL.createObjectURL(csvFile)
        a.download = fileName
        document.body.appendChild(a)
        a.click()
        // IE: "Access is denied";
        document.body.removeChild(a)
      }
    }
  },
  watch: {
    selectedBenchmark () {
      if (this.selectedBenchmark === 'custom') {
        if (this.portfolio.benchmark.length) this.benchmarks = this.portfolio.benchmark
        else if (!this.benchmarks.length) this.addBenchmark()
      } else {
        this.benchmarks = []
        this.addBenchmark()
        const selected = this.benchmarkOptions.find(o => o.id === this.selectedBenchmark)
        this.selectBenchmark(selected, 0)
      }
    }
  }
}
</script>
