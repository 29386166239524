<template>
  <div>
    <Loader class="my-24" v-if="!loaded" text="Loading historical prices..." />
    <div v-else-if="price.length">
      <div class="flex justify-between">
        <nav class="flex space-x-3 py-2">
          <div
            @click="selectPeriod(period)"
            v-for="period in periods"
            :key="period.name"
            :class="[period.id === currentPeriod ? 'border-emerald-500 text-emerald-600' : 'border-transparent text-gray-400 hover:border-gray-300 hover:text-gray-700', 'whitespace-nowrap border-b-2 font-medium text-sm cursor-pointer']">
            {{ period.name }}
          </div>
        </nav>
        <div v-if="!isNaN(percent) && loaded" :class="[percent >= 0 ? 'text-emerald-600' : 'text-red-600', 'mt-2 text-sm font-bold']">
          <span v-if="percent > 0">+</span>{{ percent }}%
        </div>
      </div>
      <div class="mt-8 p-2 m-auto h-5xl">
        <LineChart
          type="LineWithLine"
          v-if="loaded && chartdata"
          :chartdata="chartdata"
          :options="options"/>
        <Loader class="h-40 my-20" text="Loading chart..." v-else />
      </div>
    </div>
    <div class="text-gray-400 my-20" v-else>
      Price history is not currently available for this asset
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapState, mapMutations } from 'vuex'
import LineChart from './Chart.vue'
import Loader from './Loader.vue'
import format from '@/mixins/format'

export default {
  name: 'PriceHistory',
  components: {
    LineChart,
    Loader
  },
  mixins: [format],
  props: {
    stockId: {
      type: String,
      note: 'Stock id'
    },
    currency: {
      type: String,
      note: 'Currency'
    }
  },
  data () {
    return {
      loaded: false,
      periods: [
        { id: '1m', name: '1M' },
        { id: '3m', name: '3M' },
        { id: '6m', name: '6M' },
        { id: '1y', name: '1Y' },
        { id: '2y', name: '2Y' },
        { id: '5y', name: '5Y' },
        { id: 'max', name: 'MAX' }
      ],
      currentPeriod: '1y'
    }
  },
  mounted () {
    if (this.$route.query.period) this.currentPeriod = this.$route.query.period
    this.getPriceHistory()
  },
  computed: {
    ...mapState(['price', 'stock']),
    percent () {
      const first = this.chartdata.datasets[0].data[0]
      const last = this.chartdata.datasets[0].data[this.chartdata.datasets[0].data.length - 1]
      return Math.round((last - first) / last * 10000) / 100
    },
    chartdata () {
      if (!this.price) return null
      const history = this.price.slice(0)
      const labels = []
      const datasets = [{
        data: [],
        borderColor: '#11b981',
        backgroundColor: '#9BD0F5',
        fill: true,
        lineTension: 0,
        borderWidth: 2,
        spanGaps: false
      }]
      for (const h of history) {
        datasets[0].data.push(h.adjusted_close || h.close)
        labels.push(new Date(h.date || h.datetime))
      }
      return {
        labels,
        datasets
      }
    },
    options () {
      const vm = this
      let unit = 'month'
      if (this.currentPeriod === '1m') unit = 'day'
      else if (this.currentPeriod === '3m') unit = 'day'
      else if (this.currentPeriod === '6m') unit = 'day'
      else if (this.currentPeriod === '1y') unit = 'month'
      else if (this.currentPeriod === '2y') unit = 'month'
      else if (this.currentPeriod === '5y') unit = 'month'
      else if (this.currentPeriod === 'max') unit = 'month'
      return {
        title: {
          display: false
        },
        animation: {
          duration: 800
        },
        legend: {
          display: false
        },
        hover: {
          animationDuration: 0,
          enabled: false
        },
        elements: {
          lines: {
            tension: 0
          },
          point: {
            radius: 0,
            pointStyle: 'line'
          }
        },
        scales: {
          x: {
            time: {
              unit
            },
            grid: {
              color: 'rgba(0, 0, 0, 0)',
              drawBorder: false
            },
            ticks: {
              fontColor: '#718096',
              callback: function (value, index) {
                return moment(this.getLabelForValue(value)).format('LL')
              }
            }
          },
          y: {
            grid: {
              color: 'rgba(0, 0, 0, 0.08)',
              drawBorder: true
            },
            ticks: {
              fontColor: '#718096',
              callback: function (value, index, values) {
                return ` ${value} ${vm.currency || ''}`
              }
            }
          }
        },
        scaleLabel: {
          display: false
        },
        gridLines: {
          display: false
        },
        responsiveAnimationDuration: 200,
        pointRadius: 0,
        plugins: {
          tooltip: {
            mode: 'index',
            intersect: false,
            position: 'average',
            xAlign: 'center',
            yAlign: 'top',
            callbacks: {
              title: function (chart) {
                return moment(chart[0].label).format('LL')
              },
              label: function (chart) {
                return `${vm.formatValue(chart.raw)} ${vm.currency}`
              },
              labelColor: function () {
                return {
                  borderColor: '#efeeea',
                  backgroundColor: '#11b981'
                }
              }
            }
          }
        }
      }
    }
  },
  methods: {
    ...mapMutations(['SET_PRICE_HISTORY']),
    getPriceHistory () {
      this.$http.get(`/stock/${this.stockId}/price`, { params: { period: this.currentPeriod } })
        .then(res => this.SET_PRICE_HISTORY(res.data))
        .then(() => {
          this.loaded = true
        })
    },
    selectPeriod (period) {
      this.currentPeriod = period.id
      if ('URLSearchParams' in window) {
        const searchParams = new URLSearchParams(window.location.search)
        searchParams.set('period', period.id)
        const newRelativePathQuery = window.location.pathname + '?' + searchParams.toString()
        history.pushState(null, '', newRelativePathQuery)
      }
    }
  },
  watch: {
    currentPeriod: function () {
      this.getPriceHistory()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
