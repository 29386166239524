<template>
  <div class="w-full flex flex-col col-span-1 text-center bg-white rounded-lg shadow divide-y divide-gray-200">
    <Loader class="my-24" v-if="loading" text="Loading performance..." />
    <div class="relative" v-else-if="!performance?.periods?.length">
      <img class="mt-10 opacity-40" src="@/assets/images/performance-blur.jpg" alt="benchmark" />
      <div class="absolute top-10 inset-x-0 mx-auto">
        <h2 class="text-center text-xl sm:text-xl md:text-xl md:leading-tight text-gray-500">
          Performance will be calculated once there is enough historical data
        </h2>
      </div>
    </div>
    <div v-else>
      <div class="flex flex-col items-stretch m-auto pt-2">
        <div class="flex justify-between space-between mx-6 my-4">
          <div class="text-base text-left font-medium text-gray-400 flex flex-col gap-2">
            <div class="flex justify-left flex-row gap-2">
              <div class="flex flex-row">Performance {{ currentYear }}</div>
              <div
                :class="[
                  getYearlyPerformance(currentYear)?.twr  > 0 && 'text-green-600',
                  getYearlyPerformance(currentYear)?.twr  < 0 && 'text-red-600',
                  getYearlyPerformance(currentYear)?.hidden && 'blur-sm font-bold'
                ]">
                <span v-if="getYearlyPerformance(currentYear)?.twr > 0">+</span>{{ Math.round(getYearlyPerformance(currentYear)?.twr * 10000)/ 100 }}%
              </div>
            </div>
            <div class="flex justify-left flex-row gap-2" v-if="getYearlyPerformance(currentYear)?.benchmark?.twr">
              <div class="flex flex-row gap-1 items-center">
                <span class="text-xs mr-1">vs</span> <div v-for="(b, i) in portfolio.benchmark" :key="i">
                  <span class="m-1" v-if="i > 0">+</span>
                  {{ b.name }}
                  <span v-if="b.percent !== 100">({{ b.percent }}%)</span>
                </div> {{ currentYear }}
              </div>
              <div
                :class="[
                  getYearlyPerformance(currentYear)?.benchmark?.twr  > 0 && 'text-green-600',
                  getYearlyPerformance(currentYear)?.benchmark?.twr  < 0 && 'text-red-600',
                  getYearlyPerformance(currentYear)?.hidden && 'blur-sm font-bold'
                ]">
                <span v-if="getYearlyPerformance(currentYear)?.benchmark?.twr > 0">+</span>{{ Math.round(getYearlyPerformance(currentYear)?.benchmark?.twr * 10000)/ 100 }}%
              </div>
            </div>
          </div>
          <div class="flex items-center justify-between gap-4">
            <div class="flex items-center justify-between">
              <span class="isolate inline-flex rounded-md">
                <button v-for="(option, i) in periodsOptions" @click="showPeriod(option.period)" :key="i"
                  type="button" :class="[{
                    'rounded-l-md -ml-px': i === 0,
                    'rounded-r-md -ml-px': i === periodsOptions.length - 1,
                    '-ml-px': i !== 0 && i !== periodsOptions.length - 1,
                    'bg-emerald-600 text-white ring-emerald-600': period === option.period,
                    'bg-white text-gray-900 hover:text-emerald-600 hover:bg-emerald-50 ring-gray-300': period !== option.period,
                  }]" class="relative inline-flex items-center px-3 py-1.5 text-sm ring-1 ring-inset focus:z-10">
                  {{ option.name }}
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div id="performance-chart" class="content-center w-full mx-auto pt-5 border-b pl-11 h-52">
        <BarChart
          v-if="chartdata && !loading"
          chartId="perf-chart"
          type="bar"
          :isHoverExtended="true"
          @hover="updateHover"
          @mouseout="removeHover"
          :chartdata="chartdata"
          :options="options" />
      </div>
      <div class="grid grid-cols-12">
        <div class="col-span-1 text-gray-800 text-sm flex mx-auto h-10 w-full items-center justify-center"></div>
        <div class="col-span-11 grid grid-cols-12 text-xs text-gray-600">
          <div
            :class="[
              'flex items-center justify-center',
            ]"
            v-for="(month) in months" :key="month">
            {{ month }}
          </div>
        </div>
      </div>
      <div
        v-for="(year, i) in years"
        @click="selectYear(year)"
        :key="year"
        :class="[
          'grid grid-cols-12 hover:bg-gray-50 transition-colors cursor-pointer',
          currentYear === year && 'text-gray-800 bg-blue-50',
          i === 0 && 'border-t'
        ]">
        <div
          :class="[
            'col-span-1 text-gray-600 text-xs border-b flex flex-col mx-auto gap-2 h-16 w-full items-center justify-center text-center border-r',
            currentYear === year && 'text-gray-800 bg-blue-50 text-lg font-bold'
          ]">
          {{ year }}
          <div
            :class="[
              'mx-auto w-full items-center justify-center cursor-pointer transition-all duration-400',
              getYearlyPerformance(year)?.twr  > 0 && 'text-green-600',
              getYearlyPerformance(year)?.twr  < 0 && 'text-red-600',
              getYearlyPerformance(year)?.hidden && 'blur-sm font-bold'
            ]">
            <span v-if="getYearlyPerformance(year)?.twr > 0">+</span>{{ Math.round(getYearlyPerformance(year)?.twr * 10000)/ 100 }}%
          </div>
        </div>
        <div class="col-span-11 grid grid-cols-12 bg-gray-50 text-gray-400 text-sm hover:bg-gray-100 transition-colors cursor-pointer group"
          :class="[
            i !== years.length - 1 && 'border-b'
          ]">
          <div
            v-for="(month, monthNb) in months"
            :key="month"
            :class="[
              'flex mx-auto h-16 w-full items-center justify-center flex-col cursor-pointer transition-all duration-400',
              (currentYear !== year || currentIndex !== monthNb) && getData(monthNb, year)?.twr && 'bg-white group-hover:bg-blue-50',
              monthNb > 0 && getData(monthNb, year)?.twr && !getData(monthNb - 1, year)?.twr && 'border-l',
              monthNb < months.length && getData(monthNb, year)?.twr && !getData(monthNb + 1, year)?.twr && 'border-r',
              getData(monthNb, year)?.twr > 0 && 'text-green-600',
              getData(monthNb, year)?.twr < 0 && 'text-red-600',
              currentYear === year && currentIndex === monthNb && 'bg-blue-50',
              getData(monthNb, year)?.hidden && 'blur-sm font-bold'
            ]">
            <div
              v-if="getData(monthNb, year)"
              :class="[
                'transition-all duration-400',
                (currentYear !== year || currentIndex !== monthNb) && 'text-sm',
                currentYear === year && currentIndex === monthNb && 'font-medium',
                // getData(monthNb, year)?.twr - getData(monthNb, year)?.benchmark?.twr > 0 && 'text-green-600',
                // getData(monthNb, year)?.twr - getData(monthNb, year)?.benchmark?.twr < 0 && 'text-red-600'
              ]">
              <span v-if="getData(monthNb, year)?.twr > 0">+</span>{{ Math.round(getData(monthNb, year)?.twr * 10000)/ 100 }}%
            </div>
            <div v-else>-</div>
            <div
              v-if="portfolio.benchmark?.length && getData(monthNb, year)"
              :class="[
                'text-xs mt-1 text-gray-400',
              ]">
              vs <span v-if="getData(monthNb, year)?.benchmark?.twr > 0">+</span>{{ Math.round(getData(monthNb, year)?.benchmark?.twr * 10000)/ 100 }}%
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="user.plan === 'free' && previousYears"
        class="relative isolate overflow-hidden bg-gray-900 px-6 py-10 text-center shadow-sm sm:rounded-3xl sm:px-12 m-5">
        <div class="flex justify-center">
          <h2 class="justify-center text-3xl font-extrabold text-white tracking-normal sm:text-xl xl:text-4xl xl:mt-5 underlinecolor">Compare Your {{ thisYear }} Performance</h2>
        </div>
        <p class="mx-auto mt-6 max-w-3xl text-lg leading-8 text-gray-300">Upgrade to Premium and compare your {{ thisYear }} performance with {{ previousYears }}</p>
        <div class="flex items-center justify-center gap-x-6 mt-6">
          <div
            @click="SHOW_UPGRADE('performance-years')"
            class="max-w-xl cursor-pointer">
            <div class="flex-1 flex flex-col justify-between px-6 pt-6 space-y-6 sm:p-3 sm:pt-6">
              <div class="rounded-md shadow">
                <div class="flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium transition-all duration-300 rounded-md text-gray-900 hover:text-white bg-white hover:bg-emerald-500 cursor-pointer">
                  Upgrade to Premium
                </div>
              </div>
            </div>
          </div>
        </div>
        <svg viewBox="0 0 1024 1024" class="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]" aria-hidden="true">
          <circle cx="512" cy="512" r="512" fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)" fill-opacity="0.7" />
          <defs>
            <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
              <stop stop-color="#7775D6" />
              <stop offset="1" stop-color="#E935C1" />
            </radialGradient>
          </defs>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import BarChart from '@/components/Chart'
import Loader from '@/components/Loader'
import moment from 'moment'

export default {
  name: 'Performance',
  data () {
    const baseOptions = {
      maintainAspectRatio: false,
      categoryPercentage: 1,
      barPercentage: 0.9,
      responsive: true,
      title: {
        display: false
      },
      legend: {
        display: false
      },
      hover: {
        mode: 'index'
      },
      elements: {
        lines: {
          tension: 0
        },
        point: {
          radius: 0,
          pointStyle: 'line'
        }
      },
      tooltips: {
        enabled: false
      },
      animation: {
        animateScale: true,
        animateRotate: true
      },
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        }
      },
      scales: {
        x: {
          categoryPercentage: 1,
          barPercentage: 1,
          borderWidth: 0,
          grid: { display: false },
          offset: true,
          ticks: {
            display: false
          }
        },
        y: {
          categoryPercentage: 1,
          barPercentage: 1,
          grid: { color: '#eee', display: true },
          offset: true,
          ticks: {
            suggestedMin: 0,
            maxTicksLimit: 5,
            callback: function (item) {
              const percent = Math.round(item * 100)
              return percent > 0 ? `+${percent}%` : `${percent}%`
            }
          }
        }
      },
      scaleLabel: {
        display: false
      },
      responsiveAnimationDuration: 0,
      pointRadius: 0
    }
    return {
      currentYear: moment().year(),
      thisYear: moment().year(),
      loading: true,
      currentIndex: null,
      period: 'monthly',
      periodsOptions: [
        { id: 'm', name: 'Monthly', period: 'monthly' },
        { id: 'y', name: 'Yearly', period: 'yearly' }
      ],
      options: Object.assign({}, baseOptions, {
        plugins: {
          tooltip: {
            displayColors: false,
            mode: 'index',
            intersect: false,
            position: 'average',
            xAlign: 'center',
            yAlign: 'center',
            callbacks: {
              title: function () {
                return false
              },
              label: function (tooltipItem) {
                const i = tooltipItem.datasetIndex
                const value = tooltipItem.raw
                const bench = tooltipItem.dataset.benchdata[tooltipItem.index]
                let label = `${value > 0 ? '+' : ''}${Math.round(value * 10000) / 100}%`
                if (i > 0 && !isNaN(bench) && bench !== 0) label += ` (vs ${bench > 0 ? '+' : ''}${bench}%)`
                return label
              },
              labelColor: function () {
                return {
                  borderColor: 'rgba(4, 120, 87, 0.7)',
                  backgroundColor: 'rgba(4, 120, 87, 0.7)'
                }
              }
            }
          }
        }
      })
    }
  },
  components: {
    BarChart,
    Loader
  },
  computed: {
    ...mapState(['portfolio', 'performance', 'user']),
    selectedPeriods () {
      return this.period === 'yearly' ? this.performance?.yearlyPeriods : this.performance?.periods
    },
    chartdata () {
      if (!this.performance) return { labels: [], datasets: [] }
      if (this.period === 'monthly') {
        const monthlyTwr = []
        const monthlyBenchTwr = []
        const labels = []
        for (const monthNb in this.months) {
          labels.push(this.months[monthNb])
          const perf = this.getData(Number(monthNb), this.currentYear)
          if (perf?.hidden) {
            monthlyTwr.push(null)
            monthlyBenchTwr.push(null)
          } else {
            monthlyTwr.push(perf?.twr || null)
            monthlyBenchTwr.push(perf?.benchmark?.twr || null)
          }
        }
        return {
          labels,
          datasets: [{
            type: 'bar',
            borderRadius: 5,
            borderWidth: 0,
            barThickness: 'flex',
            data: monthlyTwr,
            benchdata: monthlyBenchTwr,
            borderColor: 'rgba(4, 120, 87, 0.8)',
            backgroundColor: function (context) {
              const value = context.dataset.data[context.dataIndex]
              return value >= 0 ? 'rgba(4, 120, 87, 0.8)' : 'rgba(220, 38, 39, 0.8)'
            }
          }]
        }
      } else if (this.period === 'yearly') {
        const yearlyTwr = []
        const yearlyBenchTwr = []
        const labels = []
        for (const year of this.years) {
          labels.push(year)
          const perf = this.getData(0, year)
          if (perf?.hidden) {
            yearlyTwr.push(null)
            yearlyBenchTwr.push(null)
          } else {
            yearlyTwr.push(perf?.twr || null)
            yearlyBenchTwr.push(perf?.benchmark?.twr || null)
          }
        }
        return {
          labels,
          datasets: [{
            type: 'bar',
            borderRadius: 5,
            borderWidth: 0,
            barThickness: 'flex',
            data: yearlyTwr,
            benchdata: yearlyBenchTwr,
            borderColor: 'rgba(4, 120, 87, 0.8)',
            backgroundColor: function (context) {
              const value = context.dataset.data[context.dataIndex]
              return value >= 0 ? 'rgba(4, 120, 87, 0.8)' : 'rgba(220, 38, 39, 0.8)'
            }
          }]
        }
      }
      return { labels: [], datasets: [] }
    },
    months () {
      return moment.months()
    },
    portfolioId () {
      return this.$route.params.id
    },
    years () {
      const years = []
      if (!this.selectedPeriods?.length) return years
      const year = moment(this.selectedPeriods[0].startDate).clone()
      while (year.format('YYYY') <= moment().format('YYYY')) {
        years.push(
          parseInt(year.format('YYYY'), 10)
        )
        year.add(1, 'year')
      }
      return years.reverse()
    },
    previousYears () {
      const previous = this.years.filter(year => year < this.thisYear)
      if (previous.length === 1) return previous[0]
      else if (previous.length === 2) return previous[0] + ' & ' + previous[1]
      else if (previous.length > 2) return previous[0] + ', ' + previous[1] + ' & before'
      return null
    },
    mappedPeriods () {
      const periods = {}
      if (!this.performance.periods?.length) return periods
      for (const period of this.performance.periods) periods[moment(period.startDate).format('YYYY-MM-DD')] = period
      return periods
    },
    mappedYears () {
      const yearlyPeriods = {}
      if (!this.performance.yearlyPeriods?.length) return yearlyPeriods
      for (const yearlyPeriod of this.performance.yearlyPeriods) yearlyPeriods[moment(yearlyPeriod.startDate).format('YYYY')] = yearlyPeriod
      return yearlyPeriods
    }
  },
  mounted () {
    this.getPerformance()
    if (this.$route.query.year) {
      const year = Number(this.$route.query.year)
      if (this.years.includes(year)) this.currentYear = year
    } else {
      this.$router.push({ query: { ...this.$route.query, year: this.currentYear } })
    }
  },
  methods: {
    ...mapMutations(['SET_PERFORMANCE', 'SHOW_UPGRADE']),
    getPerformance () {
      this.loading = true
      this.$http.post(`/portfolio/${this.portfolioId}/performance`).then(res => {
        this.SET_PERFORMANCE(res.data)
        this.loading = false
      })
    },
    getData (month, year) {
      const date = moment().set({ year, month, date: 1 }).format('YYYY-MM-DD')
      return this.mappedPeriods[date]
    },
    formatValue (v) {
      return Math.round(v).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    updateHover (index) {
      this.currentIndex = index
    },
    removeHover () {
      this.currentIndex = null
    },
    selectYear (year) {
      this.$router.push({ query: { ...this.$route.query, year } })
      this.currentYear = year
    },
    showPeriod (period) {
      this.period = period
    },
    getYearlyPerformance (year) {
      return this.mappedYears[year]
    }
  }
}
</script>

<style lang="scss" scoped>
.underlinecolor {
  background-image: linear-gradient(90deg, #e67399, #f2a640);
  background-position: bottom;
  background-size: 100% 15%;
  background-repeat: no-repeat;
  padding-bottom: 6px;
}
</style>
