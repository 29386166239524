import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login'
import Appsumo from '../views/Appsumo'
import Asset from '../views/Asset/index'
import AssetHoldings from '../views/Asset/Holdings'
import AssetPrice from '../views/Asset/Price'
import AssetValuation from '../views/Asset/Valuation'
import AssetFundamentals from '../views/Asset/Fundamentals'
import AssetEarnings from '../views/Asset/Earnings'
import AssetNews from '../views/Asset/News'
import AssetDividends from '../views/Asset/Dividends'
import AssetInsiders from '../views/Asset/Insiders'
import Portfolios from '../views/Portfolios'
import Portfolio from '../views/Portfolio/index'
import PortfolioOverview from '../views/Portfolio/Overview'
import PortfolioTransactions from '../views/Portfolio/Transactions'
import PortfolioBenchmark from '../views/Portfolio/Benchmark'
import PortfolioPerformance from '../views/Portfolio/Performance'
import PortfolioAllocation from '../views/Portfolio/Allocation'
import PortfolioNews from '../views/Portfolio/News'
import PortfolioAddTransactions from '../views/Portfolio/AddTransactions.vue'
import PortfolioImport from '../views/Portfolio/Import/index.vue'
import PortfolioSettings from '../views/Portfolio/Settings.vue'
import Dcf from '../views/Dcf.vue'
import Screener from '../views/Screener/index'
import Settings from '../views/Settings/index'
import UserSettings from '../views/Settings/User'
import PreferencesSettings from '../views/Settings/Preferences'
import BillingSettings from '../views/Settings/Billing'
import IntegrationsSettings from '../views/Settings/Integrations'
import IntegrationSettings from '../views/Settings/Integration'
import Subscribe from '../views/Subscribe'
import api from '@/libs/api'
import fb from '@/libs/fb'
import ap from '@/libs/amplitude'

const routes = [
  {
    path: '/',
    alias: '/welcome',
    name: 'Home',
    redirect: '/portfolios',
    title: 'Beanvest - Portfolios',
    metaTags: [
      {
        name: 'description',
        content: 'Your stocks portfolios on Beanvest'
      },
      {
        property: 'og:description',
        content: 'Your stocks portfolios on Beanvest'
      }
    ]
  },
  {
    path: '/register',
    name: 'Register',
    beforeEnter (to, from, next) {
      window.location = 'https://beanvest.com/register'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      public: true,
      title: 'Beanvest - Sign in',
      metaTags: [
        {
          name: 'description',
          content: 'Sign in to your Beanvest account'
        },
        {
          property: 'og:description',
          content: 'Sign in to your Beanvest account'
        },
        {
          property: 'og:image',
          content: 'https://beanvest.com/img/screenshots/beanvest-overview.png'
        }
      ]
    }
  },
  {
    path: '/sumo/:code',
    alias: '/sumo',
    name: 'AppSumo',
    component: Appsumo,
    meta: {
      public: true,
      title: 'AppSumo',
      metaTags: [
        {
          name: 'description',
          content: 'Claim your AppSumo deal'
        },
        {
          property: 'og:description',
          content: 'Claim your AppSumo deal'
        },
        {
          property: 'og:image',
          content: 'https://beanvest.com/img/screenshots/beanvest-overview.png'
        }
      ]
    }
  },
  {
    path: '/subscribe',
    name: 'Subscribe',
    component: Subscribe,
    meta: {
      title: 'Beanvest - Subscribe to Premium',
      metaTags: [
        {
          name: 'description',
          content: 'Subscribe to a Premium Beanvest account'
        },
        {
          property: 'og:description',
          content: 'Subscribe to a Premium Beanvest account'
        }
      ]
    }
  },
  {
    path: '/portfolios',
    alias: '/portfolio',
    name: 'Portfolios',
    component: Portfolios,
    meta: {
      title: 'Beanvest - Stock portfolio',
      metaTags: [
        {
          name: 'description',
          content: 'Manage your stocks portfolios'
        },
        {
          property: 'og:description',
          content: 'Manage your stocks portfolios'
        },
        {
          property: 'og:image',
          content: 'https://beanvest.com/img/screenshots/beanvest-overview.png'
        }
      ]
    }
  },
  {
    path: '/portfolio/:id',
    name: 'Portfolio',
    component: Portfolio,
    meta: {
      title: 'Beanvest - My portfolio'
    },
    children: [
      {
        path: '/portfolio/:id',
        name: 'PortfolioOverview',
        component: PortfolioOverview,
        meta: {}
      },
      {
        path: '/portfolio/:id/transactions',
        name: 'PortfolioTransactions',
        component: PortfolioTransactions,
        meta: {}
      },
      {
        path: '/portfolio/:id/benchmark',
        name: 'PortfolioBenchmark',
        component: PortfolioBenchmark,
        meta: {}
      },
      {
        path: '/portfolio/:id/performance',
        name: 'PortfolioPerformance',
        component: PortfolioPerformance,
        meta: {}
      },
      {
        path: '/portfolio/:id/allocation',
        name: 'PortfolioAllocation',
        component: PortfolioAllocation,
        meta: {}
      },
      {
        path: '/portfolio/:id/import',
        name: 'PortfolioImport',
        component: PortfolioImport,
        meta: {}
      },
      {
        path: '/portfolio/:id/add',
        name: 'PortfolioAddTransactions',
        component: PortfolioAddTransactions,
        meta: {}
      },
      {
        path: '/portfolio/:id/news',
        name: 'PortfolioNews',
        component: PortfolioNews,
        meta: {}
      },
      {
        path: '/portfolio/:id/settings',
        name: 'PortfolioSettings',
        component: PortfolioSettings,
        meta: {}
      }
    ]
  },
  {
    path: '/dcf',
    name: 'Dcf',
    component: Dcf,
    meta: {
      title: 'Beanvest - Discounted Cash Flow'
    },
    children: [
      {
        path: '/dcf/:id',
        name: 'Dcf',
        component: Dcf,
        meta: {}
      }
    ]
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: {
      title: 'Beanvest - Settings'
    },
    redirect: '/settings/user',
    children: [
      {
        path: '/settings/user',
        name: 'UserSettings',
        component: UserSettings,
        meta: {}
      },
      {
        path: '/settings/preferences',
        name: 'PreferencesSettings',
        component: PreferencesSettings,
        meta: {}
      },
      {
        path: '/settings/billing',
        name: 'BillingSettings',
        component: BillingSettings,
        meta: {}
      },
      {
        path: '/settings/integrations',
        name: 'IntegrationsSettings',
        component: IntegrationsSettings,
        meta: {}
      },
      {
        path: '/settings/integrations/:id',
        name: 'IntegrationSettings',
        component: IntegrationSettings,
        meta: {}
      }
    ]
  },
  {
    path: '/asset/:id',
    name: 'Asset',
    component: Asset,
    meta: {
      title: 'Beanvest - Asset'
    },
    children: [
      {
        path: '/asset/:id',
        name: 'AssetFundamentals',
        component: AssetFundamentals,
        meta: {}
      },
      {
        path: '/asset/:id/holdings',
        name: 'AssetHoldings',
        component: AssetHoldings,
        meta: {}
      },
      {
        path: '/asset/:id/price',
        name: 'AssetPrice',
        component: AssetPrice,
        meta: {}
      },
      {
        path: '/asset/:id/earnings',
        name: 'AssetEarnings',
        component: AssetEarnings,
        meta: {}
      },
      {
        path: '/asset/:id/valuation',
        name: 'AssetValuation',
        component: AssetValuation,
        meta: {}
      },
      {
        path: '/asset/:id/news',
        name: 'AssetNews',
        component: AssetNews,
        meta: {}
      },
      {
        path: '/asset/:id/dividends',
        name: 'AssetDividends',
        component: AssetDividends,
        meta: {}
      },
      {
        path: '/asset/:id/insiders',
        name: 'AssetInsiders',
        component: AssetInsiders,
        meta: {}
      }
    ]
  },
  {
    path: '/screener',
    name: 'Beanvest - Stock Screener',
    component: Screener,
    meta: {
      title: 'Stock Screener',
      metaTags: [
        {
          name: 'description',
          content: 'Stock Screener for smart investors'
        },
        {
          property: 'og:description',
          content: 'Stock Screener for smart investors'
        }
      ]
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// This callback runs before every route change, including on page load.
router.beforeEach(async (to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags)
  // const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(
    (el) => el.parentNode.removeChild(el)
  )

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next()

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement('meta')

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key])
      })

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '')

      return tag
    })
    .forEach((tag) => document.head.appendChild(tag)) // Add the meta tags to the document head.

  const { default: store } = await import('../store')

  if (to.query.coupon) {
    store.commit('SET_COUPON', { coupon: to.query.coupon })
    store.commit('SHOW_UPGRADE', to.query.couon)
  }
  // Sudo on activate
  if (to.query.token) {
    await store.commit('SET_TOKEN', { token: to.query.token })
    const res = await api.get('/user')
    fb.init(res.data.email)
    if (ap) ap.setUser(res.data._id)
    await store.commit('SET_LOGGED_IN', {
      token: to.query.token,
      user: res.data
    })
    return next({ path: '/portfolios' })
  } else {
    if (store.state.user.email) fb.init(store.state.user.email)
    if (ap && store.state.user._id) ap.setUser(store.state.user._id)
  }
  const token = store.state.token || to.query.token

  const randomShow = Math.random() < 0.05
  if (to.query.upgrade && store.state.user.plan === 'free') await store.commit('SHOW_UPGRADE', to.query.upgrade)
  else if (randomShow && store.state.user.plan === 'free') await store.commit('SHOW_UPGRADE', 'random')

  if (to.meta.public && !token) return next()
  else if (token && ['/login', '/register', '/'].includes(to.path)) { return next({ path: '/portfolios' }) } else if (token && to.path.startsWith('/sumo')) { return next({ path: '/portfolios' }) } else if (!token && to.path !== '/login') return next({ path: '/login' })
  return next()
})

export default router
