import { createStore } from 'vuex'
import moment from 'moment'
import LogRocket from 'logrocket'
import fb from '@/libs/fb'
import ap from '@/libs/amplitude'

if (process.env.NODE_ENV === 'production') LogRocket.init('er0y15/stonkspace')

const STORAGE_KEY = 'beanvest'
const savedState = {}

function identifyUser (user) {
  if (user._id && process.env.NODE_ENV === 'production') {
    LogRocket.identify(user._id, {
      email: user.email,
      plan: user.plan
    })
  }
}

try {
  const storage = JSON.parse(localStorage.getItem(STORAGE_KEY))
  if (storage) {
    savedState.token = storage.token
    savedState.user = storage.user
    identifyUser(savedState.user)
  }
} catch (e) {}

export default createStore({
  state: Object.assign({
    loading: false,
    token: null,
    user: {},
    stock: {},
    stocks: [],
    price: [],
    portfolios: [],
    portfolio: {},
    transactions: [],
    dividends: [],
    benchmark: {
      data: [],
      labels: []
    },
    performance: {
      periods: []
    },
    notifications: [],
    news: [],
    showUpgrade: null,
    couponCode: null
  }, savedState),
  getters: {
    trialLeft: state => {
      if (state.user.plan === 'free') {
        return 14 - moment().diff(moment(state.user.createdAt), 'days')
      }
      return 0
    }
  },
  mutations: {
    SET_STOCK (state, stock) {
      state.stock = stock
    },
    SET_SEARCH (state, stocks) {
      state.stocks = stocks
    },
    SET_PRICE_HISTORY (state, price) {
      state.price = price
    },
    SET_TOKEN (state, { token }) {
      state.token = token
      localStorage.setItem(STORAGE_KEY, JSON.stringify({ token, user: state.user }))
    },
    SET_USER (state, { user }) {
      state.user = user
      localStorage.setItem(STORAGE_KEY, JSON.stringify({ token: state.token, user }))
      identifyUser(state.user)
      fb.init(state.user.email)
      ap.setUser(state.user._id)
    },
    SET_LOGGED_IN (state, { token, user }) {
      state.token = token
      state.user = user
      localStorage.setItem(STORAGE_KEY, JSON.stringify({ token, user }))
    },
    SET_LOGGED_OUT (state) {
      state.token = null
      state.user = { email: state.user.email }
      localStorage.setItem(STORAGE_KEY, JSON.stringify({ token: state.token, user: state.user }))
    },
    SET_PORTFOLIOS (state, portfolios) {
      state.portfolios = portfolios
    },
    SET_PORTFOLIO (state, portfolio) {
      state.portfolio = portfolio
    },
    SET_TRANSACTIONS (state, transactions) {
      state.transactions = transactions
    },
    SET_BENCHMARK (state, benchmark) {
      state.benchmark = benchmark
    },
    SET_PERFORMANCE (state, performance) {
      state.performance = performance
    },
    NOTIFY (state, notification) {
      state.notifications.push(notification)
    },
    HIDE_NOTIFICATION (state, id) {
      state.notifications.splice(id, 1)
    },
    SET_NEWS (state, news) {
      state.news = news
    },
    SET_DIVIDENDS (state, dividends) {
      state.dividends = dividends
    },
    SET_LOADING (state, loading) {
      state.loading = loading
    },
    SET_GROUP (state, groupId) {
      if (state?.portfolio?.settings) state.portfolio.settings.groupBy = groupId
    },
    SET_POSITION (state, positionId) {
      if (state?.portfolio?.settings) state.portfolio.settings.positionBy = positionId
    },
    SHOW_UPGRADE (state, sourceId) {
      state.showUpgrade = sourceId
    },
    SET_COUPON (state, { coupon }) {
      state.couponCode = coupon
    }
  },
  actions: {
  },
  modules: {
  }
})
