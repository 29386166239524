<template>
  <div class="flex justify-between items-center max-w-xl" @click="toggle()">
    <h2 class="text-gray-400" v-if="text">{{ text }}</h2>
    <div class="m-2 w-12 h-6 flex items-center bg-gray-300 rounded-full p-1 duration-300 ease-in-out cursor-pointer" :class="{ 'bg-emerald-700 hover:bg-emerald-600': active }">
      <div class="bg-white w-4 h-4 rounded-full shadow-md transform duration-300 ease-in-out" :class="{ 'translate-x-6': active }" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Toggle',
  props: {
    text: {
      type: String,
      default: null
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      active: this.isActive
    }
  },
  methods: {
    toggle () {
      this.active = !this.active
      this.$emit('change', this.active)
    }
  },
  watch: {
    isActive () {
      this.active = this.isActive
    }
  }
}
</script>
