<template>
  <header class="z-30 w-full py-4" v-if="token">
    <div class="container flex items-center justify-between mx-auto">
      <div class="flex items-center space-x-2 sm:space-x-6 rtl:space-x-reverse">
        <Logo class="sm:mr-6 hidden md:inline-flex" />
        <img src="@/assets/images/bean.svg" class="w-14 inline-flex md:hidden" alt="Beanvest" />
        <div class="mt-2 hidden md:inline-flex">
          <MenuSimple v-if="portfoliosOptions?.length" :options="portfoliosOptions" :bottomOption="bottomOption" name="Portfolios" :openHover="true" size="w-64" />
          <router-link to="/portfolios" v-else class="hover:text-emerald-500 block rounded-md py-2 px-3 text-sm font-medium text-gray-500">Portfolios</router-link>
        </div>
        <div class="mt-2 hidden md:inline-flex" v-if="toolsOptions.length">
          <MenuSimple :options="toolsOptions" name="Tools" :openHover="true" size="w-64" />
        </div>
        <Feedback class="mt-2 hidden md:inline-flex" />
      </div>
      <div class="flex items-center space-x-6 rtl:space-x-reverse">
        <ul class="relative hidden mr-4 space-x-8 md:inline-flex rtl:space-x-reverse text-sm font-medium items-center">
          <li class="w-64">
            <StockSearch :i="i" @select="selectStock" :rounded="true" :persist="false" />
          </li>
          <li>
            <MenuSimple :options="userOptions" :name="username" :openHover="true" size="w-36" />
          </li>
        </ul>
        <div class="inline-flex md:hidden">
          <StockSearch :i="i" @select="selectStock" :rounded="true" :persist="false" />
          <button class="flex-none px-2 btn btn-white btn-sm" @click="open = !open">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              aria-hidden="true"
              class="w-5 h-5">
              <line x1="3" y1="12" x2="21" y2="12"></line>
              <line x1="3" y1="6" x2="21" y2="6"></line>
              <line x1="3" y1="18" x2="21" y2="18"></line>
            </svg>
            <span class="sr-only">Open Menu</span>
          </button>
        </div>
      </div>
    </div>
    <!-- Mobile menu -->
    <nav v-if="open" class="lg:hidden" aria-label="Global">
      <div class="px-2 pt-2 pb-3 mt-2 shadow-lg space-y-1 sm:px-4 border-b bg-white mx-8 border-gray-300 border rounded-lg">
        <!-- Current: "bg-gray-100 text-gray-900", Default: "hover:bg-gray-50" -->
        <router-link
          v-for="route in routes"
          :key="route.id"
          class="hover:text-emerald-500 block rounded-md py-2 px-3 text-sm font-medium text-gray-900"
          :to="`/${route.id}`">
          {{ route.name }}
        </router-link>
        <div
          class="hover:text-red-400 block rounded-md py-2 px-3 text-sm font-medium text-gray-900">
          <a href="https://beanvest.com/help" target="_blank" class="text-gray-700 hover:text-red-400 cursor-pointer">
            Help Center
          </a>
        </div>
        <div
          class="hover:text-yelllow-500 block rounded-md py-2 px-3 text-sm font-medium text-gray-900">
          <a href="https://beanvest.com/changelog" target="_blank" class="text-gray-700 hover:text-yelllow-500 cursor-pointer">
            Changelog
          </a>
        </div>
        <div
          v-if="user.role === 'admin'"
          class="hover:text-emerald-500 block rounded-md py-2 px-3 text-sm font-medium text-gray-900">
          <a :href="adminUrl" target="_blank" class="text-gray-700 hover:text-emerald-500 cursor-pointer">
            Admin
          </a>
        </div>
        <div @click="logout()" class="hover:text-emerald-500 block bg-red-100 rounded-md py-2 px-3 text-sm font-medium text-red-500">
          Logout
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import Logo from '@/components/Logo'
import Feedback from '@/components/Feedback'
import MenuSimple from '@/components/MenuSimple'
import StockSearch from '@/components/StockSearch'
import { CogIcon, LifebuoyIcon, ArrowRightOnRectangleIcon, TableCellsIcon, LightBulbIcon, CalculatorIcon, ChatBubbleLeftEllipsisIcon } from '@heroicons/vue/24/outline'
import { mapState, mapMutations } from 'vuex'
import p from '../../package.json'

export default {
  name: 'Navbar',
  components: {
    Logo,
    Feedback,
    MenuSimple,
    StockSearch,
    LifebuoyIcon,
    CogIcon,
    TableCellsIcon,
    ArrowRightOnRectangleIcon,
    LightBulbIcon,
    CalculatorIcon,
    ChatBubbleLeftEllipsisIcon
  },
  async mounted () {
    this.listPortfolios()
  },
  data () {
    return {
      version: p.version,
      showMenu: false,
      loading: false,
      open: false,
      toolsOptions: [
        { name: 'Community', link: 'https://www.facebook.com/groups/beanvest', icon: ChatBubbleLeftEllipsisIcon },
        { name: 'DCF Valuation', to: '/dcf', icon: CalculatorIcon }
        // { name: 'Stock Screener', to: '/screener', icon: SearchCircleIcon, soon: true }
      ],
      routes: [{
        name: 'Portfolios',
        id: 'portfolio'
      }, {
        name: 'DCF Calculator',
        id: 'dcf'
      }, {
        name: 'Settings',
        id: 'settings'
      }],
      bottomOption: {
        name: 'View all portfolios',
        to: '/portfolios'
      },
      i: 0
    }
  },
  computed: {
    ...mapState(['user', 'token', 'portfolios']),
    adminUrl () {
      const baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8081' : 'https://beanvest.com/admin/'
      return `${baseUrl}?token=${this.token}`
    },
    username () {
      if (!this.user) return '-'
      if (this.user.first_name && this.user.first_name.length) return this.user.first_name
      return this.user.email
    },
    portfoliosOptions () {
      return this.portfolios.map(p => ({ name: p.name, to: `/portfolio/${p._id}` }))
    },
    userOptions () {
      const options = [{
        name: 'Settings',
        to: '/settings',
        icon: CogIcon
      }, {
        name: 'Help',
        link: 'https://beanvest.com/help',
        icon: LifebuoyIcon,
        hovercolor: 'text-red-400'
      }, {
        name: 'Changelog',
        link: 'https://beanvest.com/changelog',
        icon: LightBulbIcon,
        hovercolor: 'text-yellow-500'
      }, {
        name: 'Logout',
        action: this.logout,
        icon: ArrowRightOnRectangleIcon
      }]
      if (this.user.role === 'admin') options.unshift({ name: 'Admin', link: this.adminUrl, icon: TableCellsIcon })
      return options
    }
  },
  methods: {
    ...mapMutations(['SET_PORTFOLIOS', 'SET_LOGGED_OUT']),
    logout () {
      this.SET_LOGGED_OUT()
      this.showMenu = false
      this.open = false
      this.$router.push('/login')
    },
    listPortfolios () {
      this.loading = true
      return this.$http.get('/portfolio').then(res => {
        this.SET_PORTFOLIOS(res.data)
        this.loading = false
      })
    },
    selectStock (stock) {
      if (stock && stock._id) this.$router.push(`/asset/${stock._id}`)
      this.i++
    }
  },
  watch: {
    $route () {
      this.showMenu = false
      this.open = false
    }
  }
}
</script>

<style lang="scss">
  #HW_badge_cont {
    position: absolute !important;
    top: -8px;
    left: -30px;
  }
  .bg-brown {
    background: #edebe7;
  }
</style>
