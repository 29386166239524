<template>
  <div class="w-full xs:w-auto max-w-5xl xs:max-w-2xl mx-auto">
    <dl class="grid grid-cols-1 rounded-lg bg-white shadow divide-y divide-gray-200 xs:grid-cols-2 xs:divide-y-0 xs:divide-x mx-4 xs:mx-2">
      <div v-for="item in stats" :key="item.name" class="px-2 py-3 sm:px-5 sm:py-4">
        <dt class="text-sm font-normal text-gray-700 mb-2">
          {{ item.name }}
          <Tooltip v-if="item.tooltip" :text="item.tooltip" />
        </dt>
        <dd class="mt-1 flex flex-col justify-between items-baseline md:block lg:flex text-center">
          <div
            class="flex items-baseline text-xl font-semibold mb-2 text-center mx-auto"
            :class="[
              { 'text-emerald-600': item.changeType === 'increase' || item.change > 0 },
              { 'text-red-600': item.changeType === 'decrease' || item.change < 0 }
            ]">
            <span v-if="numberFormat?.symbolPosition === 'before'" class="mr-1 text-sm font-medium text-gray-500">
              {{ currencySymbol(item.currency) }}
            </span>
            {{ item.stat }}
            <span class="ml-2 text-sm font-medium text-gray-500" v-if="numberFormat?.symbolPosition === 'after'">
              {{ currencySymbol(item.currency) }}
            </span>
          </div>

          <div :class="[
            { 'bg-emerald-100 text-emerald-800': (item.changeType === 'increase' || item.change > 0) },
            { 'bg-red-100 text-red-800': (item.changeType === 'decrease' || item.change < 0)},
            'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0 text-center mx-auto'
          ]">
            <ArrowSmallUpIcon v-if="item.changeType === 'increase'" class="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-emerald-500" aria-hidden="true" />
            <ArrowSmallDownIcon v-else-if="item.changeType === 'decrease'" class="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500" aria-hidden="true" />
            <span class="sr-only"> {{ item.changeType === 'increase' ? 'Increased' : 'Decreased' }} by </span>
            {{ item.substat || item.change }}
          </div>
        </dd>
      </div>
    </dl>
  </div>
</template>

<script>
import { ArrowSmallDownIcon, ArrowSmallUpIcon } from '@heroicons/vue/24/solid'
import getSymbolFromCurrency from 'currency-symbol-map'
import format from '@/mixins/format'
import Tooltip from '@/components/Tooltip.vue'

export default {
  props: {
    portfolio: {
      type: Object
    },
    numberFormat: {
      type: Object
    }
  },
  components: {
    ArrowSmallDownIcon,
    ArrowSmallUpIcon,
    Tooltip
  },
  mixins: [format],
  computed: {
    stats () {
      return [
        {
          name: 'Total Value',
          tooltip: 'Total current value of your entire portfolio',
          stat: this.formatNumber(this.portfolio.currentValue, this.numberFormat, this.portfolio.currency, false),
          currency: this.portfolio.currency
        },
        {
          name: 'Total return',
          tooltip: 'Total return in percentage and total gain/loss of your portfolio<br>' +
          `<br>Realized gains: ${this.formatNumber(this.portfolio.gain.realizedv || 0, this.numberFormat, this.portfolio.currency)} (${this.portfolio.gain.realized >= 0 ? '+' : ''}${Math.round(this.portfolio.gain.realized / this.portfolio.total * 10000) / 100}%)` +
          `<br>Unrealized gains: ${this.formatNumber(this.portfolio.gain.unrealized || 0, this.numberFormat, this.portfolio.currency)} (${this.portfolio.gain.unrealized >= 0 ? '+' : ''}${Math.round(this.portfolio.gain.unrealized / this.portfolio.total * 10000) / 100}%)` +
          `<br>Dividends: ${this.formatNumber(this.portfolio.gain.dividend || 0, this.numberFormat, this.portfolio.currency)} (${this.portfolio.gain.dividend >= 0 ? '+' : ''}${Math.round(this.portfolio.gain.dividend / this.portfolio.total * 10000) / 100}%)` +
          `<br>Fees: ${this.formatNumber(this.portfolio.gain.fees || 0, this.numberFormat, this.portfolio.currency)} (${this.portfolio.gain.fees >= 0 ? '-' : ''}${Math.round(this.portfolio.gain.fees / this.portfolio.total * 10000) / 100}%)`,
          stat: this.percent ? (this.percent > 0 ? '+' : '') + (this.percent || 0) + '%' : '-',
          substat: (this.profit > 0 ? '+' : '') + this.formatNumber(this.profit || 0, this.numberFormat, this.portfolio.currency),
          change: this.profit
        } /* ,
        {
          name: 'Time-weighted return',
          stat: (this.percent > 0 ? '+' : '') + this.percent + '%',
          change: '4.05%',
          changeType: 'decrease'
        } */
      ]
    },
    profit () {
      return Math.round(this.portfolio.gain.total)
    },
    percent () {
      if (!this.portfolio.total) return 0
      return Math.round(this.profit / this.portfolio.total * 10000) / 100
    },
    totalCurrentValue () {
      return this.formatValue(this.portfolio.totalCurrentValue)
    }
  },
  methods: {
    getSymbolFromCurrency,
    currencySymbol (currency) {
      if (this.numberFormat.symbolType === 'name') return currency
      return this.getSymbolFromCurrency(currency) || currency
    }
  }
}
</script>
