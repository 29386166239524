<template>
  <div class="py-8 px-4 sm:px-6 lg:px-8">
    <nav class="flex justify-center" aria-label="Progress">
      <ol class="space-y-6">
        <li v-for="(step, i) in steps" :key="step.name">
          <div v-if="currentStep > i" @click="$emit('select', i)" class="group cursor-pointer">
            <span class="flex items-start">
              <span class="flex-shrink-0 relative h-5 w-5 flex items-center justify-center">
                <CheckCircleIcon class="h-full w-full text-emerald-600 group-hover:text-emerald-800" aria-hidden="true" />
              </span>
              <span class="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">{{ step.name }}</span>
            </span>
          </div>
          <div v-else-if="currentStep === i" @click="$emit('select', i)" class="flex items-start" aria-current="step">
            <span class="flex-shrink-0 h-5 w-5 relative flex items-center justify-center" aria-hidden="true">
              <span class="absolute h-4 w-4 rounded-full bg-emerald-200" />
              <span class="relative block w-2 h-2 bg-emerald-600 rounded-full" />
            </span>
            <span class="ml-3 text-sm font-medium text-emerald-600">{{ step.name }}</span>
          </div>
          <div v-else class="group">
            <div class="flex items-start">
              <div class="flex-shrink-0 h-5 w-5 relative flex items-center justify-center" aria-hidden="true">
                <div class="h-2 w-2 bg-gray-300 rounded-full" />
              </div>
              <p class="ml-3 text-sm font-medium text-gray-400">{{ step.name }}</p>
            </div>
          </div>
        </li>
      </ol>
    </nav>
  </div>
</template>

<script>
import { CheckCircleIcon } from '@heroicons/vue/24/outline'

export default {
  components: {
    CheckCircleIcon
  },
  props: {
    currentStep: {
      type: Number,
      default: () => 0
    },
    steps: {
      type: Array,
      default: () => []
    }
  }
}
</script>
