<template>
  <div class="bgdark min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-emeraldlight">
    <FullLoader v-if="loading" />
    <div class="w-full min-w-64 -mt-12 sm:-mt-32" v-else-if="sent">
      <div class="w-full px-4">
        <div class="mb-12">
          <EnvelopeIcon class="w-20 h-20 sm:w-32 sm:h-32 mx-auto mb-5 font-thin text-gray-700 opacity-10" />
          <h2 class="mt-2 text-center text-2xl sm:text-4xl leading-9 font-bold text-emerald-800">
            We sent you an e-mail
          </h2>
          <h2 class="mt-2 sm:mt-6 text-center text-base sm:text-xl leading-9 font-light text-emerald-700 w-full xxl:w-4/12 mx-auto">
            Please check your e-mail {{ form.email }} and click on the link to sign in
          </h2>
        </div>
      </div>
    </div>
    <div class="w-full sm:w-3/12 xxl:w-1/5 min-w-64 sm:-mt-32" v-else>
      <a href="/">
        <ArrowLeftIcon class="w-8 h-8 absolute top-4 left-4 text-gray-400 hover:text-emerald-600" />
      </a>
      <img src="@/assets/images/bean.svg" class="w-20 mb-2 mx-auto" alt="Beanvest" />
      <div class="w-full">
        <div class="mb-12">
          <h1 class="mt-2 text-center text-2xl leading-9 font-light text-gray-900">
            Sign in to your account
          </h1>
          <p class="mt-2 text-center text-lg leading-5 text-gray-400">
            Welcome back
          </p>
        </div>
      </div>
      <div class="mt-8">
        <div v-if="error" class="text-red-600 mb-5">{{ error }}</div>
        <div v-if="lastEmail">
          <div class="px-3 py-3 flex items-center sm:px-4 bg-white shadow-md mb-4 rounded overflow-hidden sm:rounded-m">
            <div class="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
              <div class="ml-3 text-left">
                <p class="text-xs text-gray-400">Reconnect with:</p>
                <p class="text-sm font-medium text-gray-900">{{ lastEmail }}</p>
              </div>
            </div>
            <div class="ml-5 flex-shrink-0 text-gray-400 hover:text-emerald-600 cursor-pointer" @click="lastEmail = null">
              X
            </div>
          </div>
        </div>
        <div v-else>
          <span class="sr-only">Your Email</span>
          <input
            v-model="form.email"
            @keyup.enter="login()"
            class="mt-0 mb-1 border border-gray-300 focus:outline-none focus:border-emerald-600 px-3 py-2 w-full rounded"
            :class="[
              { 'border-red-500 focus:bg-white focus:border-red-500': error },
              { 'border-emerald-500 focus:bg-white focus:border-emerald-500': isValid }
            ]"
            type="email"
            placeholder="Email address"
            required="true" />
          </div>
        <button
          class="w-full mt-3 border-transparent col-auto transition-all duration-200 text-white px-4 py-2 rounded focus:outline-none focus:ring-4 focus:ring-emerald-500 focus:ring-opacity-50"
          :class="[
            { 'bg-emerald-600 hover:bg-emerald-700 active:bg-emerald-700': !isValid },
            { 'bg-emerald-500 hover:bg-emerald-600 active:bg-emerald-800': isValid }
          ]"
          @click="login">
          Sign In
        </button>
        <p class="mt-8 text-center text-xs text-gray-400">
          You will receive a magic link in your e-mail to sign in to your account
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import FullLoader from '@/components/FullLoader'
import { ArrowLeftIcon, EnvelopeIcon } from '@heroicons/vue/24/outline'
const emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export default {
  name: 'Login',
  components: {
    FullLoader,
    ArrowLeftIcon,
    EnvelopeIcon
  },
  data () {
    return {
      form: {
        email: ''
      },
      error: null,
      loading: false,
      sent: false,
      lastEmail: null
    }
  },
  mounted () {
    if (this.user.email) this.lastEmail = this.user.email
  },
  computed: {
    ...mapState(['user']),
    isValid () {
      return emailReg.test(this.form.email)
    }
  },
  methods: {
    login (symbol) {
      if (this.lastEmail) this.form.email = this.lastEmail
      if (!this.form.email || this.form.email === '') {
        this.error = 'Please fill all fields to create an account'
        this.lastEmail = null
        return
      } else if (!emailReg.test(this.form.email)) {
        this.error = 'Please enter a valid email address'
        this.lastEmail = null
        return
      }
      this.error = null
      this.loading = true
      this.$http
        .post('/signin', this.form)
        .then(res => {
          this.sent = true
        })
        .catch(error => {
          if (error && error.response) {
            this.error = error.response.data || `[${error.response.status}] An error occured`
          } else this.error = error.status
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>
  .bgdark {
    background: #f5efe8;
  }
</style>
