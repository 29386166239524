<template>
  <div class="h-full">
    <div class="flex items-stretch m-auto" v-if="stock">
      <div class="flex-1 text-gray-700 text-center px-4 py-8 m-3 bg-white rounded-lg shadow">
        <div class="mb-6" v-if="chartdata">
          Last dividend for {{ stock.name }} is <span class="font-bold text-lg">{{ lastDividend.value }} {{ lastDividend.currency }}</span> per share on {{ date }}.
        </div>
        <Loader class="mx-auto my-24" v-if="loading" text="Loading dividends..." />
        <p class="text-gray-400 py-20 flex-1 text-center px-4 m-2" v-else-if="!dividends?.length">
          {{ stock.name }} has never paid a dividend yet
        </p>
        <Chart
          v-else-if="chartdata"
          type="bar"
          chartId="dividends-chart"
          :options="options"
          :chartdata="chartdata" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import Chart from '@/components/Chart'
import Loader from '@/components/Loader'
import moment from 'moment'

export default {
  name: 'Dividends',
  components: {
    Chart,
    Loader
  },
  data () {
    return {
      loading: true,
      options: {
        responsive: true,
        legend: {
          position: 'top',
          display: false
        },
        hover: {
          animationDuration: 0,
          enabled: false
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          position: 'average',
          callbacks: {
            title: function (tooltipItem, data) {
              return moment(data.labels[tooltipItem[0].index]).format('LL')
            },
            label: function (tooltipItem, data) {
              const value = data.datasets[0]?.data[tooltipItem.index]
              const currency = data.currencies[tooltipItem.index]
              return ` Dividend  ${value} ${currency}`
            },
            labelColor: function (tooltipItem, chart) {
              return {
                borderColor: '#3e885e',
                backgroundColor: '#3e885e'
              }
            }
          }
        },
        title: {
          display: false
        },
        animation: {
          animateScale: true,
          animateRotate: true
        },
        scales: {
          x: { grid: { color: '#eee' } },
          y: { grid: { color: '#eee' } }
        }
      }
    }
  },
  computed: {
    ...mapState(['stock', 'dividends']),
    stockId () {
      return this.$route.params.id
    },
    lastDividend () {
      return this.dividends[this.dividends.length - 1]
    },
    date () {
      return this.formatDate(this.lastDividend.date)
    },
    chartdata () {
      if (!this.dividends || !this.dividends.length) return null
      const labels = []
      const currencies = []
      const datasets = [{
        data: [],
        backgroundColor: [],
        pointRadius: 5,
        pointHoverRadius: 3,
        lineTension: 0,
        borderWidth: 0,
        pointBorderColor: 'rgba(0, 0, 0, 0)',
        borderColor: 'rgba(0, 0, 0, .05)',
        borderDashOffset: 2,
        borderDash: [10, 5],
        fill: false,
        showLine: true
      }]
      for (const dividend of this.dividends) {
        datasets[0].data.push(dividend.value)
        currencies.push(dividend.currency)
        datasets[0].backgroundColor.push('#3e885e')
        labels.push(dividend.date)
      }
      return {
        currencies,
        datasets,
        labels
      }
    }
  },
  async mounted () {
    await this.getDividends(this.stockId)
  },
  methods: {
    ...mapMutations(['SET_DIVIDENDS']),
    async getDividends (stockId) {
      this.loading = true
      const res = await this.$http.get(`/stock/${stockId}/dividends`)
      this.SET_DIVIDENDS(res.data)
      this.loading = false
    },
    formatDate (date) {
      return moment(date, 'YYYY-MM-DD').format('MMMM Do YYYY')
    }
  },
  watch: {
    async stockId () {
      await this.getDividends(this.stockId)
    }
  }
}
</script>
