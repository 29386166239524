<template>
  <div class="bg-white">
    <div class="mx-auto max-w-7xl px-4 lg:px-8">
      <h2 class="text-xl font-bold tracking-tight highlight sm:text-xl text-center mt-6 mb-3">What's included</h2>
      <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-10 lg:mx-0 lg:max-w-none lg:grid-cols-2 p-4 bg-slate-50 rounded-lg">
        <dl class="col-span-2 grid grid-cols-1 gap-x-3 gap-y-6 sm:grid-cols-2">
          <div v-for="feature in features" :key="feature.name">
            <dt class="text-base font-semibold leading-7 text-gray-900 flex">
              <div class="flex h-8 w-8 items-center justify-center rounded-lg bg-highlight mr-2">
                <component :is="feature.icon" class="h-5 w-5 text-white" aria-hidden="true" />
              </div>
              {{ feature.name }}
            </dt>
            <dd class="mt-2 text-sm text-gray-600">{{ feature.description }}</dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import { ArrowTrendingUpIcon, ChartBarIcon, QueueListIcon, RectangleStackIcon } from '@heroicons/vue/24/outline'

export default {
  name: 'Feedback',
  data () {
    return {
      features: [{
        name: 'Unlimited holdings',
        description: 'Create up to 30 portfolios with unlimited holdings',
        icon: RectangleStackIcon
      },
      {
        name: 'Portfolio Performance',
        description: 'Measure your portfolio performance. Compare it with indexes (ex. S&P 500)',
        icon: ArrowTrendingUpIcon
      },
      {
        name: 'Track 250,000 securities',
        description: 'Explore financial data on more than 250,000 stocks, ETFs, mutual funds...',
        icon: QueueListIcon
      },
      {
        name: 'Value Investing tools',
        description: 'Evaluate the potential of new investment with the DCF Calculator',
        icon: ChartBarIcon
      }]
    }
  },
  components: {
    RectangleStackIcon,
    ChartBarIcon,
    ArrowTrendingUpIcon,
    QueueListIcon
  },
  computed: {
    validMessage () {
      return this.message && this.message.length > 5
    }
  }
}
</script>

<style scoped>
.bg-highlight {
  background-image: linear-gradient(90deg,#e67399,#f2a640);
  color: #fff;
}
.highlight {
  padding: 0.2em 0.5em;
  border-radius: 0.8em 0.3em;
  background: transparent;
  background-image: linear-gradient(90deg,#e67399,#f2a640);
  color: #fff;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
</style>
