<template>
  <div class="h-full">
    <Loader class="my-24" v-if="loading" text="Loading performance..." />
    <div class="flex flex-col items-stretch m-auto" v-else-if="stock">
      <div class="flex-1 text-gray-700 text-center px-4 py-8 m-3 bg-white rounded-lg shadow">
        <div class="flex justify-between space-between mb-4 mx-2">
          <h2 class="text-base text-left font-medium text-gray-400 flex">
            {{ selectedNames.map(n => formatFieldName(n)).join(', ') || 'Revenue, Free Cash Flow & Net Income' }}
            <TrashIcon v-if="selectedNames?.length" class="ml-3 w-5 h-5 mr-2 text-gray-300 hover:text-red-600 cursor-pointer" @click="selectedNames = []" />
          </h2>
          <div class="flex items-center justify-between gap-4">
            <div class="flex items-center justify-between">
              <span class="isolate inline-flex rounded-md">
                <button v-for="(option, i) in periodsOptions" @click="showPeriod(option.period)" :key="i"
                  type="button" :class="[{
                    'rounded-l-md -ml-px': i === 0,
                    'rounded-r-md -ml-px': i === periodsOptions.length - 1,
                    '-ml-px': i !== 0 && i !== periodsOptions.length - 1,
                    'bg-emerald-600 text-white ring-emerald-600': period === option.period,
                    'bg-white text-gray-900 hover:text-emerald-600 hover:bg-emerald-50 ring-gray-300': period !== option.period,
                  }]" class="relative inline-flex items-center px-3 py-1.5 text-sm ring-1 ring-inset focus:z-10">
                  {{ option.name }}
                </button>
              </span>
            </div>
            <div class="flex items-center justify-between">
              <span class="isolate inline-flex rounded-md">
                <button v-for="(option, i) in yearsOptions" @click="showNbYears(option.nbYears)" :key="i"
                  type="button" :class="[{
                    'rounded-l-md -ml-px': i === 0,
                    'rounded-r-md -ml-px': i === yearsOptions.length - 1,
                    '-ml-px': i !== 0 && i !== yearsOptions.length - 1,
                    'bg-emerald-600 text-white ring-emerald-600': nbYears === option.nbYears,
                    'bg-white text-gray-900 hover:text-emerald-600 hover:bg-emerald-50 ring-gray-300': nbYears !== option.nbYears,
                  }]" class="relative inline-flex items-center px-3 py-1.5 text-sm ring-1 ring-inset focus:z-10">
                  {{ option.name }}
                </button>
              </span>
            </div>
          </div>
        </div>
        <Chart
          v-if="stock && chartdata"
          type="bar"
          chartId="fundamentals-chart"
          :options="chartOptions"
          :chartdata="chartdata" />
      </div>
      <!-- Table -->
      <div v-if="!loading" class="mt-8 mx-2 ">
        <div class="sm:hidden">
          <label for="tabs" class="sr-only">Select a tab</label>
          <select id="tabs" name="tabs" class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm rounded-md">
            <option v-for="option in options" :key="option" :selected="option">{{ option.split('_').join(' ') }}</option>
          </select>
        </div>
        <div class="hidden sm:block">
          <div class="border-b border-gray-200">
            <nav class="-mb-px flex space-x-6" aria-label="Tabs">
              <div @click="currentOption = option" v-for="option in options" :key="option" :href="option" :class="[currentOption === option ? 'border-emerald-500 text-emerald-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'cursor-pointer whitespace-nowrap py-3 px-1 border-b-2 font-medium text-xs']" :aria-current="currentOption === option ? 'page' : undefined">
                {{ option.split('_').join(' ') }}
              </div>
            </nav>
          </div>
        </div>
      </div>
      <div v-if="!loading" class="flex w-full items-stretch bg-white shadow rounded-lg">
        <div class="flex-1 text-gray-700 text-center">
          <div class="flex m-auto align-right">
            <Table
              :key="currentOption"
              @showLine="showData"
              v-if="tableData"
              :data="tableData"
              :columns="columns"
              :selectedNames="selectedNames" />
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import Chart from '@/components/Chart'
import Table from '@/components/Table'
import Loader from '@/components/Loader'
import moment from 'moment'
import { TrashIcon } from '@heroicons/vue/24/outline'

const format = (number, space = true) => {
  const min = 1e3
  // Alter numbers larger than 1k
  if (Math.abs(number) >= min) {
    const units = ['k', 'M', 'B', 'T']
    const order = Math.floor(Math.log(Math.abs(number)) / Math.log(1000))
    const unitname = units[(order - 1)]
    const num = Math.floor(number / 1000 ** order)
    // output number remainder + unitname
    return num + (space ? ' ' : '') + unitname
  }
  // return formatted original number
  return number.toLocaleString()
}

export default {
  name: 'Fundamentals',
  components: {
    Chart,
    Table,
    TrashIcon,
    Loader
  },
  data () {
    return {
      options: ['Balance_Sheet', 'Cash_Flow', 'Income_Statement'],
      currentOption: 'Balance_Sheet',
      period: 'yearly',
      selectedNames: [],
      nbYears: 5,
      yearsOptions: [
        { id: 1, name: '1Y', nbYears: 1 },
        { id: 5, name: '5Y', nbYears: 5 },
        { id: 10, name: '10Y', nbYears: 10 },
        { id: 20, name: '20Y', nbYears: 20 }
      ],
      periodsOptions: [
        { id: 'q', name: 'Quarterly', period: 'quarterly' },
        { id: 'y', name: 'Yearly', period: 'yearly' }
      ],
      colors: [
        '#1f5739',
        '#3e885e',
        '#49b97a',
        '#2d9669',
        '#4ab385',
        '#5dc396',
        '#70cda4',
        '#87dbb6',
        '#9de3c4'
      ]
    }
  },
  computed: {
    ...mapState(['stock', 'loading', 'user']),
    stockId () {
      return this.$route.params.id
    },
    tableData () {
      if (!this.stock.profile) return []
      const dates = this.selectedYears
        ?.filter(date => {
          if (new Date(date).getFullYear() <= (this.lastYear || new Date().getFullYear()) - this.nbYears) return false
          return true
        }) || []
      const lastDate = this.selectedYears[this.selectedYears.length - 1]
      const columns = Object.keys(this.stock.profile?.Financials?.[this.currentOption][this.period][lastDate])
      const data = []
      for (const c of columns) {
        const line = {}
        for (const d of dates) {
          if (!['date', 'filing_date', 'currency_symbol'].includes(c)) {
            line.name = this.formatFieldName(c)
            line.field = c
            const v = this.stock.profile?.Financials?.[this.currentOption][this.period][d][c]
            line[d] = v ? this.commarize(parseInt(v, 10)) : '-'
          }
        }
        if (Object.keys(line).length) data.push(line)
      }
      return data
    },
    chartdata () {
      if (!this.stock.profile) return null
      if (this.selectedNames?.length) {
        const labels = []
        const datasets = this.selectedNames.map(label => ({
          label,
          data: [],
          backgroundColor: [],
          pointRadius: 5,
          pointHoverRadius: 3,
          lineTension: 0,
          borderWidth: 0,
          pointBorderColor: 'rgba(0, 0, 0, 0)',
          borderColor: 'rgba(0, 0, 0, 0)',
          borderDashOffset: 0,
          borderDash: [10, 5],
          fill: false,
          showLine: true
        }))
        let dates = [...new Set([
          ...Object.keys(this.stock?.profile?.Financials?.Balance_Sheet[this.period]),
          ...Object.keys(this.stock?.profile?.Financials?.Cash_Flow[this.period]),
          ...Object.keys(this.stock?.profile?.Financials?.Income_Statement[this.period])
        ])]
        delete dates.name
        delete dates.field
        dates = dates?.filter(date => {
          if (new Date(date).getFullYear() <= (this.lastYear || new Date().getFullYear()) - this.nbYears) return false
          return true
        }).reverse() || []
        for (const date of dates) {
          labels.push(this.getDate(date))
        }
        for (const i in this.selectedNames) {
          for (const date of dates) {
            const number = this.stock?.profile?.Financials?.Balance_Sheet[this.period][date][this.selectedNames[i]] || this.stock?.profile?.Financials?.Cash_Flow[this.period][date][this.selectedNames[i]] || this.stock?.profile?.Financials?.Income_Statement[this.period][date][this.selectedNames[i]]
            datasets[i].data.push(Math.round(number))
            datasets[i].backgroundColor.push(this.colors[i] || '#9de3c4')
          }
        }
        return {
          datasets,
          labels
        }
      }
      const labels = []
      const datasets = [{
        label: 'Revenue',
        data: [],
        backgroundColor: [],
        pointRadius: 5,
        pointHoverRadius: 3,
        lineTension: 0,
        borderWidth: 0,
        pointBorderColor: 'rgba(0, 0, 0, 0)',
        borderColor: 'rgba(0, 0, 0, 0)',
        borderDashOffset: 0,
        borderDash: [10, 5],
        fill: false,
        showLine: true
      }, {
        label: 'Free Cash Flow',
        data: [],
        backgroundColor: [],
        pointRadius: 5,
        pointHoverRadius: 3,
        lineTension: 0,
        borderWidth: 0,
        pointBorderColor: 'rgba(0, 0, 0, 0)',
        borderColor: 'rgba(0, 0, 0, 0)',
        borderDashOffset: 0,
        borderDash: [10, 5],
        fill: false,
        showLine: true
      }, {
        label: 'Net Income',
        data: [],
        backgroundColor: [],
        pointRadius: 5,
        pointHoverRadius: 3,
        lineTension: 0,
        borderWidth: 0,
        pointBorderColor: 'rgba(0, 0, 0, 0)',
        borderColor: 'rgba(0, 0, 0, 0)',
        borderDashOffset: 0,
        borderDash: [10, 5],
        fill: false,
        showLine: true
      }]
      const dates = Object.keys(this.stock?.profile?.Financials?.Cash_Flow[this.period])?.filter(date => {
        if (new Date(date).getFullYear() <= (this.lastYear || new Date().getFullYear()) - this.nbYears) return false
        return true
      }).reverse() || []
      for (const date of dates) {
        const cf = this.stock?.profile?.Financials?.Cash_Flow[this.period][date]
        const inc = this.stock?.profile?.Financials?.Income_Statement[this.period][date]
        datasets[0].data.push(Math.round(inc.totalRevenue))
        datasets[0].backgroundColor.push('#1f5739')
        datasets[1].data.push(Math.round(cf.freeCashFlow))
        datasets[1].backgroundColor.push('#3e885e')
        datasets[2].data.push(Math.round(inc.netIncome))
        datasets[2].backgroundColor.push('#49b97a')
        labels.push(this.getDate(cf.date || inc.date))
      }
      return {
        datasets,
        labels
      }
    },
    chartOptions () {
      const formatFieldName = c => c.charAt(0).toUpperCase() + c.slice(1).replace(/([a-z])([A-Z])/g, '$1 $2')
      return {
        responsive: true,
        legend: {
          position: 'bottom',
          display: true
        },
        hover: {
          animationDuration: 0,
          enabled: false
        },
        plugins: {
          tooltip: {
            mode: 'index',
            intersect: false,
            position: 'average',
            callbacks: {
              title: function (chart) {
                return chart[0].label
              },
              label: function (chart) {
                const value = chart.chart.data.datasets[chart.datasetIndex]?.data[chart.dataIndex]
                const fieldName = formatFieldName(chart?.dataset?.label || '')
                return ` ${fieldName} ${format(value)}`
              }
            }
          }
        },
        title: {
          display: false
        },
        animation: {
          animateScale: true,
          animateRotate: true
        },
        scales: {
          x: {
            grid: { color: '#eee' }
          },
          y: {
            grid: { color: '#eee' },
            ticks: {
              fontColor: '#8f9092',
              callback: function (value) {
                return format(value)
              }
            }
          }
        }
      }
    },
    columns () {
      if (!this.stock.profile) return []
      const currency = this.stock.profile?.Financials?.[this.currentOption]?.currency_symbol
      const cols = this.selectedYears
        .filter(date => {
          if (new Date(date).getFullYear() <= (this.lastYear || new Date().getFullYear()) - this.nbYears) return false
          return true
        })
        .map(value => ({ key: value, value: this.getDate(value) }))
      cols.unshift({ key: 'name', value: 'All values in ' + currency })
      return cols
    },
    selectedYears () {
      if (!this.stock?.profile?.Financials?.Balance_Sheet) return []
      const dates = [...new Set([
        ...Object.keys(this.stock?.profile?.Financials?.Balance_Sheet[this.period]),
        ...Object.keys(this.stock?.profile?.Financials?.Cash_Flow[this.period]),
        ...Object.keys(this.stock?.profile?.Financials?.Income_Statement[this.period])
      ])]
      delete dates.name
      delete dates.field
      return dates.sort((a, b) => moment(b).diff(moment(a), 'days') > 0 ? -1 : 1)
    },
    lastYear () {
      if (!this.selectedYears.length) return new Date().getFullYear()
      return new Date(this.selectedYears[this.selectedYears.length - 1]).getFullYear()
    }
  },
  methods: {
    ...mapMutations(['SHOW_UPGRADE']),
    commarize (number) {
      return format(number, false)
    },
    getDate (date) {
      const quarter = Math.floor(new Date(date).getMonth() / 3 + 1)
      const year = new Date(date).getFullYear()
      return this.period === 'yearly' ? year : `Q${quarter} ${year}`
    },
    showData (data) {
      if (this.selectedNames.includes(data.field)) this.selectedNames = this.selectedNames.filter(n => n !== data.field)
      else {
        if (this.selectedNames?.length >= 2 && this.user.plan === 'free') this.SHOW_UPGRADE('multiple-charts')
        else this.selectedNames.push(data.field)
      }
    },
    showNbYears (nbYears) {
      if (nbYears > 5 && this.user.plan === 'free') this.SHOW_UPGRADE('nb-years')
      else this.nbYears = nbYears
    },
    showPeriod (period) {
      this.period = period
    },
    formatFieldName (c) {
      return c.charAt(0).toUpperCase() + c.slice(1).replace(/([a-z])([A-Z])/g, '$1 $2')
    }
  },
  watch: {
    chartdata () {
      if (!this.loading && this.stock && !this.stock.profile?.Earnings) this.$router.push(`/asset/${this.stockId}/earnings`)
    }
  }
}
</script>
