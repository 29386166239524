<template>
  <div>
    <Loader class="mx-auto mt-24" v-if="loading" text="Loading news..." />
    <div class="max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none text-left" v-else>
      <div v-for="post in news" :key="post.title" class="flex flex-col rounded-lg shadow-md hover:shadow-lg overflow-hidden">
        <div class="flex-shrink-0">
          <img class="h-48 w-full object-cover bg-gray-100" :src="post.image" />
        </div>
        <div class="flex-1 bg-white p-6 flex flex-col justify-between">
          <div class="flex-1">
            <a :href="`${post.url}?source=beanvest.com`" target="_blank" class="block mt-2">
              <p class="text-xl font-semibold text-gray-900">
                {{ post.title }}
              </p>
              <p class="mt-3 text-base text-gray-500">
                {{ post.text }}
              </p>
            </a>
          </div>
          <div class="flex space-x-1 text-sm text-gray-500 mt-4">
              <p class="text-sm font-medium text-emerald-600">
                {{ post.symbol }}
              </p>
              <span aria-hidden="true">
                &middot;
              </span>
              <time :datetime="post.publishedDate">
                {{ formatDate(post.publishedDate) }}
              </time>
              <span aria-hidden="true">
                &middot;
              </span>
              <span class="text-sm">{{ post.site }}</span>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import moment from 'moment'
import Loader from '@/components/Loader'

export default {
  name: 'News',
  components: {
    Loader
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState(['news', 'portfolio'])
  },
  mounted () {
    this.getNews()
  },
  methods: {
    ...mapMutations(['SET_NEWS']),
    getNews () {
      this.loading = true
      if (!this.portfolio.lines || !this.portfolio.lines.length) return
      const symbols = [...new Set(this.portfolio.lines.map(s => s.symbol))]
      this.$http.post('/stock/news', { symbols }).then(res => {
        this.SET_NEWS(res.data)
        this.loading = false
      })
    },
    formatDate (date) {
      return moment(date, 'YYYYMMDD').fromNow()
    }
  }
}
</script>
