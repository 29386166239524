<template>
  <div class="mx-auto flex">
    <div
      class="mx-auto flex flex-row rounded-lg bg-gray-100 shadow divide-y divide-gray-200 sm:divide-y-0 sm:gap-px">
      <div v-for="(item, i) in stats" :key="item.name + '-' + i" @click="navigateTo(item.link)" :class="[item.link ? 'hover:bg-emerald-50 duration-500 cursor-pointer' : '', i === 0 ? 'rounded-l-lg' : '', i === stats.length - 1 ? 'rounded-r-lg' : '', 'relative group bg-white p-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-emerald-500']">
        <dt class="text-xs font-normal text-gray-700 mb-2">
          {{ item.name }}
          <Tooltip class="absolute top-1 right-1" v-if="item.tooltip" :text="item.tooltip" />
        </dt>
        <dd class="mt-1 flex flex-col justify-between items-baseline md:block lg:flex text-center">
          <div
            class="flex items-baseline text-xl font-semibold mb-2 text-center mx-auto">
            {{ item.stat }}
            <span class="ml-2 text-sm font-medium text-gray-500" v-if="item.currency">
              {{ item.currency }}
            </span>
          </div>
          <div :class="[
            { 'bg-emerald-100 text-emerald-800': (item.changeType === 'increase' || item.change > 0) },
            { 'bg-red-100 text-red-800': (item.changeType === 'decrease' || item.change < 0)},
            'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0 text-center mx-auto'
          ]" v-if="item.change">
            <ArrowSmallUpIcon v-if="item.changeType === 'increase'" class="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-emerald-500" aria-hidden="true" />
            <ArrowSmallDownIcon v-else-if="item.changeType === 'decrease'" class="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500" aria-hidden="true" />
            <span class="sr-only"> {{ item.changeType === 'increase' ? 'Increased' : 'Decreased' }} by </span>
            {{ item.substat || item.change }}
          </div>
          <div class="text-xs font-medium md:mt-2 lg:mt-0 text-center mx-auto text-gray-400" v-else-if="item.substat">
            {{ item.substat }}
          </div>
        </dd>
      </div>
    </div>
  </div>
</template>

<script>
import { ArrowSmallDownIcon, ArrowSmallUpIcon } from '@heroicons/vue/24/solid'
import Tooltip from '@/components/Tooltip.vue'

export default {
  props: {
    stats: {
      type: Array
    }
  },
  components: {
    ArrowSmallDownIcon,
    ArrowSmallUpIcon,
    Tooltip
  },
  methods: {
    navigateTo (link) {
      if (!link) return
      this.$router.push(link)
    }
  }
}
</script>
