<template>
  <router-link to="/">
    <div :class="['logo', size]">
      <img src="@/assets/images/logo.svg" alt="Beanvest" />
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: 'normal'
    }
  }
}
</script>

<style scoped>
.logo {
  color: #44a575;
  overflow: hidden;
  display: inline-block;
}
.normal {
  width: 161px;
  height: 75px;
}
.logo.normal:hover img {
  transform: translate(0, -70px);
}
.large {
  width: 210px;
  height: 90px;
}
.logo.large:hover img {
  transform: translate(0, -90px);
}
</style>
