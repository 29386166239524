<template>
  <div class="flex items-center justify-center">
    <div class="relative">
      <div
        @click="open= !open"
        class="text-gray-500 cursor-pointer group rounded-md inline-flex items-center text-sm font-medium hover:text-emerald-500 focus:outline-none focus:ring-0"
        :class="{ 'text-emerald-600': open }">
        Feedback
        <ChevronDownIcon :class="[open ? 'text-emerald-600' : 'text-gray-300', 'ml-2 h-4 w-4 group-hover:text-emerald-500']" aria-hidden="true" />
      </div>
      <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
        <div
          v-if="open"
          class="w-96 flex flex-col space-y-2 px-3 pt-1 pb-2 absolute mt-8 z-20 w-[350px] min-h-[180px] top-0 left-0 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <HandThumbUpIcon class="text-emerald-600 w-12 mx-auto mt-8 cursor-pointer" v-if="success" />
          <div class="text-emerald-600 mt-2 cursor-pointer mb-12 p-5" v-if="success" @click="open = false">
            Thanks for your feedback!
          </div>
          <div v-else>
            <div class="text-gray-800 text-lg mt-4">
              Submit your feedback!
            </div>
            <div class="text-gray-400 my-2">
              Request new features, submit bugs, send some love, ...
            </div>
            <div id="form"
              @submit.prevent="submitForm($event.currentTarget)" class="needs-validation" novalidate>
              <div class="mb-1">
                <textarea v-model="message" name="message"
                  class="w-full min-h-[100px] max-h-[300px] h-28 border-2 border-gray-200 bg-gray-50 rounded-md p-2 focus:outline-none focus:ring-4 focus:ring-emerald-50 focus:border-emerald-600"
                  placeholder="Let us know what you think..."></textarea>
              </div>
              <div class="flex justify-end space-x-2 py-2">
                <button
                  @click="open = false"
                  type="cancel"
                  class="flex justify-center w-24 px-2 py-2 text-gray-400 bg-gray-200 rounded-md focus:outline-none focus:ring focus:ring-red-300 hover:bg-red-100 hover:text-red-300">
                  Cancel
                </button>
                <button
                  @click="submitForm"
                  type="submit"
                  class="flex justify-center w-24 px-2 py-2 text-white rounded-md focus:outline-none focus:ring"
                  :class="{ 'bg-emerald-500 focus:ring-emerald-400 hover:bg-emerald-600': validMessage, 'bg-gray-500 cursor-not-allowed': !validMessage  }"
                  :disabled="submitting" id="submit">
                  <svg v-if="success" class="w-6 h-6">
                    <use href="#success" /></svg>
                  <svg v-if="error" class="w-6 h-6">
                    <use href="#error" /></svg>
                  <span>Send</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div class="hidden">
      <!-- Hidden SVG will show when it success -->
      <svg id="success" xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-white" width="24" height="24" fill="none"
        viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
          d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
      <svg id="error" xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-white" width="24" height="24" fill="none"
        viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
          d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
    </div>
  </div>
</template>

<script>
import { HandThumbUpIcon, ChevronDownIcon } from '@heroicons/vue/24/outline'

export default {
  name: 'Feedback',
  data () {
    return {
      message: null,
      open: false,
      files: null,
      submitting: false,
      success: false,
      error: false
    }
  },
  components: {
    HandThumbUpIcon,
    ChevronDownIcon
  },
  computed: {
    validMessage () {
      return this.message && this.message.length > 5
    }
  },
  methods: {
    submitForm () {
      if (!this.validMessage) return false
      this.submitting = true
      const message = this.message +
        '<br><br>_____' +
        '<br><br>Feedback submitted from page: ' + window?.location?.href +
        '<br><br>Browser/OS: ' + navigator?.appVersion
      this.$http
        .post('/feedback', { message })
        .then(res => {
          this.success = true
          setTimeout(() => {
            this.open = false
            this.error = false
            this.message = null
            this.success = false
            this.files = null
          }, 3000)
        })
        .catch(error => {
          if (error && error.response) {
            this.error = error.response.data || `[${error.response.status}] An error occured`
          } else this.error = error.status
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style>
  input[type="radio"]:checked+label {
      /*   @apply ring-1 ring-emerald-400  border-emerald-400; */
      --tw-border-opacity: 1;
      border-color: rgba(129, 140, 248, var(--tw-border-opacity));
      --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
      --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
      box-shadow: var(--tw-ring-offset-shadow),
          var(--tw-ring-shadow),
          var(--tw-shadow, 0 0 #0000);
      --tw-ring-opacity: 1;
      --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }

  .is-invalid,
  .was-validated :invalid,
  .was-validated :invalid~label {
      border-color: #dc3545;
  }

  .is-invalid,
  .was-validated :invalid:focus {
      --tw-ring-color: rgba(220, 53, 69, 0.2);
  }
</style>
