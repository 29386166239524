<template>
  <div
    class="bg-white overflow-hidden shadow rounded-lg mt-4"
    @drop="dropHandler"
    @dragover="dragOverHandler"
    @dragleave="dragLeaveHandler"
    @dragenter="dragEnterHandler">
    <div class="px-4 py-5 sm:p-6" @click="triggerUpload">
      <button type="button"
      class="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 hover:bg-gray-50 focus:outline-none"
      :class="[{ 'bg-emerald-50 text-emerald-500 border-emerald-600': dragOver }]">
        <CloudArrowUpIcon class="text-emerald-500 group-hover:text-gray-200 h-12 w-12 flex items-center justify-center mx-auto mb-8" aria-hidden="true" v-if="dragOver" />
        <ArrowUpTrayIcon class="text-gray-200 group-hover:text-gray-200 h-12 w-12 flex items-center justify-center mx-auto mb-8" aria-hidden="true" v-else />
        <span class="mt-2 block text-sm font-medium text-gray-900">
          Upload a .csv file containing your transactions
        </span>
        <input accept="text/csv,text/plain" id="hidden-input" type="file" class="hidden" @change="inputChange" />
        <button id="button"
          class="mt-4 rounded px-6 py-2 text-sm bg-gray-200 focus:shadow-outline focus:outline-none"
          :class="[{ 'bg-emerald-500 text-white': dragOver }]">
          <span v-if="dragOver">Upload this file</span>
          <span v-else>Select file</span>
        </button>
      </button>
    </div>
    <div class="p-2 mb-4 text-xs text-right mx-auto mr-5">
      <QuestionMarkCircleIcon class="w-5 h-5 mr-3 inline text-gray-300" />
      <a class="text-gray-600 font-bold hover:text-emerald-600" target="_blank" href="/help/csv-import">
        Need some help ?
      </a>
      <span class="mx-4 text-gray-300">|</span>
      <a class="text-gray-600 hover:text-emerald-600" target="_blank" href="/app/transactions-example.csv">Download an example .csv file</a>
    </div>
  </div>
</template>

<script>
import { CloudArrowUpIcon, ArrowUpTrayIcon } from '@heroicons/vue/24/outline'
import { QuestionMarkCircleIcon } from '@heroicons/vue/24/solid'

import { mapMutations } from 'vuex'

export default {
  name: 'ImportUpload',
  components: {
    CloudArrowUpIcon,
    ArrowUpTrayIcon,
    QuestionMarkCircleIcon
  },
  data () {
    return {
      dragOver: false
    }
  },
  methods: {
    ...mapMutations(['NOTIFY']),
    addFile (file) {
      const isCSV = file.type.includes('csv') || file.name.endsWith('.csv')
      if (!isCSV) {
        this.NOTIFY({ text: 'Please select a valid .csv file', type: 'error' })
        return false
      }
      this.$emit('filename', file.name)
      const reader = new FileReader()
      reader.readAsText(file, 'UTF-8')
      const t = this
      reader.onload = e => t.$emit('uploaded', e.target.result)
      reader.onerror = function (e) {
        // console.log(e)
        this.NOTIFY({ text: e, type: 'error' })
      }
    },
    inputChange (e) {
      if (!e || !e.target || !e.target.files) return false
      for (const file of e.target.files) {
        this.addFile(file)
      }
    },
    dropHandler (e) {
      if (!e || !e.dataTransfer || !e.dataTransfer.files) return false
      e.preventDefault()
      this.dragOver = false
      for (const file of e.dataTransfer.files) {
        this.addFile(file)
      }
    },
    dragOverHandler (e) {
      e.preventDefault()
      this.dragOver = true
    },
    dragLeaveHandler (e) {
      e.preventDefault()
      this.dragOver = false
    },
    dragEnterHandler (e) {
      e.preventDefault()
      this.dragOver = true
    },
    triggerUpload () {
      const hidden = document.getElementById('hidden-input')
      hidden.click()
    },
    hasFiles ({ dataTransfer: { types = [] } }) {
      return types.indexOf('Files') > -1
    }
  }
}
</script>
