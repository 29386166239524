<template>
  <Menu as="div" class="relative inline-block text-left">
    <MenuButton class="text-gray-500 group rounded-md inline-flex items-center text-sm font-medium hover:text-emerald-500 focus:outline-none focus:ring-0">
      <span>{{ name }}</span>
      <ChevronDownIcon class="text-gray-300 -mr-1 ml-2 h-4 w-4 group-hover:text-emerald-500" aria-hidden="true" />
    </MenuButton>
    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
      <MenuItems class="absolute z-10 transform mt-3 px-2 max-w-xs sm:px-0" :class="size">
        <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div class="relative grid gap-2 bg-white px-3 py-4 sm:gap-3 sm:p-3">
            <MenuItem
              as="div"
              @click="clickAction(item)"
              v-for="item in options"
              :key="item.name"
              class="group text-left cursor-pointer -m-2 p-2 flex items-start rounded-md hover:bg-gray-50"
              :class="[item.hovercolor ? `hover:text-gray-900` : 'hover:text-emerald-500']">
              <component v-if="item.icon" :is="item.icon"
                class="flex-shrink-0 h-5 w-5 text-gray-300 mr-1"
                :class="[$route.path.startsWith(item.to) ? 'text-emerald-600 font-bold': '', item.hovercolor ? `group-hover:${item.hovercolor}` : 'group-hover:text-emerald-500']"
                aria-hidden="true" />
              <p class="ml-2 text-sm font-medium" :class="[$route.path.startsWith(item.to) ? 'text-emerald-600 font-bold': '']">
                {{ item.name }}
              </p>
              <div v-if="item.new" class="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800">new</div>
              <div v-if="item.soon" class="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-700">coming soon</div>
              <p v-if="item.description" class="mt-1 text-sm text-gray-500">
                {{ item.description }}
              </p>
            </MenuItem>
          </div>
          <div v-if="bottomOption && bottomOption.name" class="px-3 py-3 bg-gray-50 w-full ">
            <div class="flow-root">
              <div @click="openLink(bottomOption.to)" class="w-full text-left cursor-pointer -m-2 p-2 flex items-center rounded-md text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-emerald-600 transition ease-in-out duration-150">
                <Bars4Icon class="flex-shrink-0 h-4 w-4 text-gray-400" aria-hidden="true" />
                <span class="ml-3">{{ bottomOption.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import { ChevronDownIcon, Bars4Icon } from '@heroicons/vue/24/outline'

export default {
  components: {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    ChevronDownIcon,
    Bars4Icon
  },
  props: {
    name: {
      type: String,
      default: '-'
    },
    options: {
      type: Array,
      default: () => []
    },
    bottomOption: {
      type: Object,
      default: () => {}
    },
    showChevron: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'w-full'
    }
  },
  data () {
    return {
      open: false
    }
  },
  methods: {
    openLink (link) {
      this.open = false
      this.$router.push(link)
    },
    clickAction (item) {
      if (item.action) item.action()
      else if (item.to) this.openLink(item.to)
      else if (item.link) window.open(item.link, '_blank').focus()
      this.$emit('select', item)
      this.open = false
    }
  }
}
</script>
