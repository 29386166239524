<template>
<div>
  <div class="w-full h-full md:flex">
    <Table
      v-if="portfolio && portfolio.lines"
      :data="lines"
      :currentIndex="currentIndex"
      :columns="columns"
      :overLimit="overLimit"
      :showTotal="true"
      :group="group" />
  </div>
</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Table from '@/components/Table'
import stock from '@/mixins/stock'
import format from '@/mixins/format'

export default {
  name: 'Overview',
  components: {
    Table
  },
  mixins: [stock, format],
  props: {
    currentTabIndex: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapState(['user', 'portfolio']),
    ...mapGetters(['trialLeft']),
    portfolioId () {
      return this.$route.params.id
    },
    overLimit () {
      if (!this.portfolio.lines) return false
      if (this.user.plan === 'free' && this.trialLeft < 0 && this.portfolio.lines?.length > 20) return this.portfolio.lines?.length - 20
      return false
    },
    lines () {
      let l = this.portfolio.lines
      if (!this.portfolio.settings.positionBy || this.portfolio.settings.positionBy === 'open') l = this.portfolio.lines.filter(l => l.units)
      else if (this.portfolio.settings.positionBy === 'closed') l = this.portfolio.lines.filter(l => !l.units)
      if (this.user.plan === 'free' && this.trialLeft < 0) return l.slice(0, 20)
      return l
    },
    group () {
      return this.portfolio.settings.groupBy || null
    },
    currentView () {
      return this.portfolio?.views?.[this.currentTabIndex]
    },
    columns () {
      const columns = this.currentView?.columns
      if (!this.currentView || !columns) return this.availableColumns.filter(c => c.default)
      const cols = []
      for (const name of columns) {
        const columnFormat = this.availableColumns.find(c => c?.key === name)
        if (columnFormat) cols.push(columnFormat)
      }
      return cols
    }
  },
  mounted () {
    /* if (this.user.beta) {
      this.columns.push({
        key: 'fair-value',
        value: 'Fair value',
        sort: l => Math.round((l.dcf - l.price) / l.price * 10000) / 100,
        map: l => l.dcf ? ('<div class="flex flex-col text-left">' +
          '<span class="text-gray-800">' +
          Math.round(l.dcf * 100) / 100 + ' ' + (l.currency || '$') +
          `</span><span class="mt-1 ${l.dcf - l.price > 0 ? 'text-green-500' : 'text-red-500'} text-xs">` +
          (l.dcf - l.price > 0 ? '+' : '') +
          Math.round((l.dcf - l.price) / l.price * 10000) / 100 + '% ' +
          '</span></div>') : '-'
      })
    } */
  },
  data () {
    return {
      showTransaction: false,
      showDelete: false,
      showDuplicate: false,
      editName: false,
      loading: false,
      currentIndex: null,
      availableColumns: [
        {
          key: 'image',
          value: '',
          map: (l, i) => `<a class="flex-shrink-0 h-10 w-10 flex items-center" href="${process.env.NODE_ENV === 'development' ? '' : '/app'}/asset/${l._id}">` +
            (l.image
              ? `<img class="rounded-md w-9 hover:opacity-90 hover:scale-105 transition delay-800 duration-400 cursor-pointer" src="${l.image}" alt="${l.name}">`
              : `<div class="bg-gray-100 text-gray-300 hover:bg-gray-200 hover:text-gray-500 w-10 h-10 hover:scale-105 transition delay-800 duration-400 cursor-pointer rounded-md inline-flex items-center justify-center"><span class="text-sm font-medium leading-none">${(l.name || l.symbol)?.charAt(0)?.toUpperCase()}</span></div>`) +
            '</a>',
          default: true
        },
        {
          key: 'name',
          value: 'Name',
          sort: l => l.name,
          map: (l, i) => `<a class="hover:text-green-600 hover:font-bold flex flex-col text-left" href="${process.env.NODE_ENV === 'development' ? '' : '/app'}/asset/${l._id}">${l.name || '-'}` +
            '<span class="mt-1 text-gray-400 text-xs">' +
            l.symbol + (l.isin ? ' · ' + l.isin : '') +
            '</span></a>',
          default: true
        },
        {
          key: 'gain',
          value: 'Total gain',
          sort: l => l.percent,
          map: l => '<div class="flex flex-col text-left">' +
            `<span class="mt-1 text-gray-400 ${l.gain.total >= 0 ? 'text-green-500' : 'text-red-500'}">` +
            (l.percent > 0 ? '+' : '') +
            (l.percent || 0) +
            `%</span><span class="mt-1 text-gray-400 text-xs ${l.gain.total >= 0 ? 'text-green-500' : 'text-red-500'}">` +
            (l.percent > 0 ? '+' : '') +
            this.formatNumber(l.gain.total, this.user.numberFormat, l.baseCurrency) +
            '</span></div>',
          default: true,
          summary: 'sum',
          summaryField: l => l.gain.total,
          summaryFormat: s => this.formatNumber(s, this.user.numberFormat, this.portfolio.currency)
        },
        {
          key: 'gain.realized',
          value: 'Realized gain',
          sort: l => l.gain.realized,
          map: l => '<div class="flex flex-col text-left">' +
            (l.gain.realized !== 0
              ? `<span class="mt-1 text-gray-400 ${l.gain.realized >= 0 ? 'text-green-500' : 'text-red-500'}">` +
              (l.gain.realized >= 0 ? '+' : '-') +
              (Math.abs(Math.round(l.gain.realized / this.portfolio.gain.total * 100)) / 100 || 0) +
              `%</span><span class="mt-1 text-gray-400 text-xs ${l.gain.realized >= 0 ? 'text-green-500' : 'text-red-500'}">` +
              (l.gain.realized > 0 ? '+' : '') +
              this.formatNumber(l.gain.realized, this.user.numberFormat, l.baseCurrency) +
              '</span>'
              : '-') +
            '</div>',
          summary: 'sum',
          summaryField: l => l.gain.realized,
          summaryFormat: s => this.formatNumber(s, this.user.numberFormat, this.portfolio.currency)
        },
        {
          key: 'gain.unrealized',
          value: 'Unrealized gain',
          sort: l => l.gain.unrealized,
          map: l => '<div class="flex flex-col text-left">' +
            (l.gain.unrealized !== 0
              ? `<span class="mt-1 text-gray-400 ${l.gain.unrealized >= 0 ? 'text-green-500' : 'text-red-500'}">` +
              (l.gain.unrealized >= 0 ? '+' : '-') +
              (Math.abs(Math.round(l.gain.unrealized / this.portfolio.gain.total * 100)) / 100 || 0) +
              `%</span><span class="mt-1 text-gray-400 text-xs ${l.gain.unrealized >= 0 ? 'text-green-500' : 'text-red-500'}">` +
              (l.gain.unrealized > 0 ? '+' : '') +
              this.formatNumber(l.gain.unrealized, this.user.numberFormat, l.baseCurrency) +
              '</span>'
              : '-') +
            '</div>',
          summary: 'sum',
          summaryField: l => l.gain.unrealized,
          summaryFormat: s => this.formatNumber(s, this.user.numberFormat, this.portfolio.currency)
        },
        {
          key: 'gain.dividend',
          value: 'Dividend gain',
          sort: l => l.gain.dividend,
          map: l => '<div class="flex flex-col text-left">' +
            (l.gain.dividend !== 0
              ? `<span class="mt-1 text-gray-400 ${l.gain.dividend >= 0 ? 'text-green-500' : 'text-red-500'}">` +
              (l.gain.dividend >= 0 ? '+' : '-') +
              (Math.abs(Math.round(l.gain.dividend / this.portfolio.gain.total * 100)) / 100 || 0) +
              `%</span><span class="mt-1 text-gray-400 text-xs ${l.gain.dividend >= 0 ? 'text-green-500' : 'text-red-500'}">` +
              (l.gain.dividend > 0 ? '+' : '') +
              this.formatNumber(l.gain.dividend, this.user.numberFormat, l.baseCurrency) +
              '</span>'
              : '-') +
            '</div>',
          summary: 'sum',
          summaryField: l => l.gain.dividend,
          summaryFormat: s => this.formatNumber(s, this.user.numberFormat, this.portfolio.currency)
        },
        {
          key: 'gain.currency',
          value: 'Currency gain',
          sort: l => l.gain.currency,
          map: l => '<div class="flex flex-col text-left">' +
            (l.gain.currency !== 0
              ? `<span class="mt-1 text-gray-400 ${l.gain.currency >= 0 ? 'text-green-500' : 'text-red-500'}">` +
              (l.gain.currency >= 0 ? '+' : '-') +
              (Math.abs(Math.round(l.gain.currency / this.portfolio.gain.total * 100)) / 100 || 0) +
              `%</span><span class="mt-1 text-gray-400 text-xs ${l.gain.currency >= 0 ? 'text-green-500' : 'text-red-500'}">` +
              (l.gain.currency > 0 ? '+' : '') +
              this.formatNumber(l.gain.currency, this.user.numberFormat, l.baseCurrency) +
              '</span>'
              : '-') +
            '</div>',
          summary: 'sum',
          summaryField: l => l.gain.currency,
          summaryFormat: s => this.formatNumber(s, this.user.numberFormat, this.portfolio.currency)
        },
        {
          key: 'gain.fees',
          value: 'Fees',
          sort: l => l.gain.fees,
          map: l => '<div class="flex flex-col text-left">' +
            (l.gain.fees !== 0
              ? `<span class="mt-1 text-gray-400 ${l.gain.fees <= 0 ? 'text-green-500' : 'text-red-500'}">` +
              (l.gain.fees >= 0 ? '-' : '+') +
              (Math.abs(Math.round(l.gain.fees / this.portfolio.gain.total * 100)) / 100 || 0) +
              `%</span><span class="mt-1 text-gray-400 text-xs ${l.gain.fees <= 0 ? 'text-green-500' : 'text-red-500'}">` +
              (l.gain.fees >= 0 ? '-' : '') +
              this.formatNumber(l.gain.fees, this.user.numberFormat, l.baseCurrency) +
              '</span>'
              : '-') +
            '</div>',
          summary: 'sum',
          summaryField: l => l.gain.fees,
          summaryFormat: s => this.formatNumber(-s, this.user.numberFormat, this.portfolio.currency)
        },
        /* {
          key: 'twr',
          value: 'Time-weighted return',
          sort: l => l.twr,
          map: l => '<div class="flex flex-col text-left">' +
            `<span class="mt-1 text-gray-400 ${l.twr >= 0 ? 'text-green-500' : 'text-red-500'}">` +
            (l.twr > 0 ? '+' : '') +
            Math.round(l.twr * 10000) / 100 + '%'
        }, */
        /* {
          key: 'daily-gain',
          value: 'Daily gain',
          sort: l => l.closePrice ? Math.round((l.price - l.closePrice) / l.closePrice * 100) / 100 : 0,
          map: l => (!l.closePrice || l.price === l.closePrice) ? '<span class="text-gray-400">-</span>' : '<div class="flex flex-col text-right">' +
            `<span class="mt-1 text-gray-400 ${Math.round((l.price - l.closePrice) / l.closePrice * 10000) / 100 >= 0 ? 'text-green-500' : 'text-red-500'}">` +
            (Math.round((l.price - l.closePrice) / l.closePrice * 10000) / 100 >= 0 ? '+' : '') +
            Math.round((l.price - l.closePrice) / l.closePrice * 10000) / 100 +
            `%</span><span class="mt-1 text-gray-400 ${Math.round((l.price - l.closePrice) / l.closePrice * 10000) / 100 >= 0 ? 'text-green-500' : 'text-red-500'}">` +
            (Math.round((l.baseCurrencyPrice - l.baseCurrencyClosePrice) / l.baseCurrencyClosePrice * 10000) / 100 >= 0 ? '+' : '') +
            Math.round((l.baseCurrencyPrice - l.baseCurrencyClosePrice) * l.units) + ' ' + (l.baseCurrency || '$') +
            '</span></div>'
        }, */
        {
          key: 'units',
          value: 'Shares',
          sort: l => l.units,
          map: l => this.formatNumber(l.units, this.user.numberFormat, l.baseCurrency, false),
          default: true
        },
        {
          key: 'price',
          value: 'Price',
          sort: l => l.baseCurrencyPrice,
          map: l => '<div class="flex flex-col text-left">' +
            '<span class="text-gray-800">' +
            this.formatNumber(l.price, this.user.numberFormat, l.currency) +
            '</span><span class="mt-1 text-gray-400 text-xs">' +
            this.formatNumber(l.baseCurrencyPrice, this.user.numberFormat, l.baseCurrency) +
            '</span></div>',
          default: true
        },
        {
          key: 'costPerShare',
          value: 'Cost per share',
          sort: l => l.baseCurrencyCostPerShare,
          map: l => '<div class="flex flex-col text-left">' +
            '<span>' +
            this.formatNumber(l.costPerShare, this.user.numberFormat, l.currency) +
            '</span>' +
            (l.baseCurrencyCostPerShare
              ? '<span class="mt-1 text-gray-400 text-xs xxl:text-sm">' +
              this.formatNumber(l.baseCurrencyCostPerShare, this.user.numberFormat, l.baseCurrency) +
              '</span>'
              : '') +
            '</div>',
          default: true
        },
        {
          key: 'baseCurrencyCurrentValue',
          value: 'Market Value',
          sort: l => l.baseCurrencyCurrentValue,
          map: l => '<div class="flex flex-col text-left">' +
            '<span>' +
            this.formatNumber(l.currentValue, this.user.numberFormat, l.currency) +
            '</span>' +
            (l.baseCurrencyCurrentValue
              ? '<span class="mt-1 text-gray-400 text-xs xxl:text-sm">' +
              this.formatNumber(l.baseCurrencyCurrentValue, this.user.numberFormat, l.baseCurrency) +
              '</span>'
              : '') +
            '</div>',
          default: true,
          summary: 'sum',
          summaryField: l => l.baseCurrencyCurrentValue,
          summaryFormat: s => this.formatNumber(s, this.user.numberFormat, this.portfolio.currency)

        },
        {
          key: 'percent',
          value: '% of portfolio',
          sort: l => Math.round(l.baseCurrencyCurrentValue / this.portfolio.currentValue * 1000) / 10,
          map: l => Math.round((l.baseCurrencyCurrentValue) / this.portfolio.currentValue * 1000) / 10 + '%',
          summary: 'sum',
          default: true,
          summaryField: l => Math.round((l.baseCurrencyCurrentValue) / this.portfolio.currentValue * 1000) / 10,
          summaryFormat: s => Math.round(s * 100) / 100 + '%'
        },
        {
          key: 'mktCapUSD',
          value: 'Market Cap',
          sort: l => l.mktCapUSD,
          map: l => l.mktCapUSD ? this.formatNumber(l.mktCapUSD, this.user.numberFormat, 'USD') : '-'
        },
        {
          key: 'mktCapCategory',
          value: 'Market Cap',
          sort: l => l.mktCapCategory,
          map: l => l.mktCapCategory
        },
        {
          key: 'country',
          value: 'Country',
          sort: l => l.country,
          map: l => l.country
        },
        {
          key: 'currency',
          value: 'Currency',
          sort: l => l.currency,
          map: l => l.currency
        },
        {
          key: 'symbol',
          value: 'Symbol',
          sort: l => l.symbol,
          map: l => l.symbol
        },
        {
          key: 'beta',
          value: 'Beta',
          sort: l => l.beta,
          map: l => l.beta ? this.formatNumber(parseFloat(l.beta, 10), this.user.numberFormat, '', false) : '-'
        },
        {
          key: 'type',
          value: 'Asset Type',
          sort: l => l.type,
          map: l => l.type ? l.type : '-'
        },
        {
          key: 'employees',
          value: 'Employees',
          sort: l => l.employees,
          map: l => l.employees ? this.formatNumber(parseInt(l.employees, 10), this.user.numberFormat, '', false) : '-'
        },
        {
          key: 'pe',
          value: 'PE',
          sort: l => l.pe,
          map: l => l.pe ? Math.round(l.pe) : '-'
        },
        {
          key: 'peg',
          value: 'PEG',
          sort: l => l.peg,
          map: l => l.peg ? this.formatNumber(parseFloat(l.peg, 10), this.user.numberFormat, '', false) : '-'
        },
        {
          key: 'dilutedEps',
          value: 'Diluted EPS',
          sort: l => l.dilutedEps,
          map: l => l.dilutedEps ? this.formatNumber(l.dilutedEps, this.user.numberFormat, l.currency) : '-'
        },
        {
          key: 'sector',
          value: 'Sector',
          sort: l => l.sector,
          map: l => l.sector
        },
        {
          key: 'industry',
          value: 'Industry',
          sort: l => l.industry,
          map: l => l.industry
        },
        {
          key: 'exchange',
          value: 'Exchange',
          sort: l => l.exchange,
          map: l => l.exchange
        },
        {
          key: 'targetPrice',
          value: 'Target Price',
          sort: l => l.targetPrice,
          map: l => l.targetPrice ? this.formatNumber(l.targetPrice, this.user.numberFormat, l.currency) : '-'
        },
        {
          key: 'ebitda',
          value: 'EBITDA',
          sort: l => l.ebitda,
          map: l => l.ebitda ? this.formatNumber(l.ebitda, this.user.numberFormat, l.currency) : '-'
        },
        {
          key: 'bookValue',
          value: 'Book Value',
          sort: l => l.bookValue,
          map: l => l.bookValue ? this.formatNumber(l.bookValue, this.user.numberFormat, l.currency) : '-'
        },
        {
          key: 'dividendYield',
          value: 'Dividend Yield',
          sort: l => l.dividendYield,
          map: l => l.dividendYield ? this.formatNumber(Math.round(l.dividendYield * 10000) / 100, this.user.numberFormat, '', false) + '%' : '-'
        },
        {
          key: 'profitMargin',
          value: 'Profit Margin',
          sort: l => l.profitMargin,
          map: l => l.profitMargin ? this.formatNumber(Math.round(l.profitMargin * 10000) / 100, this.user.numberFormat, '', false) + '%' : '-'
        },
        {
          key: 'operatingMargin',
          value: 'Operating Margin',
          sort: l => l.operatingMargin,
          map: l => l.operatingMargin ? this.formatNumber(Math.round(l.operatingMargin * 10000) / 100, this.user.numberFormat, '', false) + '%' : '-'
        },
        {
          key: 'roe',
          value: 'Return on Equity',
          sort: l => l.roe,
          map: l => l.roe ? this.formatNumber(Math.round(l.roe * 10000) / 100, this.user.numberFormat, '', false) + '%' : '-'
        },
        {
          key: 'roa',
          value: 'Return on Assets',
          sort: l => l.roa,
          map: l => l.roa ? this.formatNumber(Math.round(l.roa * 10000) / 100, this.user.numberFormat, '', false) + '%' : '-'
        },
        {
          key: 'revenue',
          value: 'Revenue',
          sort: l => l.revenue,
          map: l => l.revenue ? this.formatNumber(l.revenue, this.user.numberFormat, l.currency) : '-'
        },
        {
          key: 'revenuePerShare',
          value: 'Revenue per Share',
          sort: l => l.revenuePerShare,
          map: l => l.revenuePerShare ? this.formatNumber(l.revenuePerShare, this.user.numberFormat, l.currency) : '-'
        },
        {
          key: 'esgScore',
          value: 'ESG Score',
          sort: l => l.esgScore,
          map: l => l.esgScore ? this.formatNumber(Math.round(l.esgScore * 10000) / 100, this.user.numberFormat, '', false) + '%' : '-'
        },
        {
          key: 'analystRatings',
          value: 'Analysts Rating',
          sort: l => l.analystRatings,
          map: l => l.analystRatings ? l.analystRatings : '-'
        },
        {
          key: 'analystTargetPrice',
          value: 'Analysts Target Price',
          sort: l => l.analystTargetPrice,
          map: l => l.analystTargetPrice ? this.formatNumber(l.analystTargetPrice, this.user.numberFormat, l.currency) : '-'
        },
        {
          key: 'analystsFollowing',
          value: 'Number of Analysts',
          sort: l => l.analystsFollowing,
          map: l => l.analystsFollowing ? l.analystsFollowing : '-'
        },
        {
          key: 'percentInsiders',
          value: 'Insiders Ownership',
          sort: l => l.percentInsiders,
          map: l => l.percentInsiders ? this.formatNumber(Math.round(l.percentInsiders * 100) / 100, this.user.numberFormat, '', false) + '%' : '-'
        },
        {
          key: 'percentInstitutions',
          value: 'Institutions Ownership',
          sort: l => l.percentInstitutions,
          map: l => l.percentInstitutions ? this.formatNumber(Math.round(l.percentInstitutions * 100) / 100, this.user.numberFormat, '', false) + '%' : '-'
        },
        {
          key: 'shortRatio',
          value: 'Short Ratio',
          sort: l => l.shortRatio,
          map: l => l.shortRatio ? this.formatNumber(Math.round(l.shortRatio * 100) / 100, this.user.numberFormat, '', false) + '%' : '-'
        },
        {
          key: 'shortPercent',
          value: 'Short Percent',
          sort: l => l.shortPercent,
          map: l => l.shortPercent ? this.formatNumber(Math.round(l.shortPercent * 100) / 100, this.user.numberFormat, '', false) + '%' : '-'
        },
        {
          key: 'payoutRatio',
          value: 'Dividend Payout Ratio',
          sort: l => l.payoutRatio,
          map: l => l.payoutRatio ? this.formatNumber(Math.round(l.payoutRatio * 10000) / 100, this.user.numberFormat, '', false) + '%' : '-'
        }
      ]
    }
  },

  methods: {
    saveName () {
      if (!this.portfolio.name || this.portfolio.name.length <= 0) return
      return this.$http.patch(`/portfolio/${this.portfolioId}`, { name: this.portfolio.name }).then(res => {
        this.editName = false
      })
    },
    showIndex (index) {
      this.currentIndex = index
    }
  }
}
</script>
