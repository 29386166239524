<template>
  <div>
    <main class="max-w-7xl mx-auto pb-10 lg:py-12 lg:px-8 text-left">
      <div class="lg:grid lg:grid-cols-12 lg:gap-x-5">
        <aside class="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-2">
          <nav class="space-y-1">
            <router-link
              v-for="item in navigation"
              :key="item.id"
              :to="`/settings/${item.id}`"
              :class="[($route.path.startsWith(`/settings/${item.id}`)) ? 'text-emerald-600' : 'text-gray-900 hover:text-gray-900', 'group rounded-md px-3 py-2 flex items-center text-sm font-medium']"
              :aria-current="$route.path.startsWith(`/settings/${item.id}`) ? 'page' : undefined">
              <component :is="item.icon" :class="[$route.path.startsWith(`/settings/${item.id}`) ? 'text-emerald-500' : 'text-gray-400 group-hover:text-gray-500', 'flex-shrink-0 -ml-1 mr-3 h-6 w-6']" aria-hidden="true" />
              <span class="truncate">
                {{ item.name }}
              </span>
            </router-link>
          </nav>
        </aside>
        <div class="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
          <router-view />
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems
} from '@headlessui/vue'
import { MagnifyingGlassIcon } from '@heroicons/vue/24/solid'
import {
  CreditCardIcon,
  Bars3Icon,
  UserCircleIcon,
  SquaresPlusIcon,
  XMarkIcon,
  AdjustmentsVerticalIcon
} from '@heroicons/vue/24/outline'

const navigation = [
  { name: 'User', id: 'user', icon: UserCircleIcon },
  { name: 'Preferences', id: 'preferences', icon: AdjustmentsVerticalIcon },
  { name: 'Plan & Billing', id: 'billing', icon: CreditCardIcon },
  { name: 'Integrations', id: 'integrations', icon: SquaresPlusIcon }
]
export default {
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Bars3Icon,
    MagnifyingGlassIcon,
    XMarkIcon
  },
  setup () {
    return {
      navigation
    }
  }
}
</script>
