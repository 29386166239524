<template>
<div class="flex flex-col col-span-1 text-center bg-white rounded-lg shadow divide-y divide-gray-200">
  <div class="relative" v-if="showUpgrade">
    <img class="m-2 opacity-50" src="@/assets/images/performance-blur.png" alt="benchmark" />
    <div class="absolute top-10 sm:top-28 inset-x-0 max-w-lg mx-auto">
      <h2 class="text-center mb-3 text-xl sm:text-3xl md:text-4xl md:leading-relaxed font-semibold text-gray-900">
        Upgrade to Premium to show your Portfolio Benchmark
      </h2>
      <h3 class="text-gray-400 m-4">
        You will be able to see how your portfolio performs VS any index (for example S&P500)
      </h3>
      <div
        @click="SHOW_UPGRADE('benchmark')"
        class="max-w-2xl cursor-pointer">
        <div class="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 space-y-6 sm:p-10 sm:pt-6">
          <div class="rounded-md shadow">
            <div class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-500 cursor-pointer" aria-describedby="tier-standard">
              Upgrade
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <!-- Config -->
    <div class="w-full px-5 py-3 z-10">
      <Toggle class="absolute top-0 right-0 m-2" :text="percentText" @change="switchPercent" />
      <div class="w-1/4 flex p-1">
        <div class="bg-emerald-700 w-1 mr-2 rounded-full" />
        <div
          class="flex-initial bg-white items-center p-1 text-emerald-700">
          {{ portfolio.name }}
        </div>
      </div>
      <div class="w-full flex p-1" v-if="portfolio?.benchmark?.length">
        <div class="bg-emerald-500 w-1 mr-2 rounded-full" />
        <div class="p-1 text-emerald-500 text-left text-sm" v-for="(b, i) in portfolio.benchmark" :key="i">
          <span class="mr-2 ml-2 text-gray-300" v-if="i > 0">+</span>
          {{ b.name }}
          <span v-if="b.percent !== 100">({{ b.percent }}%)</span>
        </div>
      </div>
    </div>
    <!-- Benchmark chart -->
    <div id="benchmark-chart" class="content-center w-full mx-auto p-4">
      <Loader class="m-3" v-if="loading" text="Calculating historical benchmark..." />
      <LineChart
        v-else-if="chartdata"
        chartId="bench"
        type="LineWithLine"
        :chartdata="percent ? chartdataPercent : chartdata"
        :options="percent ? optionsPercent : options" />
    </div>
  </div>
</div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import Loader from '@/components/Loader'
import LineChart from '@/components/Chart'
import Toggle from '@/components/Toggle'
import moment from 'moment'

export default {
  name: 'Benchmark',
  components: {
    Loader,
    LineChart,
    Toggle
  },
  computed: {
    ...mapState(['portfolio', 'benchmark', 'user']),
    ...mapGetters(['trialLeft']),
    showUpgrade () {
      return this.user.plan === 'free' && this.trialLeft < 0
    },
    portfolioId () {
      return this.$route.params.id
    },
    benchmarkIds () {
      if (!this.portfolio.benchmark) return []
      return this.portfolio.benchmark.map(b => b.stockId)
    },
    totalPercent () {
      let tot = 0
      for (const b of this.benchmarks) {
        tot += Number(b.percent)
      }
      return Math.round(tot)
    },
    percentText () {
      return this.percent ? '%' : '$'
    },
    chartdata () {
      if (!this.benchmark) return null
      return {
        labels: this.benchmark.labels,
        datasets: [{
          // Invested
          data: this.benchmark.data[0],
          borderColor: 'rgba(156, 155, 152, 0.15)',
          fill: true,
          backgroundColor: 'rgb(237, 235, 230, 0.5)',
          lineTension: 0,
          borderWidth: 2
          // bezierCurve: false
        }, {
          // Portfolio
          data: this.benchmark.data[1],
          borderColor: '#047857',
          fill: true,
          backgroundColor: 'rgba(90, 103, 216, 0)'
        }, {
          // Benchmark
          data: this.benchmark.data[2],
          borderColor: '#11b981',
          fill: true,
          backgroundColor: 'rgb(235, 244, 255, 0)'
        }]
      }
    },
    chartdataPercent () {
      if (!this.benchmark) return null
      const portfolioData = []
      const benchmarkData = []
      for (const i in this.benchmark.data[1]) {
        portfolioData.push((this.benchmark.data[1][i] - this.benchmark.data[0][i]) / this.benchmark.data[0][i] * 100)
        benchmarkData.push((this.benchmark.data[1][i] - this.benchmark.data[2][i]) / this.benchmark.data[2][i] * 100)
      }
      return {
        labels: this.benchmark.labels,
        datasets: [{
          // Portfolio vs Investment
          data: portfolioData,
          borderColor: '#047857',
          fill: true,
          backgroundColor: 'rgb(237, 235, 230, 0.2)'
        }, {
          // Portfolio vs Benchmark
          data: benchmarkData,
          borderColor: '#11b981',
          fill: true,
          backgroundColor: 'rgba(17, 185, 129, 0.15)'
        }]
      }
    }
  },
  data () {
    const vm = this
    const baseOptions = {
      maintainAspectRatio: false,
      title: {
        display: false
      },
      legend: {
        display: false
      },
      hover: {
        mode: 'index'
      },
      elements: {
        lines: {
          tension: 0
        },
        point: {
          radius: 0,
          pointStyle: 'line'
        }
      },
      scales: {
        x: {
          grid: {
            color: 'rgba(226, 232, 240, 0.5)',
            drawBorder: true
          },
          ticks: {
            fontColor: '#718096',
            callback: function (value, index) {
              return moment(this.getLabelForValue(value)).format('LL')
            }
          }
        },
        y: {
          grid: {
            color: 'rgba(226, 232, 240, 0.5)',
            drawBorder: false
          },
          ticks: {
            fontColor: '#718096',
            min: 0,
            callback: function (value, index, values) {
              return `${vm.formatValue(value)} ${vm.user.currency}`
            }
          }
        }
      },
      scaleLabel: {
        display: false
      },
      responsiveAnimationDuration: 0,
      pointRadius: 0
    }
    return {
      loading: false,
      ids: [],
      benchmarks: [],
      error: null,
      percent: false,
      options: Object.assign({}, baseOptions, {
        plugins: {
          tooltip: {
            mode: 'index',
            intersect: false,
            position: 'average',
            callbacks: {
              title: function (value, a, b) {
                return moment(value.label).format('LL')
              },
              label: function (chart) {
                const i = chart.datasetIndex
                const names = {
                  0: 'Invested',
                  1: 'Portfolio',
                  2: 'Benchmark'
                }
                const value = chart.chart.data.datasets[chart.datasetIndex]?.data[chart.dataIndex]
                const invested = chart.chart.data.datasets[0]?.data[chart.dataIndex]
                const perf = Math.round((value - invested) / invested * 10000) / 100
                let label = ` ${names[i]}  ${vm.formatValue(value)} ${vm.user.currency}`
                if (i > 0 && !isNaN(perf) && perf !== 0) label += ` (${perf > 0 ? '+' : ''}${perf}%)`
                return label
              },
              labelColor: function (chart) {
                const i = chart.datasetIndex
                const colors = {
                  0: '#efeeea',
                  1: '#047857',
                  2: '#11b981'
                }
                return {
                  borderColor: colors[i],
                  backgroundColor: colors[i]
                }
              }
            }
          }
        }
      }),
      optionsPercent: Object.assign({}, baseOptions, {
        plugins: {
          tooltip: {
            mode: 'index',
            intersect: false,
            position: 'average',
            callbacks: {
              title: function (value) {
                return moment(value.label).format('LL')
              },
              label: function (chart) {
                const i = chart.datasetIndex
                const names = {
                  0: 'Portfolio vs Investment',
                  1: 'Portfolio vs Benchmark'
                }
                const value = chart.chart.data.datasets[chart.datasetIndex]?.data[chart.dataIndex]
                const perf = Math.round(value * 100) / 100
                return ` ${names[i]}  ${perf > 0 ? '+' : ''}${perf}%`
              },
              labelColor: function (chart) {
                const i = chart.datasetIndex
                const colors = {
                  0: '#047857',
                  1: '#11b981'
                }
                return {
                  borderColor: colors[i],
                  backgroundColor: colors[i]
                }
              }
            }
          }
        },
        scales: {
          x: {
            grid: {
              color: 'rgba(226, 232, 240, 0.5)',
              drawBorder: true
            },
            ticks: {
              fontColor: '#718096'
            }
          },
          y: {
            grid: {
              color: 'rgba(226, 232, 240, 0.5)',
              drawBorder: true
            },
            ticks: {
              fontColor: '#718096',
              callback: function (value, index, values) {
                return value > 0 ? `+${value}%` : `${value}%`
              }
            }
          }
        }
      })
    }
  },
  mounted () {
    this.getBenchmark()
  },
  methods: {
    ...mapMutations(['SET_BENCHMARK', 'SHOW_UPGRADE']),
    selectBenchmark (stock, i) {
      this.benchmarks[i].stockId = stock._id
      this.benchmarks[i].name = stock.name
    },
    getBenchmark () {
      if (this.showUpgrade) return false
      this.loading = true
      const params = { benchmark: this.portfolio.benchmark }
      this.$http.post(`/portfolio/${this.portfolioId}/benchmark`, params).then(res => {
        this.SET_BENCHMARK(res.data)
        this.loading = false
      })
    },
    formatValue (v) {
      return Math.round(v).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    switchPercent (toggled) {
      this.loading = true
      this.percent = toggled
      this.loading = false
    }
  },
  watch: {
    benchmarkIds (ids) {
      if (!Array.isArray(ids) || !Array.isArray(this.benchmarkIds) ||
        !ids.length === this.benchmarkIds.length ||
        !ids.every((val, index) => val === this.benchmarkIds[index])) {
        this.getBenchmark()
      }
      this.ids = ids
    }
  }
}
</script>

<style scoped>
#benchmark-chart {
  height: 80vh;
}
</style>
