<template>
  <div class="bg-white overflow-hidden shadow rounded-lg mt-4">
    <Loader v-if="loading" />
    <div class="py-2 px-4 flex items-center justify-between flex-wrap sm:flex-nowrap border-b border-gray-100" v-else>
      <div class="flex flex-col p-2">
        <h3 class="text-sm text-gray-500">
          Verify your data and finalize import
        </h3>
        <div v-if="nbTransactions - filteredTransactions?.length > 0" class="text-yellow-500 text-sm mt-1">{{ nbTransactions - filteredTransactions?.length }}/{{ nbTransactions }} transactions will be ignored</div>
      </div>
      <div class="ml-4 mt-2 flex-shrink-0">
        <button
          @click="importCSV"
          type="button"
          class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
          :class="[{ 'bg-emerald-600 hover:bg-emerald-700': filteredTransactions?.length > 0 }, { 'bg-gray-300': filteredTransactions?.length === 0 }]">
          <CheckCircleIcon v-if="nbTransactions" class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          <span v-if="filteredTransactions?.length">Import {{ filteredTransactions?.length }} transactions</span>
          <span v-else>Import transactions</span>
        </button>
      </div>
    </div>
    <Table
      :key="!!matchedStocks"
      v-if="filteredTransactions?.length"
      :data="filteredTransactions"
      :columns="columns" />
  </div>
</template>

<script>
import { CheckCircleIcon } from '@heroicons/vue/24/solid'
import Table from '@/components/Table'
import Loader from '@/components/Loader'
import moment from 'moment'

export default {
  name: 'ImportPreview',
  components: {
    CheckCircleIcon,
    Table,
    Loader
  },
  async mounted () {
    await this.matchStocks()
  },
  data () {
    return {
      loading: false,
      matchedStocks: null
    }
  },
  computed: {
    nbTransactions () {
      return this.payload.filter(l => l.stockId).length
    },
    payload () {
      if (this.matchedStocks) return this.matchedStocks
      const map = {}
      for (const m of this.mapping) map[m.name] = { name: m.mappedTo, format: m.options?.format?.format, mapping: m.options?.mapping }
      return this.data
        .map(line => {
          const mappedLine = { portfolioId: this.portfolioId }
          for (const field of Object.keys(line)) {
            const name = map[field].name
            if (name && name.length) {
              // Transform date to international format if it is not
              if (name === 'date') {
                const format = map[field].format
                mappedLine[name] = moment(line[field], format).format('YYYY-MM-DD')
              // Map field values
              } else if (['type', 'currency'].includes(name)) {
                const submapping = map[field].mapping
                const submap = {}
                for (const s of submapping) submap[s.name] = s.mappedTo
                mappedLine[name] = submap[line[field]]
              } else if (['units', 'price', 'fee'].includes(name)) {
                mappedLine[name] = Number(line[field])
                // replace 9,03 by 9.03
                if (isNaN(mappedLine[name])) mappedLine[name] = Number(line[field].replace(/,/gi, '.'))
                if (isNaN(mappedLine[name])) mappedLine[name] = 0
              } else mappedLine[name] = line[field]
            }
          }
          return mappedLine
        })
    },
    columns () {
      const fields = this.mapping
        .map(line => ({
          key: line.mappedTo,
          value: line.mappedTo,
          sort: l => l[line.mappedTo],
          map: (l, i) => `<span class="text-xs truncate">${l[line.mappedTo]}</span>`
        }))
        .filter(l => l.key && l.key.length)
      if (this.matchedStocks) {
        fields.unshift({
          key: 'name',
          value: 'Name',
          sort: l => !!l.stockId,
          map: (l, i) => (!l.stockId ? '<span class="text-red-500">Not found</span>' : '<div class="flex flex-col text-left">') +
            '<span class="text-xs text-gray-900 truncate">' +
            (l.matchedStock?.name || '-') +
            '</span><span class="mt-1 text-gray-400 text-xs">' +
            l.matchedStock?.symbol +
            '</span></div>'
        })
        fields.unshift({
          key: 'image',
          value: '',
          map: (l, i) => '<div class="flex-shrink-0 h-10 w-10">' +
            (l.matchedStock?.image
              ? `<img class="" src="${l.matchedStock.image}" alt="${l.matchedStock.name}">`
              : '<div class="bg-gray-200 h-8 w-8 rounded-full" />') +
            '</div>'
        })
      }
      return fields
    },
    filteredTransactions () {
      return this.payload.filter(s => {
        if (!s.stockId) return false
        for (const field of Object.keys(s)) {
          if (s[field] === 'ignore') return false
        }
        return true
      })
    }
  },
  props: {
    portfolioId: {
      type: String
    },
    filename: {
      type: String
    },
    mapping: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    async importCSV () {
      if (!this.nbTransactions) return false
      this.loading = true
      const t = this.filteredTransactions.map(l => {
        delete l.matchedStock
        l.source = this.filename
        return l
      })
      await this.importTransactions(t)
      this.$emit('created')
    },
    async importTransactions (transactions) {
      const chunkSize = 100
      for (let i = 0; i < transactions.length; i += chunkSize) {
        const chunk = transactions.slice(i, i + chunkSize)
        await this.$http.post('/transactions', chunk)
      }
      return true
    },
    async importChunk (chunk) {
      return this.$http.post('/transactions', chunk)
    },
    async matchStocks () {
      const res = await this.$http.post('/transactions/preview', this.payload)
      this.matchedStocks = res.data
    }
  }
}
</script>
